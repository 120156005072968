// import React, { useEffect, useState, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import AuthContext from "../Components/AuthProvider";
// import { Pagination, Tab, Tabs, Container, } from "react-bootstrap";
// import {
//     Card,
//     CardBody,
//     Col,
//     FormGroup,
//     Row,
//     Label,
//     Button,
//     Table
// } from "reactstrap";
// import { toast } from "react-toastify";

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSearch, faTruckPickup } from '@fortawesome/free-solid-svg-icons';
// import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
// import Select from 'react-select';
// import ReactLoading from 'react-loading';
// import moment from 'moment';
// import DatePicker from "react-datepicker";
// import Rate_Chart_Service from "../services/Rate_Chart_Service";
// import InviceService from "../services/InviceService";


// export default function E_Invoice_Results(props) {
//     const {
//         userId,
//         branchId,
//         companyid
//     } = useContext(AuthContext);


//     const navigate = useNavigate();
//     const { isAuthenticated } = useContext(AuthContext);

//     useEffect(() => {
//         if (!isAuthenticated) {
//             navigate(
//                 "/login?message=You need to be authenticated to access this page."
//             );
//         }
//     }, [isAuthenticated, navigate]);
//     const [activeTab, setActiveTab] = useState('success');



//     const initialSearchCriteria = {
//         companyId: companyid,
//         branchId: branchId,
//         startDate: moment(new Date()).format('YYYY-MM-DD'),
//         endDate: moment(new Date()).format('YYYY-MM-DD'),
//         PartyId: '',
//     };
//     const [parties, setParties] = useState([]);
//     const [selectedParty, setSelectedParty] = useState(null);
//     const [selectedPartyFailure, setSelectedPartyFailure] = useState(null);
//     const [searchCriteria, setSearchCriteria] = useState(initialSearchCriteria);
//     const [searchCriteriaFailure, setSearchCriteriaFailure] = useState(initialSearchCriteria);
//     const [loading, setLoading] = useState(false);
//     const [failureInvoices, setFailureInvoices] = useState([]);


//     const styles = {
//         overlay: {
//             position: 'fixed',
//             top: 0,
//             left: 0,
//             width: '100%',
//             height: '100%',
//             backgroundColor: 'rgba(255, 255, 255, 0.8)', // Adjust the opacity and color as needed
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             zIndex: 9999, // Ensure the overlay is above other elements
//         },
//     };


//     const findParties = async () => {
//         const partyResponse = await Rate_Chart_Service.getAllActiveParties(companyid, branchId);
//         const partyOptions = partyResponse.data.map(party => ({
//             value: party[0],
//             label: party[1]
//         }));
//         setParties(partyOptions);

//     };

//     useEffect(() => {
//         findParties();
//     }, []);


//     const [filteredData, setFilteredData] = useState([]);
//     const [currentPage, setCurrentPage] = useState(1);
//     const [currentPageFailure, setCurrentPageFailure] = useState(1);
    
//     const [itemsPerPage] = useState(10);

//     const indexOfLastItem = currentPage * itemsPerPage;
//     const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//     const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
//     const totalPages = Math.ceil(filteredData.length / itemsPerPage);

//     // Function to handle page change
//     const handlePageChange = (page) => {
//         if (page >= 1 && page <= totalPages) {
//             setCurrentPage(page);
//         }
//     };
//     const displayPages = () => {
//         const centerPageCount = 5;
//         const middlePage = Math.floor(centerPageCount / 2);
//         let startPage = currentPage - middlePage;
//         let endPage = currentPage + middlePage;

//         if (startPage < 1) {
//             startPage = 1;
//             endPage = Math.min(totalPages, centerPageCount);
//         }

//         if (endPage > totalPages) {
//             endPage = totalPages;
//             startPage = Math.max(1, totalPages - centerPageCount + 1);
//         }

//         return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
//     };





//     const indexOfLastItemFailure = currentPageFailure * itemsPerPage;
//     const indexOfFirstItemFailure = indexOfLastItemFailure - itemsPerPage;
//     const currentItemsFailure = failureInvoices.slice(indexOfFirstItemFailure, indexOfLastItemFailure);
//     const totalPagesFailure = Math.ceil(failureInvoices.length / itemsPerPage);

//     // Function to handle page change
//     const handlePageChangeFailure = (page) => {
//         if (page >= 1 && page <= totalPagesFailure) {
//             setCurrentPageFailure(page);
//         }
//     };




//     const displayPagesFailure = () => {
//         const centerPageCount = 5;
//         const middlePage = Math.floor(centerPageCount / 2);
//         let startPage = currentPageFailure - middlePage;
//         let endPage = currentPageFailure + middlePage;

//         if (startPage < 1) {
//             startPage = 1;
//             endPage = Math.min(totalPagesFailure, centerPageCount);
//         }

//         if (endPage > totalPages) {
//             endPage = totalPages;
//             startPage = Math.max(1, totalPagesFailure - centerPageCount + 1);
//         }

//         return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
//     };



//     const handleSearch = async (searchCriteria, screen) => {
//         setLoading(true);
//         try {
//             const response = await InviceService.getEinvoiceData(searchCriteria, screen);

//             if (screen === "success") {
//                 setFilteredData(response.data);
//             }
//             if (screen === "failure") {
//                 setFailureInvoices(response.data);
//             }

//         }
//         catch {
//             toast.error('Oops something went wrong!', {
//                 position: toast.POSITION.TOP_CENTER,
//                 autoClose: 700,
//             });
//         }
//         finally {
//             setLoading(false);
//         }
//     };


//     const handleReset = () => {
//         setSearchCriteria(initialSearchCriteria);
//         setSelectedParty(null);
//         handleSearch(initialSearchCriteria, "success");
//     };

//     const handleResetFailure = () => {
//         setSearchCriteriaFailure(initialSearchCriteria);
//         setSelectedPartyFailure(null);
//         handleSearch(initialSearchCriteria, "failure");
//     };

//     const formatDateTime = (value) => {
//         if (!value) {
//             return ""; // Return an empty string if value is empty or undefined
//         }

//         const date = new Date(value);
//         const day = date.getDate().toString().padStart(2, '0'); // Get the day of the month with leading zero
//         const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get the month (0-indexed, so add 1) with leading zero
//         const year = date.getFullYear(); // Get the year

//         // Construct the final formatted date string
//         const formattedDate = `${day}/${month}/${year}`;

//         return formattedDate;
//     };
   

//     const handlePartyChange = (selectedOption) => {
//         setSelectedParty(selectedOption);
//         setSearchCriteria({ ...searchCriteria, PartyId: selectedOption ? selectedOption.value : '' });
//     };
    
//     const handlePartyChangeFailure = (selectedOption) => {
//         setSelectedPartyFailure(selectedOption);
//         setSearchCriteriaFailure({ ...searchCriteriaFailure, PartyId: selectedOption ? selectedOption.value : '' });
//     };
    
//     return (
//         <>
//             {loading && (
//                 <div style={styles.overlay}>
//                     <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
//                 </div>
//             )}
//             <Container>
//                 <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '2%', paddingRight: '-20px' }}>
//                     <FontAwesomeIcon
//                         icon={faTruckPickup}
//                         style={{
//                             marginRight: '8px',
//                             color: 'black',
//                         }}
//                     />
//                     E-Invoice Management
//                 </h5>
//                 <Card style={{
//                     backgroundSize: 'cover',
//                     backgroundRepeat: 'no-repeat',
//                     backgroundPosition: 'center',
//                     display: 'flex',
//                     flexDirection: 'column',
//                     minHeight: '40vw',
//                     overflowX: "hidden",
//                 }}>
//                     <CardBody style={{ paddingBottom: '-5px' }}>
//                         <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
//                             <Tab eventKey="success" title="Success">
//                                 <>
//                                 <Row>
//                                     <Col md={3}>
//                                         <FormGroup>
//                                             <Label className="forlabel" for="branchId">Date From</Label>
//                                             <div>
//                                                 <DatePicker
//                                                     selected={searchCriteria.startDate ? new Date(searchCriteria.startDate) : null}
//                                                     wrapperClassName="custom-react-datepicker-wrapper"
//                                                     onChange={(date) => {
//                                                         if (date) {
//                                                             date.setHours(12, 0, 0, 0);
//                                                             const formattedDate = date.toISOString();
//                                                             setSearchCriteria({ ...searchCriteria, startDate: formattedDate });
//                                                         } else {
//                                                             setSearchCriteria({ ...searchCriteria, startDate: null });
//                                                         }
//                                                     }}
//                                                     dateFormat="dd/MM/yyyy"
//                                                     className="form-control border-right-0 inputField"
//                                                     customInput={<input style={{ width: '100%' }} />}
//                                                 />
//                                             </div>
//                                         </FormGroup>
//                                     </Col>
//                                     <Col md={3}>
//                                         <FormGroup>
//                                             <Label className="forlabel" for="branchId">Date To</Label>
//                                             <div>
//                                                 <DatePicker
//                                                     selected={searchCriteria.endDate ? new Date(searchCriteria.endDate) : null}
//                                                     wrapperClassName="custom-react-datepicker-wrapper"
//                                                     onChange={(date) => {
//                                                         if (date) {
//                                                             date.setHours(12, 0, 0, 0);
//                                                             const formattedDate = date.toISOString();
//                                                             setSearchCriteria({ ...searchCriteria, endDate: formattedDate });
//                                                         } else {
//                                                             setSearchCriteria({ ...searchCriteria, endDate: null });
//                                                         }
//                                                     }}
//                                                     dateFormat="dd/MM/yyyy"
//                                                     className="form-control border-right-0 inputField"
//                                                     customInput={<input style={{ width: '100%' }} />}
//                                                 />
//                                             </div>
//                                         </FormGroup>
//                                     </Col>
//                                     <Col md={3}>
//                                         <FormGroup>
//                                             <Label className="forlabel" for="branchId">Select Importer</Label>
//                                             <Select
//                                                 options={parties}
//                                                 placeholder="Select a party"
//                                                 isClearable
//                                                 value={selectedParty}
//                                                 onChange={handlePartyChange}
//                                                 styles={{
//                                                     control: (provided, state) => ({
//                                                         ...provided,
//                                                         border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
//                                                         boxShadow: 'none',
//                                                         '&:hover': {
//                                                             border: '1px solid #ccc'
//                                                         }
//                                                     }),
//                                                     indicatorSeparator: () => ({
//                                                         display: 'none'
//                                                     }),
//                                                     dropdownIndicator: () => ({
//                                                         display: 'none'
//                                                     }),
//                                                     placeholder: (provided) => ({
//                                                         ...provided,
//                                                         display: 'flex',
//                                                         color: 'gray',
//                                                     }),
//                                                 }}
//                                             />
//                                         </FormGroup>
//                                     </Col>
//                                     <Col md={3}>
//                                         <div className="text-center" style={{ marginTop: '30px' }}>
//                                             <Button
//                                                 color="primary"
//                                                 outline
//                                                 onClick={() => handleSearch(searchCriteria, "success")}
//                                                 className="mr-2"
//                                                 style={{ marginRight: '25px', fontWeight: 'bold' }}
//                                             >
//                                                 <FontAwesomeIcon icon={faSearch} style={{ marginRight: '5px' }} />
//                                                 SEARCH
//                                             </Button>
//                                             <Button
//                                                 color="danger"
//                                                 outline
//                                                 onClick={handleReset}
//                                                 className="mr-2"
//                                                 style={{ marginRight: '5px', fontWeight: 'bold' }}
//                                             >
//                                                 <FontAwesomeIcon icon={faSyncAlt} style={{ marginRight: '5px' }} />
//                                                 RESET
//                                             </Button>
//                                         </div>
//                                     </Col>
//                                 </Row>
//                                 <hr />

//                                 {filteredData && filteredData.length > 0 && (
//                                     <>
//                                         <div className="table-responsive mt-3">
//                                             <Table className="table table-bordered table-hover" style={{ border: '2px solid black' }}>
//                                                 <thead className="thead-dark bg-dark">
//                                                     <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px' }}>
//                                                         <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font', height: '60px' }}>Sr No</th>
//                                                         <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>IRN No</th>
//                                                         <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Date</th>
//                                                         <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Invoice No</th>
//                                                         <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Invoice Date</th>
//                                                         <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Party Name</th>
//                                                     </tr>
//                                                 </thead>
//                                                 <tbody>
//                                                     {currentItems.map((item, index) => (
//                                                         <tr key={item.id} className='text-center'>
//                                                             <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{((currentPage - 1) * itemsPerPage) + index + 1}</td>
//                                                             <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.irnNo}</td>
//                                                             <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{formatDateTime(item.oprationDate)}</td>
//                                                             <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.invoiceNO}</td>
//                                                             <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{formatDateTime(item.invoiceDate)}</td>
//                                                             <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.partyId}</td>
//                                                         </tr>
//                                                     ))}
//                                                 </tbody>
//                                             </Table>
//                                             <Pagination style={{ display: 'flex', justifyContent: 'center', color: 'gray' }}>
//                                                 <Pagination.First onClick={() => handlePageChange(1)} />
//                                                 <Pagination.Prev
//                                                     onClick={() => handlePageChange(currentPage - 1)}
//                                                     disabled={currentPage === 1}
//                                                 />
//                                                 <Pagination.Ellipsis />
//                                                 {displayPages().map((pageNumber) => (
//                                                     <Pagination.Item
//                                                         key={pageNumber}
//                                                         active={pageNumber === currentPage}
//                                                         onClick={() => handlePageChange(pageNumber)}
//                                                     >
//                                                         {pageNumber}
//                                                     </Pagination.Item>
//                                                 ))}
//                                                 <Pagination.Ellipsis />
//                                                 <Pagination.Next
//                                                     onClick={() => handlePageChange(currentPage + 1)}
//                                                     disabled={currentPage === Math.ceil(filteredData.length / itemsPerPage)}
//                                                 />
//                                                 <Pagination.Last onClick={() => handlePageChange(Math.ceil(filteredData.length / itemsPerPage))} />
//                                             </Pagination>
//                                         </div>
//                                     </>
//                                 )}
//                                 </>
//                             </Tab>
//                             <Tab eventKey="failure" title="Failure">
//                             <>
//                                 <Row>
//                                     <Col md={3}>
//                                         <FormGroup>
//                                             <Label className="forlabel" for="branchId">Date From</Label>
//                                             <div>
//                                                 <DatePicker
//                                                     selected={searchCriteriaFailure.startDate ? new Date(searchCriteriaFailure.startDate) : null}
//                                                     wrapperClassName="custom-react-datepicker-wrapper"
//                                                     onChange={(date) => {
//                                                         if (date) {
//                                                             date.setHours(12, 0, 0, 0);
//                                                             const formattedDate = date.toISOString();
//                                                             setSearchCriteriaFailure({ ...searchCriteriaFailure, startDate: formattedDate });
//                                                         } else {
//                                                             setSearchCriteriaFailure({ ...searchCriteriaFailure, startDate: null });
//                                                         }
//                                                     }}
//                                                     dateFormat="dd/MM/yyyy"
//                                                     className="form-control border-right-0 inputField"
//                                                     customInput={<input style={{ width: '100%' }} />}
//                                                 />
//                                             </div>
//                                         </FormGroup>
//                                     </Col>
//                                     <Col md={3}>
//                                         <FormGroup>
//                                             <Label className="forlabel" for="branchId">Date To</Label>
//                                             <div>
//                                                 <DatePicker
//                                                     selected={searchCriteriaFailure.endDate ? new Date(searchCriteriaFailure.endDate) : null}
//                                                     wrapperClassName="custom-react-datepicker-wrapper"
//                                                     onChange={(date) => {
//                                                         if (date) {
//                                                             date.setHours(12, 0, 0, 0);
//                                                             const formattedDate = date.toISOString();
//                                                             setSearchCriteriaFailure({ ...searchCriteriaFailure, endDate: formattedDate });
//                                                         } else {
//                                                             setSearchCriteria({ ...searchCriteriaFailure, endDate: null });
//                                                         }
//                                                     }}
//                                                     dateFormat="dd/MM/yyyy"
//                                                     className="form-control border-right-0 inputField"
//                                                     customInput={<input style={{ width: '100%' }} />}
//                                                 />
//                                             </div>
//                                         </FormGroup>
//                                     </Col>
//                                     <Col md={3}>
//                                         <FormGroup>
//                                             <Label className="forlabel" for="branchId">Select Importer</Label>
//                                             <Select
//                                                 options={parties}
//                                                 placeholder="Select a party"
//                                                 isClearable
//                                                 value={selectedPartyFailure}
//                                                 onChange={handlePartyChangeFailure}
//                                                 styles={{
//                                                     control: (provided, state) => ({
//                                                         ...provided,
//                                                         border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
//                                                         boxShadow: 'none',
//                                                         '&:hover': {
//                                                             border: '1px solid #ccc'
//                                                         }
//                                                     }),
//                                                     indicatorSeparator: () => ({
//                                                         display: 'none'
//                                                     }),
//                                                     dropdownIndicator: () => ({
//                                                         display: 'none'
//                                                     }),
//                                                     placeholder: (provided) => ({
//                                                         ...provided,
//                                                         display: 'flex',
//                                                         color: 'gray',
//                                                     }),
//                                                 }}
//                                             />
//                                         </FormGroup>
//                                     </Col>
//                                     <Col md={3}>
//                                         <div className="text-center" style={{ marginTop: '30px' }}>
//                                             <Button
//                                                 color="primary"
//                                                 outline
//                                                 onClick={() => handleSearch(searchCriteriaFailure, "failure")}
//                                                 className="mr-2"
//                                                 style={{ marginRight: '25px', fontWeight: 'bold' }}
//                                             >
//                                                 <FontAwesomeIcon icon={faSearch} style={{ marginRight: '5px' }} />
//                                                 SEARCH
//                                             </Button>
//                                             <Button
//                                                 color="danger"
//                                                 outline
//                                                 onClick={handleResetFailure}
//                                                 className="mr-2"
//                                                 style={{ marginRight: '5px', fontWeight: 'bold' }}
//                                             >
//                                                 <FontAwesomeIcon icon={faSyncAlt} style={{ marginRight: '5px' }} />
//                                                 RESET
//                                             </Button>
//                                         </div>
//                                     </Col>
//                                 </Row>
//                                 <hr />

//                                 {failureInvoices && failureInvoices.length > 0 && (
//                                     <>
//                                         <div className="table-responsive mt-3">
//                                             <Table className="table table-bordered table-hover" style={{ border: '2px solid black' }}>
//                                                 <thead className="thead-dark bg-dark">
//                                                     <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px' }}>
//                                                         <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font', height: '60px' }}>Sr No</th>
//                                                         <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Date</th>
//                                                         <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Invoice No</th>
//                                                         <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Invoice Date</th>
//                                                         <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Party Name</th>
//                                                         <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Error Code</th>
//                                                         <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Error</th>


//                                                     </tr>
//                                                 </thead>
//                                                 <tbody>
//                                                     {currentItemsFailure.map((item, index) => (
//                                                         <tr key={item.id} className='text-center'>
//                                                             <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{((currentPageFailure - 1) * itemsPerPage) + index + 1}</td>
//                                                             <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{formatDateTime(item.oprationDate)}</td>
//                                                             <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.invoiceNO}</td>
//                                                             <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{formatDateTime(item.invoiceDate)}</td>
//                                                             <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.partyId}</td>
//                                                             <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.errorCode}</td>
//                                                             <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.errorMessege}</td>
//                                                         </tr>
//                                                     ))}
//                                                 </tbody>
//                                             </Table>
//                                             <Pagination style={{ display: 'flex', justifyContent: 'center', color: 'gray' }}>
//                                                 <Pagination.First onClick={() => handlePageChangeFailure(1)} />
//                                                 <Pagination.Prev
//                                                     onClick={() => handlePageChangeFailure(currentPageFailure - 1)}
//                                                     disabled={currentPageFailure === 1}
//                                                 />
//                                                 <Pagination.Ellipsis />
//                                                 {displayPagesFailure().map((pageNumber) => (
//                                                     <Pagination.Item
//                                                         key={pageNumber}
//                                                         active={pageNumber === currentPageFailure}
//                                                         onClick={() => handlePageChangeFailure(pageNumber)}
//                                                     >
//                                                         {pageNumber}
//                                                     </Pagination.Item>
//                                                 ))}
//                                                 <Pagination.Ellipsis />
//                                                 <Pagination.Next
//                                                     onClick={() => handlePageChangeFailure(currentPage + 1)}
//                                                     disabled={currentPageFailure === Math.ceil(failureInvoices.length / itemsPerPage)}
//                                                 />
//                                                 <Pagination.Last onClick={() => handlePageChangeFailure(Math.ceil(failureInvoices.length / itemsPerPage))} />
//                                             </Pagination>
//                                         </div>
//                                     </>
//                                 )}
//                                 </>
//                             </Tab>
//                         </Tabs>
//                     </CardBody>
//                 </Card>
//             </Container>
//         </>
//     );
// }



import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../Components/AuthProvider";
import { Pagination, Tab, Tabs, Container, } from "react-bootstrap";
import {
    Card,
    CardBody,
    Col,
    FormGroup,
    Row,
    Label,
    Button,
    Table
} from "reactstrap";
import { toast } from "react-toastify";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTruckPickup } from '@fortawesome/free-solid-svg-icons';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import ReactLoading from 'react-loading';
import moment from 'moment';
import DatePicker from "react-datepicker";
import Rate_Chart_Service from "../services/Rate_Chart_Service";
import InviceService from "../services/InviceService";


export default function E_Invoice_Results(props) {
    const {
        userId,
        branchId,
        companyid
    } = useContext(AuthContext);


    const navigate = useNavigate();
    const { isAuthenticated } = useContext(AuthContext);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate(
                "/login?message=You need to be authenticated to access this page."
            );
        }
    }, [isAuthenticated, navigate]);
    const [activeTab, setActiveTab] = useState('success');



    const initialSearchCriteria = {
        companyId: companyid,
        branchId: branchId,
        startDate: moment(new Date()).format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
        PartyId: '',
    };
    const [parties, setParties] = useState([]);
    const [selectedParty, setSelectedParty] = useState(null);
    const [selectedPartyFailure, setSelectedPartyFailure] = useState(null);
    const [searchCriteria, setSearchCriteria] = useState(initialSearchCriteria);
    const [searchCriteriaFailure, setSearchCriteriaFailure] = useState(initialSearchCriteria);
    const [loading, setLoading] = useState(false);
    const [failureInvoices, setFailureInvoices] = useState([]);


    const styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)', // Adjust the opacity and color as needed
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999, // Ensure the overlay is above other elements
        },
    };


    const findParties = async () => {
        const partyResponse = await Rate_Chart_Service.getAllActiveParties(companyid, branchId);
        const partyOptions = partyResponse.data.map(party => ({
            value: party[0],
            label: party[1]
        }));
        setParties(partyOptions);

    };

    useEffect(() => {
        findParties();
    }, []);


    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageFailure, setCurrentPageFailure] = useState(1);

    const [itemsPerPage] = useState(10);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    // Function to handle page change
    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };
    const displayPages = () => {
        const centerPageCount = 5;
        const middlePage = Math.floor(centerPageCount / 2);
        let startPage = currentPage - middlePage;
        let endPage = currentPage + middlePage;

        if (startPage < 1) {
            startPage = 1;
            endPage = Math.min(totalPages, centerPageCount);
        }

        if (endPage > totalPages) {
            endPage = totalPages;
            startPage = Math.max(1, totalPages - centerPageCount + 1);
        }

        return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    };





    const indexOfLastItemFailure = currentPageFailure * itemsPerPage;
    const indexOfFirstItemFailure = indexOfLastItemFailure - itemsPerPage;
    const currentItemsFailure = failureInvoices.slice(indexOfFirstItemFailure, indexOfLastItemFailure);
    const totalPagesFailure = Math.ceil(failureInvoices.length / itemsPerPage);

    // Function to handle page change
    const handlePageChangeFailure = (page) => {
        if (page >= 1 && page <= totalPagesFailure) {
            setCurrentPageFailure(page);
        }
    };




    const displayPagesFailure = () => {
        const centerPageCount = 5; // Number of pages to display around the current page
        const middlePage = Math.floor(centerPageCount / 2);

        let startPage = currentPageFailure - middlePage;
        let endPage = currentPageFailure + middlePage;

        if (startPage < 1) {
            startPage = 1;
            endPage = Math.min(centerPageCount, totalPagesFailure);
        }

        if (endPage > totalPagesFailure) {
            endPage = totalPagesFailure;
            startPage = Math.max(1, totalPagesFailure - centerPageCount + 1);
        }

        return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    };



    const handleSearch = async (searchCriteria, screen) => {
        setCurrentPage(1);
        setCurrentPageFailure(1);
        setLoading(true);
        try {
            const response = await InviceService.getEinvoiceData(searchCriteria, screen);

            if (screen === "success") {
                setFilteredData(response.data);
            }
            if (screen === "failure") {
                setFailureInvoices(response.data);
            }

            console.log(response.data);


        }
        catch {
            toast.error('Oops something went wrong!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 700,
            });
        }
        finally {
            setLoading(false);
        }
    };


    const handleReset = () => {
        setSearchCriteria(initialSearchCriteria);
        setSelectedParty(null);
        handleSearch(initialSearchCriteria, "success");
    };

    const handleResetFailure = () => {
        setSearchCriteriaFailure(initialSearchCriteria);
        setSelectedPartyFailure(null);
        handleSearch(initialSearchCriteria, "failure");
    };

    const formatDateTime = (value) => {
        if (!value) {
            return ""; // Return an empty string if value is empty or undefined
        }

        const date = new Date(value);
        const day = date.getDate().toString().padStart(2, '0'); // Get the day of the month with leading zero
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get the month (0-indexed, so add 1) with leading zero
        const year = date.getFullYear(); // Get the year

        // Construct the final formatted date string
        const formattedDate = `${day}/${month}/${year}`;

        return formattedDate;
    };


    const handlePartyChange = (selectedOption) => {
        setSelectedParty(selectedOption);
        setSearchCriteria({ ...searchCriteria, PartyId: selectedOption ? selectedOption.value : '' });
    };

    const handlePartyChangeFailure = (selectedOption) => {
        setSelectedPartyFailure(selectedOption);
        setSearchCriteriaFailure({ ...searchCriteriaFailure, PartyId: selectedOption ? selectedOption.value : '' });
    };

    return (
        <>
            {loading && (
                <div style={styles.overlay}>
                    <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
                </div>
            )}
            <Container>
                <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '2%', paddingRight: '-20px' }}>
                    <FontAwesomeIcon
                        icon={faTruckPickup}
                        style={{
                            marginRight: '8px',
                            color: 'black',
                        }}
                    />
                    E-Invoice Management
                </h5>
                <Card style={{
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '40vw',
                    overflowX: "hidden",
                }}>
                    <CardBody style={{ paddingBottom: '-5px' }}>
                        <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
                            <Tab eventKey="success" title="Success">
                                <>
                                    <Row>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label className="forlabel" for="branchId">Date From</Label>
                                                <div>
                                                    <DatePicker
                                                        selected={searchCriteria.startDate ? new Date(searchCriteria.startDate) : null}
                                                        wrapperClassName="custom-react-datepicker-wrapper"
                                                        onChange={(date) => {
                                                            if (date) {
                                                                date.setHours(12, 0, 0, 0);
                                                                const formattedDate = date.toISOString();
                                                                setSearchCriteria({ ...searchCriteria, startDate: formattedDate });
                                                            } else {
                                                                setSearchCriteria({ ...searchCriteria, startDate: null });
                                                            }
                                                        }}
                                                        dateFormat="dd/MM/yyyy"
                                                        className="form-control border-right-0 inputField"
                                                        customInput={<input style={{ width: '100%' }} />}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label className="forlabel" for="branchId">Date To</Label>
                                                <div>
                                                    <DatePicker
                                                        selected={searchCriteria.endDate ? new Date(searchCriteria.endDate) : null}
                                                        wrapperClassName="custom-react-datepicker-wrapper"
                                                        onChange={(date) => {
                                                            if (date) {
                                                                date.setHours(12, 0, 0, 0);
                                                                const formattedDate = date.toISOString();
                                                                setSearchCriteria({ ...searchCriteria, endDate: formattedDate });
                                                            } else {
                                                                setSearchCriteria({ ...searchCriteria, endDate: null });
                                                            }
                                                        }}
                                                        dateFormat="dd/MM/yyyy"
                                                        className="form-control border-right-0 inputField"
                                                        customInput={<input style={{ width: '100%' }} />}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label className="forlabel" for="branchId">Select Importer</Label>
                                                <Select
                                                    options={parties}
                                                    placeholder="Select a party"
                                                    isClearable
                                                    value={selectedParty}
                                                    onChange={handlePartyChange}
                                                    styles={{
                                                        control: (provided, state) => ({
                                                            ...provided,
                                                            border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                                                            boxShadow: 'none',
                                                            '&:hover': {
                                                                border: '1px solid #ccc'
                                                            }
                                                        }),
                                                        indicatorSeparator: () => ({
                                                            display: 'none'
                                                        }),
                                                        dropdownIndicator: () => ({
                                                            display: 'none'
                                                        }),
                                                        placeholder: (provided) => ({
                                                            ...provided,
                                                            display: 'flex',
                                                            color: 'gray',
                                                        }),
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <div className="text-center" style={{ marginTop: '30px' }}>
                                                <Button
                                                    color="primary"
                                                    outline
                                                    onClick={() => handleSearch(searchCriteria, "success")}
                                                    className="mr-2"
                                                    style={{ marginRight: '25px', fontWeight: 'bold' }}
                                                >
                                                    <FontAwesomeIcon icon={faSearch} style={{ marginRight: '5px' }} />
                                                    SEARCH
                                                </Button>
                                                <Button
                                                    color="danger"
                                                    outline
                                                    onClick={handleReset}
                                                    className="mr-2"
                                                    style={{ marginRight: '5px', fontWeight: 'bold' }}
                                                >
                                                    <FontAwesomeIcon icon={faSyncAlt} style={{ marginRight: '5px' }} />
                                                    RESET
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <hr />

                                    {filteredData && filteredData.length > 0 && (
                                        <>
                                            <div className="table-responsive mt-3">
                                                <Table className="table table-bordered table-hover" style={{ border: '2px solid black' }}>
                                                    <thead className="thead-dark bg-dark">
                                                        <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px' }}>
                                                            <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font', height: '60px' }}>Sr No</th>
                                                            <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>IRN No</th>
                                                            <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Date</th>
                                                            <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Invoice No</th>
                                                            <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Invoice Date</th>
                                                            <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Party Name</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {currentItems.map((item, index) => (
                                                            <tr key={item.id} className='text-center'>
                                                                <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{((currentPage - 1) * itemsPerPage) + index + 1}</td>
                                                                <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.irnNo}</td>
                                                                <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{formatDateTime(item.oprationDate)}</td>
                                                                <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.invoiceNO}</td>
                                                                <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{formatDateTime(item.invoiceDate)}</td>
                                                                <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.partyId}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                                <Pagination style={{ display: 'flex', justifyContent: 'center', color: 'gray' }}>
                                                    <Pagination.First onClick={() => handlePageChange(1)} />
                                                    <Pagination.Prev
                                                        onClick={() => handlePageChange(currentPage - 1)}
                                                        disabled={currentPage === 1}
                                                    />
                                                    <Pagination.Ellipsis />
                                                    {displayPages().map((pageNumber) => (
                                                        <Pagination.Item
                                                            key={pageNumber}
                                                            active={pageNumber === currentPage}
                                                            onClick={() => handlePageChange(pageNumber)}
                                                        >
                                                            {pageNumber}
                                                        </Pagination.Item>
                                                    ))}
                                                    <Pagination.Ellipsis />
                                                    <Pagination.Next
                                                        onClick={() => handlePageChange(currentPage + 1)}
                                                        disabled={currentPage === Math.ceil(filteredData.length / itemsPerPage)}
                                                    />
                                                    <Pagination.Last onClick={() => handlePageChange(Math.ceil(filteredData.length / itemsPerPage))} />
                                                </Pagination>
                                            </div>
                                        </>
                                    )}
                                </>
                            </Tab>
                            <Tab eventKey="failure" title="Failure">
                                <>
                                    <Row>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label className="forlabel" for="branchId">Date From</Label>
                                                <div>
                                                    <DatePicker
                                                        selected={searchCriteriaFailure.startDate ? new Date(searchCriteriaFailure.startDate) : null}
                                                        wrapperClassName="custom-react-datepicker-wrapper"
                                                        onChange={(date) => {
                                                            if (date) {
                                                                date.setHours(12, 0, 0, 0);
                                                                const formattedDate = date.toISOString();
                                                                setSearchCriteriaFailure({ ...searchCriteriaFailure, startDate: formattedDate });
                                                            } else {
                                                                setSearchCriteriaFailure({ ...searchCriteriaFailure, startDate: null });
                                                            }
                                                        }}
                                                        dateFormat="dd/MM/yyyy"
                                                        className="form-control border-right-0 inputField"
                                                        customInput={<input style={{ width: '100%' }} />}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label className="forlabel" for="branchId">Date To</Label>
                                                <div>
                                                    <DatePicker
                                                        selected={searchCriteriaFailure.endDate ? new Date(searchCriteriaFailure.endDate) : null}
                                                        wrapperClassName="custom-react-datepicker-wrapper"
                                                        onChange={(date) => {
                                                            if (date) {
                                                                date.setHours(12, 0, 0, 0);
                                                                const formattedDate = date.toISOString();
                                                                setSearchCriteriaFailure({ ...searchCriteriaFailure, endDate: formattedDate });
                                                            } else {
                                                                setSearchCriteria({ ...searchCriteriaFailure, endDate: null });
                                                            }
                                                        }}
                                                        dateFormat="dd/MM/yyyy"
                                                        className="form-control border-right-0 inputField"
                                                        customInput={<input style={{ width: '100%' }} />}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label className="forlabel" for="branchId">Select Importer</Label>
                                                <Select
                                                    options={parties}
                                                    placeholder="Select a party"
                                                    isClearable
                                                    value={selectedPartyFailure}
                                                    onChange={handlePartyChangeFailure}
                                                    styles={{
                                                        control: (provided, state) => ({
                                                            ...provided,
                                                            border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                                                            boxShadow: 'none',
                                                            '&:hover': {
                                                                border: '1px solid #ccc'
                                                            }
                                                        }),
                                                        indicatorSeparator: () => ({
                                                            display: 'none'
                                                        }),
                                                        dropdownIndicator: () => ({
                                                            display: 'none'
                                                        }),
                                                        placeholder: (provided) => ({
                                                            ...provided,
                                                            display: 'flex',
                                                            color: 'gray',
                                                        }),
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <div className="text-center" style={{ marginTop: '30px' }}>
                                                <Button
                                                    color="primary"
                                                    outline
                                                    onClick={() => handleSearch(searchCriteriaFailure, "failure")}
                                                    className="mr-2"
                                                    style={{ marginRight: '25px', fontWeight: 'bold' }}
                                                >
                                                    <FontAwesomeIcon icon={faSearch} style={{ marginRight: '5px' }} />
                                                    SEARCH
                                                </Button>
                                                <Button
                                                    color="danger"
                                                    outline
                                                    onClick={handleResetFailure}
                                                    className="mr-2"
                                                    style={{ marginRight: '5px', fontWeight: 'bold' }}
                                                >
                                                    <FontAwesomeIcon icon={faSyncAlt} style={{ marginRight: '5px' }} />
                                                    RESET
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <hr />

                                    {failureInvoices && failureInvoices.length > 0 && (
                                        <>
                                            <div className="table-responsive mt-3">
                                                <Table className="table table-bordered table-hover" style={{ border: '2px solid black' }}>
                                                    <thead className="thead-dark bg-dark">
                                                        <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px' }}>
                                                            <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font', height: '60px' }}>Sr No</th>
                                                            <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Date</th>
                                                            <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Invoice No</th>
                                                            <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Invoice Date</th>
                                                            <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Party Name</th>
                                                            <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Error Code</th>
                                                            <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Error</th>


                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {currentItemsFailure.map((item, index) => (
                                                            <tr key={item.id} className='text-center'>
                                                                <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{((currentPageFailure - 1) * itemsPerPage) + index + 1}</td>
                                                                <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{formatDateTime(item.oprationDate)}</td>
                                                                <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.invoiceNO}</td>
                                                                <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{formatDateTime(item.invoiceDate)}</td>
                                                                <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.partyId}</td>
                                                                <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.errorCode}</td>
                                                                <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.errorMessege}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                                <Pagination style={{ display: 'flex', justifyContent: 'center', color: 'gray' }}>
                                                    <Pagination.First onClick={() => handlePageChangeFailure(1)} />
                                                    <Pagination.Prev
                                                        onClick={() => handlePageChangeFailure(currentPageFailure - 1)}
                                                        disabled={currentPageFailure === 1}
                                                    />
                                                    <Pagination.Ellipsis />
                                                    {displayPagesFailure().map((pageNumber) => (
                                                        <Pagination.Item
                                                            key={pageNumber}
                                                            active={pageNumber === currentPageFailure}
                                                            onClick={() => handlePageChangeFailure(pageNumber)}
                                                        >
                                                            {pageNumber}
                                                        </Pagination.Item>
                                                    ))}
                                                    <Pagination.Ellipsis />
                                                    <Pagination.Next
                                                        onClick={() => handlePageChangeFailure(currentPageFailure + 1)}
                                                        disabled={currentPageFailure === totalPagesFailure}
                                                    />
                                                    <Pagination.Last onClick={() => handlePageChangeFailure(totalPagesFailure)} />
                                                </Pagination>
                                            </div>
                                        </>
                                    )}
                                </>
                            </Tab>
                        </Tabs>
                    </CardBody>
                </Card>
            </Container>
        </>
    );
}

