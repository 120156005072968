import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../Components/AuthProvider";
import { Pagination } from "react-bootstrap";
import {
    Card,
    CardBody,
    Col,
    FormGroup,
    Row,
    Label,
    Button,
    Table
} from "reactstrap";
import { toast } from "react-toastify";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAtom, faEye, faFileAlt, faFileExcel, faFilePdf, faPrint, faSearch, faTrashAlt, faTruck, faTruckArrowRight, faTruckFast, faTruckFront, faTruckPickup } from '@fortawesome/free-solid-svg-icons';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import ReactLoading from 'react-loading';
import InviceService from "../services/InviceService";
import moment from 'moment';
import DatePicker from "react-datepicker";
import Rate_Chart_Service from "../services/Rate_Chart_Service";


export default function ForwardedReports(props) {
    const {
        userId,
        branchId,
        companyid
    } = useContext(AuthContext);


    const navigate = useNavigate();
    const { isAuthenticated } = useContext(AuthContext);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate(
                "/login?message=You need to be authenticated to access this page."
            );
        }
    }, [isAuthenticated, navigate]);



    const calculateTotals = (items) => {
        const totals = {
            totalNop: 0,           
            totalIr: 0
        };
    
        // Using a Set to keep track of distinct item[0] values
        const distinctItems = new Set();
    
        items.forEach(item => {
            const key = item[0]+'_'+item[10] ; // Assuming item[0] is the distinct key
    
            if (!distinctItems.has(key)) {
                distinctItems.add(key);
                totals.totalNop += parseFloat(item[5]) || 0; // Ensure item[5] is treated as a number
                // totals.totalPackets += parseFloat(item[6]) || 0; // Ensure item[6] is treated as a number
            }
        });
        totals.totalIr = distinctItems.size;
        return totals;
    };


    const initialSearchCriteria = {
        companyId: companyid,
        branchId: branchId,
        startDate: moment(new Date()).format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
        cha: '',
        totalSir:0 ,
        totalNop: 0,     

    };
    const [chas, setChas] = useState([])
    const [selectedCha, setselectedCha] = useState(null);
    const [searchCriteria, setSearchCriteria] = useState(initialSearchCriteria);
    const [filteredData, setFilteredData] = useState([]);

    const [loading, setLoading] = useState(false);
    const { totalNop, totalPackets, totalIr} = calculateTotals(filteredData);


  

    const updateSearchCriteriaWithTotals = () => {
        const { totalNop, totalIr } = calculateTotals(filteredData);

        setSearchCriteria(prevCriteria => ({
            ...prevCriteria,
            totalSir: totalIr,
            totalNop: totalNop,
            totalPackage: totalPackets
        }));
    };

    // Update search criteria when filteredData changes
    useEffect(() => {
        updateSearchCriteriaWithTotals();
    }, [filteredData]);


   
    const styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)', // Adjust the opacity and color as needed
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999, // Ensure the overlay is above other elements
        },
    };

    const findCHAs = async () => {
        const partyOptions = await findExternalPartyByType(companyid, branchId, 'CHA')
        setChas(partyOptions);

    };

    const findExternalPartyByType = async (compid, branchId, Type) => {
        const partyResponse = await Rate_Chart_Service.getAllExternalPartiesByType(compid, branchId, Type);
        const partyOptions = partyResponse.data.map(externalUser => ({
            value: externalUser[0],
            label: externalUser[1]
        }));
        return partyOptions;

    };

    useEffect(() => {
        const fetchData = async () => {
            handleSearch(initialSearchCriteria);
        };
        fetchData();
        findCHAs();
    }, []);


    const handleCHAChange = selectedOption => {
        setselectedCha(selectedOption);
        setSearchCriteria({ ...searchCriteria, cha: selectedOption ? selectedOption.value : '' });
    };


    const handleSearch = async (searchCriteria) => {
        setLoading(true);
        try {
            const response = await InviceService.searchForwardedInReport({ params: searchCriteria });
            setFilteredData(response.data);
        }
        catch {
            toast.error('Oops something went wrong!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 700,
            });
        }
        finally {
            setLoading(false);
        }
    };


    const handleReset = () => {
        setSearchCriteria(initialSearchCriteria);
        setselectedCha(null);
        handleSearch(initialSearchCriteria);
    };

    const formatDateTimeMonth = (value) => {
        if (!value) {
            return "";
        }

        const date = new Date(value);
        const day = date.getDate().toString().padStart(2, '0'); // Get the day of the month, padded to 2 digits
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get the month (0-based, so add 1), padded to 2 digits
        const year = date.getFullYear(); // Get the year
        const hour = date.getHours().toString().padStart(2, '0'); // Get the hour, padded to 2 digits
        const minute = date.getMinutes().toString().padStart(2, '0'); // Get the minute, padded to 2 digits
        const second = date.getSeconds().toString().padStart(2, '0'); // Get the second, padded to 2 digits

        // Construct the final formatted date and time string
        const formattedDateTime = `${day}/${month}/${year} ${hour}:${minute}:${second}`;

        return formattedDateTime;
    };


    const handlePrint = async (type) => {
        setLoading(true);
        try {
            const response = await InviceService.generateForwardedInSummaryPrint(searchCriteria);

            if (response.status === 200) {
                const pdfData = response.data;
                const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
                const blobUrl = URL.createObjectURL(pdfBlob);

                if (type === "PDF") {
                    // Create an anchor element for downloading
                    const downloadLink = document.createElement('a');
                    downloadLink.href = blobUrl;
                    downloadLink.download = 'Forwarded_Report.pdf';
                    downloadLink.style.display = 'none';
                    document.body.appendChild(downloadLink);
                    // Trigger the download
                    downloadLink.click();
                    // Clean up
                    document.body.removeChild(downloadLink);
                    window.URL.revokeObjectURL(blobUrl);
                }
                if (type === 'PRINT') {
                    window.open(blobUrl, '_blank');
                }
                toast.success("Downloading Pdf!", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 800,
                });
            } else {
                toast.error("error downLoading file!", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 800,
                });
            }
        } catch (error) {
            console.error("Error downloading PDF:", error);
        }
        finally {
            setLoading(false);
        }
    };


    const handleXLSdownLoad = async () => {
        setLoading(true);
        try {
            const response = await InviceService.downLoadForwardedInSummaryXLS(searchCriteria);
            if (response.status === 200) {
                const blob = new Blob([response.data], { type: response.headers['content-type'] });

                // Create a temporary URL for the blob
                const url = window.URL.createObjectURL(blob);

                // Create a link element to trigger the download
                const a = document.createElement("a");
                a.href = url;
                a.download = 'Forwarded_Report.xlsx';
                document.body.appendChild(a);
                a.click();

                // Clean up
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }
        }
        catch {
            toast.error("error downLoading file!", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 800,
            });
        }
        finally {
            setLoading(false);
        }
    };

   


    return (
        <>
            {loading && (
                <div style={styles.overlay}>
                    <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
                </div>
            )}



            <div className="Container">
                {/* <Container > */}
                <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '2%', paddingRight: '-20px' }}>
                    <FontAwesomeIcon
                        icon={faFileAlt}
                        style={{
                            marginRight: '8px',
                            color: 'black',
                        }}
                    />Forwarded Report </h5>
                <Card style={{
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '60vw',
                    overflowX: "hidden",
                }} >
                    <CardBody style={{ paddingBottom: '-5px' }} >

                        <Row>

                            <Col md={3}>
                                <FormGroup>
                                    <Label className="forlabel" for="branchId">Date From</Label>
                                    <div> {/* Wrap in an input group */}

                                        <DatePicker
                                            selected={searchCriteria.startDate ? new Date(searchCriteria.startDate) : null}
                                            wrapperClassName="custom-react-datepicker-wrapper"
                                            onChange={(date) => {
                                                if (date) {
                                                    // Set the time to 12:00 AM in UTC time
                                                    date.setHours(12, 0, 0, 0);
                                                    // Convert the selected date and time to ISO format
                                                    const formattedDate = date.toISOString();
                                                    setSearchCriteria({ ...searchCriteria, startDate: formattedDate });
                                                } else {
                                                    setSearchCriteria({ ...searchCriteria, startDate: null });
                                                }
                                            }}
                                            dateFormat="dd/MM/yyyy" // Specify the combined format
                                            className="form-control border-right-0 inputField"
                                            customInput={<input style={{ width: '100%' }} />}

                                        />
                                    </div>
                                </FormGroup>
                            </Col>


                            <Col md={3}>
                                <FormGroup>
                                    <Label className="forlabel" for="branchId">Date To</Label>
                                    <div> {/* Wrap in an input group */}
                                        <DatePicker
                                            selected={searchCriteria.endDate ? new Date(searchCriteria.endDate) : null} // Use endDate from searchCriteria if it's defined
                                            wrapperClassName="custom-react-datepicker-wrapper"
                                            onChange={(date) => {
                                                if (date) {
                                                    // Set the time to 12:00 PM (noon)
                                                    date.setHours(12, 0, 0, 0);
                                                    const formattedDate = date.toISOString(); // Convert to ISO format
                                                    setSearchCriteria({ ...searchCriteria, endDate: formattedDate });
                                                } else {
                                                    setSearchCriteria({ ...searchCriteria, endDate: null });
                                                }
                                            }}
                                            dateFormat="dd/MM/yyyy"
                                            className="form-control border-right-0 inputField"
                                            customInput={<input style={{ width: '100%' }} />}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label className="forlabel" for="branchId">CHA</Label>
                                    <Select
                                        options={chas}
                                        placeholder="Select cha"
                                        isClearable
                                        value={selectedCha}
                                        onChange={handleCHAChange}
                                        styles={{
                                            control: (provided, state) => ({
                                                ...provided,
                                                border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                                                boxShadow: 'none',
                                                '&:hover': {
                                                    border: '1px solid #ccc'
                                                }
                                            }),
                                            indicatorSeparator: () => ({
                                                display: 'none'
                                            }),
                                            dropdownIndicator: () => ({
                                                display: 'none'
                                            }),
                                            placeholder: (provided) => ({
                                                ...provided,
                                                display: 'flex',
                                                color: 'gray',
                                            }),
                                        }}
                                    />
                                </FormGroup>
                            </Col>

                            <Col md={3}>

                                <div className="text-center" style={{ marginTop: '30px' }}>

                                    <Button
                                        color="primary"
                                        outline
                                        onClick={() => handleSearch(searchCriteria)}
                                        className="mr-2"
                                        style={{ marginRight: '25px', fontWeight: 'bold' }}
                                    >
                                        <FontAwesomeIcon icon={faSearch} style={{ marginRight: '5px' }} />
                                        SEARCH
                                    </Button>
                                    <Button
                                        color="danger"
                                        outline
                                        onClick={handleReset}
                                        className="mr-2"
                                        style={{ marginRight: '5px', fontWeight: 'bold' }}
                                    >
                                        <FontAwesomeIcon icon={faSyncAlt} style={{ marginRight: '5px' }} />
                                        RESET
                                    </Button>
                                </div>

                            </Col>
                        </Row>



                        <hr />


                        {filteredData && filteredData.length > 0 && (
                            <>

                                <Row className='mt-1 mb-1'>
                                    <Col className="text-end mt-1">
                                        <Button
                                            color="success"
                                            outline
                                            style={{ marginRight: 10, marginLeft: 5, fontWeight: 'bold' }}
                                            onClick={() => handlePrint("PRINT")}
                                        >
                                            <FontAwesomeIcon
                                                icon={faPrint}
                                                style={{ marginRight: "5px" }}
                                            />
                                            Print
                                        </Button>

                                        <Button
                                            color="primary"
                                            outline
                                            style={{ marginRight: 10, marginLeft: 5, fontWeight: 'bold' }}
                                            onClick={() => handlePrint("PDF")}
                                        >
                                            <FontAwesomeIcon
                                                icon={faFilePdf}
                                                style={{ marginRight: "5px" }}
                                            />
                                            PDF
                                        </Button>

                                        <Button
                                            color="success"
                                            outline
                                            style={{ marginLeft: 5, fontWeight: 'bold' }}
                                            onClick={handleXLSdownLoad}
                                        >
                                            <FontAwesomeIcon
                                                icon={faFileExcel}
                                                style={{ marginRight: "5px" }}
                                            />
                                            XLS
                                        </Button>
                                    </Col>

                                </Row>

                                <div className="table-responsive mt-3">

                                    <Table className="table table-bordered table-hover" style={{ border: '2px solid black' }}>
                                        <thead className="thead-dark bg-dark"  >
                                            <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px' }}>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font', height: '60px' }}>Sr No</th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>IR No</th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>CHA</th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>HAWB</th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Importer Name</th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>NOP</th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Package Number</th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Out Date</th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>In Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredData.map((item, index) => (
                                                <tr key={item.id} className='text-center'>
                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{index + 1}</td>
                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item[0]}</td>
                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item[1]}</td>
                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item[3]}</td>
                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item[4]}</td>
                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item[5]}</td>
                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item[6]}</td>
                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{formatDateTimeMonth(item[7])}</td>
                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{formatDateTimeMonth(item[8])}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        <tr className="text-center dynamic-row-width total-row">
                                            <td>Total</td>
                                            <td>{totalIr}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>{totalNop}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                    </Table>
                                </div>
                            </>
                        )}
                    </CardBody>
                </Card>
            </div>
        </>
    );
}