import AuthContext from "../Components/AuthProvider";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useContext } from "react";
import "../Components/Style.css";
import DatePicker from "react-datepicker";
import ipaddress from "../Components/IpAddress";
import { Pagination } from "react-bootstrap";
import {
    Card,
    CardBody,
    Row,
    Col,
    FormGroup,
    Button,
    Label,
    Table,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFileAlt,
    faEye,
    faRefresh,
    faFilePdf,
    faFileExcel,
    faArrowsToEye,
    faSyncAlt,
    faSearch,
} from "@fortawesome/free-solid-svg-icons";

import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import axios from "axios";
import InviceService from "../services/InviceService";
import moment from 'moment';
import ReactLoading from 'react-loading';

export default function Monthly_Receipt_Report() {

    const styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)', // Adjust the opacity and color as needed
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999, // Ensure the overlay is above other elements
        },
    };

    const navigate = useNavigate();
    const {
        jwtToken,
        userId,
        username,
        branchId,
        companyid,
        role,
        companyname,
        branchname,
        isAuthenticated,
        login,
        logout,
    } = useContext(AuthContext);
    // If the user is not authenticated, redirect to the login page
    useEffect(() => {
        if (!isAuthenticated) {
            navigate(
                "/login?message=You need to be authenticated to access this page."
            );
        }
    }, [isAuthenticated, navigate]);


    const initialSearchCriteria = {
        companyId: companyid,
        branchId: branchId,
        startDate: moment(new Date()).format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD')
    };

    const [searchCriteria, setSearchCriteria] = useState(initialSearchCriteria);
    const [loading, setLoading] = useState(false);

    const [invoiceData, setInvoiceData] = useState([]);

    // const getData = async () => {
    //     let month = 0;
    //     let year = 0;

    //     if (startDate) {
    //         month = startDate.getMonth() + 1;
    //         year = startDate.getFullYear();
    //     }
    //     setInvoiceData([]);
    //     setLoading(true);

    //     try {
    //         const response = await axios.get(`https://${ipaddress}Invoice/getSHBMonthly/${companyid}/${branchId}/${month}/${year}`);

    //         if (response.data && response.data.length > 0) {
    //             toast.success("Data found successfully.", { autoClose: 700 });
    //             setInvoiceData(response.data);
    //             setCurrentPage(1);
    //         } else {
    //             toast.error("Data not found.", { autoClose: 700 });
    //             setInvoiceData([]);
    //         }
    //     } catch (error) {
    //         console.error("Error fetching data: ", error);
    //         toast.error("Error fetching data.", { autoClose: 700 });
    //     } finally {
    //         setLoading(false);
    //     }
    // };


    const handleSearch = async (searchCriteria) => {
        setLoading(true);
        try {
            const response = await InviceService.searchMonthlyReceiptReport({ params: searchCriteria });
            setCurrentPage(1);
            setInvoiceData(response.data);
            console.log(' Response : \n',response.data);
        }
        catch {
            toast.error('Oops something went wrong!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 700,
            });
        }
        finally {
            setLoading(false);
        }
    };








    const handleReset = () => {
        setSearchCriteria(initialSearchCriteria);
        handleSearch(initialSearchCriteria);
    }

    const formatedDate = (inputDate) => {
        const date = new Date(inputDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${day}/${month}/${year}`;
    };


    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);



    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = invoiceData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(invoiceData.length / itemsPerPage);

    // Function to handle page change
    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };
    const displayPages = () => {
        const centerPageCount = 5;
        const middlePage = Math.floor(centerPageCount / 2);
        let startPage = currentPage - middlePage;
        let endPage = currentPage + middlePage;

        if (startPage < 1) {
            startPage = 1;
            endPage = Math.min(totalPages, centerPageCount);
        }

        if (endPage > totalPages) {
            endPage = totalPages;
            startPage = Math.max(1, totalPages - centerPageCount + 1);
        }

        return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    };


    const handleXLSdownLoad = async () => {
        setLoading(true);
        try {
            const response = await InviceService.downLoadMonthlyReceiptReportXLS(searchCriteria);

            if (response.status === 200) {
                const blob = new Blob([response.data], { type: response.headers['content-type'] });

                // Create a temporary URL for the blob
                const url = window.URL.createObjectURL(blob);

                // Create a link element to trigger the download
                const a = document.createElement("a");
                a.href = url;
                a.download = 'Monthly_Receipt.xlsx';
                document.body.appendChild(a);
                a.click();

                // Clean up
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }
        }
        catch {
            toast.error("error downLoading file!", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 800,
            });
        }
        finally {
            setLoading(false);
        }
    };



    return (

        <>
            {loading && (
                <div style={styles.overlay}>
                    <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
                </div>
            )}

            <div className="Container">
                <h5
                    className="pageHead"
                    style={{
                        fontFamily: "Your-Heading-Font",
                        paddingLeft: "2%",
                        paddingRight: "-20px",
                    }}
                >
                    {" "}
                    <FontAwesomeIcon
                        icon={faFileAlt}
                        style={{
                            marginRight: "8px",
                            color: "black", // Set the color to golden
                        }}
                    />
                    Monthly Receipt Reports
                </h5>

                <Card>
                    <CardBody>
                        <Row>
                            <Col md={3}>
                                <FormGroup>
                                    <Label className="forlabel" for="branchId">Date From</Label>
                                    <div> {/* Wrap in an input group */}

                                        <DatePicker
                                            selected={searchCriteria.startDate ? new Date(searchCriteria.startDate) : null}
                                            wrapperClassName="custom-react-datepicker-wrapper"
                                            onChange={(date) => {
                                                if (date) {
                                                    // Set the time to 12:00 AM in UTC time
                                                    date.setHours(12, 0, 0, 0);
                                                    // Convert the selected date and time to ISO format
                                                    const formattedDate = date.toISOString();
                                                    setSearchCriteria({ ...searchCriteria, startDate: formattedDate });
                                                } else {
                                                    setSearchCriteria({ ...searchCriteria, startDate: null });
                                                }
                                            }}
                                            dateFormat="dd/MM/yyyy" // Specify the combined format
                                            className="form-control border-right-0 inputField"
                                            customInput={<input style={{ width: '100%' }} />}

                                        />
                                    </div>
                                </FormGroup>
                            </Col>


                            <Col md={3}>
                                <FormGroup>
                                    <Label className="forlabel" for="branchId">Date To</Label>
                                    <div> {/* Wrap in an input group */}
                                        <DatePicker
                                            selected={searchCriteria.endDate ? new Date(searchCriteria.endDate) : null} // Use endDate from searchCriteria if it's defined
                                            wrapperClassName="custom-react-datepicker-wrapper"
                                            onChange={(date) => {
                                                if (date) {
                                                    // Set the time to 12:00 PM (noon)
                                                    date.setHours(12, 0, 0, 0);
                                                    const formattedDate = date.toISOString(); // Convert to ISO format
                                                    setSearchCriteria({ ...searchCriteria, endDate: formattedDate });
                                                } else {
                                                    setSearchCriteria({ ...searchCriteria, endDate: null });
                                                }
                                            }}
                                            dateFormat="dd/MM/yyyy"
                                            className="form-control border-right-0 inputField"
                                            customInput={<input style={{ width: '100%' }} />}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>



                            <Col md={4}>

                                <div className="text-center" style={{ marginTop: '29px' }}>

                                    <Button
                                        color="primary"
                                        outline
                                        onClick={() => handleSearch(searchCriteria)}
                                        className="mr-2"
                                        style={{ marginRight: '25px', fontWeight: 'bold' }}
                                    >
                                        <FontAwesomeIcon icon={faSearch} style={{ marginRight: '5px' }} />
                                        SEARCH
                                    </Button>
                                    <Button
                                        color="danger"
                                        outline
                                        onClick={handleReset}
                                        className="mr-2"
                                        style={{ marginRight: '5px', fontWeight: 'bold' }}
                                    >
                                        <FontAwesomeIcon icon={faSyncAlt} style={{ marginRight: '5px' }} />
                                        RESET
                                    </Button>
                                </div>

                            </Col>



                        </Row>



                        {(invoiceData.length > 0 && invoiceData != null) && (
                            <>
                                <hr />
                                <Row>

                                    <Col className="text-end">

                                        {/* <Button
                                            color="primary"
                                            outline
                                            style={{ marginRight: 10, marginLeft: 5, fontWeight: 'bold' }}                                            
                                            onClick={() => handlePrint("PRINT")}
                                        >
                                            <FontAwesomeIcon
                                                icon={faPrint}
                                                style={{ marginRight: "5px" }}
                                            />
                                            Print
                                        </Button>
                                        <Button
                                            color="primary"
                                            outline
                                            style={{ marginRight: 10, marginLeft: 5, fontWeight: 'bold' }}                                           
                                            onClick={() => handlePrint("PDF")}
                                        >
                                            <FontAwesomeIcon
                                                icon={faFilePdf}
                                                style={{ marginRight: "5px" }}
                                            />
                                            PDF
                                        </Button> */}
                                        <Button
                                            color="success"
                                            outline
                                            style={{ marginLeft: 5, fontWeight: 'bold' }}                                          
                                            onClick={handleXLSdownLoad}
                                        >
                                            <FontAwesomeIcon
                                                icon={faFileExcel}
                                                style={{ marginRight: "5px" }}
                                            />
                                            XLS
                                        </Button>
                                    </Col>
                                </Row>
                                <Row >
                                    <div className="table-responsive text-center" >
                                        <Table
                                            style={{ marginTop: 9 }}
                                            className="table table-bordered text-center custom-table mt-3"
                                        >
                                            <thead>
                                                <tr>
                                                    <th style={{ background: "#BADDDA", textAlign: 'center' }}>Sr. No</th>
                                                    <th style={{ background: "#BADDDA", textAlign: 'center' }}>Doc Type</th>
                                                    <th style={{ background: "#BADDDA", textAlign: 'center' }}>Trans Id</th>
                                                    <th style={{ background: "#BADDDA", textAlign: 'center' }}>Trans Date</th>
                                                    <th style={{ background: "#BADDDA", textAlign: 'center' }}>Party Code</th>
                                                    <th style={{ background: "#BADDDA", textAlign: 'center' }}>
                                                        Party Name
                                                    </th>
                                                    <th style={{ background: "#BADDDA", textAlign: 'center' }}>Bank Code</th>
                                                    <th style={{ background: "#BADDDA", textAlign: 'center' }}>Bank Name</th>
                                                    <th style={{ background: "#BADDDA", textAlign: 'center' }}>Cheque No</th>
                                                    <th style={{ background: "#BADDDA", textAlign: 'center' }}>Cheque Date</th>
                                                    <th style={{ background: "#BADDDA", textAlign: 'center' }}>Cheque Bank Name</th>
                                                    <th style={{ background: "#BADDDA", textAlign: 'center' }}>Receip Amount</th>
                                                    <th style={{ background: "#BADDDA", textAlign: 'center' }}>Serial No</th>
                                                    <th style={{ background: "#BADDDA", textAlign: 'center' }}>Adjusted Type</th>
                                                    <th style={{ background: "#BADDDA", textAlign: 'center' }}>Adjusted Doc. No.</th>
                                                    <th style={{ background: "#BADDDA", textAlign: 'center' }}>Adjsted Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItems.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{((currentPage - 1) * itemsPerPage) + index + 1}</td>
                                                        <td>{item[0]}</td>
                                                        <td>{item[1]}</td>
                                                        <td>{formatedDate(item[2])}</td>
                                                        <td>{item[3]}</td>
                                                        <td>{item[4]}</td>
                                                        <td>{item[5]}</td>
                                                        <td>{item[6]}</td>
                                                        <td>{item[7]}</td>
                                                        <td>{formatedDate(item[8])}</td>
                                                        <td>{item[9]}</td>
                                                        <td>{item[10]}</td>
                                                        <td>{item[14]}</td>
                                                        <td>{item[11]}</td>
                                                        <td>{item[12]}</td>
                                                        <td>{item[13]}</td>
                                                    </tr>
                                                ))
                                                }
                                            </tbody>
                                        </Table>
                                        <Pagination style={{ display: 'flex', justifyContent: 'center', color: 'gray' }}>
                                            <Pagination.First onClick={() => handlePageChange(1)} />
                                            <Pagination.Prev
                                                onClick={() => handlePageChange(currentPage - 1)}
                                                disabled={currentPage === 1}
                                            />
                                            <Pagination.Ellipsis />

                                            {displayPages().map((pageNumber) => (
                                                <Pagination.Item
                                                    key={pageNumber}
                                                    active={pageNumber === currentPage}
                                                    onClick={() => handlePageChange(pageNumber)}
                                                >
                                                    {pageNumber}
                                                </Pagination.Item>
                                            ))}

                                            <Pagination.Ellipsis />
                                            <Pagination.Next
                                                onClick={() => handlePageChange(currentPage + 1)}
                                                disabled={currentPage === totalPages}
                                            />
                                            <Pagination.Last onClick={() => handlePageChange(totalPages)} />
                                        </Pagination>
                                    </div>
                                </Row>
                            </>
                        )

                        }
                    </CardBody>
                </Card>


            </div>
        </>
    )
}