import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../Components/AuthProvider";
import { Pagination } from "react-bootstrap";
import {
    Card,
    CardBody,
    Col,
    FormGroup,
    Row,
    Label,
    Button,
    Table
} from "reactstrap";
import { toast } from "react-toastify";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCarriageBaby, faFileExcel, faFilePdf, faPrint, faSearch } from '@fortawesome/free-solid-svg-icons';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import ReactLoading from 'react-loading';
import InviceService from "../services/InviceService";
import moment from 'moment';
import DatePicker from "react-datepicker";
import Rate_Chart_Service from "../services/Rate_Chart_Service";


export default function YearlyReport() {
    const {
        branchId,
        companyid
    } = useContext(AuthContext);


    const navigate = useNavigate();
    const { isAuthenticated } = useContext(AuthContext);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate(
                "/login?message=You need to be authenticated to access this page."
            );
        }
    }, [isAuthenticated, navigate]);



    const initialSearchCriteria = {
        companyId: companyid,
        branchId: branchId,
        startDate: moment(new Date()).format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
        process: '',
        partyId: '',
        parcelType: '',
        partyName: ''
    };


    const [parcelType, setSelectedParcelType] = useState(null);
    const [parcelTypes, setParcelTypes] = useState([]);

    const [partiesAll, setPartiesAll] = useState([]);
    const [selectedParty, setSelectedParty] = useState(null);
    const [selectedPartyContent, setSelectedPartyContent] = useState(null);

    const [selectedProcess, setSelectedProcess] = useState(null);
    const [process, setProcess] = useState([]);

    const [searchCriteria, setSearchCriteria] = useState(initialSearchCriteria);

    const [searchCriteriaContent, setSearchCriteriaContent] = useState(initialSearchCriteria);
    const [loading, setLoading] = useState(false);
    const styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
        },
    };



    useEffect(() => {
        const fetchData = async () => {
            handleSearch(initialSearchCriteria);
        };
        fetchData();
    }, []);

    useEffect(() => {
        findPartiesAll();
        findParcelTypes();
        findProcess();
    }, []);




    const findProcess = async () => {
        const PCKTYPEResponse = await Rate_Chart_Service.getjarsByJarId('J00041', companyid, branchId);
        const partyOptions = PCKTYPEResponse.data.map(jar => ({
            value: jar.jarDtlDesc,
            label: jar.jarDtlDesc
        }));
        setProcess(partyOptions);
    };


    const findParcelTypes = async () => {
        const PCKTYPEResponse = await Rate_Chart_Service.getjarsByJarId('J00008', companyid, branchId);
        const partyOptions = PCKTYPEResponse.data.map(jar => ({
            value: jar.jarDtlDesc,
            label: jar.jarDtlDesc
        }));
        setParcelTypes(partyOptions);
    };

    const findPartiesAll = async () => {
        const partyResponse = await Rate_Chart_Service.getAllActiveParties(companyid, branchId);
        const partyOptions = partyResponse.data.map(party => ({
            value: party[0],
            label: party[1]
        }));
        setPartiesAll(partyOptions);
    };


    const handlePartyDaily = async (selectedOption) => {
        const partyId = selectedOption ? selectedOption.value : '';
        const partyName = selectedOption ? selectedOption.label : '';
    
        setSelectedParty(selectedOption);
        setSearchCriteria(prevCriteria => ({
            ...prevCriteria,
            partyId,
            partyName
        }));
    };
    
    const handlePartyDailyContent = async (selectedOption) => {
        const partyId = selectedOption ? selectedOption.value : null;
        const partyName = selectedOption ? selectedOption.label : '';

        setSelectedPartyContent(selectedOption);
        setSearchCriteriaContent(prevCriteria => ({
            ...prevCriteria,
            partyId,
            partyName
        }));
    };
    



    const handleParcelTypeChange = async (selectedOption) => {
        setSelectedParcelType(selectedOption);
        setSearchCriteriaContent({ ...searchCriteriaContent, parcelType: selectedOption ? selectedOption.value : '' });
    };


    const [filterData, setFilterData] = useState([]);
    const [filterDataContent, setFilterDataContent] = useState([]);

    const handleSearch = async (searchCriteria) => {
        setLoading(true);
        try {
            const response = await InviceService.searchImportExportReport({ params: searchCriteria });

            setFilterData(response.data);
            console.log(response.data);
        }
        catch {
            toast.error('Oops something went wrong!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 700,
            });
        }
        finally {
            setLoading(false);
        }
    };

    const handleSearchContent = async (searchCriteria) => {
        setLoading(true);
        try {
            const response = await InviceService.searchImportExportReportContent({ params: searchCriteria });

            setFilterDataContent(response.data);
            console.log(response.data);
        }
        catch {
            toast.error('Oops something went wrong!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 700,
            });
        }
        finally {
            setLoading(false);
        }
    };

    

    const handleReset = () => {
        setSearchCriteria(initialSearchCriteria);
        setSelectedParty(null);     
        handleSearch(initialSearchCriteria);
    };



    const handleResetContent = () => {
        setSelectedParcelType(null);
        setSelectedProcess(null);
        setSelectedParcelType(null);
        setSearchCriteriaContent(initialSearchCriteria);      
        handleSearchContent(initialSearchCriteria);
    };



    const handleXLSdownLoad = async () => {
        setLoading(true);
        try {
            const response = await InviceService.downLoadImportExportXLS(searchCriteria);

            if (response.status === 200) {
                const blob = new Blob([response.data], { type: response.headers['content-type'] });

                // Create a temporary URL for the blob
                const url = window.URL.createObjectURL(blob);

                // Create a link element to trigger the download
                const a = document.createElement("a");
                a.href = url;
                a.download = 'IMP_EXP_Summary.xlsx';
                document.body.appendChild(a);
                a.click();

                // Clean up
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }
        }
        catch {
            toast.error("error downLoading file!", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 800,
            });
        }
        finally {
            setLoading(false);
        }
    };

    const handleXLSdownLoadContent = async () => {
        setLoading(true);
        try {
            const response = await InviceService.downLoadImportExportXLSContent(searchCriteriaContent);

            if (response.status === 200) {
                const blob = new Blob([response.data], { type: response.headers['content-type'] });

                // Create a temporary URL for the blob
                const url = window.URL.createObjectURL(blob);

                // Create a link element to trigger the download
                const a = document.createElement("a");
                a.href = url;
                a.download = 'IMP_EXP_ParcelType.xlsx';
                document.body.appendChild(a);
                a.click();

                // Clean up
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }
        }
        catch {
            toast.error("error downLoading file!", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 800,
            });
        }
        finally {
            setLoading(false);
        }
    };



    // Function to calculate the total for a specific column
    const calculateTotal = (data, columnIndex) => {
        const total = data.reduce((sum, row) => sum + (parseFloat(row[columnIndex]) || 0), 0);
        return parseFloat(total) !== 0 ? total : 0;
    };





    return (
        <>
            {loading && (
                <div style={styles.overlay}>
                    <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
                </div>
            )}


            <div className='' style={{ marginTop: 20 }}>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item tabspace" role="presentation">
                        <button style={{ color: 'gray' }} className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true"><h6>Summary Report</h6></button>
                    </li>

                    <li className="nav-item tabspace" role="presentation">
                        <button style={{ color: 'gray' }} className="nav-link" id="transaction-tab" data-bs-toggle="tab" data-bs-target="#transaction" type="button" role="tab" aria-controls="transaction" aria-selected="false"><h6>Content Report</h6></button>
                    </li>

                </ul>

                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">




                        <Card>
                            <CardBody style={{ paddingBottom: '-5px' }} >

                                <Row>

                                    <Col md={3}>
                                        <FormGroup>
                                            <Label className="forlabel" for="branchId">Date From</Label>
                                            <div> {/* Wrap in an input group */}

                                                <DatePicker
                                                    selected={searchCriteria.startDate ? new Date(searchCriteria.startDate) : null}
                                                    wrapperClassName="custom-react-datepicker-wrapper"
                                                    onChange={(date) => {
                                                        if (date) {
                                                            // Set the time to 12:00 AM in UTC time
                                                            date.setHours(12, 0, 0, 0);
                                                            // Convert the selected date and time to ISO format
                                                            const formattedDate = date.toISOString();
                                                            setSearchCriteria({ ...searchCriteria, startDate: formattedDate });
                                                        } else {
                                                            setSearchCriteria({ ...searchCriteria, startDate: null });
                                                        }
                                                    }}
                                                    dateFormat="dd/MM/yyyy" // Specify the combined format
                                                    className="form-control border-right-0 inputField"
                                                    customInput={<input style={{ width: '100%' }} />}

                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>


                                    <Col md={3}>
                                        <FormGroup>
                                            <Label className="forlabel" for="branchId">Date To</Label>
                                            <div> {/* Wrap in an input group */}
                                                <DatePicker
                                                    selected={searchCriteria.endDate ? new Date(searchCriteria.endDate) : null} // Use endDate from searchCriteria if it's defined
                                                    wrapperClassName="custom-react-datepicker-wrapper"
                                                    onChange={(date) => {
                                                        if (date) {
                                                            // Set the time to 12:00 PM (noon)
                                                            date.setHours(12, 0, 0, 0);
                                                            const formattedDate = date.toISOString(); // Convert to ISO format
                                                            setSearchCriteria({ ...searchCriteria, endDate: formattedDate });
                                                        } else {
                                                            setSearchCriteria({ ...searchCriteria, endDate: null });
                                                        }
                                                    }}
                                                    dateFormat="dd/MM/yyyy"
                                                    className="form-control border-right-0 inputField"
                                                    customInput={<input style={{ width: '100%' }} />}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>



                                    <Col md={6}>
                                        <FormGroup>
                                            <Label className="forlabel" for="branchId">Select Party</Label>
                                            <Select
                                                options={partiesAll}
                                                value={selectedParty}
                                                onChange={handlePartyDaily}
                                                isClearable
                                                placeholder="Select a Party"
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                                                        boxShadow: 'none',
                                                        '&:hover': {
                                                            border: '1px solid #ccc'
                                                        }
                                                    }),
                                                    indicatorSeparator: () => ({
                                                        display: 'none'
                                                    }),
                                                    dropdownIndicator: () => ({
                                                        display: 'none'
                                                    })
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>

                                </Row>


                                <div className="text-center" style={{ marginTop: '25px' }}>

                                    <Button
                                        color="primary"
                                        outline
                                        onClick={() => handleSearch(searchCriteria)}
                                        className="mr-2"
                                        style={{ marginRight: '25px', fontWeight: 'bold' }}
                                    >
                                        <FontAwesomeIcon icon={faSearch} style={{ marginRight: '5px' }} />
                                        SEARCH
                                    </Button>
                                    <Button
                                        color="danger"
                                        outline
                                        onClick={handleReset}
                                        className="mr-2"
                                        style={{ marginRight: '5px', fontWeight: 'bold' }}
                                    >
                                        <FontAwesomeIcon icon={faSyncAlt} style={{ marginRight: '5px' }} />
                                        RESET
                                    </Button>
                                </div>


                                {filterData.length > 0 ? (

                                    <>
                                       <hr />
                                        <Row className='mt-1 mb-1'>
                                            <Col className="text-end mt-1">
                                                <Button
                                                    color="success"
                                                    outline
                                                    style={{ marginLeft: 5, fontWeight: 'bold' }}
                                                    onClick={handleXLSdownLoad}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faFileExcel}
                                                        style={{ marginRight: "5px" }}
                                                    />
                                                    XLS
                                                </Button>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive">
                                         


                                            <Table className="table table-bordered table-hover mt-1" style={{ border: '2px solid black' }}>
                                                <thead className="thead-dark bg-dark">
                                                    <tr className="text-center" style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px', fontFamily: 'Your-Data-Font' }}>
                                                        <th rowSpan="2" style={{ width: '3%', background: '#BADDDA' }}>SR NO.</th>
                                                        <th rowSpan="2" style={{ width: '10%', background: '#BADDDA' }}>YEAR</th>
                                                        <th rowSpan="2" style={{ width: '10%', background: '#BADDDA' }}>MONTH</th>
                                                        <th colSpan="2" style={{ width: '8%', background: '#BADDDA' }}>IMPORT</th>
                                                        <th colSpan="2" style={{ width: '5%', background: '#BADDDA' }}>EXPORT</th>
                                                    </tr>
                                                    <tr className="text-center" style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px', fontFamily: 'Your-Data-Font' }}>
                                                        <th style={{ width: '6%', background: '#BADDDA' }}>PKG</th>
                                                        <th style={{ width: '6%', background: '#BADDDA' }}>PCL</th>
                                                        <th style={{ width: '6%', background: '#BADDDA' }}>PKG</th>
                                                        <th style={{ width: '6%', background: '#BADDDA' }}>PCL</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filterData.map((import2, index) =>
                                                        <tr className="text-center" style={{ fontFamily: 'Your-Data-Font' }}>
                                                            <td>{index + 1}</td>
                                                            <td>{import2[0]}</td>
                                                            <td>{import2[1]}</td>
                                                            <td>{import2[2]}</td>
                                                            <td>{import2[3]}</td>
                                                            <td>{import2[4]}</td>
                                                            <td>{import2[5]}</td>
                                                        </tr>
                                                    )}
                                                    <tr className="text-center dynamic-row-width total-row">
                                                        <td colSpan={3}>Total</td>
                                                        <td>{calculateTotal(filterData, 2)}</td>
                                                        <td>{calculateTotal(filterData, 3)}</td>
                                                        <td>{calculateTotal(filterData, 4)}</td>
                                                        <td>{calculateTotal(filterData, 5)}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </>
                                ) : null}

                            </CardBody>
                        </Card>

                    </div>

                    <div className="tab-pane fade show " id="transaction" role="tabpanel" aria-labelledby="transaction-tab">




                        <Card>
                            <CardBody style={{ paddingBottom: '-5px' }} >

                                <Row>

                                    <Col md={2}>
                                        <FormGroup>
                                            <Label className="forlabel" for="branchId">Date From</Label>
                                            <div> {/* Wrap in an input group */}

                                                <DatePicker
                                                    selected={searchCriteriaContent.startDate ? new Date(searchCriteriaContent.startDate) : null}
                                                    wrapperClassName="custom-react-datepicker-wrapper"
                                                    onChange={(date) => {
                                                        if (date) {
                                                            // Set the time to 12:00 AM in UTC time
                                                            date.setHours(12, 0, 0, 0);
                                                            // Convert the selected date and time to ISO format
                                                            const formattedDate = date.toISOString();
                                                            setSearchCriteriaContent({ ...searchCriteriaContent, startDate: formattedDate });
                                                        } else {
                                                            setSearchCriteriaContent({ ...searchCriteriaContent, startDate: null });
                                                        }
                                                    }}
                                                    dateFormat="dd/MM/yyyy" // Specify the combined format
                                                    className="form-control border-right-0 inputField"
                                                    customInput={<input style={{ width: '100%' }} />}

                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>


                                    <Col md={2}>
                                        <FormGroup>
                                            <Label className="forlabel" for="branchId">Date To</Label>
                                            <div> {/* Wrap in an input group */}
                                                <DatePicker
                                                    selected={searchCriteriaContent.endDate ? new Date(searchCriteriaContent.endDate) : null} // Use endDate from searchCriteria if it's defined
                                                    wrapperClassName="custom-react-datepicker-wrapper"
                                                    onChange={(date) => {
                                                        if (date) {
                                                            // Set the time to 12:00 PM (noon)
                                                            date.setHours(12, 0, 0, 0);
                                                            const formattedDate = date.toISOString(); // Convert to ISO format
                                                            setSearchCriteriaContent({ ...searchCriteriaContent, endDate: formattedDate });
                                                        } else {
                                                            setSearchCriteriaContent({ ...searchCriteriaContent, endDate: null });
                                                        }
                                                    }}
                                                    dateFormat="dd/MM/yyyy"
                                                    className="form-control border-right-0 inputField"
                                                    customInput={<input style={{ width: '100%' }} />}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>



                                    <Col md={4}>
                                        <FormGroup>
                                            <Label className="forlabel" for="branchId">Select Party</Label>
                                            <Select
                                                options={partiesAll}
                                                value={selectedPartyContent}
                                                onChange={handlePartyDailyContent}
                                                isClearable
                                                placeholder="Select a Party"
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                                                        boxShadow: 'none',
                                                        '&:hover': {
                                                            border: '1px solid #ccc'
                                                        }
                                                    }),
                                                    indicatorSeparator: () => ({
                                                        display: 'none'
                                                    }),
                                                    dropdownIndicator: () => ({
                                                        display: 'none'
                                                    })
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md={4}>
                                        <FormGroup>
                                            <Label className="forlabel" for="branchId">Select Parcel Type</Label>
                                            <Select
                                                options={parcelTypes}
                                                value={parcelType}
                                                onChange={handleParcelTypeChange}
                                                isClearable
                                                placeholder="Select a Parcel Type"
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                                                        boxShadow: 'none',
                                                        '&:hover': {
                                                            border: '1px solid #ccc'
                                                        }
                                                    }),
                                                    indicatorSeparator: () => ({
                                                        display: 'none'
                                                    }),
                                                    dropdownIndicator: () => ({
                                                        display: 'none'
                                                    })
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>

                                  

                                </Row>


                                <div className="text-center" style={{ marginTop: '25px' }}>

                                    <Button
                                        color="primary"
                                        outline
                                        onClick={() => handleSearchContent(searchCriteriaContent)}
                                        className="mr-2"
                                        style={{ marginRight: '25px', fontWeight: 'bold' }}
                                    >
                                        <FontAwesomeIcon icon={faSearch} style={{ marginRight: '5px' }} />
                                        SEARCH
                                    </Button>
                                    <Button
                                        color="danger"
                                        outline
                                        onClick={handleResetContent}
                                        className="mr-2"
                                        style={{ marginRight: '5px', fontWeight: 'bold' }}
                                    >
                                        <FontAwesomeIcon icon={faSyncAlt} style={{ marginRight: '5px' }} />
                                        RESET
                                    </Button>
                                </div>


                                




                                {filterDataContent.length > 0 ? (

                                    <>
                                    <hr />
                                        <Row className='mt-1 mb-1'>
                                            <Col className="text-end mt-1">
                                                <Button
                                                    color="success"
                                                    outline
                                                    style={{ marginLeft: 5, fontWeight: 'bold' }}
                                                    onClick={handleXLSdownLoadContent}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faFileExcel}
                                                        style={{ marginRight: "5px" }}
                                                    />
                                                    XLS
                                                </Button>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive">
                                         


                                            <Table className="table table-bordered table-hover mt-1" style={{ border: '2px solid black' }}>
                                                <thead className="thead-dark bg-dark">
                                                    <tr className="text-center" style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px', fontFamily: 'Your-Data-Font' }}>
                                                        <th rowSpan="2" style={{ width: '3%', background: '#BADDDA' }}>SR NO.</th>
                                                        <th rowSpan="2" style={{ width: '10%', background: '#BADDDA' }}>YEAR</th>                                                        
                                                        <th rowSpan="2" style={{ width: '10%', background: '#BADDDA' }}>MONTH</th>
                                                        <th rowSpan="2" style={{ width: '10%', background: '#BADDDA' }}>Content Type</th>
                                                        <th colSpan="2" style={{ width: '8%', background: '#BADDDA' }}>IMPORT</th>
                                                        <th colSpan="2" style={{ width: '5%', background: '#BADDDA' }}>EXPORT</th>
                                                    </tr>
                                                    <tr className="text-center" style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px', fontFamily: 'Your-Data-Font' }}>
                                                        <th style={{ width: '6%', background: '#BADDDA' }}>PKG</th>
                                                        <th style={{ width: '6%', background: '#BADDDA' }}>PCL</th>
                                                        <th style={{ width: '6%', background: '#BADDDA' }}>PKG</th>
                                                        <th style={{ width: '6%', background: '#BADDDA' }}>PCL</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filterDataContent.map((import2, index) =>
                                                        <tr className="text-center" style={{ fontFamily: 'Your-Data-Font' }}>
                                                            <td>{index + 1}</td>
                                                            <td>{import2[0]}</td>
                                                            <td>{import2[1]}</td>
                                                            <td>{import2[2]}</td>
                                                            <td>{import2[3]}</td>
                                                            <td>{import2[4]}</td>
                                                            <td>{import2[5]}</td>
                                                            <td>{import2[6]}</td>
                                                        </tr>
                                                    )}
                                                    <tr className="text-center dynamic-row-width total-row">
                                                        <td colSpan={4}>Total</td>
                                                        <td>{calculateTotal(filterDataContent, 3)}</td>
                                                        <td>{calculateTotal(filterDataContent, 4)}</td>
                                                        <td>{calculateTotal(filterDataContent, 5)}</td>
                                                        <td>{calculateTotal(filterDataContent, 6)}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </>
                                ) : null}

                            </CardBody>
                        </Card>


                    </div>


                </div>
            </div>
        </>
    );
}