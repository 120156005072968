import AuthContext from "../Components/AuthProvider";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useContext } from "react";
import { Button, Pagination } from 'react-bootstrap';
import "../Components/Style.css";
import dgdcImage from "../Images/report.jpeg";
import DatePicker from "react-datepicker";
import ipaddress from "../Components/IpAddress";
import {
    Card,
    CardBody,
    Row,
    Col,
    FormGroup,
    Label,
    Table,
} from "reactstrap";
import Rate_Chart_Service from "../services/Rate_Chart_Service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faRefresh, faFilePdf, faUserCircle, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { toast } from "react-toastify";
import ReactLoading from 'react-loading';
import Select from 'react-select';
import InviceService from "../services/InviceService";
import moment from 'moment';

export default function Party_Ledger_Report() {

    const styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)', // Adjust the opacity and color as needed
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999, // Ensure the overlay is above other elements
        },
    };


    const navigate = useNavigate();
    const { isAuthenticated } = useContext(AuthContext);
    useEffect(() => {
        if (!isAuthenticated) {
            navigate(
                "/login?message=You need to be authenticated to access this page."
            );
        }
    }, [isAuthenticated, navigate]);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [selectedParty, setSelectedParty] = useState(null);
    const [Invoices, setInvoices] = useState([]);
    const [noRecordsFound, setNoRecordsFound] = useState(false);

    const [startDate2, setStartDate2] = useState(new Date());
    const [endDate2, setEndDate2] = useState(new Date());
    const [selectedParty2, setSelectedParty2] = useState(null);
    const [selectedPartyId, setSelectedPartyId] = useState('');


    const [Invoices2, setInvoices2] = useState([]);
    const [loading, setLoading] = useState(false);

    const formatDateTime2 = (value) => {
        if (!value) {
            return ""; // Return an empty string if value is empty or undefined
        }

        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    function calculateTotal(invoicesArray, propertyName) {
        // Check if the invoicesArray is valid and not empty
        if (!invoicesArray || invoicesArray.length === 0) {
            return 0;
        }

        // Use reduce method to sum the specified property
        const total = invoicesArray.reduce((accumulator, currentValue) => {
            // Check if the property exists in the current object
            if (currentValue.hasOwnProperty(propertyName)) {
                // Parse the property value to a float and add it to the accumulator
                accumulator += parseFloat(currentValue[propertyName]) || 0;
            }
            return accumulator;
        }, 0);

        return total;
    }




    const {
        userId,
        branchId,
        companyid,
        logintype,
        logintypeid
    } = useContext(AuthContext);

    console.log('logintype : ', logintype, ' \n logintypeid : ', logintypeid);



    const handlePrint = async (type) => {
        setLoading(true);
        try {
            const response = await InviceService.generatePartyLedgerPrint(searchCriteria);

            if (response.status === 200) {
                const pdfData = response.data;
                const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
                const blobUrl = URL.createObjectURL(pdfBlob);

                if (type === "PDF") {
                    // Create an anchor element for downloading
                    const downloadLink = document.createElement('a');
                    downloadLink.href = blobUrl;
                    downloadLink.download = 'Party_Ledger.pdf'; // Set the filename for the downloaded PDF
                    downloadLink.style.display = 'none';
                    document.body.appendChild(downloadLink);
                    // Trigger the download
                    downloadLink.click();
                    // Clean up
                    document.body.removeChild(downloadLink);
                    window.URL.revokeObjectURL(blobUrl);
                }
                if (type === 'PRINT') {
                    window.open(blobUrl, '_blank');
                }
                toast.success("Downloading Pdf!", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 800,
                });
            } else {
                toast.error("error downLoading file!", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 800,
                });
            }
        } catch (error) {
            console.error("Error downloading PDF:", error);
            // Handle the error, show an error message, etc.
        }
        finally {
            setLoading(false);
        }
    };

    const handleXLSdownLoad = async () => {
        setLoading(true);
        try {
            const response = await InviceService.downLoadLedgerXLS(searchCriteria);

            if (response.status === 200) {
                const blob = new Blob([response.data], { type: response.headers['content-type'] });

                // Create a temporary URL for the blob
                const url = window.URL.createObjectURL(blob);

                // Create a link element to trigger the download
                const a = document.createElement("a");
                a.href = url;
                a.download = 'Party_Ledger_Report.xlsx';
                document.body.appendChild(a);
                a.click();

                // Clean up
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }
        }
        catch {
            toast.error("error downLoading file!", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 800,
            });
        }
        finally {
            setLoading(false);
        }
    };



    // SHB
    const customFilterOption = (candidate, input) => {
        const inputLower = input.toLowerCase();
        const labelLower = candidate.label.toLowerCase();
        return candidate.data.__isNew__ || labelLower.startsWith(inputLower);
    };

    useEffect(() => {
        findPartiesAll();
        findCHAs();
    }, [])

    const [partiesAll, setPartiesAll] = useState([]);
    const [selectedPartyTransaction, setSelectedPartyTransaction] = useState(null);

    const findPartiesAll = async () => {
        let partyResponse;
        if (logintype === 'Party' || logintype === 'CHA') {
            partyResponse = await Rate_Chart_Service.getAllActivePartiesLoginType(companyid, branchId, logintypeid, logintype);
        }
        else {
            partyResponse = await Rate_Chart_Service.getAllActiveParties(companyid, branchId);
        }

        const partyOptions = partyResponse.data.map(party => ({
            value: party[0],
            label: party[1]
        }));
        setPartiesAll(partyOptions);
    };

    const formatDate = (date) => {
        return moment(date).format('YYYY-MM-DD');
    };
    const currentDate = new Date();
    const endDate1 = new Date(currentDate);
    const startDate1 = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

    const initialSearchCriteria = {
        companyid: companyid,
        branchId: branchId,
        PartyId: logintype === 'Party' ? logintypeid : '',
        startDate: formatDate(startDate1),
        endDate: formatDate(endDate1),
        cha: logintype === 'CHA' ? logintypeid : ''
    };
    const [searchCriteria, setSearchCriteria] = useState(initialSearchCriteria);
    const [chaArray, setChaArray] = useState([]);
    const [selectedCha, setSelectedCha] = useState(null);


    console.log('searchCriteria : \n', searchCriteria);


    const handlePartyTransaction = async (selectedOption) => {
        setSelectedPartyTransaction(selectedOption ? selectedOption : null);
        setSearchCriteria({ ...searchCriteria, PartyId: selectedOption ? selectedOption.value : null });
        if (!selectedOption) {
            setInvoices([]);
        }
    };

    const findCHAs = async () => {
        const partyOptions = await findExternalPartyByType(companyid, branchId, 'CHA')
        setChaArray(partyOptions);

    };

    const findExternalPartyByType = async (compid, branchId, Type) => {
        const partyResponse = await Rate_Chart_Service.getAllExternalPartiesByType(compid, branchId, Type);
        const partyOptions = partyResponse.data.map(externalUser => ({
            value: externalUser[0],
            label: externalUser[1]
        }));
        return partyOptions;

    };




    const handleCHAChange = selectedOption => {
        setSelectedCha(selectedOption);
        setSearchCriteria((prevData) => ({
            ...prevData,
            cha: selectedOption ? selectedOption.value : '',
        }));

    };




    const [TotalDebit, setTotalDebit] = useState(0);
    const [TotalCredit, setTotalCredit] = useState(0);
    const handleSearch = async () => {
        setInvoices([]);
        setLoading(true);
        try {
            const results = await InviceService.getPartyLedgerData(searchCriteria);
            if (!results.data || results.data.length === 0) {
                toast.info('No data found', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 600,
                });
            } else {

                const data = results.data;
                const groupedData = {};
                let totalCredit = 0;
                let totalDebit = 0;
                data.forEach((item) => {
                    totalDebit += item[6];
                    totalCredit += item[13];
                    const key = item[3];
                    if (!groupedData[key]) {
                        groupedData[key] = [];
                    }
                    groupedData[key].push(item);
                });
                setInvoices(groupedData);
                setTotalDebit(totalDebit);
                setTotalCredit(totalCredit);
            }
        } catch (error) {
            toast.error('Something went wrong', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 600,
            });
        }
        finally {
            setLoading(false);
        };
    };

    const formatedDate = (inputDate) => {
        const date = new Date(inputDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${day}/${month}/${year}`;
    };

    let count = 1;

    const handleReset = () => {
        setSelectedPartyTransaction(null);
        setSearchCriteria(initialSearchCriteria);
        setInvoices([]);
    };








    const handlePrintSummary = async (type) => {
        setLoading(true);
        try {
            const response = await InviceService.generatePartyLedgerPrintSummary(searchCriteria);

            if (response.status === 200) {
                const pdfData = response.data;
                const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
                const blobUrl = URL.createObjectURL(pdfBlob);

                if (type === "PDF") {
                    // Create an anchor element for downloading
                    const downloadLink = document.createElement('a');
                    downloadLink.href = blobUrl;
                    downloadLink.download = 'Party_Ledger_Summary.pdf'; // Set the filename for the downloaded PDF
                    downloadLink.style.display = 'none';
                    document.body.appendChild(downloadLink);
                    // Trigger the download
                    downloadLink.click();
                    // Clean up
                    document.body.removeChild(downloadLink);
                    window.URL.revokeObjectURL(blobUrl);
                }
                if (type === 'PRINT') {
                    window.open(blobUrl, '_blank');
                }
                toast.success("Downloading Pdf!", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 800,
                });
            } else {
                toast.error("error downLoading file!", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 800,
                });
            }
        } catch (error) {
            console.error("Error downloading PDF:", error);
            // Handle the error, show an error message, etc.
        }
        finally {
            setLoading(false);
        }
    };

    const handleXLSdownLoadSummary = async () => {
        setLoading(true);
        try {
            const response = await InviceService.downLoadLedgerXLSSummary(searchCriteria);

            if (response.status === 200) {
                const blob = new Blob([response.data], { type: response.headers['content-type'] });

                // Create a temporary URL for the blob
                const url = window.URL.createObjectURL(blob);

                // Create a link element to trigger the download
                const a = document.createElement("a");
                a.href = url;
                a.download = 'Party_Ledger_Summary_Report.xlsx';
                document.body.appendChild(a);
                a.click();

                // Clean up
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }
        }
        catch {
            toast.error("error downLoading file!", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 800,
            });
        }
        finally {
            setLoading(false);
        }
    };














    return (
        <>
            {loading && (
                <div style={styles.overlay}>
                    <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
                </div>
            )}

            <div className='' style={{ marginTop: 20 }}>
                <div className="Container">
                    <h5
                        className="pageHead"
                        style={{
                            fontFamily: "Your-Heading-Font",
                            paddingLeft: "2%",
                            paddingRight: "-50px",
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faUserCircle}
                            style={{
                                marginRight: "8px",
                                color: "black",
                            }}
                        />
                        Party Ledger Report
                    </h5>

                    <Card>
                        <CardBody>
                            <Row>

                                {logintype !== 'Party' ? (
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label className="forlabel" for="branchId">Select Party</Label>
                                            <Select
                                                options={partiesAll}
                                                value={selectedPartyTransaction}
                                                onChange={handlePartyTransaction}
                                                isClearable
                                                filterOption={customFilterOption}
                                                placeholder="Select a Party"
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                                                        boxShadow: 'none',
                                                        '&:hover': {
                                                            border: '1px solid #ccc'
                                                        }
                                                    }),
                                                    indicatorSeparator: () => ({
                                                        display: 'none'
                                                    }),
                                                    dropdownIndicator: () => ({
                                                        display: 'none'
                                                    })
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>

                                ) : null}


                                {logintype !== 'CHA' && logintype !== 'Party' ? (
                                    <Col md={3} >
                                        <FormGroup>
                                            <Label className="forlabel" for="branchId">Select CHA</Label>
                                            <Select
                                                options={chaArray}
                                                placeholder="Select cha"
                                                isClearable
                                                value={selectedCha}
                                                onChange={handleCHAChange}
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                                                        boxShadow: 'none',
                                                        '&:hover': {
                                                            border: '1px solid #ccc'
                                                        }
                                                    }),
                                                    indicatorSeparator: () => ({
                                                        display: 'none'
                                                    }),
                                                    dropdownIndicator: () => ({
                                                        display: 'none'
                                                    }),
                                                    placeholder: (provided) => ({
                                                        ...provided,
                                                        display: 'flex',
                                                        color: 'gray',
                                                    }),
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>


                                ) : null}







                                <Col md={3}>
                                    <FormGroup>
                                        <Label className="forlabel" for="branchId">From Date</Label>
                                        <div>
                                            <DatePicker
                                                selected={searchCriteria.startDate ? new Date(searchCriteria.startDate) : null}
                                                wrapperClassName="custom-react-datepicker-wrapper"
                                                onChange={(date) => {
                                                    if (date) {
                                                        setSearchCriteria({ ...searchCriteria, startDate: date.toISOString() });
                                                    } else {
                                                        setSearchCriteria({ ...searchCriteria, startDate: null });
                                                    }
                                                }}
                                                dateFormat="dd/MM/yyyy"
                                                className="form-control"
                                                maxDate={endDate1}
                                                customInput={<input style={{ width: '100%' }} />}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label className="forlabel" for="branchId">To Date</Label>
                                        <div>
                                            <DatePicker
                                                selected={searchCriteria.endDate ? new Date(searchCriteria.endDate) : null}
                                                wrapperClassName="custom-react-datepicker-wrapper"
                                                onChange={(date) => {
                                                    if (date) {
                                                        setSearchCriteria({ ...searchCriteria, endDate: date.toISOString() });
                                                    } else {
                                                        setSearchCriteria({ ...searchCriteria, endDate: null });
                                                    }
                                                }}
                                                dateFormat="dd/MM/yyyy" // Specify the combined format
                                                className="form-control"
                                                maxDate={endDate1}
                                                customInput={<input style={{ width: '100%' }} />}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>




                            </Row>


                            <div className='text-center'>
                                <Button
                                    type="button"
                                    className=""
                                    variant="outline-primary"
                                    style={{ marginTop: "10px", marginRight: 10 }}
                                    onClick={handleSearch}
                                >
                                    <FontAwesomeIcon
                                        icon={faSearch}
                                        style={{ marginRight: "5px" }}
                                    />
                                    Search
                                </Button>
                                <Button
                                    type="button"
                                    className=""
                                    variant="outline-danger"
                                    style={{ marginTop: "10px" }}
                                    onClick={handleReset}
                                >
                                    <FontAwesomeIcon
                                        icon={faRefresh}
                                        style={{ marginRight: "5px" }}
                                    />
                                    Reset
                                </Button>
                            </div>

                            {Object.keys(Invoices).length > 0 ? (
                                <div className='mt-4'>
                                    <Row>



                                        <Col className="text-start">
                                            <h5 style={{ marginLeft: '2vw' }}>Summary</h5>
                                            <Button
                                                type="submit"
                                                className=""
                                                style={{ marginRight: 10, marginLeft: 5, fontWeight: 'bold' }}
                                                variant="outline-success"
                                                onClick={() => handlePrintSummary("PRINT")}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faPrint}
                                                    style={{ marginRight: "5px" }}
                                                />
                                                Print
                                            </Button>

                                            <Button
                                                type="button"
                                                className=""
                                                style={{ marginRight: 10, marginLeft: 5, fontWeight: 'bold' }}
                                                variant="outline-primary"
                                                onClick={() => handlePrintSummary("PDF")}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faFilePdf}
                                                    style={{ marginRight: "5px" }}
                                                />
                                                PDF
                                            </Button>

                                            <Button
                                                type="button"
                                                className=""
                                                style={{ marginLeft: 5, fontWeight: 'bold' }}
                                                variant="outline-primary"
                                                onClick={handleXLSdownLoadSummary}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faFileExcel}
                                                    style={{ marginRight: "5px" }}
                                                />
                                                XLS
                                            </Button>
                                        </Col>

                                        <Col className="text-end">
                                            <h5 style={{ marginRight: '12vw' }}>Detailed</h5>
                                            <Button
                                                type="submit"
                                                className=""
                                                style={{ marginRight: 10, marginLeft: 5, fontWeight: 'bold' }}
                                                variant="outline-success"
                                                onClick={() => handlePrint("PRINT")}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faPrint}
                                                    style={{ marginRight: "5px" }}
                                                />
                                                Print
                                            </Button>

                                            <Button
                                                type="button"
                                                className=""
                                                style={{ marginRight: 10, marginLeft: 5, fontWeight: 'bold' }}
                                                variant="outline-primary"
                                                onClick={() => handlePrint("PDF")}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faFilePdf}
                                                    style={{ marginRight: "5px" }}
                                                />
                                                PDF
                                            </Button>

                                            <Button
                                                type="button"
                                                className=""
                                                style={{ marginLeft: 5, fontWeight: 'bold' }}
                                                variant="outline-primary"
                                                onClick={handleXLSdownLoad}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faFileExcel}
                                                    style={{ marginRight: "5px" }}
                                                />
                                                XLS
                                            </Button>
                                        </Col>

                                    </Row>
                                    <Table className="table table-bordered table-hover mt-2" style={{ border: '2px solid black' }}>
                                        <thead className="thead-dark bg-dark">
                                            <tr className='text-center' style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', fontSize: '17px', color: 'black', fontFamily: 'Your-Heading-Font', height: '60px' }} >
                                                <th rowSpan="2" style={{ width: '3%', background: '#BADDDA' }}>Sr No.</th>
                                                <th rowSpan="2" style={{ width: '10%', background: '#BADDDA' }}>DATE</th>
                                                <th rowSpan="2" style={{ width: '10%', background: '#BADDDA' }}>NUMBER</th>
                                                <th rowSpan="2" style={{ width: '8%', background: '#BADDDA' }}>DESCRIPTION</th>
                                                <th rowSpan="2" style={{ width: '5%', background: '#BADDDA' }}>DEBIT(RS)</th>
                                                <th rowSpan="2" style={{ width: '15%', background: '#BADDDA' }}>CREDIT(RS)</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {Object.keys(Invoices).map((partyId) => {
                                                const group = Invoices[partyId];

                                                let partyTotalCredit = 0;
                                                let partyTotalDedit = 0;

                                                return (
                                                    <React.Fragment key={partyId}>
                                                        {group.map((itemAll, index) => {

                                                            partyTotalCredit += itemAll[13];
                                                            partyTotalDedit += itemAll[6];

                                                            return (
                                                                <React.Fragment key={index}>
                                                                    {index === 0 && (
                                                                        <tr className="text-center" style={{ fontWeight: "bold" }}>
                                                                            <td>{count++}</td>
                                                                            <td>{itemAll[2]}</td>
                                                                            <td colSpan={2}>{itemAll[0]}</td>
                                                                            <td colSpan={2}>{itemAll[1]}</td>
                                                                        </tr>
                                                                    )}
                                                                    <tr className="text-center">
                                                                        <td></td>
                                                                        <td>
                                                                            {itemAll[9] === 'General Ledger Adjustment' ? '' : (itemAll[5] ? formatedDate(itemAll[5]) : formatedDate(itemAll[8]))}
                                                                        </td>

                                                                        {/* <td>{itemAll[5] ? formatedDate(itemAll[5]) : formatedDate(itemAll[8])}</td> */}
                                                                        <td>
                                                                            {itemAll[9] === 'General Ledger Adjustment' ? '' : (itemAll[4] ? itemAll[4] : itemAll[7])}
                                                                        </td>

                                                                        {/* <td>{itemAll[4] ? itemAll[4] : itemAll[7]}</td> */}
                                                                        <td>{itemAll[9]}</td>
                                                                        <td>{itemAll[6]}</td>
                                                                        <td>{itemAll[13]}</td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                        <tr className="text-center">
                                                            <td colSpan={3}><strong>CLOSING</strong></td>
                                                            {/* <td> <strong>{partyTotalDedit - partyTotalCredit}</strong></td> */}

                                                            {/* <td>
                                                                <strong>{((partyTotalDedit - partyTotalCredit) || 0).toFixed(2)}</strong>
                                                            </td> */}

                                                            <td>
                                                                <strong>
                                                                    {((partyTotalCredit - partyTotalDedit) || 0).toFixed(2).replace('-', '')}
                                                                    {((partyTotalCredit - partyTotalDedit) || 0) < 0 ? ' DB' : ' CR'}
                                                                </strong>
                                                            </td>


                                                            <td>
                                                                <strong>{(partyTotalDedit || 0).toFixed(2)}</strong>
                                                            </td>
                                                            <td>
                                                                <strong>{(partyTotalCredit || 0).toFixed(2)}</strong>
                                                            </td>

                                                        </tr>
                                                    </React.Fragment>
                                                );
                                            })}
                                            <tr className="text-center">
                                                <td colSpan={3}><strong>TOTAL</strong></td>
                                                <td>
                                                    <strong>
                                                        {((TotalCredit - TotalDebit) || 0).toFixed(2).replace('-', '')}
                                                        {((TotalCredit - TotalDebit) || 0) < 0 ? ' DB' : ' CR'}
                                                    </strong>
                                                </td>

                                                {/* <td>
                                                    <strong>{((TotalDebit - TotalCredit) || 0).toFixed(2)}</strong>
                                                </td> */}
                                                {/* <td><strong>{TotalDebit - TotalCredit}</strong></td> */}
                                                <td><strong>{(TotalDebit || 0).toFixed(2)}</strong></td>
                                                <td><strong>{(TotalCredit || 0).toFixed(2)}</strong></td>
                                            </tr>

                                        </tbody>
                                    </Table>
                                </div>
                            ) : null}

                        </CardBody>
                    </Card>
                </div>
            </div>
        </>
    );
}