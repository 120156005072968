import { faEye, faSearch, faUsersViewfinder } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useEffect, useContext } from "react";
import { Card, CardBody, Row, Col, Form, FormGroup, Label, Input } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import { Button } from 'react-bootstrap';
import Rate_Chart_Service from '../services/Rate_Chart_Service';
import AuthContext from "../Components/AuthProvider";
import Table from 'react-bootstrap/Table';
import { toast } from 'react-toastify';

function OTPViewer() {
    const [ConsoleNameById, setConsoleNameById] = useState(null);
    const [consoles, setConsoles] = useState([]);

    const {
        branchId,
        companyid,
        login,
        logout,
    } = useContext(AuthContext);


    const [representatives, setRepresentatives] = useState([]);
    const [selectedRepresentative, setSelectedRepresentative] = useState(null);
    const [errors, setErrors] = useState({});

    const [representativeNew, setRepresentativeNew] = useState({
        companyId: '',
        branchId: '',
        userId: '',
        representativeId: '',
        firstName: '',
        middleName: '',
        lastName: '',
        mobile: '',
        otp: '',
        imagePath: ''
    });

    useEffect(() => {
        findConsoles();
    }, []);

    const findExternalPartyByTypeNew = async (compid, branchId, Type) => {
        const partyResponse = await Rate_Chart_Service.getAllExternalPartiesByType(compid, branchId, Type);
        const partyOptions = partyResponse.data.map(externalUser => ({
            value: externalUser[0],
            label: externalUser[1]
        }));
        return partyOptions;
    };


    const findConsoles = async () => {
        const partyOptions = await findExternalPartyByTypeNew(companyid, branchId, 'console')
        setConsoles(partyOptions);
    };


    const getRepresentativeByUser = async (console) => {
        const response = await Rate_Chart_Service.getRepresentativeByUser(companyid, branchId, console);
        const partyOptions = response.data.map(externalUser => ({
            value: externalUser[0],
            label: externalUser[1]
        }));
        setRepresentatives(partyOptions);
    };


    const handleConsoleChange = selectedOption => {
        setErrors(prevErrors => selectedOption ? { ...prevErrors, ConsoleNameById: '' } : { ...prevErrors, ConsoleNameById: 'Console is required' });
        setConsoleNameById(selectedOption);
        if (selectedOption) {
            getRepresentativeByUser(selectedOption.value);
        } else {
            setRepresentativeNew({
                companyId: '',
                branchId: '',
                userId: '',
                representativeId: '',
                firstName: '',
                middleName: '',
                lastName: '',
                mobile: '',
                otp: '',
                imagePath: ''
            });
            setSelectedRepresentative(null);
            setRepresentatives([]);
        }
    };

    const handleselectedRepresentative = selectedOption => {
        setErrors(prevErrors => selectedOption ? { ...prevErrors, selectedRepresentative: '' } : { ...prevErrors, selectedRepresentative: 'please select representative' });
        setSelectedRepresentative(selectedOption);
        setRepresentativeNew({
            companyId: '',
            branchId: '',
            userId: '',
            representativeId: '',
            firstName: '',
            middleName: '',
            lastName: '',
            mobile: '',
            otp: '',
            imagePath: ''
        });
    };

    const getReprentative = async (represenatative) => {
        const res = await Rate_Chart_Service.getRepresentative(companyid, branchId, represenatative);
        setRepresentativeNew({
            companyId: res.data.companyId,
            branchId: res.data.branchId,
            userId: res.data.userId,
            representativeId: res.data.representativeId,
            firstName: res.data.firstName,
            middleName: res.data.middleName,
            lastName: res.data.lastName,
            mobile: res.data.mobile,
            otp: res.data.otp,
            imagePath: res.data.imagePath
        });
    };

    return (
        <div className="Container" >
            <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '2%', paddingRight: '-20px' }} > <FontAwesomeIcon
                icon={faEye}
                style={{
                    marginRight: '8px',
                    color: 'black',
                }}
            />OTP Viewer</h5>
            <Card>

                <CardBody>


                    <Row className="modal-contents">
                        <Col md={5} >
                            <FormGroup>
                                <Label className="forlabel" for="branchId">Select Console</Label>
                                <Select
                                    options={consoles}
                                    value={ConsoleNameById}
                                    onChange={handleConsoleChange}
                                    placeholder="Select Console"
                                    isClearable
                                    className={`${errors.ConsoleNameById ? 'error-border' : ''}`}
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                                            boxShadow: 'none',
                                            '&:hover': {
                                                border: '1px solid #ccc'
                                            }
                                        }),
                                        indicatorSeparator: () => ({
                                            display: 'none'
                                        }),
                                        dropdownIndicator: () => ({
                                            display: 'none'
                                        })
                                    }}
                                />
                                {errors.ConsoleNameById && (
                                    <div className="error-message">
                                        {errors.ConsoleNameById}
                                    </div>
                                )}

                            </FormGroup>
                        </Col>


                        <Col md={5}>
                            <FormGroup>
                                <Label className="forlabel" for="branchId">Select Representative </Label>
                                <Select
                                    options={representatives}
                                    value={selectedRepresentative}
                                    onChange={handleselectedRepresentative}
                                    className={errors.selectedRepresentative ? 'error-border' : ''}
                                    isClearable
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            borderColor: errors.representative ? '#f52b2b' : '',
                                            border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                                            boxShadow: 'none',
                                            '&:hover': {
                                                border: '1px solid #ccc'
                                            }
                                        }),
                                        indicatorSeparator: () => ({
                                            display: 'none'
                                        }),
                                        dropdownIndicator: () => ({
                                            display: 'none'
                                        })
                                    }}
                                />
                            </FormGroup>
                        </Col>

                        <Col md={2}>
                            <Button
                                type="button"
                                style={{
                                    marginTop: 32,
                                    marginLeft: 5,
                                }}
                                variant="outline-primary"
                                disabled={!ConsoleNameById || !selectedRepresentative}
                                onClick={() => getReprentative(selectedRepresentative.value)}
                            >
                                <FontAwesomeIcon
                                    icon={faSearch}
                                    style={{ marginRight: "5px" }}
                                />
                                Search
                            </Button>
                        </Col>
                    </Row>
                    <hr />
                    {representativeNew.mobile && (
                        <div className="table-responsive">
                            <Table className="table table-bordered custom-table mt-3">
                                <thead style={{ backgroundColor: 'rgb(226 232 240)' }}>
                                    <tr className="text-center">
                                        <th style={{ backgroundColor: '#BADDDA' }} scope="col">Representative</th>
                                        <th style={{ backgroundColor: '#BADDDA' }} scope="col">Mobile No</th>
                                        <th style={{ backgroundColor: '#BADDDA' }} scope="col">Image</th>
                                        <th style={{ backgroundColor: '#BADDDA' }} scope="col">Otp</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="text-center">
                                        <td>{representativeNew.firstName + ' ' + representativeNew.lastName}</td>
                                        <td>{representativeNew.mobile}</td>
                                        <td>
                                            {representativeNew.imagePath ? (
                                                <img
                                                    src={representativeNew.imagePath}
                                                    alt={representativeNew.imagePath}
                                                    style={{ width: "110px", height: "100px" }}
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                        <td>{representativeNew.otp}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    )}
                </CardBody>
            </Card>
        </div>
    );
}
export default OTPViewer;