import axios from "axios";
import ipaddress from "../Components/IpAddress";

const import_url = `https://${ipaddress}importmain/`;
const party_url = `https://${ipaddress}parties`;
const invoice_url = `https://${ipaddress}Invoice/`;
const invoicetaxdetails_url = `https://${ipaddress}invoicetaxdetails/`;
const payment_url = `https://${ipaddress}payment/`;
const barcode_url = `https://${ipaddress}barcodeGenerater/`;
const default_party_Cha = `https://${ipaddress}defaultparty/`;
const user_url = `https://${ipaddress}user/`;
const auth_url = `https://${ipaddress}auth/`;
const import_url2 = `https://${ipaddress}import/`;
const export_shb = `https://${ipaddress}exportshb/`;
const scanning_url = `https://${ipaddress}scan/`;
const forwarded_In_url = `https://${ipaddress}forwardin/`;
const cancel_url = `https://${ipaddress}invoiceCancel/`;

class InviceService {


 // SEARCH MONTHLY REPORTS
 searchMonthlyReceiptReport(params) {
    return axios.get(`${invoice_url}getSHBReceiptMonthly`, params);

};
downLoadMonthlyReceiptReportXLS(params) {
    return axios.get(`${invoice_url}SHBMonthlyReceiptexcel`,
        {
            params: params,
            responseType: 'arraybuffer'
        });
};
 // Party Daily Invoice XLS 
 downLoadDebitXls(data) {
    return axios.get(`${cancel_url}downLoadDebitXls`, {
        params: data, responseType: 'arraybuffer'
    });
};

    searchDebitNote(params) {
        return axios.get(`${cancel_url}searchDebitNote`, params
        );
    };


    saveDebitNote(compId, branchId, InvoiceCancelHdr, user) {        

        return axios.post(`${cancel_url}saveDebitNote`, InvoiceCancelHdr, {
            params: {
                companyId: compId,
                branchId: branchId,               
                userId: user
            }
        });
    };









 // Ledger Report
 downLoadLedgerXLSSummary(data) {
    return axios.get(`${invoice_url}downLoadLedgerXLSSummary`,
        {
            params: data,
            responseType: 'arraybuffer'
        });
};


generatePartyLedgerPrintSummary(data) {
    return axios.get(`${invoice_url}downLoadLedgerPrintSummary`,
        {
            params: data,
            responseType: 'arraybuffer'
        });
};












 // Party Daily Invoice XLS 
 downLoadInvoiceCancelXlsNew(data) {
    return axios.get(`${cancel_url}downLoadInvoiceCancelXlsNew`, {
        params: data, responseType: 'arraybuffer'
    });
};


 // Party Daily Invoice XLS 
 downLoadInvoiceCancelXls(data) {
    return axios.get(`${cancel_url}downLoadInvoiceCancelXls`, {
        params: data, responseType: 'arraybuffer'
    });
};





    saveCancleInvoice(compId, branchId, InvoiceCancelDtl, user) {
        const requestData = {
            InvoiceCancelDtl: InvoiceCancelDtl            
        };

        return axios.post(`${cancel_url}saveCancleInvoice`, requestData, {
            params: {
                companyId: compId,
                branchId: branchId,               
                userId: user
            }
        });
    };

    searchInvoiceCancelsNew(params) {
        return axios.get(`${cancel_url}searchInvoiceCancelsNew`, params
        );
    };


 // GateIn Summary
 getCreditNoteById(compId, branchId, documentNo, partyId) {
    return axios.get(`${cancel_url}getCreditNoteById`, {
        params: {
            companyId: compId,
            branchId: branchId,
            partyId: partyId,
            documentNo: documentNo
        }

    }
    );
};



    saveCreditNote(compId, branchId, InvoiceCancelHdr, InvoiceCancelDtl, user) {
        const requestData = {
            InvoiceCancelHdr: InvoiceCancelHdr,
            InvoiceCancelDtl: InvoiceCancelDtl            
        };

        return axios.post(`${cancel_url}saveCreditNote`, requestData, {
            params: {
                companyId: compId,
                branchId: branchId,               
                userId: user
            }
        });
    };


searchInvoiceCancels(params) {
        return axios.get(`${cancel_url}searchInvoiceCancels`, params
        );
    };

searchInvoiceDetails(params) {
    return axios.get(`${invoice_url}searchInvoiceDetailscreditNote`, params
    );
};



    // Old
    getPendingScanData(companyId, branchId,) {
        const requestData = {
          params: {
            companyId: companyId,
            branchId: branchId       
          }
        }
        return axios.get(`${import_url}getPendingData`, requestData);
      };
    
    


 // SEARCH MONTHLY REPORTS
 searchMonthlyReport(params) {
    return axios.get(`${invoice_url}getSHBMonthly`, params);

};
downLoadMonthlyReportXLS(params) {
    return axios.get(`${invoice_url}SHBMonthlyexcel`,
        {
            params: params,
            responseType: 'arraybuffer'
        });
};


// Print Monthly combine Invoice Report
getMonthlyReports(params) {
return axios.get(`${invoice_url}getMonthlyReports`,  {
    params: params,
    responseType: 'arraybuffer'
});
};


// IMPORT - EXPORT REPORT
searchImportExportReport(params) {
    return axios.get(`${import_url}searchImportExportReport`, params);

};


downLoadImportExportXLS(params) {
    return axios.get(`${import_url}downLoadImportExportXLS`,
        {
            params: params,
            responseType: 'arraybuffer'
        });
};


searchImportExportReportContent(params) {
    return axios.get(`${import_url}searchImportExportReportContent`, params);

};


downLoadImportExportXLSContent(params) {
    return axios.get(`${import_url}downLoadXLSConsolidatorContent`,
        {
            params: params,
            responseType: 'arraybuffer'
        });
};



// Consolidator Report

searchConsolidatorReport(params) {
    return axios.get(`${import_url}searchConsolidatorReport`, params);

};


downLoadXLS(params) {
    return axios.get(`${import_url}downLoadXLSConsolidator`,
        {
            params: params,
            responseType: 'arraybuffer'
        });
};




 // Billing Transaction
 getDailyBillingTransactionAfter(data) {
    return axios.get(`${invoice_url}searchDailyBillinTransactionAfter`, {
        params: data, // Pass the URL as a parameter
    });

};









// Daily Invoice Reports
downLoadDailyInvoiceSummaryXLS(data) {
    return axios.get(`${invoice_url}downLoadDailyInvoiceSummaryXLS`,
        {
            params: data,
            responseType: 'arraybuffer'
        });
};


generatePartyDailyInvoiceBillSummary(data) {
    return axios.get(`${invoice_url}generatePartyDailyInvoiceBillSummary`,
        {
            params: data,
            responseType: 'arraybuffer'
        });
};

// Party Daily Invoice Summary Report
getPartyDailyInvoiceSummaryReport(data) {
    return axios.get(`${invoice_url}getPartyDailyInvoiceSummaryReport`, {
        params: data, // Pass the URL as a parameter
    });
};




 // Party Daily Invoice XLS 
 getPartyDailyInvoiceDailyReportXLS(data) {
    return axios.get(`${invoice_url}downLoadDailyXLSDailyInvoice`, {
        params: data, responseType: 'arraybuffer'
    });
};



// Party Daily Invoice Search 
getPartyDailyInvoiceDailyReport(data) {
    return axios.get(`${invoice_url}getPartyDailyReportDailyInvoice`, {
        params: data, // Pass the URL as a parameter
    });
};







// Advance
addAdvaceReceipt(compId, branchId, partyId, partyName, user, finTrans, finTransDtl) {
    const requestData = {
        finTransDtl: finTransDtl,
        finTrans: finTrans      
    };
    return axios.post(`${payment_url}addAdvaceReceipt`, requestData, {
        params: {
            companyId: compId,
            branchId: branchId,
            partyId: partyId,
            PartyName: partyName,          
            userId: user
        }
    });
};


    // Receipt

    savePayment(compId, branchId, partyId, partyName, billingPartyId, billingPartyName, user, finTrans, finTransDtl, finTransInv) {
        const requestData = {
            finTransDtl: finTransDtl,
            finTrans: finTrans,
            finTransInv: finTransInv
        };
        return axios.post(`${payment_url}saveReceipt`, requestData, {
            params: {
                companyId: compId,
                branchId: branchId,
                partyId: partyId,
                PartyName: partyName,
                billingId: billingPartyId,
                billingName: billingPartyName,
                userId: user
            }
        });
    };

    // GateIn Summary
    getReceiptById(compId, branchId, receiptId, partyId) {
        return axios.get(`${payment_url}getReceiptById`, {
            params: {
                companyId: compId,
                branchId: branchId,
                partyId: partyId,
                receiptId: receiptId
            }

        }
        );
    };






    // GateIn Summary
    searchReceipts(params) {
        return axios.get(`${invoice_url}searchReceipts`, params
        );
    };



    // GateIn Summary
    searchForwardedInReport(params) {
        return axios.get(`${forwarded_In_url}searchForwardedInReport`, params
        );
    };


    downLoadForwardedInSummaryXLS(data) {
        return axios.get(`${forwarded_In_url}downLoadForwardedInSummaryXLS`,
            {
                params: data,
                responseType: 'arraybuffer'
            });
    };

    generateForwardedInSummaryPrint(data) {
        return axios.get(`${forwarded_In_url}downLoadForwardedInSummaryPrint`,
            {
                params: data,
                responseType: 'arraybuffer'
            });
    };




    // Get Todays Billing Data
    getMainByPartyId(data) {
        return axios.get(`${invoice_url}getInviceByPartyId`, {
            params: data, // Pass the URL as a parameter        
        });
    };

    // E-Invoice Data
    getEinvoiceData(data, screen) {
        return axios.get(`${invoice_url}getEinvoiceData/${screen}`, {
            params: data, // Pass the URL as a parameter
        });
    };

    // Ledger Report
    downLoadLedgerXLS(data) {
        return axios.get(`${invoice_url}downLoadLedgerXLS`,
            {
                params: data,
                responseType: 'arraybuffer'
            });
    };


    generatePartyLedgerPrint(data) {
        return axios.get(`${invoice_url}downLoadLedgerPrint`,
            {
                params: data,
                responseType: 'arraybuffer'
            });
    };

    // Party Summary Report
    getPartyLedgerData(data) {
        return axios.get(`${invoice_url}getPartyLedgerReport`, {
            params: data, // Pass the URL as a parameter
        });
    };



    getScanningData(compId, branchId, type, trans, trans2) {
        return axios.get(`${scanning_url}getScanningData`,
            {
                params:
                {
                    companyId: compId,
                    branchId: branchId,
                    type: type,
                    trans: trans,
                    trans2: trans2
                }
            });
    };


    getScanningDataSHBOut(compId, branchId, type, trans, trans2) {
        return axios.get(`${scanning_url}getScanningDataSHBOut`,
            {
                params:
                {
                    companyId: compId,
                    branchId: branchId,
                    type: type,
                    trans: trans,
                    trans2: trans2
                }
            });
    };


    // Print Monthly combine Invoice Report
    // getMonthlyReports(compid, bid, invoiceDate) {
    //     return axios.get(`${invoice_url}getMonthlyReports`, {
    //         params: {
    //             companyId: compid,
    //             branchId: bid,
    //             invoiceDate: invoiceDate
    //         }, responseType: 'arraybuffer'
    //     });
    // };

    // Party Summary Report
    getPartyDailyReportXLS(data) {
        return axios.get(`${invoice_url}downLoadDailyXLS`, {
            params: data, responseType: 'arraybuffer'
        });
    };



    // Party Summary Report
    getPartyDailyReport(data) {
        return axios.get(`${invoice_url}getPartyDailyReport`, {
            params: data, // Pass the URL as a parameter
        });
    };

    // Print ER Slips
    printErSlipsByCha(compid, bid, cha, erDate) {
        return axios.get(`${export_shb}printErSlipsByCha`, {
            params: {
                companyId: compid,
                branchId: bid,
                cha: cha,
                erDate: erDate
            }
        });
    };



    // Print Sir Tags By Master Bill Number 
    printErByConsoles(compid, bid, console, erDate) {
        return axios.get(`${barcode_url}printErByConsoles`, {
            params: {
                companyId: compid,
                branchId: bid,
                console: console,
                erDate: erDate
            }
        });
    };

    // *********************Party TDS Report **********************************

    // Advance Tab 

    addAdvamce(compId, branchId, partyId, user, dtl, trans) {
        const requestData = {
            finTransDtl: dtl,
            finTrans: trans
        };
        return axios.post(`${payment_url}addvance`, requestData,
            {
                params:
                {
                    companyId: compId,
                    branchId: branchId,
                    PartyId: partyId,
                    userId: user
                }
            });
    };








    // 
    downLoadTDSXLS(data) {
        return axios.get(`${invoice_url}downLoadTdsXLS`,
            {
                params: data,
                responseType: 'arraybuffer'
            });
    };


    generatePartyTDSPrint(data) {
        return axios.get(`${invoice_url}downLoadTdsPrint`,
            {
                params: data,
                responseType: 'arraybuffer'
            });
    };

    // Party Summary Report
    getPartyTDSData(data) {
        return axios.get(`${invoice_url}getPartyTdsReport`, {
            params: data, // Pass the URL as a parameter
        });
    };



    // *********************Party Bill Summary Report **********************************
    downLoadSummaryXLS(data) {
        return axios.get(`${invoice_url}downLoadSummaryXLS`,
            {
                params: data,
                responseType: 'arraybuffer'
            });
    };


    generatePartyBillSummary(data) {
        return axios.get(`${invoice_url}downLoadSummaryPrint`,
            {
                params: data,
                responseType: 'arraybuffer'
            });
    };

    // Party Summary Report
    getPartySummaryReport(data) {
        return axios.get(`${invoice_url}getPartySummaryReport`, {
            params: data, // Pass the URL as a parameter
        });
    };

    // Billing Transaction
    getBillingTransactionAfter(data) {
        return axios.get(`${invoice_url}searchBillinTransactionAfter`, {
            params: data, // Pass the URL as a parameter
        });

    };














    // PAYMENT TAB URL'S

    getReceiptDataOfParty(compId, branchId, partyId, invoiceNo) {
        return axios.get(`${payment_url}getReceiptData`,
            {
                params:
                {
                    companyId: compId,
                    branchId: branchId,
                    PartyId: partyId,
                    invoiceNo: invoiceNo
                }
            });
    };




    downloadReceipt(companyid, branchId, partyId, receiptId) {
        return axios.get(`${payment_url}downLoadReceipt`,
            {
                params:
                {
                    companyId: companyid,
                    branchId: branchId,
                    PartyId: partyId,
                    receiptId: receiptId
                },
                responseType: 'arraybuffer'
            });
    };

    addFintranceDTL(compId, branchId, partyId, user, dtl, trans) {
        const requestData = {
            finTransDtl: dtl,
            finTrans: trans
        };
        return axios.post(`${payment_url}addtransdtl`, requestData,
            {
                params:
                {
                    companyId: compId,
                    branchId: branchId,
                    PartyId: partyId,
                    userId: user
                }
            });
    };



    getAllAdvanceData(compId, branchId, partyId) {
        return axios.get(`${payment_url}getAllAdvanceData`,
            {
                params:
                {
                    companyId: compId,
                    branchId: branchId,
                    PartyId: partyId,
                }
            });
    };



    getPartyAdvAndClearedAmount(compId, branchId, partyId) {
        return axios.get(`${payment_url}getSumOfAdvAndCleared`,
            {
                params:
                {
                    companyId: compId,
                    branchId: branchId,
                    PartyId: partyId,
                }
            });
    };


    getTransIdByPartyId(compId, branchId, partyId) {
        return axios.get(`${payment_url}gettransByPartyId`,
            {
                params:
                {
                    companyId: compId,
                    branchId: branchId,
                    PartyId: partyId,
                }
            });
    };

    // Searching Invoice Numbers
    getTransByReceiptId(compId, branchId, receiptId, partyId) {
        return axios.get(`${payment_url}getTransReceiptId`,
            {
                params:
                {
                    companyId: compId,
                    branchId: branchId,
                    PartyId: partyId,
                    receiptId: receiptId
                }
            });
    };

    addAdvamce(compId, branchId, partyId, advance) {
        return axios.post(`${payment_url}addvance`, advance,
            {
                params:
                {
                    companyId: compId,
                    branchId: branchId,
                    PartyId: partyId  // Use 'PartyId' instead of 'partyId'
                }
            });
    };











    // Downloading Single Pdf Invice
    getSingleInvicePDFromBillsTab(compId, branchId, partyId, invoiceNo) {
        return axios.get(`${invoice_url}generateSingleinvice`,
            {
                params:
                {
                    companyId: compId,
                    branchId: branchId,
                    PartyId: partyId,
                    invoiceNo: invoiceNo
                },
                responseType: 'arraybuffer' // Tell Axios to treat the response as binary data
            });
    };





    // Get Todays Billing Data
    getPendingInvoicesPartyId(compId, branchId, partyId) {
        return axios.get(`${invoice_url}getpendingInvoices`, {
            params:
            {
                companyId: compId,
                branchId: branchId,
                PartyId: partyId
            }
        });
    };




    getCombinedImportsandxports(comapnyId, branchId, party) {
        return axios.get(`${invoice_url}searchBillinTransaction`, {
            params: {
                companyId: comapnyId,
                branchId: branchId,
                PartyId: party
            }
        });
    };







    getCommonPassPrint(companyId, BranchId, type, dataToSent) {
        // console.log("Data To BE Send ");
        // console.log(dataToSent);
        return axios.post(`${import_url}common/printgatepass/${companyId}/${BranchId}/${type}`, dataToSent);
    };

    getMopPassPrint1(dataToSent, cid, bid) {

        return axios.post(`${import_url}generateMopGetPass1/${cid}/${bid}`, dataToSent);
    };




    getCommonPassPrint(companyId, BranchId, type, dataToSent) {
        // console.log("Data To BE Send ");
        // console.log(dataToSent);
        return axios.post(`${import_url}common/printgatepass/${companyId}/${BranchId}/${type}`, dataToSent);
    };

    getCommonPassPrint1(companyId, BranchId, dataToSent) {
        // console.log("Data To BE Send ");
        // console.log(dataToSent);
        return axios.post(`${import_url}common/printgatepass1/${companyId}/${BranchId}/${dataToSent}`);
    };


    getProformaByPartyId(compId, branchId, partyId) {
        return axios.get(`${invoice_url}${compId}/${branchId}/${partyId}/getProformaByPartyId`);
    };

    getSingleProformaPDFromBillsTab(compId, branchId, partyId, invoiceNo) {
        return axios.get(`${invoice_url}${compId}/${branchId}/${partyId}/${invoiceNo}/getSingleProformaPDFromBillsTab`);
    };

    //getUserByUserId
    getUserByUserId(compId, branchId, userId) {
        // console.log("In service " + userId);
        return axios.get(`${user_url}${compId}/${branchId}/${userId}/findByUserId`)
    };


    // sendOtpForForgotPassword
    updatePassword(companyId, branchId, userId, password) {


        const requestData = {
            params: {
                companyId: companyId,
                branchId: branchId,
                userId: userId,
                password: password,
            }
        };

        return axios.get(`${user_url}UpdatePassword`, requestData)
    };


    confirmOtp(companyId, branchId, userId, otp) {

        const requestData = {
            params: {
                companyId: companyId,
                branchId: branchId,
                userId: userId,
                otp: otp,
            }
        };
        return axios.get(`${user_url}checkOtpForPasswordChange`, requestData)
    };




    // sendOtpForForgotPassword
    sendOtpForgotPassword(companyId, branchId, userId, mobileNo) {
        const requestData = {
            params: {
                companyId: companyId,
                branchId: branchId,
                userId: userId,
                mobileNo: mobileNo,
            }
        };
        return axios.get(`${auth_url}passwordChange`, requestData)
    };

    getAllCompanies(reactPageName) {
        return axios.get(`${user_url}company`, { headers: { 'React-Page-Name': reactPageName } });
    };

    getBranchesOfCompany(companyId, reactPageName) {
        return axios.get(`${user_url}${companyId}/branch`, { headers: { 'React-Page-Name': reactPageName } });
    };







    getMopPassPrint(type, dataToSent) {
        return axios.post(`${import_url}${type}/generateMopGetPass`, dataToSent);
    };

    getMOPSearchData(compId, branchId, searchValue) {
        return axios.get(`${import_url}${compId}/${branchId}/${searchValue}/getDataForMopGetPass`);
    };


    getDefaultPartyCha(companyId, BranchId, userId) {
        return axios.get(`${default_party_Cha}getdata/${companyId}/${BranchId}/${userId}`)
    }

    // Searching Invoice Numbers
    SearchInvoiceNoList(params) {
        return axios.get(`${invoice_url}getInvoiceNoListByParty`, params)
    }

    // Searching Invoice Numbers
    SearchInvoiceNoListByInvoiceHistoryNumber(params) {
        return axios.get(`${invoice_url}getInvoiceNoListByPartyAndInvoiceNumber`, params)
    }

    downLoadProforma(compId, branchId, invoiceNo) {
        return axios.get(`${invoice_url}${compId}/${branchId}/${invoiceNo}/generateProformapdf2`);
    };



    downLoad(compId, branchId, invoiceNo, invoicelist) {
        return axios.post(`${invoice_url}${compId}/${branchId}/${invoiceNo}/generatepdf`, invoicelist);
    };

    downLoadBill(compId, branchId, invoiceNo, invoicelist) {
        return axios.post(`${invoice_url}${compId}/${branchId}/${invoiceNo}/generatebillpdf`, invoicelist);
    };


    getPartyNameById(compId, branchId, PartyId) {
        return axios.get(`${party_url}${compId}/${branchId}/${PartyId}/findPartyName`)
    };

    generateInvoice(data) {
        return axios.get(`${invoice_url}InvoiceGeneration`, {
            params: data, // Pass the URL as a parameter
        });

    };

    getDetailByInvoiceNo(compId, branchId, invoiceNo) {
        return axios.get(`${invoice_url}${compId}/${branchId}/${invoiceNo}/getDetailByInvoiceNo`);
    };

    getInvoiceDetailByInvoiceNo(compId, branchId, partyId, invoiceNo) {
        return axios.get(`${invoicetaxdetails_url}${compId}/${branchId}/${partyId}/${invoiceNo}/byinvoicenumber`);
    };

    getbarcode(mawb, sir, noofpackages, sirdate, bedate, type, niptStatus, requestId, hawb, igm, subtype) {
        const requestData = {
            mawbno: mawb,
            sirno: sir,
            noOfPackages: noofpackages,
            sirDate: sirdate,
            reqDate: bedate,
            type: type,
            niptStatus: niptStatus,
            requestId: requestId,
            HAWB: hawb,
            IGM: igm,
            subType: subtype
        };

        return axios.post(`${barcode_url}generatePDFWithMultipleBarcodes`, requestData);
    };


    getbarcodeDetention(siNo, depositDate, noofpackages) {
        const requestData = {
            siNo: siNo,
            depositDate: depositDate,
            noOfPackages: noofpackages,

        };

        return axios.post(`${barcode_url}generatePDFWithMDetention`, requestData);
    };

    // Downloading Single Pdf Bill
    getSingleBillPDFromBillsTab(compId, branchId, partyId, invoiceNo) {
        return axios.get(`${invoice_url}${compId}/${branchId}/${partyId}/${invoiceNo}/generateSingleBill`);
    };





    // Print Sir Tags By Master Bill Number 
    printSirByMAWB(compid, bid, mawb) {
        return axios.get(`${barcode_url}${compid}/${bid}/${mawb}/printByMawb`);
    };




    // Downloading Single Pdf Demurages
    getSingleDemuragesPDFromBillsTab(compId, branchId, partyId, invoiceNo) {
        return axios.get(`${invoice_url}${compId}/${branchId}/${partyId}/${invoiceNo}/generateSingleDemurage`);
    };

    // GetData for Stock AT Vault
    // getdataForStockAtVault(compId,branch_id)
    // {
    //    return axios.get(`${barcode_url}${compId}/${branch_id}/getDataStockAtVault`)
    // };

    // GetData for Stock AT Vault
    // getdataForStockAtVault(compId, branch_id) {
    //     return axios.get(`${barcode_url}${compId}/${branch_id}/getDataStockAtVault`)
    // };

    // getdataForStockAtVaultDetention(compId, branch_id) {
    //     return axios.get(`${barcode_url}${compId}/${branch_id}/getDataStockAtVaultDetention`)
    // };


    getdataForStockAtVault(compId, branch_id) {
        return axios.get(`${barcode_url}${compId}/${branch_id}/getDataStockAtVault`)
    };

    getdataForStockAtVaultDetention(compId, branch_id) {
        return axios.get(`${barcode_url}${compId}/${branch_id}/getDataStockAtVaultDetention`)
    };



    // getBillingTransactionAfter(data) {
    //     return axios.get(`${import_url}searchBillinTransactionAfter`, {
    //         params: data, // Pass the URL as a parameter
    //     });

    // };

    // New Print

    getImportRegisterPrint(dataToSent) {
        return axios.get(`${import_url2}importRegiter`, {
            params: dataToSent,
        });
    };



}

export default new InviceService();