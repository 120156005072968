import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../Components/AuthProvider";
import { Pagination } from "react-bootstrap";
import {
    Card,
    CardBody,
    Col,
    FormGroup,
    Row,
    Label,
    Button,
    Table
} from "reactstrap";
import { toast } from "react-toastify";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCarriageBaby, faFileExcel, faFilePdf, faPrint, faSearch } from '@fortawesome/free-solid-svg-icons';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import ReactLoading from 'react-loading';
import InviceService from "../services/InviceService";
import moment from 'moment';
import DatePicker from "react-datepicker";
import Rate_Chart_Service from "../services/Rate_Chart_Service";


export default function ConsoleReport() {
    const {
        branchId,
        companyid
    } = useContext(AuthContext);


    const navigate = useNavigate();
    const { isAuthenticated } = useContext(AuthContext);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate(
                "/login?message=You need to be authenticated to access this page."
            );
        }
    }, [isAuthenticated, navigate]);



    const initialSearchCriteria = {
        companyId: companyid,
        branchId: branchId,
        startDate: moment(new Date()).format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
        process: '',
        console: ''
    };


    const [selectedConsole, setSelectedConsole] = useState(null);
    const [consoles, setConsoles] = useState([]);

    const [consolesShorts, setConsolesShorts] = useState([]);

    const [selectedProcess, setSelectedProcess] = useState(null);
    const [process, setProcess] = useState([]);

    const [searchCriteria, setSearchCriteria] = useState(initialSearchCriteria);
    const [loading, setLoading] = useState(false);
    const styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)', // Adjust the opacity and color as needed
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999, // Ensure the overlay is above other elements
        },
    };



    useEffect(() => {
        const fetchData = async () => {
            handleSearch(initialSearchCriteria);
        };
        fetchData();
    }, []);

    useEffect(() => {
        findConsoles();
        findProcess();
    }, []);


    const findExternalPartyByType = async (compid, branchId, Type) => {
        const partyResponse = await Rate_Chart_Service.getAllExternalPartiesByType(compid, branchId, Type);
        const partyOptions = partyResponse.data.map(externalUser => ({
            value: externalUser[0],
            label: externalUser[1]
        }));

        const partyOptionsShorts = partyResponse.data.map(externalUser => ({
            value: externalUser[0],
            shorts: externalUser[2]
        }));


        return {
            partyOptions: partyOptions,
            partyOptionsShorts: partyOptionsShorts
        };
    };
    const findConsoles = async () => {
        const partyOptions = await findExternalPartyByType(companyid, branchId, 'console')
        setConsoles(partyOptions.partyOptions);
        setConsolesShorts(partyOptions.partyOptionsShorts);
    };

    const findProcess = async () => {
        const PCKTYPEResponse = await Rate_Chart_Service.getjarsByJarId('J00041', companyid, branchId);
        const partyOptions = PCKTYPEResponse.data.map(jar => ({
            value: jar.jarDtlDesc,
            label: jar.jarDtlDesc
        }));
        setProcess(partyOptions);
    };



    const handleProcessChange = selectedOption => {
        setSelectedProcess(selectedOption);
        setSearchCriteria({ ...searchCriteria, process: selectedOption ? selectedOption.value : '' });
    };

    const handleConsoleChange = async (selectedOption) => {
        setSelectedConsole(selectedOption);
        setSearchCriteria({ ...searchCriteria, console: selectedOption ? selectedOption.value : '' });
    };


    const [importdata, setImportdata] = useState([]);
    const [exportdata, setExportdata] = useState([]);



    const allConsoles = Array.from(new Set(importdata.flatMap(entry => Object.keys(entry.consoles))));
    const allConsolesExports = Array.from(new Set(exportdata.flatMap(entry => Object.keys(entry.consoles))));

    const totals = allConsoles.reduce((acc, console) => {
        acc[console] = { nopSum: 0, noOfParcelSum: 0 };
        return acc;
    }, {});


    const totalsExports = allConsolesExports.reduce((acc, console) => {
        acc[console] = { nopSum: 0, noOfParcelSum: 0 };
        return acc;
    }, {});

    importdata.forEach(entry => {
        allConsoles.forEach(console => {
            totals[console].nopSum += entry.consoles[console]?.nopSum || 0;
            totals[console].noOfParcelSum += entry.consoles[console]?.noOfParcelSum || 0;
        });
    });

    exportdata.forEach(entry => {
        allConsolesExports.forEach(console => {
            totalsExports[console].nopSum += entry.consoles[console]?.nopSum || 0;
            totalsExports[console].noOfParcelSum += entry.consoles[console]?.noOfParcelSum || 0;
        });
    });

    const consoleMap = consolesShorts.reduce((acc, console) => {
        acc[console.value] = console.shorts;
        return acc;
    }, {});



    const handleSearch = async (searchCriteria) => {
        setLoading(true);
        try {
            const response = await InviceService.searchConsolidatorReport({ params: searchCriteria });
            const { IMPORT, EXPORT } = response.data

            setImportdata(IMPORT || []);
            setExportdata(EXPORT || []);

            console.log(response.data);
        }
        catch {
            toast.error('Oops something went wrong!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 700,
            });
        }
        finally {
            setLoading(false);
        }
    };



    const handleReset = () => {
        setSearchCriteria(initialSearchCriteria);
        setSelectedConsole(null);
        setSelectedProcess(null);
        handleSearch(initialSearchCriteria);
    };


    // const handlePrint = async (type) => {
    //     setLoading(true);
    //     try {
    //         const response = await InviceService.generateGateInSummaryPrint(searchCriteria);

    //         if (response.status === 200) {
    //             const pdfData = response.data;
    //             const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
    //             const blobUrl = URL.createObjectURL(pdfBlob);

    //             if (type === "PDF") {
    //                 // Create an anchor element for downloading
    //                 const downloadLink = document.createElement('a');
    //                 downloadLink.href = blobUrl;
    //                 downloadLink.download = 'GateIn_Summary.pdf';
    //                 downloadLink.style.display = 'none';
    //                 document.body.appendChild(downloadLink);
    //                 // Trigger the download
    //                 downloadLink.click();
    //                 // Clean up
    //                 document.body.removeChild(downloadLink);
    //                 window.URL.revokeObjectURL(blobUrl);
    //             }
    //             if (type === 'PRINT') {
    //                 window.open(blobUrl, '_blank');
    //             }
    //             toast.success("Downloading Pdf!", {
    //                 position: toast.POSITION.TOP_CENTER,
    //                 autoClose: 800,
    //             });
    //         } else {
    //             toast.error("error downLoading file!", {
    //                 position: toast.POSITION.TOP_CENTER,
    //                 autoClose: 800,
    //             });
    //         }
    //     } catch (error) {
    //         console.error("Error downloading PDF:", error);
    //     }
    //     finally {
    //         setLoading(false);
    //     }
    // };


    const handleXLSdownLoad = async () => {
        setLoading(true);
        try {
            const response = await InviceService.downLoadXLS(searchCriteria);

            if (response.status === 200) {
                const blob = new Blob([response.data], { type: response.headers['content-type'] });

                // Create a temporary URL for the blob
                const url = window.URL.createObjectURL(blob);

                // Create a link element to trigger the download
                const a = document.createElement("a");
                a.href = url;
                a.download = 'Consolidator_Report.xlsx';
                document.body.appendChild(a);
                a.click();

                // Clean up
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }
        }
        catch {
            toast.error("error downLoading file!", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 800,
            });
        }
        finally {
            setLoading(false);
        }
    };



    const calculateTotalColoumn = (data, columnName) => {
        const total = data.reduce((sum, row) => sum + (parseFloat(row[columnName]) || 0), 0);
        return total.toFixed(2); // Round the total to two decimal places
    };




    return (
        <>
            {loading && (
                <div style={styles.overlay}>
                    <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
                </div>
            )}



            <div className="Container">
                {/* <Container > */}
                <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '2%', paddingRight: '-20px' }}>
                    <FontAwesomeIcon
                        icon={faCarriageBaby}
                        style={{
                            marginRight: '8px',
                            color: 'black',
                        }}
                    />Console Report</h5>
                <Card style={{
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '40vw',
                    overflowX: "hidden",
                }} >
                    <CardBody style={{ paddingBottom: '-5px' }} >

                        <Row>

                            <Col md={2}>
                                <FormGroup>
                                    <Label className="forlabel" for="branchId">Date From</Label>
                                    <div> {/* Wrap in an input group */}

                                        <DatePicker
                                            selected={searchCriteria.startDate ? new Date(searchCriteria.startDate) : null}
                                            wrapperClassName="custom-react-datepicker-wrapper"
                                            onChange={(date) => {
                                                if (date) {
                                                    // Set the time to 12:00 AM in UTC time
                                                    date.setHours(12, 0, 0, 0);
                                                    // Convert the selected date and time to ISO format
                                                    const formattedDate = date.toISOString();
                                                    setSearchCriteria({ ...searchCriteria, startDate: formattedDate });
                                                } else {
                                                    setSearchCriteria({ ...searchCriteria, startDate: null });
                                                }
                                            }}
                                            dateFormat="dd/MM/yyyy" // Specify the combined format
                                            className="form-control border-right-0 inputField"
                                            customInput={<input style={{ width: '100%' }} />}

                                        />
                                    </div>
                                </FormGroup>
                            </Col>


                            <Col md={2}>
                                <FormGroup>
                                    <Label className="forlabel" for="branchId">Date To</Label>
                                    <div> {/* Wrap in an input group */}
                                        <DatePicker
                                            selected={searchCriteria.endDate ? new Date(searchCriteria.endDate) : null} // Use endDate from searchCriteria if it's defined
                                            wrapperClassName="custom-react-datepicker-wrapper"
                                            onChange={(date) => {
                                                if (date) {
                                                    // Set the time to 12:00 PM (noon)
                                                    date.setHours(12, 0, 0, 0);
                                                    const formattedDate = date.toISOString(); // Convert to ISO format
                                                    setSearchCriteria({ ...searchCriteria, endDate: formattedDate });
                                                } else {
                                                    setSearchCriteria({ ...searchCriteria, endDate: null });
                                                }
                                            }}
                                            dateFormat="dd/MM/yyyy"
                                            className="form-control border-right-0 inputField"
                                            customInput={<input style={{ width: '100%' }} />}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>



                            <Col md={4}>
                                <FormGroup>
                                    <Label className="forlabel" for="typeOfProduct">Select Console</Label>

                                    <Select
                                        options={consoles}
                                        value={selectedConsole}
                                        onChange={handleConsoleChange}
                                        isClearable
                                        placeholder="Select a Console"
                                        styles={{
                                            control: (provided, state) => ({
                                                ...provided,
                                                border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                                                boxShadow: 'none',
                                                '&:hover': {
                                                    border: '1px solid #ccc'
                                                }
                                            }),
                                            indicatorSeparator: () => ({
                                                display: 'none'
                                            }),
                                            dropdownIndicator: () => ({
                                                display: 'none'
                                            })
                                        }}
                                    />

                                </FormGroup>
                            </Col>



                            <Col md={4}>
                                <FormGroup>
                                    <Label className="forlabel" for="branchId">Select Process</Label>
                                    <Select
                                        options={process}
                                        value={selectedProcess}
                                        onChange={handleProcessChange}
                                        isClearable
                                        placeholder="Select a Process"
                                        styles={{
                                            control: (provided, state) => ({
                                                ...provided,
                                                border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                                                boxShadow: 'none',
                                                '&:hover': {
                                                    border: '1px solid #ccc'
                                                }
                                            }),
                                            indicatorSeparator: () => ({
                                                display: 'none'
                                            }),
                                            dropdownIndicator: () => ({
                                                display: 'none'
                                            })
                                        }}
                                    />
                                </FormGroup>
                            </Col>




                        </Row>

                        <Row>

                            <div className="text-center" style={{ marginTop: '5px' }}>

                                <Button
                                    color="primary"
                                    outline
                                    onClick={() => handleSearch(searchCriteria)}
                                    className="mr-2"
                                    style={{ marginRight: '25px', fontWeight: 'bold' }}
                                >
                                    <FontAwesomeIcon icon={faSearch} style={{ marginRight: '5px' }} />
                                    SEARCH
                                </Button>
                                <Button
                                    color="danger"
                                    outline
                                    onClick={handleReset}
                                    className="mr-2"
                                    style={{ marginRight: '5px', fontWeight: 'bold' }}
                                >
                                    <FontAwesomeIcon icon={faSyncAlt} style={{ marginRight: '5px' }} />
                                    RESET
                                </Button>
                            </div>

                        </Row>



                        <hr />



                        {((importdata && importdata.length > 0) || (exportdata && exportdata.length > 0)) && (
                            <Row className='mt-1 mb-1'>
                                <Col className="text-end mt-1">
                                    <Button
                                        color="success"
                                        outline
                                        style={{ marginLeft: 5, fontWeight: 'bold' }}
                                        onClick={handleXLSdownLoad}
                                    >
                                        <FontAwesomeIcon
                                            icon={faFileExcel}
                                            style={{ marginRight: "5px" }}
                                        />
                                        XLS
                                    </Button>
                                </Col>
                            </Row>
                        )}


                        {importdata && importdata.length > 0 && (
                            <>
                                <div className="table-responsive mt-3">

                                    <h3 className="text-center"><strong>IMPORT</strong></h3>
                                    <Table className="table table-bordered table-hover" style={{ border: '2px solid black' }}>
                                        <thead className="thead-dark bg-dark">
                                            <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px' }}>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font', height: '60px' }}>YEAR</th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>MONTH</th>
                                                {allConsoles.map(console => (
                                                    <th key={console} colSpan={2} className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>
                                                        {consoleMap[console] || console}
                                                    </th>
                                                ))}
                                            </tr>
                                            <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px' }}>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}></th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}></th>
                                                {allConsoles.map(console => (
                                                    <>
                                                        <th key={console + '_pkg'} className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>PKG</th>
                                                        <th key={console + '_pcl'} className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>PCL</th>
                                                    </>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {importdata.map((entry, index) => (
                                                <tr key={index} className='text-center'>
                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{entry.year}</td>
                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{entry.month}</td>
                                                    {allConsoles.map(console => (
                                                        <>
                                                            <td key={console + '_pkg_data'} style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>
                                                                {entry.consoles[console]?.nopSum || 0}
                                                            </td>
                                                            <td key={console + '_pcl_data'} style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>
                                                                {entry.consoles[console]?.noOfParcelSum || 0}
                                                            </td>
                                                        </>
                                                    ))}
                                                </tr>
                                            ))}

                                            <tr className="text-center dynamic-row-width total-row">
                                                <td colSpan={2}>Total</td>
                                                {allConsoles.map(console => (
                                                    <React.Fragment key={console}>
                                                        <td>
                                                            {totals[console].nopSum}
                                                        </td>
                                                        <td>
                                                            {totals[console].noOfParcelSum}
                                                        </td>
                                                    </React.Fragment>
                                                ))}
                                            </tr>
                                        </tbody>
                                    </Table>


                                </div>
                            </>
                        )}

                        {exportdata && exportdata.length > 0 && (
                            <>
                                <hr />
                                <div className="table-responsive mt-3">

                                    <h3 className="text-center"><strong>EXPORT</strong></h3>
                                    <Table className="table table-bordered table-hover" style={{ border: '2px solid black' }}>
                                        <thead className="thead-dark bg-dark">
                                            <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px' }}>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font', height: '60px' }}>YEAR</th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>MONTH</th>
                                                {allConsolesExports.map(console => (
                                                    <th key={console} colSpan={2} className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>
                                                        {consoleMap[console] || console}
                                                    </th>
                                                ))}
                                            </tr>
                                            <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px' }}>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}></th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}></th>
                                                {allConsolesExports.map(console => (
                                                    <>
                                                        <th key={console + '_pkg'} className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>PKG</th>
                                                        <th key={console + '_pcl'} className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>PCL</th>
                                                    </>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {exportdata.map((entry, index) => (
                                                <tr key={index} className='text-center'>
                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{entry.year}</td>
                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{entry.month}</td>
                                                    {allConsolesExports.map(console => (
                                                        <>
                                                            <td key={console + '_pkg_data'} style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>
                                                                {entry.consoles[console]?.nopSum || 0}
                                                            </td>
                                                            <td key={console + '_pcl_data'} style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>
                                                                {entry.consoles[console]?.noOfParcelSum || 0}
                                                            </td>
                                                        </>
                                                    ))}
                                                </tr>
                                            ))}


                                            <tr className="text-center dynamic-row-width total-row">
                                                <td colSpan={2}>Total</td>
                                                {allConsolesExports.map(console => (
                                                    <React.Fragment key={console}>
                                                        <td>
                                                            {totalsExports[console].nopSum}
                                                        </td>
                                                        <td>
                                                            {totalsExports[console].noOfParcelSum}
                                                        </td>
                                                    </React.Fragment>
                                                ))}
                                            </tr>
                                        </tbody>
                                    </Table>


                                </div>
                            </>
                        )}
                    </CardBody>
                </Card>
            </div>
        </>
    );
}