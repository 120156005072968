// const ipaddress = '103.189.88.215';
// const ipaddress ='103.189.88.215:8080/';



// const ipaddress ='3.110.185.139:9090/DGDC/';


// const ipaddress ='13.200.53.157:9090/DGDC/';




// const ipaddress ='13.200.53.157:9090/DGDC/';






// const ipaddress ='3.109.62.177:8443/DGDC_SHB/';


// const ipaddress ='103.189.88.215:8085/';


const ipaddress ='dgdcshb.in:8443/DGDC_SHB/';


// const ipaddress ='rapportsoft.in:8443/DGDC/';





//const ipaddress = '52.206.107.131:8080/DGDC/'13.201.217.58


// 103.101.58.143


// const ipaddress ='192.168.1.123:8080/DGDC/';
export default ipaddress;