// import AuthContext from "../Components/AuthProvider";
// import { useNavigate } from "react-router-dom";
// import React, { useEffect, useState, useContext, useCallback, useRef } from "react";
// import Tab from 'react-bootstrap/Tab';
// import Tabs from 'react-bootstrap/Tabs';
// import Button from 'react-bootstrap/Button';
// import ipaddress from "../Components/IpAddress";
// import ReactLoading from 'react-loading';
// import {
//     Card,
//     CardBody,
//     Container,
//     Row,
//     Col,
//     Form,
//     FormGroup,
//     Label,
//     Input,
//     Table,
// } from "reactstrap";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowDown, faArrowUp, faBarcode, faSearch } from '@fortawesome/free-solid-svg-icons';
// import { faEdit } from '@fortawesome/free-solid-svg-icons';
// import { faTrash } from '@fortawesome/free-solid-svg-icons';
// import { faCheck, faSave, faTimes, faSyncAlt, faCancel, faCog, faPrint, faXmark, faFileLines, faChessKing } from '@fortawesome/free-solid-svg-icons';
// import axios from "axios";
// import { toast } from "react-toastify";
// import "../Components/Style.css";
// import InviceService from "../services/InviceService";

// export default function Scan_Parcels1() {
//     const styles = {
//         overlay: {
//             position: 'fixed',
//             top: 0,
//             left: 0,
//             width: '100%',
//             height: '100%',
//             backgroundColor: 'rgba(255, 255, 255, 0.8)', // Adjust the opacity and color as needed
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             zIndex: 9999, // Ensure the overlay is above other elements
//         },
//     };
//     const navigate = useNavigate();
//     const [getlink, setGetLink] = useState("");
//     const [getalldata, setAlldata] = useState([]);
//     const {
//         jwtToken,
//         userId,
//         username,
//         branchId,
//         companyid,
//         role,
//         companyname,
//         branchname,
//         login,
//         logout,
//     } = useContext(AuthContext);
//     const { isAuthenticated } = useContext(AuthContext);
//     useEffect(() => {
//         if (!isAuthenticated) {
//             navigate(
//                 "/login?message=You need to be authenticated to access this page."
//             );
//         }
//     }, [isAuthenticated, navigate]);


//     const getScan = {
//         companyId: companyid,
//         branchId: branchId,
//         createdBy: userId,
//         editedBy: userId,
//         approvedBy: userId,
//         link: getlink,
//         status: "A",


//     }

//     const handleSubmit = () => {
//         getScan.companyId = companyid;
//         getScan.branchId = branchId;
//         getScan.createdBy = userId;
//         getScan.editedBy = userId;
//         getScan.approvedBy = userId;


//         axios.post(`https://${ipaddress}export/readgateinurl`, getScan)
//             .then(response => {
//                 toast.success("Data scanned successfully", {
//                     autoClose: 700
//                 })
//                 getalldataa();
//                 console.log('Post request successful:', response.data);
//                 setGetLink('');

//                 getalldataa();
//             })
//             .catch(error => {
//                 // Handle error
//                 console.error('Error sending post request:', error);
//                 getalldataa();
//                 // Check if the error status code is 401
//                 if (error.response && error.response.status === 401) {
//                     // Show an error message for unauthorized access
//                     toast.error("Data already exists.", {
//                         autoClose: 700
//                     });
//                     getalldataa();
//                 }
//                 setGetLink('');
//             });
//     };
//     const [len, setLen] = useState(0); // Initialize len with 0
//     //     if(handleSubmit){
//     //         setTimeout(() => {

//     //     const linkLength = getlink.length;
//     //     setLen(linkLength);
//     // }, 10);

//     // }

//     useEffect(() => {
//         // Check if the length of getlink matches len
//         if (getlink.length >= 50) {
//             // Delay the execution of handleSubmit by 10 milliseconds
//             const timer = setTimeout(() => {
//                 handleSubmit();
//             }, 60);

//             // Cleanup the timer to avoid multiple calls
//             return () => clearTimeout(timer);
//         }
//     }, [getlink, len]);



//     const [exportincount, setExportinCount] = useState(0);
//     const [importoutcount, setImportoutcount] = useState(0);
//     const getalldataa = () => {
//         axios
//             .get(`https://${ipaddress}scan/cargodata/${companyid}/${branchId}`)
//             .then((response) => {
//                 const exportInCount = response.data.filter(item => item.typeOfTransaction === 'Export-in').length;
//                 setExportinCount(exportInCount);
//                 const importOutCount = response.data.filter(item => item.typeOfTransaction === 'Import-out').length;
//                 setImportoutcount(importOutCount);
//                 setAlldata(response.data); // Store the list in the state
//             })
//             .catch((error) => {
//                 console.error("GET list error:", error);
//             });
//     };

//     useEffect(() => {
//         getalldataa();
//     }, [companyid, branchId])


//     const convertTimestampToDateTime = (timestamp) => {
//         const date = new Date(timestamp);

//         // Get the individual components (day, month, year, hours, minutes, seconds)
//         const day = date.getDate();
//         const month = date.getMonth() + 1; // Note: Months are zero-based, so we add 1
//         const year = date.getFullYear();
//         const hours = date.getHours();
//         const minutes = date.getMinutes();
//         const seconds = date.getSeconds();

//         // Create a formatted date and time string
//         const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

//         return formattedDateTime;
//     }

//     const [partys, setPartys] = useState([]);
//     const [getpartyId, setGetpartyId] = useState({});

//     const fetchPartyNames = async () => {
//         try {
//             const response = await fetch(`https://${ipaddress}parties/getAll/${companyid}/${branchId}`);
//             const data = await response.json();
//             const namesMap = {};
//             data.forEach(party => {
//                 namesMap[party.partyId] = party.partyName;
//             });
//             setGetpartyId(namesMap);
//             setPartys(data);
//         } catch (error) {
//             console.error("Error fetching party names:", error);
//         }
//     };


//     useEffect(() => {
//         fetchPartyNames();
//     }, [companyid, branchId])





//     const [CARGOInData, setCARGOInData] = useState([]);
//     const [CARGOOutData, setCARGOOutData] = useState([]);
//     const [CARGOInSummary, setCARGOInSummary] = useState([]);
//     const [CARGOOutSummary, setCARGOOutSummary] = useState([]);
//     const [TotalExportIn, setTotalExportIn] = useState(0);
//     const [TotalExportParcelIn, setTotalExportParcelIn] = useState(0);
//     const [TotalImportOut, setTotalImportOut] = useState(0);
//     const [TotalImportParcelOut, setTotalImportParcelOut] = useState(0);

//     const getCARGOInData = async () => {
//         const { List, summary } = await getData('cargo', 'Export-in', '');
//         setCARGOInData(List);
//         let exportParcelTotal = 0;
//         let exportTotal = 0;
//         summary.forEach(item => {
//             exportTotal += item[1];
//             exportParcelTotal += item[3];
//         });
//         console.log("In");
//         console.log(summary);
//         setTotalExportIn(exportTotal);
//         setTotalExportParcelIn(exportParcelTotal);
//         setCARGOInSummary(summary);      
//     };

//     const getCARGOOutData = async () => {
//         const { List, summary } = await getData('cargo', 'Import-out', '');
//         setCARGOOutData(List);
//         let importTotal = 0;
//         let importParcelTotal = 0;
// console.log("Out");
// console.log(summary);
//         summary.forEach(item => {
//             importTotal += item[1];
//             importParcelTotal += item[3];
//         });
//         setTotalImportOut(importTotal);
//         setTotalImportParcelOut(importParcelTotal);
//         setCARGOOutSummary(summary);        
//     };


//     const getData = async (type, trans1, trans2) => {
//         const response = await InviceService.getScanningData(companyid, branchId, type, trans1, trans2);
//         return response.data;
//     };

//     useEffect(() => {
//         getCARGOInData();
//         getCARGOOutData();
//     }, [companyid, branchId])







//     const [scanalldata, setScalalldata] = useState('');
//     const index1 = scanalldata.length - 4;
//     const sir = scanalldata.substring(0, index1).toUpperCase();
//     const packnum = scanalldata.substring(index1);
//     const [exportsubdata, setExportsubdata] = useState([]);
//     const [loading1, setLoading1] = useState(false);



//     const handleSubmit1 = () => {
//         setLoading1(true);


//         axios.post(`https://${ipaddress}scan/alldataforcargoout/${companyid}/${branchId}/${userId}/${sir}/${packnum}`)
//             .then(response => {
//                 if (response.data === "wrong barcode") {
//                     toast.error("Invalid qr or barcode format", {
//                         autoClose: 700
//                     });
//                     setScalalldata('');
//                     getCARGOOutData();
//                     setLoading1(false);
//                     return;
//                 }

//                 if (response.data === "not found") {
//                     toast.error("Data not found", {
//                         autoClose: 700
//                     });
//                     setScalalldata('');
//                     getCARGOOutData();
//                     setLoading1(false);
//                     return;
//                 }
//                 if (response.data === 'NOP is not matched') {
//                     toast.success("Data scanned successfully", {
//                         autoClose: 700
//                     })
//                     setScalalldata('');
//                     getCARGOOutData();
//                     setLoading1(false);
//                     return;
//                 }
//                 if (response.data === "wrong status") {
//                     toast.error("Data hasn't been handed over to carting agent", {
//                         autoClose: 700
//                     });
//                     setScalalldata('');
//                     getCARGOOutData();
//                     setLoading1(false);
//                     return;
//                 }
//                 if (response.data === "Dgdc_seepz_out_scan is already 'Y'") {
//                     toast.error("Data already scanned", {
//                         autoClose: 700
//                     })
//                     getCARGOOutData();
//                     setScalalldata('');
//                     setLoading1(false);
//                     return;
//                 }
//                 if (response.data === "success") {
//                     toast.success("Data scanned successfully", {
//                         autoClose: 700
//                     })
//                     setLoading1(false);
//                     setScalalldata('');
//                     getCARGOOutData();
//                 }

//                 setLoading1(false);
//                 setScalalldata('');
//                 getCARGOOutData();

//             })
//             .catch(error => {
//                 if (error) {
//                     toast.error("Data not found", {
//                         autoClose: 700
//                     })
//                     getCARGOOutData();
//                     setLoading1(false);
//                 }
//                 getCARGOOutData();
//                 setScalalldata('');
//                 setLoading1(false);
//             });
//     };






//     const [scanalldata1, setScalalldata1] = useState('');
//     const index2 = scanalldata1.length - 4;
//     const sir1 = scanalldata1.substring(0, index2).toUpperCase();
//     const packnum1 = scanalldata1.substring(index2);
//     const [exportsubdata1, setExportsubdata1] = useState([]);
//     const [loading, setLoading] = useState(false);



//     const handleSubmit2 = () => {
//         setLoading(true);


//         axios.post(`https://${ipaddress}scan/alldataforcargoin/${companyid}/${branchId}/${userId}/${sir1}/${packnum1}`)
//             .then(response => {
//                 if (response.data === "wrong barcode") {
//                     toast.error("Invalid qr or barcode format", {
//                         autoClose: 700
//                     });
//                     setScalalldata1('');
//                     getCARGOInData();
//                     setLoading(false);
//                     return;
//                 }

//                 if (response.data === "not found") {
//                     toast.error("Data not found", {
//                         autoClose: 700
//                     });
//                     setScalalldata1('');
//                     getCARGOInData();
//                     setLoading(false);
//                     return;
//                 }
//                 if (response.data === 'NOP is not matched') {
//                     toast.success("Data scanned successfully", {
//                         autoClose: 700
//                     })
//                     setScalalldata1('');
//                     getCARGOInData();
//                     setLoading(false);
//                     return;
//                 }
//                 if (response.data === "wrong status") {
//                     toast.error("Data hasn't been exit from dgdc seepz gate", {
//                         autoClose: 700
//                     });
//                     setScalalldata1('');
//                     getCARGOInData();
//                     setLoading(false);
//                     return;
//                 }
//                 if (response.data === "Dgdc_seepz_out_scan is already 'Y'") {
//                     toast.error("Data already scanned", {
//                         autoClose: 700
//                     })
//                     getCARGOInData();
//                     setScalalldata1('');
//                     setLoading(false);
//                     return;
//                 }
//                 if (response.data === "success") {
//                     toast.success("Data scanned successfully", {
//                         autoClose: 700
//                     });
//                     getCARGOInData();
//                     setScalalldata1('');
//                     setLoading(false);
//                 }
//                 getCARGOInData();
//                 setScalalldata1('');
//                 setLoading(false);
//             })
//             .catch(error => {
//                 if (error) {
//                     toast.error("Data not found", {
//                         autoClose: 700
//                     })
//                     getCARGOInData();
//                     setLoading(false);
//                 }
//                 getCARGOInData();
//                 setScalalldata1('');
//                 setLoading(false);
//             });
//     };

//     const inputRef = useRef();

//     const handleKeyPress = (event) => {
//         if (event.key === 'Enter') {
//             event.preventDefault();
//             document.getElementById("submitButton").click();
//         }
//     };


//     const inputRef1 = useRef();

//     const handleKeyPress1 = (event) => {
//         if (event.key === 'Enter') {
//             event.preventDefault();
//             document.getElementById("submitButton1").click();
//         }
//     };

//     // Sort  Table 
//     const [sortBy, setSortBy] = useState(null);
//     const [sortOrder, setSortOrder] = useState('asc'); // Initial sort order
//     const handleSort = (columnName) => {
//         if (sortBy === columnName) {
//             // If already sorting by this column, reverse the order
//             setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
//         } else {
//             // If sorting by a different column, set it as the new sort column
//             setSortBy(columnName);
//             setSortOrder('asc'); // Default to ascending order
//         }
//     };
//     const sortedInData = [...CARGOInData].sort((a, b) => {
//         if (sortBy) {
//             const valueA = a[sortBy];
//             const valueB = b[sortBy];
//             if (valueA < valueB) {
//                 return sortOrder === 'asc' ? -1 : 1;
//             }
//             if (valueA > valueB) {
//                 return sortOrder === 'asc' ? 1 : -1;
//             }
//             return 0;
//         }
//         return 0;
//     });


//     const [sortOutBy, setSortOutBy] = useState(null);
//     const [sortOrderOut, setSortOrderOut] = useState('asc'); // Initial sort order
//     const sortedOutData = [...CARGOOutData].sort((a, b) => {
//         if (sortOutBy) {
//             const valueA = a[sortOutBy];
//             const valueB = b[sortOutBy];
//             if (valueA < valueB) {
//                 return sortOrderOut === 'asc' ? -1 : 1;
//             }
//             if (valueA > valueB) {
//                 return sortOrderOut === 'asc' ? 1 : -1;
//             }
//             return 0;
//         }
//         return 0;
//     });

//     const handleSortOut = (columnName) => {
//         if (sortOutBy === columnName) {
//             // If already sorting by this column, reverse the order
//             setSortOrderOut(sortOrderOut === 'asc' ? 'desc' : 'asc');
//         } else {
//             // If sorting by a different column, set it as the new sort column
//             setSortOutBy(columnName);
//             setSortOrderOut('asc'); // Default to ascending order
//         }
//     };



//     return (
//         <Container>

//             <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '4%', paddingRight: '-50px' }} > <FontAwesomeIcon
//                 icon={faBarcode}
//                 style={{
//                     marginRight: '8px',
//                     color: 'black', // Set the color to golden
//                 }}
//             />Scan Parcel</h5>

//             <Card  >
//                 <CardBody>


//                     <Tabs
//                         defaultActiveKey="home"
//                         transition={false}
//                         id="noanim-tab-example"
//                         className="mb-3"
//                     >

//                         <Tab eventKey="home" title="Incoming">
//                             {loading && (
//                                 <div style={styles.overlay}>
//                                     <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
//                                 </div>
//                             )}
//                             <Row>
//                                 <Col md={4}>
//                                     <FormGroup>
//                                         <Label className="forlabel" for="branchId">Scan URL</Label>
//                                         <Input
//                                             type="text"
//                                             name="remarks"
//                                             id="branchname"
//                                             placeholder='Scan SER'
//                                             value={scanalldata1}
//                                             ref={inputRef1}

//                                             onKeyDown={handleKeyPress1}
//                                             autoFocus
//                                             onChange={(e) => setScalalldata1(e.target.value)}
//                                             className="inputField"

//                                         />
//                                     </FormGroup>
//                                 </Col>
//                                 <Col md={2}>
//                                     <Button id="submitButton1" variant="outline-primary" onClick={handleSubmit2} style={{ marginTop: 32 }}>
//                                         <FontAwesomeIcon icon={faSearch} style={{ marginRight: '5px' }} />
//                                         Scan
//                                     </Button>
//                                 </Col>
//                                 <Col md={6}>
//                                     {CARGOInSummary && CARGOInSummary.length > 0 && (
//                                         <Table className="table table-bordered table-hover">
//                                             <thead className="thead-dark bg-dark">
//                                                 <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px' }}>
//                                                     <th rowSpan='2' className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Console</th>
//                                                     <th colSpan='2' className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Export</th>
//                                                 </tr>
//                                                 <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '18px' }}>
//                                                     <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Parcel</th>
//                                                     <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Packets</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody>
//                                                 {CARGOInSummary.map((item, index) => (

//                                                     <tr key={index} className='text-center' style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '15px' }}>
//                                                         <td>{item[0]}</td>
//                                                         <td>{item[3]}</td>
//                                                         <td>{item[1]}</td>
//                                                     </tr>
//                                                 ))}
//                                                 <tr className="text-center" style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '19px', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
//                                                     <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>Total</td>
//                                                     <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{TotalExportParcelIn}</td>
//                                                     <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{TotalExportIn}</td>
//                                                 </tr>
//                                             </tbody>

//                                         </Table>
//                                     )}

//                                 </Col>
//                             </Row>
//                             <Row style={{ marginTop: 30 }}>
//                                 <div className="table-responsive">
//                                     <Table className="table table-bordered table-hover" style={{ border: '2px solid black' }}>
//                                         <thead className="thead-dark bg-dark">
//                                             <tr className='text-center' style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', fontSize: '17px', color: 'black', fontFamily: 'Your-Heading-Font', height: '60px' }}>
//                                                 <th scope="col" style={{ backgroundColor: '#BADDDA' }}>Sr No.</th>
//                                                 <th scope="col" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Type of Transaction</th>

//                                                 <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('srNo')} className="sortable-header">

//                                                     SER / SIR NO
//                                                     <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                         {sortOrderOut === 'asc' ? (
//                                                             <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                         ) : (
//                                                             <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                         )}
//                                                     </span>
//                                                 </th>
//                                                 <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} className="sortable-header">

//                                                     Parcel Type

//                                                     <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                         {sortOrderOut === 'asc' ? (
//                                                             <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                         ) : (
//                                                             <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                         )}
//                                                     </span>
//                                                 </th>
//                                                 <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('doc_Ref_No')} className="sortable-header">
//                                                     SB/BE No.

//                                                     <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                         {sortOrderOut === 'asc' ? (
//                                                             <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                         ) : (
//                                                             <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                         )}
//                                                     </span>

//                                                 </th>
//                                                 <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('party')} className="sortable-header">
//                                                     Exporter/Importer Name

//                                                     <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                         {sortOrderOut === 'asc' ? (
//                                                             <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                         ) : (
//                                                             <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                         )}
//                                                     </span>
//                                                 </th>
//                                                 <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('nop')} className="sortable-header">
//                                                     No. of packages

//                                                     <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                         {sortOrderOut === 'asc' ? (
//                                                             <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                         ) : (
//                                                             <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                         )}
//                                                     </span>
//                                                 </th>
//                                                 <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('packnum')} className="sortable-header">
//                                                     Package Number


//                                                     <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                         {sortOrderOut === 'asc' ? (
//                                                             <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                         ) : (
//                                                             <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                         )}
//                                                     </span>
//                                                 </th>
//                                                 <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('gateiout')} className="sortable-header">
//                                                     Date & Time

//                                                     <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                         {sortOrderOut === 'asc' ? (
//                                                             <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                         ) : (
//                                                             <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                         )}
//                                                     </span>
//                                                 </th>


//                                             </tr>
//                                         </thead>
//                                         <tbody>
//                                             {sortedInData.map((item, index) => (

//                                                 <tr key={index} >

//                                                     <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{index + 1}</td>
//                                                     <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.typeOfTransaction}</td>

//                                                     <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.srNo}</td>
//                                                     <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}></td>
//                                                     <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.doc_Ref_No}</td>
//                                                     <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.party}</td>
//                                                     <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.nop}</td>
//                                                     <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.packnum}</td>
//                                                     <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{convertTimestampToDateTime(item.gateiout)}</td>
//                                                 </tr>
//                                             ))}
//                                         </tbody>
//                                     </Table>
//                                 </div>

//                             </Row>

//                         </Tab>
//                         <Tab eventKey="profile" title="Outgoing">
//                             {loading1 && (
//                                 <div style={styles.overlay}>
//                                     <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
//                                 </div>
//                             )}
//                             <Row>
//                                 <Col md={4}>
//                                     <FormGroup>
//                                         <Label className="forlabel" for="branchId">Scan URL</Label>
//                                         <Input
//                                             type="text"
//                                             name="remarks"
//                                             id="branchname"
//                                             placeholder='Scan SIR'
//                                             ref={inputRef}
//                                             onKeyDown={handleKeyPress}
//                                             value={scanalldata}
//                                             onChange={(e) => setScalalldata(e.target.value)}
//                                             className="inputField"
//                                             autoFocus
//                                         />
//                                     </FormGroup>
//                                 </Col>
//                                 <Col md={2}>
//                                     <Button id="submitButton" variant="outline-primary" onClick={handleSubmit1} style={{ marginTop: 32 }}>
//                                         <FontAwesomeIcon icon={faSearch} style={{ marginRight: '5px' }} />
//                                         Scan
//                                     </Button>
//                                 </Col>
//                                 <Col md={6}>
//                                     {CARGOOutSummary && CARGOOutSummary.length > 0 && (
//                                         <Table className="table table-bordered table-hover">
//                                             <thead className="thead-dark bg-dark">
//                                                 <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px' }}>
//                                                     <th rowSpan='2' className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Console</th>
//                                                     <th colSpan='2' className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Import</th>
//                                                 </tr>
//                                                 <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '18px' }}>
//                                                     <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Parcel</th>
//                                                     <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Packets</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody>
//                                                 {CARGOOutSummary.map((item, index) => (

//                                                     <tr key={index} className='text-center' style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '15px' }}>
//                                                         <td>{item[0]}</td>
//                                                         <td>{item[3]}</td>
//                                                         <td>{item[1]}</td>
//                                                     </tr>
//                                                 ))}
//                                                 <tr className="text-center" style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '19px', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
//                                                     <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>Total</td>
//                                                     <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{TotalImportParcelOut}</td>
//                                                     <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{TotalImportOut}</td>
//                                                 </tr>
//                                             </tbody>
//                                         </Table>
//                                     )}
//                                 </Col>
//                             </Row>

//                             <Row style={{ marginTop: 30 }}>
//                                 <div className="table-responsive">
//                                     <Table className="table table-bordered table-hover" style={{ border: '2px solid black' }}>
//                                         <thead className="thead-dark bg-dark">
//                                             <tr className='text-center' style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', fontSize: '17px', color: 'black', fontFamily: 'Your-Heading-Font', height: '60px' }}>
//                                                 <th scope="col" style={{ backgroundColor: '#BADDDA' }}>Sr No.</th>
//                                                 <th scope="col" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Type of Transaction</th>

//                                                 <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('srNo')} className="sortable-header">

//                                                     SIR NO
//                                                     <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                         {sortOrderOut === 'asc' ? (
//                                                             <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                         ) : (
//                                                             <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                         )}
//                                                     </span>
//                                                 </th>
//                                                 <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} className="sortable-header">

//                                                     Parcel Type

//                                                     <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                         {sortOrderOut === 'asc' ? (
//                                                             <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                         ) : (
//                                                             <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                         )}
//                                                     </span>
//                                                 </th>

//                                                 <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('party')} className="sortable-header">
//                                                     Importer Name

//                                                     <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                         {sortOrderOut === 'asc' ? (
//                                                             <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                         ) : (
//                                                             <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                         )}
//                                                     </span>
//                                                 </th>
//                                                 <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('nop')} className="sortable-header">
//                                                     No. of packages

//                                                     <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                         {sortOrderOut === 'asc' ? (
//                                                             <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                         ) : (
//                                                             <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                         )}
//                                                     </span>
//                                                 </th>
//                                                 <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('packnum')} className="sortable-header">
//                                                     Package Number


//                                                     <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                         {sortOrderOut === 'asc' ? (
//                                                             <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                         ) : (
//                                                             <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                         )}
//                                                     </span>
//                                                 </th>
//                                                 <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('gateiout')} className="sortable-header">
//                                                     Date & Time

//                                                     <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                         {sortOrderOut === 'asc' ? (
//                                                             <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                         ) : (
//                                                             <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                         )}
//                                                     </span>
//                                                 </th>


//                                             </tr>
//                                         </thead>

//                                         <tbody>
//                                             {sortedOutData.map((item, index) => (

//                                                 <tr key={index} >

//                                                     <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{index + 1}</td>
//                                                     <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.typeOfTransaction}</td>

//                                                     <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.srNo}</td>
//                                                     <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}></td>
//                                                     <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.party}</td>
//                                                     <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.nop}</td>
//                                                     <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.packnum}</td>
//                                                     <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{convertTimestampToDateTime(item.gateiout)}</td>

//                                                 </tr>
//                                             ))}
//                                         </tbody>
//                                     </Table>
//                                 </div>

//                             </Row>
//                         </Tab>

//                     </Tabs>

//                 </CardBody>
//             </Card  >
//         </Container>



//     )
// }


import AuthContext from "../Components/AuthProvider";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useContext, useCallback, useRef } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import ipaddress from "../Components/IpAddress";
import ReactLoading from 'react-loading';
import {
    Card,
    CardBody,
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Table,
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faBarcode, faSearch } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faCheck, faSave, faTimes, faSyncAlt, faCancel, faCog, faPrint, faXmark, faFileLines, faChessKing } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import { toast } from "react-toastify";
import "../Components/Style.css";
import InviceService from "../services/InviceService";

export default function Scan_Parcels1() {
    const styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)', // Adjust the opacity and color as needed
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999, // Ensure the overlay is above other elements
        },
    };
    const navigate = useNavigate();
    const [getlink, setGetLink] = useState("");
    const [getalldata, setAlldata] = useState([]);
    const {
        jwtToken,
        userId,
        username,
        branchId,
        companyid,
        role,
        companyname,
        branchname,
        login,
        logout,
    } = useContext(AuthContext);
    const { isAuthenticated } = useContext(AuthContext);
    useEffect(() => {
        if (!isAuthenticated) {
            navigate(
                "/login?message=You need to be authenticated to access this page."
            );
        }
    }, [isAuthenticated, navigate]);


    const getScan = {
        companyId: companyid,
        branchId: branchId,
        createdBy: userId,
        editedBy: userId,
        approvedBy: userId,
        link: getlink,
        status: "A",


    }

    const handleSubmit = () => {
        getScan.companyId = companyid;
        getScan.branchId = branchId;
        getScan.createdBy = userId;
        getScan.editedBy = userId;
        getScan.approvedBy = userId;


        axios.post(`https://${ipaddress}export/readgateinurl`, getScan)
            .then(response => {
                toast.success("Data scanned successfully", {
                    autoClose: 700
                })
                getalldataa();
                console.log('Post request successful:', response.data);
                setGetLink('');

                getalldataa();
            })
            .catch(error => {
                // Handle error
                console.error('Error sending post request:', error);
                getalldataa();
                // Check if the error status code is 401
                if (error.response && error.response.status === 401) {
                    // Show an error message for unauthorized access
                    toast.error("Data already exists.", {
                        autoClose: 700
                    });
                    getalldataa();
                }
                setGetLink('');
            });
    };
    const [len, setLen] = useState(0); // Initialize len with 0
    //     if(handleSubmit){
    //         setTimeout(() => {

    //     const linkLength = getlink.length;
    //     setLen(linkLength);
    // }, 10);

    // }

    useEffect(() => {
        // Check if the length of getlink matches len
        if (getlink.length >= 50) {
            // Delay the execution of handleSubmit by 10 milliseconds
            const timer = setTimeout(() => {
                handleSubmit();
            }, 60);

            // Cleanup the timer to avoid multiple calls
            return () => clearTimeout(timer);
        }
    }, [getlink, len]);



    const [exportincount, setExportinCount] = useState(0);
    const [importoutcount, setImportoutcount] = useState(0);
    const getalldataa = () => {
        axios
            .get(`https://${ipaddress}scan/cargodata/${companyid}/${branchId}`)
            .then((response) => {
                const exportInCount = response.data.filter(item => item.typeOfTransaction === 'Export-in').length;
                setExportinCount(exportInCount);
                const importOutCount = response.data.filter(item => item.typeOfTransaction === 'Import-out').length;
                setImportoutcount(importOutCount);
                setAlldata(response.data); // Store the list in the state
            })
            .catch((error) => {
                console.error("GET list error:", error);
            });
    };

    useEffect(() => {
        getalldataa();
    }, [companyid, branchId])


    const convertTimestampToDateTime = (timestamp) => {
        const date = new Date(timestamp);

        // Get the individual components (day, month, year, hours, minutes, seconds)
        const day = date.getDate();
        const month = date.getMonth() + 1; // Note: Months are zero-based, so we add 1
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        // Create a formatted date and time string
        const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

        return formattedDateTime;
    }

    const [partys, setPartys] = useState([]);
    const [getpartyId, setGetpartyId] = useState({});

    const fetchPartyNames = async () => {
        try {
            const response = await fetch(`https://${ipaddress}parties/getAll/${companyid}/${branchId}`);
            const data = await response.json();
            const namesMap = {};
            data.forEach(party => {
                namesMap[party.partyId] = party.partyName;
            });
            setGetpartyId(namesMap);
            setPartys(data);
        } catch (error) {
            console.error("Error fetching party names:", error);
        }
    };


    useEffect(() => {
        fetchPartyNames();
    }, [companyid, branchId])





    const [CARGOInData, setCARGOInData] = useState([]);
    const [CARGOOutData, setCARGOOutData] = useState([]);
    const [CARGOInSummary, setCARGOInSummary] = useState([]);
    const [CARGOOutSummary, setCARGOOutSummary] = useState([]);
    const [TotalExportIn, setTotalExportIn] = useState(0);
    const [TotalExportParcelIn, setTotalExportParcelIn] = useState(0);
    const [TotalImportOut, setTotalImportOut] = useState(0);
    const [TotalImportParcelOut, setTotalImportParcelOut] = useState(0);

    const getCARGOInData = async () => {
        const { List, summary } = await getData('cargo', 'Export-in', '');
        setCARGOInData(List);
        let exportParcelTotal = 0;
        let exportTotal = 0;
        summary.forEach(item => {
            exportTotal += item[1];
            exportParcelTotal += item[3];
        });
        console.log("In");
        console.log(summary);
        setTotalExportIn(exportTotal);
        setTotalExportParcelIn(exportParcelTotal);
        setCARGOInSummary(summary);
    };

    const getCARGOOutData = async () => {
        const { List, summary } = await getData('cargo', 'Import-out', '');
        setCARGOOutData(List);
        let importTotal = 0;
        let importParcelTotal = 0;
        console.log("Out");
        console.log(summary);
        summary.forEach(item => {
            importTotal += item[1];
            importParcelTotal += item[3];
        });
        setTotalImportOut(importTotal);
        setTotalImportParcelOut(importParcelTotal);
        setCARGOOutSummary(summary);
    };


    const getData = async (type, trans1, trans2) => {
        const response = await InviceService.getScanningData(companyid, branchId, type, trans1, trans2);
        return response.data;
    };

    useEffect(() => {
        getCARGOInData();
        getCARGOOutData();
    }, [companyid, branchId])







    const [scanalldata, setScalalldata] = useState('');
    const index1 = scanalldata.length - 4;
    const sir = scanalldata.substring(0, index1).toUpperCase();
    const packnum = scanalldata.substring(index1);
    const [exportsubdata, setExportsubdata] = useState([]);
    const [loading1, setLoading1] = useState(false);



    const handleSubmit1 = () => {
        setLoading1(true);


        axios.post(`https://${ipaddress}scan/alldataforcargoout/${companyid}/${branchId}/${userId}/${sir}/${packnum}`)
            .then(response => {
                if (response.data === "wrong barcode") {
                    toast.error("Invalid qr or barcode format", {
                        autoClose: 700
                    });
                    setScalalldata('');
                    getCARGOOutData();
                    setLoading1(false);
                    return;
                }

                if (response.data === "not found") {
                    toast.error("Data not found", {
                        autoClose: 700
                    });
                    setScalalldata('');
                    getCARGOOutData();
                    setLoading1(false);
                    return;
                }
                if (response.data === 'NOP is not matched') {
                    toast.success("Data scanned successfully", {
                        autoClose: 700
                    })
                    setScalalldata('');
                    getCARGOOutData();
                    setLoading1(false);
                    return;
                }
                if (response.data === "wrong status") {
                    toast.error("Data hasn't been handed over to carting agent", {
                        autoClose: 1000,
                        style: { width: `30vw` },
                    });
                    setScalalldata('');
                    getCARGOOutData();
                    setLoading1(false);
                    return;
                }
                if (response.data === "Dgdc_seepz_out_scan is already 'Y'") {
                    toast.error("Data already scanned", {
                        autoClose: 1000,
                        style: { width: `30vw` },
                    })
                    getCARGOOutData();
                    setScalalldata('');
                    setLoading1(false);
                    return;
                }
                if (response.data === "success") {
                    toast.success("Data scanned successfully", {
                        autoClose: 700
                    })
                    setLoading1(false);
                    setScalalldata('');
                    getCARGOOutData();
                }

                setLoading1(false);
                setScalalldata('');
                getCARGOOutData();

            })
            .catch(error => {
                if (error) {
                    toast.error("Data not found", {
                        autoClose: 700
                    })
                    getCARGOOutData();
                    setLoading1(false);
                }
                getCARGOOutData();
                setScalalldata('');
                setLoading1(false);
            });
    };






    const [scanalldata1, setScalalldata1] = useState('');
    const index2 = scanalldata1.length - 4;
    const sir1 = scanalldata1.substring(0, index2).toUpperCase();
    const packnum1 = scanalldata1.substring(index2);
    const [exportsubdata1, setExportsubdata1] = useState([]);
    const [loading, setLoading] = useState(false);



    const handleSubmit2 = () => {
        setLoading(true);


        axios.post(`https://${ipaddress}scan/alldataforcargoin/${companyid}/${branchId}/${userId}/${sir1}/${packnum1}`)
            .then(response => {
                if (response.data === "wrong barcode") {
                    toast.error("Invalid qr or barcode format", {
                        autoClose: 700,
                        style: {                            
                            width: '30vw' // Prevent text wrapping
                          }
                    });
                    setScalalldata1('');
                    getCARGOInData();
                    setLoading(false);
                    return;
                }

                if (response.data === "not found") {
                    toast.error("Data not found", {
                        autoClose: 700
                    });
                    setScalalldata1('');
                    getCARGOInData();
                    setLoading(false);
                    return;
                }
                if (response.data === 'NOP is not matched') {
                    toast.success("Data scanned successfully", {
                        autoClose: 700
                    })
                    setScalalldata1('');
                    getCARGOInData();
                    setLoading(false);
                    return;
                }
                if (response.data === "wrong status") {
                    toast.error("Data hasn't been exit from dgdc seepz gate", {
                        autoClose: 1000,
                        style: { width: `30vw` },
                    });
                    setScalalldata1('');
                    getCARGOInData();
                    setLoading(false);
                    return;
                }
                if (response.data === "Dgdc_seepz_out_scan is already 'Y'") {
                    toast.error("Data already scanned", {
                        autoClose: 700,                        
                    })
                    getCARGOInData();
                    setScalalldata1('');
                    setLoading(false);
                    return;
                }
                if (response.data === "success") {
                    toast.success("Data scanned successfully", {
                        autoClose: 700
                    });
                    getCARGOInData();
                    setScalalldata1('');
                    setLoading(false);
                }
                getCARGOInData();
                setScalalldata1('');
                setLoading(false);
            })
            .catch(error => {
                if (error) {
                    toast.error("Data not found", {
                        autoClose: 700
                    })
                    getCARGOInData();
                    setLoading(false);
                }
                getCARGOInData();
                setScalalldata1('');
                setLoading(false);
            });
    };

    const inputRef = useRef();

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            document.getElementById("submitButton").click();
        }
    };


    const inputRef1 = useRef();

    const handleKeyPress1 = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            document.getElementById("submitButton1").click();
        }
    };

    // Sort  Table 
    const [sortBy, setSortBy] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc'); // Initial sort order
    const handleSort = (columnName) => {
        if (sortBy === columnName) {
            // If already sorting by this column, reverse the order
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            // If sorting by a different column, set it as the new sort column
            setSortBy(columnName);
            setSortOrder('asc'); // Default to ascending order
        }
    };
    const sortedInData = [...CARGOInData].sort((a, b) => {
        if (sortBy) {
            const valueA = a[sortBy];
            const valueB = b[sortBy];
            if (valueA < valueB) {
                return sortOrder === 'asc' ? -1 : 1;
            }
            if (valueA > valueB) {
                return sortOrder === 'asc' ? 1 : -1;
            }
            return 0;
        }
        return 0;
    });


    const [sortOutBy, setSortOutBy] = useState(null);
    const [sortOrderOut, setSortOrderOut] = useState('asc'); // Initial sort order
    const sortedOutData = [...CARGOOutData].sort((a, b) => {
        if (sortOutBy) {
            const valueA = a[sortOutBy];
            const valueB = b[sortOutBy];
            if (valueA < valueB) {
                return sortOrderOut === 'asc' ? -1 : 1;
            }
            if (valueA > valueB) {
                return sortOrderOut === 'asc' ? 1 : -1;
            }
            return 0;
        }
        return 0;
    });

    const handleSortOut = (columnName) => {
        if (sortOutBy === columnName) {
            // If already sorting by this column, reverse the order
            setSortOrderOut(sortOrderOut === 'asc' ? 'desc' : 'asc');
        } else {
            // If sorting by a different column, set it as the new sort column
            setSortOutBy(columnName);
            setSortOrderOut('asc'); // Default to ascending order
        }
    };



    return (
        <Container>

            <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '4%', paddingRight: '-50px' }} > <FontAwesomeIcon
                icon={faBarcode}
                style={{
                    marginRight: '8px',
                    color: 'black', // Set the color to golden
                }}
            />Scan Parcel</h5>

            <Card  >
                <CardBody>


                    <Tabs
                        defaultActiveKey="home"
                        transition={false}
                        id="noanim-tab-example"
                        className="mb-3"
                    >

                        <Tab eventKey="home" title="Incoming">
                            {loading && (
                                <div style={styles.overlay}>
                                    <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
                                </div>
                            )}
                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label className="forlabel" for="branchId">Scan URL</Label>
                                        <Input
                                            type="text"
                                            name="remarks"
                                            id="branchname"
                                            placeholder='Scan SER'
                                            value={scanalldata1}
                                            ref={inputRef1}

                                            onKeyDown={handleKeyPress1}
                                            autoFocus
                                            onChange={(e) => setScalalldata1(e.target.value)}
                                            className="inputField"

                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <Button id="submitButton1" variant="outline-primary" onClick={handleSubmit2} style={{ marginTop: 32 }}>
                                        <FontAwesomeIcon icon={faSearch} style={{ marginRight: '5px' }} />
                                        Scan
                                    </Button>
                                </Col>
                                <Col md={6}>
                                    {CARGOInSummary && CARGOInSummary.length > 0 && (
                                        <Table className="table table-bordered table-hover">
                                            <thead className="thead-dark bg-dark">
                                                <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px' }}>
                                                    <th rowSpan='2' className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Console</th>
                                                    <th colSpan='2' className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Export</th>
                                                </tr>
                                                <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '18px' }}>
                                                    <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Parcel</th>
                                                    <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Packets</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {CARGOInSummary.map((item, index) => (

                                                    <tr key={index} className='text-center' style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '15px' }}>
                                                        <td>{item[0]}</td>
                                                        <td>{item[3]}</td>
                                                        <td>{item[1]}</td>
                                                    </tr>
                                                ))}
                                                <tr className="text-center" style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '19px', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
                                                    <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>Total</td>
                                                    <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{TotalExportParcelIn}</td>
                                                    <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{TotalExportIn}</td>
                                                </tr>
                                            </tbody>

                                        </Table>
                                    )}

                                </Col>
                            </Row>

                            {sortedInData && sortedInData.length > 0 && (

                            <Row style={{ marginTop: 30 }}>
                                <div className="table-responsive">
                                    <Table className="table table-bordered table-hover" style={{ border: '2px solid black' }}>
                                        <thead className="thead-dark bg-dark">
                                            <tr className='text-center' style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', fontSize: '17px', color: 'black', fontFamily: 'Your-Heading-Font', height: '60px' }}>
                                                <th scope="col" style={{ backgroundColor: '#BADDDA' }}>Sr No.</th>
                                                <th scope="col" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Type of Transaction</th>

                                                <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('srNo')} className="sortable-header">

                                                    SER NO
                                                    <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                        {sortOrderOut === 'asc' ? (
                                                            <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                        ) : (
                                                            <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                        )}
                                                    </span>
                                                </th>
                                                <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('cartingAgent')} className="sortable-header">

                                                    Console

                                                    <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                        {sortOrderOut === 'asc' ? (
                                                            <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                        ) : (
                                                            <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                        )}
                                                    </span>
                                                </th>

                                                <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('hawb')} className="sortable-header">
                                                   HAWB

                                                    <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                        {sortOrderOut === 'asc' ? (
                                                            <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                        ) : (
                                                            <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                        )}
                                                    </span>

                                                </th>

                                                <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('doc_Ref_No')} className="sortable-header">
                                                    SB No.

                                                    <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                        {sortOrderOut === 'asc' ? (
                                                            <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                        ) : (
                                                            <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                        )}
                                                    </span>

                                                </th>
                                                <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('party')} className="sortable-header">
                                                    Exporter Name

                                                    <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                        {sortOrderOut === 'asc' ? (
                                                            <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                        ) : (
                                                            <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                        )}
                                                    </span>
                                                </th>
                                                <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('nop')} className="sortable-header">
                                                   NOP

                                                    <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                        {sortOrderOut === 'asc' ? (
                                                            <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                        ) : (
                                                            <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                        )}
                                                    </span>
                                                </th>
                                                <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('packnum')} className="sortable-header">
                                                   PKG NO


                                                    <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                        {sortOrderOut === 'asc' ? (
                                                            <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                        ) : (
                                                            <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                        )}
                                                    </span>
                                                </th>
                                                <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('gateiout')} className="sortable-header">
                                                    Date & Time

                                                    <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                        {sortOrderOut === 'asc' ? (
                                                            <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                        ) : (
                                                            <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                        )}
                                                    </span>
                                                </th>


                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sortedInData.map((item, index) => (

                                                <tr key={index} >

                                                    <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{index + 1}</td>
                                                    <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.typeOfTransaction}</td>
                                                    <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.srNo}</td>
                                                    <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.cartingAgent}</td>
                                                    <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.hawb}</td>
                                                    <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.doc_Ref_No}</td>
                                                    <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.party}</td>
                                                    <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.nop}</td>
                                                    <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.packnum}</td>
                                                    <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{convertTimestampToDateTime(item.gateiout)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>

                            </Row>
                            )}
                        </Tab>
                        <Tab eventKey="profile" title="Outgoing">
                            {loading1 && (
                                <div style={styles.overlay}>
                                    <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
                                </div>
                            )}
                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label className="forlabel" for="branchId">Scan URL</Label>
                                        <Input
                                            type="text"
                                            name="remarks"
                                            id="branchname"
                                            placeholder='Scan SIR'
                                            ref={inputRef}
                                            onKeyDown={handleKeyPress}
                                            value={scanalldata}
                                            onChange={(e) => setScalalldata(e.target.value)}
                                            className="inputField"
                                            autoFocus
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <Button id="submitButton" variant="outline-primary" onClick={handleSubmit1} style={{ marginTop: 32 }}>
                                        <FontAwesomeIcon icon={faSearch} style={{ marginRight: '5px' }} />
                                        Scan
                                    </Button>
                                </Col>
                                <Col md={6}>
                                    {CARGOOutSummary && CARGOOutSummary.length > 0 && (
                                        <Table className="table table-bordered table-hover">
                                            <thead className="thead-dark bg-dark">
                                                <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px' }}>
                                                    <th rowSpan='2' className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Console</th>
                                                    <th colSpan='2' className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Import</th>
                                                </tr>
                                                <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '18px' }}>
                                                    <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Parcel</th>
                                                    <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Packets</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {CARGOOutSummary.map((item, index) => (

                                                    <tr key={index} className='text-center' style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '15px' }}>
                                                        <td>{item[0]}</td>
                                                        <td>{item[3]}</td>
                                                        <td>{item[1]}</td>
                                                    </tr>
                                                ))}
                                                <tr className="text-center" style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '19px', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
                                                    <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>Total</td>
                                                    <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{TotalImportParcelOut}</td>
                                                    <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{TotalImportOut}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    )}
                                </Col>
                            </Row>



                            {sortedOutData && sortedOutData.length > 0 && (
                            <Row style={{ marginTop: 30 }}>
                                <div className="table-responsive">
                                    <Table className="table table-bordered table-hover" style={{ border: '2px solid black' }}>
                                        <thead className="thead-dark bg-dark">
                                            <tr className='text-center' style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', fontSize: '17px', color: 'black', fontFamily: 'Your-Heading-Font', height: '60px' }}>
                                                <th scope="col" style={{ backgroundColor: '#BADDDA' }}>Sr No.</th>
                                                <th scope="col" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Type of Transaction</th>

                                                <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('srNo')} className="sortable-header">

                                                    SIR NO
                                                    <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                        {sortOrderOut === 'asc' ? (
                                                            <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                        ) : (
                                                            <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                        )}
                                                    </span>
                                                </th>
                                                <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }}  onClick={() => handleSortOut('cartingAgent')} className="sortable-header">

                                                    Console

                                                    <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                        {sortOrderOut === 'asc' ? (
                                                            <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                        ) : (
                                                            <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                        )}
                                                    </span>
                                                </th>
                                                <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('hawb')} className="sortable-header">
                                                       HAWB

                                                        <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                            {sortOrderOut === 'asc' ? (
                                                                <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                            ) : (
                                                                <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                            )}
                                                        </span>

                                                    </th>
                                                <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('party')} className="sortable-header">
                                                    Importer Name

                                                    <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                        {sortOrderOut === 'asc' ? (
                                                            <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                        ) : (
                                                            <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                        )}
                                                    </span>
                                                </th>
                                                <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('nop')} className="sortable-header">
                                                   NOP

                                                    <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                        {sortOrderOut === 'asc' ? (
                                                            <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                        ) : (
                                                            <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                        )}
                                                    </span>
                                                </th>
                                                <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('packnum')} className="sortable-header">
                                                    PKG NO


                                                    <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                        {sortOrderOut === 'asc' ? (
                                                            <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                        ) : (
                                                            <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                        )}
                                                    </span>
                                                </th>
                                                <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('gateiout')} className="sortable-header">
                                                    Date & Time

                                                    <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                        {sortOrderOut === 'asc' ? (
                                                            <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                        ) : (
                                                            <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                        )}
                                                    </span>
                                                </th>


                                            </tr>
                                        </thead>

                                        <tbody>
                                            {sortedOutData.map((item, index) => (

                                                <tr key={index} >
                                                    <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{index + 1}</td>
                                                    <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.typeOfTransaction}</td>
                                                    <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.srNo}</td>
                                                    <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.cartingAgent}</td>
                                                    <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.hawb}</td>
                                                    <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.party}</td>
                                                    <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.nop}</td>
                                                    <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.packnum}</td>
                                                    <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{convertTimestampToDateTime(item.gateiout)}</td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>

                            </Row>
                            )}
                        </Tab>

                    </Tabs>

                </CardBody>
            </Card  >
        </Container>



    )
}



