// import React, { useState, useEffect, useContext, useLayoutEffect } from "react";
// import AuthContext from "../Components/AuthProvider";
// import { Button } from "react-bootstrap";
// import DatePicker from "react-datepicker";
// import Select, { components } from 'react-select';
// import Rate_Chart_Service from "../services/Rate_Chart_Service";
// import { Card, CardBody, Container, Row, Col, Form, FormGroup, label, Input, Table } from "reactstrap";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBackward, faClose, faPlaneCircleCheck, faPlaneUp, faPlus, faPlusCircle, faReceipt, faRefresh, faSearch, faTimesCircle, faTruckArrowRight, faTruckFast, faUserCircle } from '@fortawesome/free-solid-svg-icons';
// import { faSave } from '@fortawesome/free-solid-svg-icons';
// import { useNavigate, useLocation } from "react-router-dom";
// import { toast } from 'react-toastify';
// import ReactLoading from 'react-loading';
// import InviceService from "../services/InviceService";


// function AddReceipts() {


//     const styles = {
//         overlay: {
//             position: 'fixed',
//             top: 0,
//             left: 0,
//             width: '100%',
//             height: '100%',
//             backgroundColor: 'rgba(255, 255, 255, 0.8)', // Adjust the opacity and color as needed
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             zIndex: 9999, // Ensure the overlay is above other elements
//         },
//     };
//     const [loading, setLoading] = useState(false);

//     const navigate = useNavigate();
//     const {
//         userId,
//         username,
//         branchId,
//         companyid,
//         role,
//         login,
//         logout,
//     } = useContext(AuthContext);

//     const { isAuthenticated } = useContext(AuthContext);
//     const location = useLocation();
//     const [errors, setErrors] = useState({});


//     const transId = location.state?.transId;
//     const partyId = location.state?.partyId;
//     const operationScreen = location.state?.operation;
//     const searchCriteria = location.state?.searchCriteria;
//     const currentPage = location.state?.currentPage;
//     const [balAdvAmt, setBalAdvAmt] = useState(0);
//     const [selectedPartyPayment, setSelectedPartyPayment] = useState(null);
//     const [billingPartyId, setBillingPartyId] = useState(null);
//     const [advanceAmount, setAdvanceAmount] = useState(0);


//     const [operation, setOperation] = useState(operationScreen);


//     const Handleback = () => {
//         navigate(`/parent/Receipts`, { state: { searchCriteria: searchCriteria, currentPage: currentPage } })
//     };

//     useEffect(() => {
//         const fetchData = async () => {
//             await findPartiesAll();
//             if (transId && partyId) {
//                 await getReceiptById(companyid, branchId, transId, partyId);
//             }
//         };
//         fetchData();
//     }, [0]);


//     const [partiesAll, setPartiesAll] = useState([]);
//     const [partiesAllSearchData, setPartiesAllSearchData] = useState([]);

//     const findPartiesAll = async (value) => {
//         try {
//             const partyResponse = await Rate_Chart_Service.getAllPartiesReceipt(companyid, branchId, value);
//             const results = partyResponse.data;

//             if (results && results.length > 0) {
//                 // Set only the first 200 results if data is available
//                 setPartiesAll(results);
//                 setPartiesAllSearchData(results.slice(0, 200));
//             } else {
//                 // Handle the case where no data is returned
//                 setPartiesAll([]);
//                 setPartiesAllSearchData([]);
//                 toast.warn('No parties found!', {
//                     position: toast.POSITION.TOP_CENTER,
//                     autoClose: 900,
//                 });
//             }
//         } catch (error) {
//             console.error("Error fetching parties:", error);
//             toast.error('failed to fetch parties!', {
//                 position: toast.POSITION.TOP_CENTER,
//                 autoClose: 900,
//             });
//         }
//     };

//     // console.log('partiesAll : \n', partiesAll);

//     const findPartiesAllSearch = (inputValue) => {
//         if (!inputValue) {
//             if (partiesAll && partiesAll.length > 0) {
//                 setPartiesAllSearchData(partiesAll.slice(0, 200));
//             } else {
//                 setPartiesAllSearchData([]);
//             }
//             return;
//         }

//         const lowerCasedInput = inputValue.toLowerCase();
//         const filteredParties = partiesAll.filter(party =>
//             party.value.toLowerCase().includes(lowerCasedInput) ||
//             party.label.toLowerCase().includes(lowerCasedInput) ||
//             party.partyCode.toLowerCase().includes(lowerCasedInput)
//         );
//         setPartiesAllSearchData(filteredParties);
//     };



//     const CustomOptionParty = (props) => {
//         return (
//             <components.Option {...props}>
//                 <span style={{ fontWeight: 'bold' }}>{props.data.partyCode}</span>
//                 {" - " + props.data.label}
//             </components.Option>
//         );
//     };


//     const [toBeCheckFinTransInv, setToBeCheckFinTransInv] = useState([]);
//     const [toBeCheckFinTranTrans, setToBeCheckFinTrans] = useState([]);


//     const getReceiptById = async (companyid, branchId, transId, partyId) => {
//         setLoading(true);
//         try {
//             console.log("Calling");
//             const response = await InviceService.getReceiptById(companyid, branchId, transId, partyId);
//             const { finTrans: updatedFinTrans, finTransDtl: updatedFinTransDtl, finTransInv: updatedFinTransInv } = response.data;

//             console.log('updatedFinTransInv : \n', updatedFinTransInv, ' \n finTransInv  All : ', response.data);
//             setToBeCheckFinTrans(updatedFinTrans);
//             setToBeCheckFinTransInv(updatedFinTransInv);
//             setFinTrans(updatedFinTrans);
//             setFinTransDtl(updatedFinTransDtl);
//             setFintransInv(updatedFinTransInv);
//             setSelectedItems(updatedFinTransInv);
//             getTransByPartyId(partyId);
//         }
//         catch (error) {

//             console.error("Error occurred while saving payment:", error);
//             toast.error('Oops something went wrong!', {
//                 position: toast.POSITION.TOP_CENTER,
//                 autoClose: 900,
//             });
//         }
//         finally {
//             setLoading(false);
//         }
//     };






//     const handlePartyPayment = async (selectedOption) => {
//         setErrors(prevErrors => ({
//             ...prevErrors,
//             selectedPartyPayment: selectedOption ? '' : 'Please Select Party'
//         }));

//         setSelectedPartyPayment(selectedOption ? selectedOption : null);
//         setSelectedItems([]);
//         setFintransInv([]);
//         setFinTrans(initialFintrans);
//         setFinTransDtl(initialFintransDtl);

//         // Update the `partyId` and `partyName` fields
//         setFinTrans(prevFinTrans => ({
//             ...prevFinTrans,
//             partyId: selectedOption ? selectedOption.value : '',
//             partyName: selectedOption ? selectedOption.label : ''
//         }));

//         // Call the function to get invoice data
//         if (selectedOption) {
//             await getInvoiceDataByPartyId(selectedOption.value);
//         }

//         // Call `handlePartyPaymentNew` to handle the billing related updates
//         await handlePartyPaymentNew(selectedOption);
//     };

//     const handlePartyPaymentNew = async (selectedOption) => {
//         setErrors(prevErrors => ({
//             ...prevErrors,
//             billingPartyId: selectedOption ? '' : 'Please Select Party'
//         }));

//         setBillingPartyId(selectedOption);
//         // console.log('selectedOption \n', selectedOption);

//         // Update the `billingPartyId` and `billingPartyName` fields
//         setFinTrans(prevFinTrans => ({
//             ...prevFinTrans,
//             billingPartyId: selectedOption ? selectedOption.value : '',
//             billingPartyName: selectedOption ? selectedOption.label : ''
//         }));

//         // Call the function to get transaction data by party ID
//         if (selectedOption) {
//             await getTransByPartyId(selectedOption.value);
//         } else {
//             setBalAdvAmt(0);
//         }
//     };







//     const getTransByPartyId = async (partyId) => {
//         const response = await InviceService.getPartyAdvAndClearedAmount(companyid, branchId, partyId);
//         setBalAdvAmt(response.data);
//         // console.log('setBalAdvAmt(response.data); \n', response.data, ' \n companyid, branchId, partyId', companyid, branchId, partyId);
//     };


//     const initialFintrans = {
//         srno: 1,
//         branchId: branchId,
//         companyId: companyid,
//         docType: 'REC',
//         transId: '',
//         transDate: new Date(),
//         partyId: '',
//         partyName: '',
//         billingPartyId: '',
//         billingPartyName: '',
//         currency: 'INR',
//         totalAMt: 0,
//         receiptAmt: 0,
//         narration: '',
//         clearedAmt: 0,
//         advFlag: 'N',
//         balAdvAmt: 0,
//         advAmt: 0,
//         tdsPercentage: 0,
//         tdsAmt: 0,
//         expensiveAmt: 0,
//         tdsStatus: '',
//         status: 'N',
//         createdBy: userId,
//         createdDate: new Date(),
//         editedBy: userId,
//         editedDate: new Date(),
//         approvedBy: userId,
//         approvedDate: new Date()
//     };


//     const initialFintransDtl = [{
//         srno: 1,
//         companyId: companyid,
//         branchId: branchId,
//         transId: '',
//         transDate: new Date(),
//         partyId: selectedPartyPayment ? selectedPartyPayment.value : '',
//         partyName: selectedPartyPayment ? selectedPartyPayment.label : '',
//         billingPartyId: billingPartyId ? billingPartyId.value : '',
//         billingPartyName: billingPartyId ? billingPartyId.name : '',
//         paymentMode: 'CHEQUE',
//         paymentDate: null,
//         paymentNo: '',
//         bankName: '',
//         amount: 0,
//         status: 'A',
//         createdBy: userId,
//         createdDate: new Date(),
//         editedBy: userId,
//         editedDate: new Date(),
//         approvedBy: userId,
//         approvedDate: new Date()
//     }];



//     const [finTrans, setFinTrans] = useState(initialFintrans);
//     const [finTransDtl, setFinTransDtl] = useState(initialFintransDtl);
//     const [fintransInv, setFintransInv] = useState([]);
//     const [selectedItems, setSelectedItems] = useState([]);
//     const [selectAll, setSelectAll] = useState(false);
//     const [totalReceiptAmount, setTotalReceiptAmount] = useState(0);
//     const [totalTdsAmount, setTotalTdsAmount] = useState(0);



//     useEffect(() => {
//         if (partiesAll.length > 0 && transId && partyId) {
//             const foundType = partiesAll.find(type => type.value === finTrans.partyId);
//             setSelectedPartyPayment(foundType ? foundType : null);
//         }
//     }, [partiesAll, finTrans.partyId]);

//     useEffect(() => {
//         if (partiesAll.length > 0 && (transId && partyId)) {
//             const foundType = partiesAll.find(type => type.value === finTrans.billingPartyId);
//             setBillingPartyId(foundType ? foundType : null);
//         }
//     }, [partiesAll, finTrans.billingPartyId]);







//     // Function to calculate total amount entered in finTransDtl
//     const calculateTotalPaidAmount = () => {
//         let total = 0;
//         finTransDtl.forEach(payment => {
//             const amount = parseFloat(payment.amount) || 0;
//             total += amount;
//         });
//         setTotalReceiptAmount(parseFloat(total.toFixed(2)));
//         // console.log('total', total, ' ');
//     };

//     // Function to calculate totals
//     const calculateTotals = () => {
//         let tdsTotal = 0;
//         let receiptTotal = 0;

//         selectedItems.forEach(item => {
//             const tdsAmt = parseFloat(item.tdsAmt) || 0;
//             const receiptAmt = parseFloat(item.receiptAmt) || 0;
//             tdsTotal += tdsAmt;
//             receiptTotal += receiptAmt;
//         });

//         // console.log('Calling in ',receiptTotal);


//         setTotalTdsAmount(parseFloat(tdsTotal.toFixed(2)));
//         // Update finTrance with calculated totals
//         setFinTrans(prevState => ({
//             ...prevState,
//             // tdsAmt: parseFloat(tdsTotal.toFixed(2)),
//             receiptAmt: parseFloat(receiptTotal.toFixed(2)),
//             totalAMt: parseFloat(tdsTotal.toFixed(2)) + parseFloat(receiptTotal.toFixed(2))
//         }));
//     };


//     // Call calculateTotalAmount whenever finTransDtl changes
//     useEffect(() => {
//         calculateTotalPaidAmount();
//     }, [finTransDtl, finTrans.advAmt]);
//     useEffect(() => {
//         calculateTotals();
//     }, [selectedItems]);


//     const convertToFibtransInv = (invoices) => {
//         return invoices.map((invoice) => ({
//             srno: null, // or generate a unique srno if needed
//             companyId: companyid,
//             branchId: branchId,
//             invoiceNo: invoice.invoiceNO,
//             invoiceDate: invoice.invoiceDate,
//             partyId: invoice.partyId,
//             partyName: invoice.partyName,
//             billingPartyId: '',
//             billingPartyName: '',
//             currency: 'INR',
//             invoiceAmt: invoice.totalInvoiceAmount,
//             tdsStatus: '',
//             receiptAmt: invoice.balanceAmount,
//             balanceAmt: invoice.balanceAmount,
//             tdsPercentage: 0,
//             tdsAmt: 0,
//             status: '',
//             createdBy: '',
//             createdDate: null,
//             editedBy: '',
//             editedDate: null,
//             approvedBy: '',
//             approvedDate: null
//         }));
//     };


//     console.log('Tds Total : ', totalTdsAmount);




//     const formatDateTime = (value) => {
//         if (!value) {
//             return "";
//         }
//         const date = new Date(value);
//         const day = String(date.getDate()).padStart(2, "0");
//         const month = String(date.getMonth() + 1).padStart(2, "0");
//         const year = date.getFullYear();
//         return `${day}/${month}/${year}`;
//     };




//     //   Get Invoice By Party
//     const getInvoiceDataByPartyId = async (partyId) => {
//         setLoading(true);
//         try {
//             setTotalTdsAmount(0);
//             await InviceService.getPendingInvoicesPartyId(companyid, branchId, partyId).then((res) => {
//                 const fibtransInvList = convertToFibtransInv(res.data);
//                 setFintransInv(fibtransInvList);
//             });
//         }
//         catch {
//             console.log("Error");
//         } finally {
//             setLoading(false);
//         }
//     };



//     // Function to handle individual checkbox change
//     const handleRowCheckboxChange = (invoice) => {
//         const isChecked = selectedItems.some((item) => item.invoiceNo === invoice.invoiceNo);

//         if (isChecked) {
//             const updatedSelectedItems = selectedItems.filter((item) => item.invoiceNo !== invoice.invoiceNo);
//             setSelectedItems(updatedSelectedItems);
//         } else {
//             setSelectedItems([...selectedItems, invoice]);
//         }
//     };

//     // Use useEffect to manage selectAll state
//     useEffect(() => {
//         setSelectAll(selectedItems.length === fintransInv.length);
//     }, [selectedItems, fintransInv]);

//     // Function to handle select all checkbox change
//     const handleSelectAllChange = () => {
//         if (selectAll) {
//             setSelectedItems([]);
//         } else {
//             setSelectedItems(fintransInv);
//         }
//         setSelectAll(!selectAll);
//     };


//     // console.log("finTrans: \n", finTrans);



//     // const handleInputChange = (invoiceNo, field, value) => {
//     //     const updatedFintransInv = fintransInv.map((invoice) => {
//     //         if (invoice.invoiceNo === invoiceNo) {
//     //             let updatedValue = value;
//     //             const currentTdsAmt = parseFloat(invoice.tdsAmt || 0);
//     //             const currentReceiptAmt = parseFloat(invoice.receiptAmt || 0);
//     //             const currentBalanceAmt = parseFloat(invoice.balanceAmt);
//     //             const tdsPercentage = parseFloat(invoice.tdsPercentage || 0);

//     //             // Handle tdsAmt based on tdsPercentage and balanceAmt
//     //             if (field === 'tdsAmt' || field === 'receiptAmt') {
//     //                 if (field === 'tdsAmt' && (!tdsPercentage || tdsPercentage === 0)) {
//     //                     // Reset tdsAmt if tdsPercentage is not entered or is '0'
//     //                     updatedValue = '';
//     //                 } else {
//     //                     // Ensure tdsAmt + receiptAmt does not exceed balanceAmt
//     //                     let newTdsAmt = field === 'tdsAmt' ? parseFloat(updatedValue) : currentTdsAmt;
//     //                     let newReceiptAmt = field === 'receiptAmt' ? parseFloat(updatedValue) : currentReceiptAmt;

//     //                     if (newTdsAmt + newReceiptAmt > currentBalanceAmt) {
//     //                         // Adjust the value to not exceed balanceAmt
//     //                         updatedValue = field === 'tdsAmt'
//     //                             ? (currentBalanceAmt - currentReceiptAmt).toString()
//     //                             : (currentBalanceAmt - currentTdsAmt).toString();
//     //                     }
//     //                 }
//     //             }

//     //             // Clear tdsAmt when tdsPercentage is set to '0'
//     //             if (field === 'tdsPercentage' && (!updatedValue || updatedValue === '0')) {
//     //                 updatedValue = parseFloat(updatedValue || 0);
//     //                 return { ...invoice, [field]: updatedValue, tdsAmt: 0 };
//     //             }

//     //             // Convert specific fields to numbers if necessary
//     //             if (field === 'tdsPercentage' || field === 'tdsAmt' || field === 'receiptAmt') {
//     //                 updatedValue = parseFloat(updatedValue || 0);
//     //             }

//     //             return { ...invoice, [field]: updatedValue };
//     //         }
//     //         return invoice;
//     //     });

//     //     setFintransInv(updatedFintransInv);

//     //     // Update selectedItems if necessary
//     //     const updatedSelectedItems = selectedItems.map((item) => {
//     //         if (item.invoiceNo === invoiceNo) {
//     //             let updatedValue = value;
//     //             const currentTdsAmt = parseFloat(item.tdsAmt || 0);
//     //             const currentReceiptAmt = parseFloat(item.receiptAmt || 0);
//     //             const currentBalanceAmt = parseFloat(item.balanceAmt);
//     //             const tdsPercentage = parseFloat(item.tdsPercentage || 0);

//     //             // Handle tdsAmt based on tdsPercentage and balanceAmt in selectedItems
//     //             if (field === 'tdsAmt' || field === 'receiptAmt') {
//     //                 if (field === 'tdsAmt' && (!tdsPercentage || tdsPercentage === 0)) {
//     //                     updatedValue = '';
//     //                 } else {
//     //                     let newTdsAmt = field === 'tdsAmt' ? parseFloat(updatedValue) : currentTdsAmt;
//     //                     let newReceiptAmt = field === 'receiptAmt' ? parseFloat(updatedValue) : currentReceiptAmt;

//     //                     if (newTdsAmt + newReceiptAmt > currentBalanceAmt) {
//     //                         updatedValue = field === 'tdsAmt'
//     //                             ? (currentBalanceAmt - currentReceiptAmt).toString()
//     //                             : (currentBalanceAmt - currentTdsAmt).toString();
//     //                     }
//     //                 }
//     //             }

//     //             // Clear tdsAmt when tdsPercentage is set to '0'
//     //             if (field === 'tdsPercentage' && (!updatedValue || updatedValue === '0')) {
//     //                 updatedValue = parseFloat(updatedValue || 0);
//     //                 return { ...item, [field]: updatedValue, tdsAmt: 0 };
//     //             }

//     //             // Convert specific fields to numbers if necessary
//     //             if (field === 'tdsPercentage' || field === 'tdsAmt' || field === 'receiptAmt') {
//     //                 updatedValue = parseFloat(updatedValue || 0);
//     //             }

//     //             return { ...item, [field]: updatedValue };
//     //         }
//     //         return item;
//     //     });

//     //     setSelectedItems(updatedSelectedItems);
//     // };




//     // const handleInputChange = (invoiceNo, field, value) => {
//     //     const updatedFintransInv = fintransInv.map((invoice) => {
//     //         if (invoice.invoiceNo === invoiceNo) {
//     //             const currentTdsAmt = parseFloat(invoice.tdsAmt || 0);
//     //             const currentReceiptAmt = parseFloat(invoice.receiptAmt || 0);
//     //             const currentBalanceAmt = parseFloat(invoice.balanceAmt);
//     //             let updatedValue = parseFloat(value || 0);

//     //             if (field === 'tdsPercentage') {
//     //                 // Calculate tdsAmt based on tdsPercentage and current receiptAmt
//     //                 const newTdsAmt = (currentReceiptAmt * updatedValue) / 100;
//     //                 // Calculate new receiptAmt after applying tdsAmt
//     //                 const newReceiptAmt = currentReceiptAmt;

//     //                 // Check if the new values are within the allowed range
//     //                 if (newReceiptAmt - newTdsAmt > currentBalanceAmt) {
//     //                     // If the constraint is violated, do not update the values
//     //                     return invoice;
//     //                 }

//     //                 return { 
//     //                     ...invoice, 
//     //                     [field]: updatedValue, 
//     //                     tdsAmt: newTdsAmt 
//     //                 };
//     //             }

//     //             if (field === 'tdsAmt' || field === 'receiptAmt') {
//     //                 let newTdsAmt = field === 'tdsAmt' ? updatedValue : currentTdsAmt;
//     //                 let newReceiptAmt = field === 'receiptAmt' ? updatedValue : currentReceiptAmt;

//     //                 // Check if the constraint `receiptAmt - tdsAmt <= balanceAmt` is maintained
//     //                 if (newReceiptAmt - newTdsAmt > currentBalanceAmt) {
//     //                     // If the constraint is violated, do not update the values
//     //                     return invoice;
//     //                 }

//     //                 // Clear tdsAmt when tdsPercentage is set to '0'
//     //                 if (field === 'tdsPercentage' && (!updatedValue || updatedValue === '0')) {
//     //                     updatedValue = 0;
//     //                     return { 
//     //                         ...invoice, 
//     //                         [field]: updatedValue, 
//     //                         tdsAmt: 0 
//     //                     };
//     //                 }

//     //                 // Convert specific fields to numbers if necessary
//     //                 if (field === 'tdsPercentage' || field === 'tdsAmt' || field === 'receiptAmt') {
//     //                     updatedValue = parseFloat(updatedValue || 0);
//     //                 }

//     //                 return { ...invoice, [field]: updatedValue };
//     //             }

//     //             return invoice;
//     //         }
//     //         return invoice;
//     //     });

//     //     setFintransInv(updatedFintransInv);

//     //     // Update selectedItems if necessary
//     //     const updatedSelectedItems = selectedItems.map((item) => {
//     //         if (item.invoiceNo === invoiceNo) {
//     //             const currentTdsAmt = parseFloat(item.tdsAmt || 0);
//     //             const currentReceiptAmt = parseFloat(item.receiptAmt || 0);
//     //             const currentBalanceAmt = parseFloat(item.balanceAmt);
//     //             let updatedValue = parseFloat(value || 0);

//     //             if (field === 'tdsPercentage') {
//     //                 // Calculate tdsAmt based on tdsPercentage and current receiptAmt
//     //                 const newTdsAmt = (currentReceiptAmt * updatedValue) / 100;
//     //                 // Calculate new receiptAmt after applying tdsAmt
//     //                 const newReceiptAmt = currentReceiptAmt;

//     //                 // Check if the new values are within the allowed range
//     //                 if (newReceiptAmt - newTdsAmt > currentBalanceAmt) {
//     //                     // If the constraint is violated, do not update the values
//     //                     return item;
//     //                 }

//     //                 return { 
//     //                     ...item, 
//     //                     [field]: updatedValue, 
//     //                     tdsAmt: newTdsAmt 
//     //                 };
//     //             }

//     //             if (field === 'tdsAmt' || field === 'receiptAmt') {
//     //                 let newTdsAmt = field === 'tdsAmt' ? updatedValue : currentTdsAmt;
//     //                 let newReceiptAmt = field === 'receiptAmt' ? updatedValue : currentReceiptAmt;

//     //                 // Check if the constraint `receiptAmt - tdsAmt <= balanceAmt` is maintained
//     //                 if (newReceiptAmt - newTdsAmt > currentBalanceAmt) {
//     //                     // If the constraint is violated, do not update the values
//     //                     return item;
//     //                 }

//     //                 // Clear tdsAmt when tdsPercentage is set to '0'
//     //                 if (field === 'tdsPercentage' && (!updatedValue || updatedValue === '0')) {
//     //                     updatedValue = 0;
//     //                     return { 
//     //                         ...item, 
//     //                         [field]: updatedValue, 
//     //                         tdsAmt: 0 
//     //                     };
//     //                 }

//     //                 // Convert specific fields to numbers if necessary
//     //                 if (field === 'tdsPercentage' || field === 'tdsAmt' || field === 'receiptAmt') {
//     //                     updatedValue = parseFloat(updatedValue || 0);
//     //                 }

//     //                 return { ...item, [field]: updatedValue };
//     //             }

//     //             return item;
//     //         }
//     //         return item;
//     //     });

//     //     setSelectedItems(updatedSelectedItems);
//     // };

//     // console.log(' operation : ', operation);

//     const handleInputChange = (invoiceNo, field, value) => {
//         if (operation === 'U') {
//             return handleInputChangeForModify(invoiceNo, field, value);
//         }
//         // Helper function to filter and format the value
//         const formatValue = (val) => {
//             // Remove any invalid characters except for digits and the decimal point
//             let filteredValue = val.replace(/[^0-9.]/g, '');
//             const parts = filteredValue.split('.');
//             // If there's more than one decimal point, join the first part with the first two digits after the decimal
//             if (parts.length > 2) {
//                 filteredValue = parts[0] + '.' + parts.slice(1).join('').substring(0, 2);
//             } else {
//                 // If there's exactly one decimal point, limit to two digits
//                 if (parts.length === 2) {
//                     filteredValue = parts[0] + '.' + parts[1].substring(0, 2);
//                 }
//             }

//             // Return '0' if the value is empty
//             return filteredValue === '' ? '0' : filteredValue;
//         };

//         // Validate and format the input value
//         const formattedValue = formatValue(value);


//         const updatedFintransInv = fintransInv.map((invoice) => {
//             if (invoice.invoiceNo === invoiceNo) {
//                 const currentTdsAmt = parseFloat(invoice.tdsAmt) || 0;
//                 const currentReceiptAmt = parseFloat(invoice.receiptAmt) || 0;
//                 const currentBalanceAmt = parseFloat(invoice.balanceAmt) || 0;

//                 let newTdsAmt = currentTdsAmt;
//                 let newReceiptAmt = currentReceiptAmt;

//                 // if (field === 'tdsPercentage') {
//                 //     const tdsPercentage = parseFloat(formattedValue);
//                 //     if (tdsPercentage < 0 || tdsPercentage > 100) {
//                 //         return invoice; // Constraint violated
//                 //     }
//                 //     // Calculate tdsAmt based on tdsPercentage and current receiptAmt
//                 //     newTdsAmt = parseFloat((currentBalanceAmt * parseFloat(formattedValue) / 100).toFixed(2));
//                 //     // Ensure receiptAmt - tdsAmt <= balanceAmt and receiptAmt - tdsAmt is not negative
//                 //     newReceiptAmt = (currentBalanceAmt - parseFloat(newTdsAmt).toFixed(2));

//                 //     newReceiptAmt = parseFloat(newReceiptAmt).toFixed(2);

//                 //     console.log('tdsPercentage : ', tdsPercentage);

//                 //     if ((parseFloat(newReceiptAmt) + parseFloat(newTdsAmt)).toFixed(2) < 0 || (parseFloat(newReceiptAmt) + parseFloat(newTdsAmt)).toFixed(2) > currentBalanceAmt) {

//                 //         console.log('Here !  : ', (parseFloat(newReceiptAmt) + parseFloat(newTdsAmt)), " 2: ", (parseFloat(newReceiptAmt) + parseFloat(newTdsAmt)), 'currentBalanceAmt ', currentBalanceAmt);
//                 //         return invoice; // Constraint violated
//                 //     }
//                 // }

//                 if (field === 'tdsAmt' || field === 'receiptAmt') {
//                     newTdsAmt = field === 'tdsAmt' ? formattedValue : currentTdsAmt;
//                     newReceiptAmt = field === 'receiptAmt' ? formattedValue : currentReceiptAmt;

//                     // Ensure receiptAmt - tdsAmt <= balanceAmt and receiptAmt - tdsAmt is not negative
//                     if ((parseFloat(newReceiptAmt) + parseFloat(newTdsAmt)).toFixed(2) < 0 || (parseFloat(newReceiptAmt) + parseFloat(newTdsAmt)).toFixed(2) > currentBalanceAmt) {
//                         return invoice; // Constraint violated
//                     }
//                 }

//                 return {
//                     ...invoice,
//                     // [field]: formattedValue,
//                     // tdsAmt: newTdsAmt,
//                     receiptAmt: newReceiptAmt
//                 };
//             }
//             return invoice;
//         });

//         setFintransInv(updatedFintransInv);

//         // Update selectedItems if necessary
//         const updatedSelectedItems = selectedItems.map((item) => {
//             if (item.invoiceNo === invoiceNo) {
//                 const currentTdsAmt = parseFloat(item.tdsAmt) || 0;
//                 const currentReceiptAmt = parseFloat(item.receiptAmt) || 0;
//                 const currentBalanceAmt = parseFloat(item.balanceAmt) || 0;

//                 let newTdsAmt = currentTdsAmt;
//                 let newReceiptAmt = currentReceiptAmt;

//                 // if (field === 'tdsPercentage') {
//                 //     // Calculate tdsAmt based on tdsPercentage and current receiptAmt
//                 //     newTdsAmt = parseFloat((currentBalanceAmt * parseFloat(formattedValue) / 100).toFixed(2));
//                 //     // Ensure receiptAmt - tdsAmt <= balanceAmt and receiptAmt - tdsAmt is not negative
//                 //     newReceiptAmt = (currentBalanceAmt - parseFloat(newTdsAmt).toFixed(2));

//                 //     newReceiptAmt = parseFloat(newReceiptAmt).toFixed(2);


//                 //     if ((parseFloat(newReceiptAmt) + parseFloat(newTdsAmt)).toFixed(2) < 0 || (parseFloat(newReceiptAmt) + parseFloat(newTdsAmt)).toFixed(2) > currentBalanceAmt) {

//                 //         console.log('Here !  : ', (parseFloat(newReceiptAmt) + parseFloat(newTdsAmt)), " 2: ", (parseFloat(newReceiptAmt) + parseFloat(newTdsAmt)), 'currentBalanceAmt ', currentBalanceAmt);

//                 //         return item; // Constraint violated
//                 //     }
//                 // }

//                 if (field === 'tdsAmt' || field === 'receiptAmt') {
//                     newTdsAmt = field === 'tdsAmt' ? formattedValue : currentTdsAmt;
//                     newReceiptAmt = field === 'receiptAmt' ? formattedValue : currentReceiptAmt;
//                     // Ensure receiptAmt - tdsAmt <= balanceAmt and receiptAmt - tdsAmt is not negative
//                     if ((parseFloat(newReceiptAmt) + parseFloat(newTdsAmt)).toFixed(2) < 0 || (parseFloat(newReceiptAmt) + parseFloat(newTdsAmt)).toFixed(2) > currentBalanceAmt) {
//                         return item; // Constraint violated
//                     }
//                 }

//                 return {
//                     ...item,
//                     // [field]: formattedValue,
//                     // tdsAmt: newTdsAmt,
//                     receiptAmt: newReceiptAmt
//                 };
//             }
//             return item;
//         });

//         setSelectedItems(updatedSelectedItems);
//     };



//     const handleInputChangeForModify = (invoiceNo, field, value) => {
//         // Helper function to filter and format the value
//         const formatValue = (val) => {
//             let filteredValue = val.replace(/[^0-9.]/g, '');
//             const parts = filteredValue.split('.');
//             if (parts.length > 2) {
//                 filteredValue = parts[0] + '.' + parts.slice(1).join('').substring(0, 2);
//             } else {
//                 if (parts.length === 2) {
//                     filteredValue = parts[0] + '.' + parts[1].substring(0, 2);
//                 }
//             }
//             return filteredValue === '' ? '0' : filteredValue;
//         };

//         // Validate and format the input value
//         const formattedValue = formatValue(value);

//         // Find the previous state for comparison
//         const previousEntry = toBeCheckFinTransInv.find(entry => entry.invoiceNo === invoiceNo);
//         if (!previousEntry) {
//             // If no previous entry is found, just return or handle it accordingly
//             return;
//         }
//         const previousTdsAmt = parseFloat(previousEntry.tdsAmt) || 0;
//         const previousReceiptAmt = parseFloat(previousEntry.receiptAmt) || 0;

//         // Updated state after input change
//         const updatedFintransInv = fintransInv.map((invoice) => {
//             if (invoice.invoiceNo === invoiceNo) {
//                 const currentTdsAmt = parseFloat(invoice.tdsAmt) || 0;
//                 const currentReceiptAmt = parseFloat(invoice.receiptAmt) || 0;

//                 let newTdsAmt = currentTdsAmt;
//                 let newReceiptAmt = currentReceiptAmt;

//                 if (field === 'tdsPercentage') {
//                     const tdsPercentage = parseFloat(formattedValue);
//                     if (tdsPercentage < 0 || tdsPercentage > 100) {
//                         return invoice; // Constraint violated
//                     }
//                     newTdsAmt = parseFloat((previousReceiptAmt * tdsPercentage / 100).toFixed(2));
//                     newReceiptAmt = (previousReceiptAmt - newTdsAmt).toFixed(2);
//                 }

//                 if (field === 'tdsAmt' || field === 'receiptAmt') {
//                     newTdsAmt = field === 'tdsAmt' ? formattedValue : currentTdsAmt;
//                     newReceiptAmt = field === 'receiptAmt' ? formattedValue : currentReceiptAmt;
//                 }

//                 // Check if updated values are within constraints compared to previous saved entry
//                 if ((parseFloat(newReceiptAmt) + parseFloat(newTdsAmt)).toFixed(2) < 0 || (parseFloat(newReceiptAmt) + parseFloat(newTdsAmt)).toFixed(2) > (previousReceiptAmt + previousTdsAmt)) {
//                     return invoice; // Constraint violated
//                 }

//                 return {
//                     ...invoice,
//                     [field]: formattedValue,
//                     tdsAmt: newTdsAmt,
//                     receiptAmt: newReceiptAmt
//                 };
//             }
//             return invoice;
//         });

//         setFintransInv(updatedFintransInv);

//         // Update selectedItems similarly
//         const updatedSelectedItems = selectedItems.map((item) => {
//             if (item.invoiceNo === invoiceNo) {
//                 const currentTdsAmt = parseFloat(item.tdsAmt) || 0;
//                 const currentReceiptAmt = parseFloat(item.receiptAmt) || 0;

//                 let newTdsAmt = currentTdsAmt;
//                 let newReceiptAmt = currentReceiptAmt;

//                 if (field === 'tdsPercentage') {
//                     newTdsAmt = parseFloat((currentReceiptAmt * parseFloat(formattedValue) / 100).toFixed(2));
//                     newReceiptAmt = (currentReceiptAmt - newTdsAmt).toFixed(2);
//                 }
//                 if (field === 'tdsAmt' || field === 'receiptAmt') {
//                     newTdsAmt = field === 'tdsAmt' ? formattedValue : currentTdsAmt;
//                     newReceiptAmt = field === 'receiptAmt' ? formattedValue : currentReceiptAmt;
//                 }
//                 // Check if updated values are within constraints compared to previous saved entry
//                 if ((parseFloat(newReceiptAmt) + parseFloat(newTdsAmt)).toFixed(2) < 0 || (parseFloat(newReceiptAmt) + parseFloat(newTdsAmt)).toFixed(2) > (previousReceiptAmt + previousTdsAmt)) {
//                     return item; // Constraint violated
//                 }
//                 return {
//                     ...item,
//                     [field]: formattedValue,
//                     tdsAmt: newTdsAmt,
//                     receiptAmt: newReceiptAmt
//                 };
//             }
//             return item;
//         });
//         setSelectedItems(updatedSelectedItems);
//     };













//     const handlePaymentModeChange = (e, index) => {
//         const { value } = e.target;
//         setFinTransDtl(prevState => {
//             const updatedTransDtl = [...prevState];
//             updatedTransDtl[index] = {
//                 ...updatedTransDtl[index],
//                 paymentMode: value,
//                 paymentDate: null,
//                 paymentNo: '',
//                 bankName: '',
//                 amount: 0
//             };
//             return updatedTransDtl;
//         });
//     };



//     const handleAddRow = () => {
//         const newIndex = finTransDtl.length + 1;
//         setFinTransDtl([
//             ...finTransDtl,
//             {
//                 srno: newIndex,
//                 companyId: companyid,
//                 branchId: branchId,
//                 transId: '',
//                 transDate: new Date(),
//                 partyId: selectedPartyPayment ? selectedPartyPayment.value : '',
//                 partyName: selectedPartyPayment ? selectedPartyPayment.label : '',
//                 billingPartyId: billingPartyId ? billingPartyId.value : '',
//                 billingPartyName: billingPartyId ? billingPartyId.label : '',
//                 paymentMode: 'CHEQUE',
//                 paymentDate: null,
//                 paymentNo: '',
//                 bankName: '',
//                 amount: 0,
//                 status: '',
//                 createdBy: userId,
//                 createdDate: new Date(),
//                 editedBy: userId,
//                 editedDate: new Date(),
//                 approvedBy: userId,
//                 approvedDate: new Date()
//             }
//         ]);
//     };


//     const handleRemoveRow = (index) => {
//         if (finTransDtl.length > 1) {
//             setFinTransDtl(finTransDtl.filter((_, i) => i !== index));
//         }
//     };




//     const handleFieldChange = (e, index, fieldName, paymentMode, balAdvAmt) => {
//         let { value } = e.target;

//         // Validate and format based on fieldName
//         if (fieldName === 'paymentNo') {
//             // Limit to max length of 30 characters
//             value = value.slice(0, 30);
//         } else if (fieldName === 'amount') {
//             // Ensure numeric format with up to 10 digits and up to 2 decimal places
//             // Helper function to filter and format the value
//             const formatValue = (val) => {
//                 // Remove any invalid characters except for digits and the decimal point
//                 let filteredValue = val.replace(/[^0-9.]/g, '');
//                 const parts = filteredValue.split('.');
//                 // If there's more than one decimal point, join the first part with the first two digits after the decimal
//                 if (parts.length > 2) {
//                     filteredValue = parts[0] + '.' + parts.slice(1).join('').substring(0, 2);
//                 } else {
//                     // If there's exactly one decimal point, limit to two digits
//                     if (parts.length === 2) {
//                         filteredValue = parts[0].substring(0, 10) + '.' + parts[1].substring(0, 2);
//                     }
//                 }

//                 // Return '0' if the value is empty
//                 return filteredValue === '' ? '0' : filteredValue;
//             };

//             // Validate and format the input value
//             value = formatValue(value);

//             // Convert to a number for comparison
//             const numericValue = parseFloat(value);

//             // Check if payment mode is ADVANCE and amount exceeds balAdvAmt
//             if (paymentMode === 'ADVANCE' && numericValue > balAdvAmt) {
//                 return; // Exit the function without updating the state
//             }
//         } else if (fieldName === 'bankName') {
//             // Limit to max length of 30 characters
//             value = value.slice(0, 30);
//         }

//         setFinTransDtl(prevState => {
//             const updatedTransDtl = [...prevState];
//             updatedTransDtl[index] = {
//                 ...updatedTransDtl[index],
//                 [fieldName]: fieldName === 'amount' ? value : value,
//                 paymentDate: fieldName === 'paymentDate' ? value : updatedTransDtl[index].paymentDate
//             };
//             return updatedTransDtl;
//         });

//         // Clear the validation error for the field
//         setValidationErrors(prevErrors => {
//             const updatedErrors = [...prevErrors];
//             if (updatedErrors[index]) {
//                 delete updatedErrors[index][fieldName];
//             }
//             return updatedErrors;
//         });


//     };



//     const handlePaymentDateChange = (date, index) => {
//         setFinTransDtl(prevState => {
//             const updatedTransDtl = [...prevState];
//             updatedTransDtl[index] = {
//                 ...updatedTransDtl[index],
//                 paymentDate: date
//             };
//             return updatedTransDtl;
//         });

//         // Clear the validation error for paymentDate
//         setValidationErrors(prevErrors => {
//             const updatedErrors = [...prevErrors];
//             if (updatedErrors[index]) {
//                 delete updatedErrors[index].paymentDate;
//             }
//             return updatedErrors;
//         });
//     };

//     const [validationErrors, setValidationErrors] = useState([]);

//     const validateFinTransDtl = (finTransDtl) => {
//         let errors = [];

//         finTransDtl.forEach((detail, index) => {
//             const { paymentMode, amount, paymentDate, paymentNo, bankName } = detail;
//             let error = {};

//             switch (paymentMode) {
//                 case 'ADVANCE':
//                     if (!amount || amount <= 0) {
//                         error.amount = 'Amount is required for ADVANCE payment mode.';
//                     }
//                     break;

//                 case 'CHEQUE':
//                     if (!paymentDate) error.paymentDate = 'Payment Date is required.';
//                     if (!paymentNo) error.paymentNo = 'Payment No is required.';
//                     if (!bankName) error.bankName = 'Bank Name is required.';
//                     if (!amount || amount <= 0) error.amount = 'Amount is required.';
//                     break;

//                 case 'CASH':
//                     if (!amount || amount <= 0) {
//                         error.amount = 'Amount is required for CASH payment mode.';
//                     }
//                     break;

//                 case 'UPI':
//                     if (!paymentDate) error.paymentDate = 'Payment Date is required.';
//                     if (!paymentNo) error.paymentNo = 'Payment No is required.';
//                     if (!amount || amount <= 0) error.amount = 'Amount is required.';
//                     break;

//                 case 'NEFT':
//                     if (!paymentDate) error.paymentDate = 'Payment Date is required.';
//                     if (!paymentNo) error.paymentNo = 'Payment No is required.';
//                     if (!amount || amount <= 0) error.amount = 'Amount is required.';
//                     break;

//                 default:
//                     toast.error('Unknown payment mode.', {
//                         position: toast.POSITION.TOP_CENTER,
//                         autoClose: 1500,
//                         style: { width: '35vw' }
//                     });
//                     return false;
//             }

//             errors.push(error);
//         });

//         setValidationErrors(errors);

//         // Check if there are any errors
//         return errors.every(error => Object.keys(error).length === 0);
//     };








//     // Save Receipt
//     const savePayment = async (selectedParty, billingParty) => {

//         // value={finTrans.receiptAmt - parseFloat(totalReceiptAmount - finTrans.advAmt + parseFloat(finTrans.expensiveAmt)+ parseFloat(finTrans.tdsAmt))}



//         // value={((finTrans.receiptAmt + finTrans.tdsAmt) - 
//         //     (totalReceiptAmount + finTrans.tdsAmt - finTrans.advAmt + 
//         //     )).toFixed(2)}

//         const calculatedAmount = parseFloat(finTrans.receiptAmt) + parseFloat(finTrans.tdsAmt);


//         // (
//         //    ( (parseFloat(totalReceiptAmount) || 0) - ((parseFloat(finTrans.advAmt) || 0) +
//         //     (parseFloat(finTrans.expensiveAmt) || 0)
//         //     ) + (parseFloat(finTrans.tdsAmt) || 0))
//         // ).toFixed(2);

//         const receiptAndTdsAmount = totalReceiptAmount + parseFloat(finTrans.tdsAmt) - parseFloat(finTrans.advAmt) + (parseFloat(finTrans.expensiveAmt) || 0);




//         // (
//         //     ((parseFloat(finTrans.receiptAmt) || 0) - ((parseFloat(finTrans.advAmt) || 0) +
//         //     (parseFloat(finTrans.expensiveAmt) || 0)
//         //     ) (parseFloat(finTrans.tdsAmt) || 0))
//         // ).toFixed(2);
//         console.log('calculatedAmount ', calculatedAmount, 'receiptAndTdsAmount ', receiptAndTdsAmount);

//         console.log('finTrans.tdsAmt ', parseFloat(finTrans.tdsAmt), 'totalTdsAmount ', totalTdsAmount);

//         // if ((parseFloat(finTrans.tdsAmt) !== totalTdsAmount)) {
//         //     toast.error('Header Tds Amount and detail tds Amount not matching !', {
//         //         position: toast.POSITION.TOP_CENTER,
//         //         autoClose: 1500,
//         //         style: { width: '32vw' }
//         //     });
//         //     return;
//         // }


//         // const calculatedAmount = ((totalReceiptAmount - finTrans.advAmt + finTrans.expensiveAmt) + finTrans.tdsAmt).toFixed(2);
//         // const receiptAndTdsAmount = (finTrans.receiptAmt + finTrans.tdsAmt - ).toFixed(2);

//         if ((finTrans.receiptAmt === 0 && totalReceiptAmount === 0) || (calculatedAmount !== receiptAndTdsAmount)) {
//             toast.error('Please check selected invoice amount, receipt amount and advance amount!', {
//                 position: toast.POSITION.TOP_CENTER,
//                 autoClose: 1500,
//                 style: { width: '39vw' }
//             });
//             return;
//         }

//         // console.log("parseFloat(calculatedAmount):", parseFloat(calculatedAmount), typeof parseFloat(calculatedAmount));
//         // console.log("finTrans.totalAMt:", parseFloat(finTrans.totalAMt), typeof parseFloat(finTrans.totalAMt));
//         if (operation === 'U') {
//             if (finTrans.totalAMt !== parseFloat(calculatedAmount)) {
//                 toast.error(`Please check totalReceiptAmount + Tds Amount!`, {
//                     position: toast.POSITION.TOP_CENTER,
//                     autoClose: 1500,
//                     style: { width: '35vw' }
//                 });
//                 return;
//             }
//         }


//         if (operation === 'U') {
//             console.log('parseFloat(finTrans.totalAMt) ', parseFloat(finTrans.totalAMt), ' parseFloat(toBeCheckFinTranTrans.totalAMt) ', parseFloat(toBeCheckFinTranTrans.totalAMt));

//             if (parseFloat(finTrans.totalAMt) !== parseFloat(toBeCheckFinTranTrans.totalAMt)) {
//                 toast.error('Previous Total Amount & Updated Total Amount not matching...', {
//                     position: toast.POSITION.TOP_CENTER,
//                     autoClose: 1500,
//                     style: { width: '35vw' }
//                 });
//                 return;
//             }
//         }





//         if (!validateFinTransDtl(finTransDtl)) {
//             return; // If validation fails, stop the function
//         }


//         setLoading(true);
//         try {
//             const response = await InviceService.savePayment(companyid, branchId, selectedParty.value, selectedParty.label, billingParty.value, billingParty.label, userId, finTrans, finTransDtl, selectedItems);

//             console.log(response.data);

//             const { finTrans: updatedFinTrans, finTransDtl: updatedFinTransDtl, finTransInv: updatedFinTransInv } = response.data;
//             setFinTrans(updatedFinTrans);
//             setFinTransDtl(updatedFinTransDtl);
//             setFintransInv(updatedFinTransInv);

//             setToBeCheckFinTrans(updatedFinTrans);
//             setToBeCheckFinTransInv(updatedFinTransInv);
//             setSelectedItems(updatedFinTransInv);

//             getTransByPartyId(billingParty.value);
//             setOperation('U');
//         }
//         catch (error) {

//             console.error("Error occurred while saving payment:", error);
//             toast.error('Oops something went wrong!', {
//                 position: toast.POSITION.TOP_CENTER,
//                 autoClose: 900,
//             });
//         }
//         finally {
//             setLoading(false);
//         }
//     };

//     // DOWNLOAD RECEIPT
//     const downLoadReceipt = async (partyId, receiptId, type) => {
//         setLoading(true);
//         try {
//             const response = await InviceService.downloadReceipt(companyid, branchId, partyId, receiptId);
//             if (response.status === 200) {
//                 const pdfData = response.data;
//                 const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
//                 const blobUrl = URL.createObjectURL(pdfBlob);

//                 if (type === "PDF") {
//                     // Create an anchor element for downloading
//                     const downloadLink = document.createElement('a');
//                     downloadLink.href = blobUrl;
//                     downloadLink.download = 'Receipt.pdf'; // Set the filename for the downloaded PDF
//                     downloadLink.style.display = 'none';
//                     document.body.appendChild(downloadLink);
//                     // Trigger the download
//                     downloadLink.click();
//                     // Clean up
//                     document.body.removeChild(downloadLink);
//                     window.URL.revokeObjectURL(blobUrl);
//                 }
//                 if (type === 'PRINT') {
//                     window.open(blobUrl, '_blank');
//                 }
//                 toast.success("Downloading Pdf!", {
//                     position: toast.POSITION.TOP_CENTER,
//                     autoClose: 800,
//                 });
//             } else {
//                 console.error("Error downloading PDF:", response.statusText);
//             }
//         } catch (error) {
//             console.error("Error downloading PDF:", error);
//         } finally {
//             setLoading(false);
//         }
//     };


//     const clearReceipt = () => {
//         setFinTrans(initialFintrans);
//         setFinTransDtl(initialFintransDtl);
//         setFintransInv([]);
//         setSelectedItems([]);
//         setSelectAll(false);
//         setSelectedPartyPayment(null);
//         setBillingPartyId(null);
//         setValidationErrors([]);
//         setOperation(null);
//         setToBeCheckFinTrans([]);
//         setToBeCheckFinTransInv([]);
//     };



//     const handleTdsAmountChange = async (e) => {
//         if (selectedItems && selectedItems.length === 0) {
//             toast.error('Please select invoices!', {
//                 position: toast.POSITION.TOP_CENTER,
//                 autoClose: 900,
//             });
//             return;
//         }

//         const value = e.target.value;
//         const regex = /^\d*(\.\d{0,2})?$/;

//         console.log('value : ', value);


//         if (regex.test(value) || value === '') {

//             // Update finTrans state if necessary
//             setFinTrans(prevState => ({
//                 ...prevState,
//                 tdsAmt: value === '' ? 0 : value
//             }));

//         }
//     };

//     // // Function to update finTransInv based on selectedItems
//     // const updateFinTransInv = () => {
//     //     const updatedFinTransInv = fintransInv.map(inv => {
//     //         const relatedItem = selectedItems.find(item => item.invoiceNo === inv.invoiceNo);
//     //         return {
//     //             ...inv,
//     //             receiptAmt:  parseFloat(relatedItem ? relatedItem.balanceAmt.toFixed(2) : 0) - parseFloat(relatedItem ? relatedItem.tdsAmt.toFixed(2) : 0),
//     //             tdsAmt: relatedItem ? relatedItem.tdsAmt : 0,
//     //             tdsPercentage: relatedItem ? relatedItem.tdsPercentage : 0
//     //         };
//     //     });

//     //     setFintransInv(updatedFinTransInv);
//     // };

//     // Function to update finTransInv based on selectedItems
//     const updateFinTransInv = (selectedItems) => {
//         const updatedFinTransInv = fintransInv.map(inv => {
//             const relatedItem = selectedItems.find(item => item.invoiceNo === inv.invoiceNo);
//             return {
//                 ...inv,
//                 tdsAmt: relatedItem ? relatedItem.tdsAmt : 0,
//                 tdsPercentage: relatedItem ? relatedItem.tdsPercentage : 0,
//                 // If you need to update receiptAmt, include logic here
//                 receiptAmt: relatedItem ? (parseFloat(relatedItem.balanceAmt) - parseFloat(relatedItem.tdsAmt)).toFixed(2) : inv.receiptAmt
//             };
//         });
//         setFintransInv(updatedFinTransInv);
//     };

//     // Function to calculate TDS amounts
//     const calculateTdsAmounts = (tdsValue, selectedItems) => {
//         if (selectedItems.length > 0) {
//             const totalSelectedItems = selectedItems.length;
//             let equalTdsAmount = tdsValue / totalSelectedItems;
//             let carryOver = 0;
//             const updatedItems = [];

//             for (const item of selectedItems) {
//                 let newTotal = equalTdsAmount + carryOver;

//                 // Cap the total to the balance amount
//                 if (newTotal > item.balanceAmt) {
//                     carryOver = newTotal - item.balanceAmt;
//                     newTotal = item.balanceAmt;
//                 } else {
//                     carryOver = 0;
//                 }

//                 updatedItems.push({
//                     ...item,
//                     tdsAmt: parseFloat(newTotal).toFixed(2),
//                     tdsPercentage: parseFloat(((newTotal / parseFloat(item.balanceAmt)) * 100).toFixed(2)),
//                     receiptAmt: (parseFloat(item.balanceAmt) - parseFloat(newTotal)).toFixed(2)
//                 });
//             }
//             setSelectedItems(updatedItems);
//             return updatedItems; // Indicate that calculation was performed
//             // }
//         }
//         return false; // No calculations performed
//     };

//     // Function to handle TDS updates
//     const handleTdsUpdate = async (value, selectedItems) => {

//         const tdsValue = parseFloat(value) || 0;
//         if (!operation) {
//             const returnValue = await calculateTdsAmounts(tdsValue, selectedItems);
//             if (Array.isArray(returnValue) && returnValue.length > 0) {
//                 updateFinTransInv(returnValue);
//             }
//         }
//         if (operation === 'U') {
//             const returnValue = await calculateTdsAmountsUpdate(tdsValue, selectedItems);
//             if (Array.isArray(returnValue) && returnValue.length > 0) {
//                 updateFinTransInvUpdate(returnValue);
//             }
//         }
//     };


//     const updateFinTransInvUpdate = (selectedItems) => {
//         const updatedFinTransInv = fintransInv.map(inv => {
//             const relatedItem = selectedItems.find(item => item.invoiceNo === inv.invoiceNo);
//             return {
//                 ...inv,
//                 tdsAmt: relatedItem ? relatedItem.tdsAmt : 0,
//                 tdsPercentage: relatedItem ? relatedItem.tdsPercentage : 0,
//                 // If you need to update receiptAmt, include logic here
//                 receiptAmt: relatedItem ? parseFloat(relatedItem.receiptAmt).toFixed(2) : inv.receiptAmt
//             };
//         });
//         setFintransInv(updatedFinTransInv);
//     };

//     // Function to calculate TDS amounts
//     const calculateTdsAmountsUpdate = (tdsValue, selectedItems) => {
//         if (selectedItems.length > 0) {
//             const totalSelectedItems = selectedItems.length;
//             let equalTdsAmount = tdsValue / totalSelectedItems;
//             let carryOver = 0;
//             const updatedItems = [];

//             for (const item of selectedItems) {
//                 let newTotal = equalTdsAmount + carryOver;

//                 const previousEntry = toBeCheckFinTransInv.find(entry => entry.invoiceNo === item.invoiceNo);
//                 if (!previousEntry) {
//                     return;
//                 }
//                 const previousTdsAmt = parseFloat(previousEntry.tdsAmt) || 0;
//                 const previousReceiptAmt = parseFloat(previousEntry.receiptAmt) || 0;
//                 let balanceAmt = previousTdsAmt + previousReceiptAmt;

//                 // Cap the total to the balance amount
//                 if (newTotal > balanceAmt) {
//                     carryOver = newTotal - balanceAmt;
//                     newTotal = balanceAmt;
//                 } else {
//                     carryOver = 0;
//                 }

//                 updatedItems.push({
//                     ...item,
//                     tdsAmt: parseFloat(newTotal).toFixed(2),
//                     tdsPercentage: parseFloat(((newTotal / parseFloat(balanceAmt)) * 100).toFixed(2)),
//                     receiptAmt: (parseFloat(balanceAmt) - parseFloat(newTotal)).toFixed(2)
//                 });
//             }
//             setSelectedItems(updatedItems);
//             return updatedItems; // Indicate that calculation was performed
//             // }
//         }
//         return false; // No calculations performed
//     };





//     useEffect(() => {
//         if (!operation) {
//             handleTdsUpdate(finTrans.tdsAmt, selectedItems)
//         }
//     }, [selectedItems.length, operation]);

//     // Call handleTdsUpdate whenever necessary
//     // For example, in an event handler or after a specific user action


//     // console.log('selectedItems : ', selectedItems);
//     // console.log('finTrans : ', finTrans, ' \n finTransInv : ', fintransInv, ' \n selectedItems ', selectedItems);


//     // console.log(((finTrans.receiptAmt + finTrans.tdsAmt) -
//     //     (totalReceiptAmount + finTrans.tdsAmt - finTrans.advAmt +
//     //         (parseFloat(finTrans.expensiveAmt) || 0))).toFixed(2));

//     // console.log('finTrans.receiptAmt ', finTrans.receiptAmt, ' finTrans.tdsAmt ', finTrans.tdsAmt, ' totalReceiptAmount ', totalReceiptAmount,
//     //     ' finTrans.tdsAmt - finTrans.advAmt ', finTrans.tdsAmt - finTrans.advAmt, ' (parseFloat(finTrans.expensiveAmt) || 0) ', (parseFloat(finTrans.expensiveAmt) || 0));

//     return (
//         <>
//             {loading && (
//                 <div style={styles.overlay}>
//                     <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
//                 </div>
//             )}


//             <div className="Container" >
//                 <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '2%', paddingRight: '-10px' }} > <FontAwesomeIcon
//                     icon={faReceipt}
//                     style={{
//                         marginRight: '5px',
//                         color: 'black',
//                     }}
//                 />Add Receipt</h5>
//                 <Card>
//                     <CardBody>
//                         <Row>
//                             <Col md={3}>
//                                 <FormGroup>
//                                     <label className="forlabel" for="branchId">Receipt Id</label>
//                                     <Input
//                                         type="text"
//                                         name="approvedBy"
//                                         id="service"
//                                         readOnly
//                                         value={finTrans.transId}
//                                         className="inputField"
//                                         tabIndex="-1"
//                                     />
//                                 </FormGroup>
//                             </Col>
//                             <Col md={3}>
//                                 <FormGroup>
//                                     <label className="forlabel" for="branchId">Receipt Date</label>
//                                     <div>
//                                         <DatePicker
//                                             selected={finTrans.transDate}
//                                             wrapperClassName="custom-react-datepicker-wrapper"
//                                             value={finTrans.transDate}
//                                             dateFormat="dd/MM/yyyy"
//                                             className="form-control"
//                                             disabled
//                                             id='service'
//                                             tabIndex="-1"
//                                         />
//                                     </div>
//                                 </FormGroup>
//                             </Col>
//                             <Col md={3}>
//                                 <FormGroup>
//                                     <label className="forlabel" for="branchId">Receipt Amount</label>
//                                     <Input
//                                         type="number"
//                                         name="receiptAmt"
//                                         value={totalReceiptAmount}
//                                         readOnly
//                                         tabIndex="-1"
//                                         id='service'
//                                     />
//                                 </FormGroup>
//                             </Col>
//                             <Col md={3}>
//                                 <FormGroup>
//                                     <label className="forlabel" for="branchId">Selected Invoice Amount</label>
//                                     <Input
//                                         type="number"
//                                         name="tdsAmt"
//                                         value={finTrans.receiptAmt}
//                                         readOnly
//                                         tabIndex="-1"
//                                         id='service'
//                                     />
//                                 </FormGroup>
//                             </Col>
//                         </Row>
//                         <Row>
//                             <Col md={3}>
//                                 <FormGroup>
//                                     <label className="forlabel" for="branchId">Select Invoice Party</label>
//                                     <Select
//                                         options={partiesAllSearchData}
//                                         value={selectedPartyPayment}
//                                         onInputChange={(inputValue, { action }) => {
//                                             if (action === 'input-change') {
//                                                 findPartiesAllSearch(inputValue);
//                                             }
//                                         }}
//                                         onChange={handlePartyPayment}
//                                         className={errors.selectedPartyPayment ? 'error-border' : ''}
//                                         isClearable
//                                         isDisabled={operation}
//                                         id={operation ? 'service' : ''}
//                                         components={{ Option: CustomOptionParty }}
//                                         filterOption={() => true}
//                                         styles={{
//                                             control: (provided, state) => ({
//                                                 ...provided,
//                                                 border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
//                                                 boxShadow: 'none',
//                                                 '&:hover': {
//                                                     border: '1px solid #ccc'
//                                                 }
//                                             }),
//                                             indicatorSeparator: () => ({
//                                                 display: 'none'
//                                             }),
//                                             dropdownIndicator: () => ({
//                                                 display: 'none'
//                                             })
//                                         }}
//                                     />
//                                 </FormGroup>
//                             </Col>


//                             <Col md={3}>
//                                 <FormGroup>
//                                     <label className="forlabel" for="branchId">Select Payment Party</label>
//                                     <Select
//                                         options={partiesAllSearchData}
//                                         value={billingPartyId}
//                                         onChange={handlePartyPaymentNew}
//                                         onInputChange={(inputValue, { action }) => {
//                                             if (action === 'input-change') {
//                                                 findPartiesAllSearch(inputValue);
//                                             }
//                                         }}
//                                         className={errors.billingPartyId ? 'error-border' : ''}
//                                         isClearable
//                                         components={{ Option: CustomOptionParty }}
//                                         isDisabled={operation}
//                                         id={operation ? 'service' : ''}
//                                         filterOption={() => true}
//                                         styles={{
//                                             control: (provided, state) => ({
//                                                 ...provided,
//                                                 border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
//                                                 boxShadow: 'none',
//                                                 '&:hover': {
//                                                     border: '1px solid #ccc'
//                                                 }
//                                             }),
//                                             indicatorSeparator: () => ({
//                                                 display: 'none'
//                                             }),
//                                             dropdownIndicator: () => ({
//                                                 display: 'none'
//                                             })
//                                         }}
//                                     />
//                                 </FormGroup>
//                             </Col>



//                             <Col md={3}>

//                                 <FormGroup>
//                                     <label className="forlabel" for="branchId">Tds Amount</label>
//                                     <Input
//                                         type="text"
//                                         name="approvedBy"
//                                         value={finTrans.tdsAmt}
//                                         readOnly={operation === 'E'}
//                                         id={operation === 'E' ? 'service' : ''}
//                                         onChange={(e) => handleTdsAmountChange(e)}
//                                         onBlur={(e) => handleTdsUpdate(e.target.value, selectedItems)}
//                                     />
//                                 </FormGroup>


//                             </Col>
//                             <Col md={3}>
//                                 <FormGroup>
//                                     <label className="forlabel" for="branchId">Balance advance amount</label>
//                                     <Input
//                                         type="text"
//                                         name="balAdvAmt"
//                                         value={balAdvAmt}
//                                         onChange={(e) => setBalAdvAmt(e.target.value)}
//                                         maxLength={8}
//                                         tabIndex="-1"
//                                         readOnly
//                                         id='service'
//                                     />
//                                 </FormGroup>
//                             </Col>

//                         </Row>


//                         {/* Add Paymenr By Payment Type  */}


//                         <Row>

//                             <Col md={3}>
//                                 <FormGroup>
//                                     <label className="forlabel" for="branchId">Currency</label>
//                                     <Input
//                                         type="text"
//                                         name="INR"
//                                         placeholder='INR'
//                                         value={finTrans.currency}
//                                         readOnly
//                                         tabIndex="-1"
//                                         id='service'
//                                     />
//                                 </FormGroup>
//                             </Col>

//                             <Col md={3}>
//                                 <FormGroup>
//                                     <label className="forlabel" for="branchId">Remarks</label>
//                                     <textarea
//                                         name="approvedBy"
//                                         rows="2"
//                                         value={finTrans.narration}
//                                         className="inputField form-control"
//                                         maxLength={250}
//                                         onChange={(e) => setFinTrans(prevState => ({ ...prevState, narration: e.target.value }))}
//                                         readOnly={operation === 'E'}
//                                         id={operation === 'E' ? 'service' : ''}
//                                     ></textarea>

//                                 </FormGroup>
//                             </Col>

//                             <Col md={2}>
//                                 <FormGroup>
//                                     <label className="forlabel" for="branchId">Difference Amount</label>
//                                     <Input
//                                         type="text"
//                                         name="INR"
//                                         placeholder='INR'
//                                         // value={((parseFloat(totalReceiptAmount) || 0) + (parseFloat(finTrans.tdsAmt) || 0) - (parseFloat(finTrans.advAmt) || 0)) - ((parseFloat(finTrans.receiptAmt) || 0) + (parseFloat(finTrans.tdsAmt) || 0) - (parseFloat(finTrans.advAmt) || 0))}
//                                         // value={(finTrans.receiptAmt + finTrans.tdsAmt) - (totalReceiptAmount + finTrans.tdsAmt - finTrans.advAmt + (parseFloat(finTrans.expensiveAmt) || 0))}
//                                         value={((parseFloat(finTrans.receiptAmt) + parseFloat(finTrans.tdsAmt)) -
//                                             (totalReceiptAmount + parseFloat(finTrans.tdsAmt) - parseFloat(finTrans.advAmt) +
//                                                 (parseFloat(finTrans.expensiveAmt) || 0))).toFixed(2)}

//                                         readOnly
//                                         tabIndex="-1"
//                                         id='service'
//                                     />
//                                 </FormGroup>
//                             </Col>


//                             <Col md={2}>
//                                 <FormGroup>
//                                     <label className="forlabel" for="branchId">Advance Amount</label>
//                                     <Input
//                                         type="text"
//                                         name="advanceAmount"
//                                         placeholder='Enter Advance Amount'
//                                         onChange={(e) => {
//                                             const value = e.target.value;
//                                             const regex = /^\d*(\.\d{0,2})?$/;

//                                             if (regex.test(value) || value === '') {
//                                                 setFinTrans(prevState => ({
//                                                     ...prevState,
//                                                     advAmt: value === '' ? '0' : value
//                                                 }));
//                                             }
//                                         }}
//                                         readOnly={operation}
//                                         id={operation ? 'service' : ''}
//                                         // tabIndex="-1"
//                                         value={finTrans.advAmt}
//                                         maxLength={10}
//                                     />


//                                 </FormGroup>
//                             </Col>

//                             <Col md={2}>
//                                 <FormGroup>
//                                     <label className="forlabel" for="branchId">Expensive Amount</label>
//                                     <Input
//                                         type="text"
//                                         name="expensiveAmt"
//                                         placeholder='Enter Expensive Amount'
//                                         onChange={(e) => {
//                                             const value = e.target.value;
//                                             const regex = /^\d*(\.\d{0,2})?$/;

//                                             if (regex.test(value) || value === '') {
//                                                 setFinTrans(prevState => ({
//                                                     ...prevState,
//                                                     expensiveAmt: value === '' ? 0 : value
//                                                 }));
//                                             }
//                                         }}
//                                         readOnly={operation}
//                                         id={operation ? 'service' : ''}
//                                         // tabIndex="-1"
//                                         value={finTrans.expensiveAmt}
//                                         maxLength={5}
//                                     />
//                                 </FormGroup>
//                             </Col>


//                         </Row>


//                         <div className="text-center">
//                             <Button
//                                 variant="outline-success"
//                                 style={{ marginTop: '2vw' }}
//                                 disabled={
//                                     (
//                                         !selectedPartyPayment ||
//                                         !billingPartyId
//                                     ) ||
//                                     (operation === 'E')
//                                 } onClick={() => savePayment(selectedPartyPayment, billingPartyId)}
//                             >
//                                 <FontAwesomeIcon icon={faSave} style={{ marginRight: '5px' }} />
//                                 SAVE
//                             </Button>

//                             <Button
//                                 variant="outline-primary"
//                                 style={{ marginTop: '2vw', marginLeft: '10px' }}
//                                 disabled={!selectedPartyPayment || !finTrans.transId}
//                                 onClick={() => downLoadReceipt(finTrans.partyId, finTrans.transId, 'PRINT')}
//                             >
//                                 <FontAwesomeIcon icon={faSave} style={{ marginRight: '5px' }} />
//                                 Download
//                             </Button>



//                             {operation !== 'E' && (
//                                 <Button
//                                     variant="outline-success"
//                                     style={{ marginTop: '2vw', marginLeft: '10px' }}
//                                     onClick={clearReceipt}
//                                 >
//                                     <FontAwesomeIcon icon={faSave} style={{ marginRight: '5px' }} />
//                                     Clear
//                                 </Button>
//                             )}

//                             <Button
//                                 variant="outline-danger"
//                                 style={{ marginTop: '2vw', marginLeft: '1.5vw' }}
//                                 onClick={Handleback}
//                             >
//                                 <FontAwesomeIcon icon={faBackward} style={{ marginRight: '5px' }} />
//                                 BACK
//                             </Button>

//                         </div>


//                         <div className="table-responsive mt-2">
//                             <h5 style={{ marginLeft: '10px' }}>Payment :</h5>

//                             <Table className="table table-bordered" style={{ border: '2px solid black' }}>
//                                 <thead>
//                                     <tr>
//                                         <th className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font', height: '60px' }}>Select Payment Mode</th>
//                                         <th className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Transaction No / Cheque No</th>
//                                         <th className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Transaction Date / Cheque Date</th>
//                                         <th className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Bank Name</th>
//                                         <th className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Amount</th>
//                                         {operation !== 'E' && (
//                                             <th className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Actions</th>
//                                         )}
//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                     {finTransDtl.map((payment, index) => (
//                                         <tr key={index} className="text-center">
//                                             <td>
//                                                 <FormGroup>
//                                                     <Input
//                                                         type="select"
//                                                         value={payment.paymentMode}
//                                                         disabled={operation === 'E' || payment.transId}
//                                                         id={operation === 'E' || payment.transId ? 'service' : ''}
//                                                         onChange={(e) => handlePaymentModeChange(e, index)}
//                                                     >
//                                                         <option value="CHEQUE">Cheque</option>
//                                                         <option value="NEFT">NEFT</option>
//                                                         <option value="UPI">UPI</option>
//                                                         <option value="CASH">Cash</option>
//                                                         <option value="ADVANCE">Advance</option>
//                                                     </Input>
//                                                 </FormGroup>
//                                             </td>

//                                             <td>
//                                                 {(payment.paymentMode !== 'ADVANCE' && payment.paymentMode !== 'CASH') && (
//                                                     <FormGroup>
//                                                         <Input
//                                                             type="text"
//                                                             readOnly={operation === 'E'}
//                                                             id={operation === 'E' ? 'service' : ''}
//                                                             value={payment.paymentNo}
//                                                             onChange={(e) => handleFieldChange(e, index, 'paymentNo', payment.paymentMode, balAdvAmt)}
//                                                             className={validationErrors[index]?.paymentNo ? 'is-invalid' : ''}
//                                                         />
//                                                         {/* {validationErrors[index]?.paymentNo && <div className="invalid-feedback">{validationErrors[index].paymentNo}</div>} */}
//                                                     </FormGroup>
//                                                 )}
//                                             </td>

//                                             <td>
//                                                 {(payment.paymentMode !== 'ADVANCE' && payment.paymentMode !== 'CASH') && (
//                                                     <FormGroup>
//                                                         <DatePicker
//                                                             selected={payment.paymentDate}
//                                                             onChange={(date) => handlePaymentDateChange(date, index)}
//                                                             dateFormat="dd/MM/yyyy"
//                                                             className={`form-control ${validationErrors[index]?.paymentDate ? 'is-invalid' : ''}`}
//                                                             customInput={<Input style={{ width: '100%' }} />}
//                                                             disabled={operation === 'E'}
//                                                             id={operation === 'E' ? 'service' : ''}
//                                                         />
//                                                         {/* {validationErrors[index]?.paymentDate && <div className="invalid-feedback">{validationErrors[index].paymentDate}</div>} */}
//                                                     </FormGroup>
//                                                 )}
//                                             </td>
//                                             <td>
//                                                 {payment.paymentMode === 'CHEQUE' && (
//                                                     <FormGroup>
//                                                         <Input
//                                                             type="text"
//                                                             readOnly={operation === 'E'}
//                                                             id={operation === 'E' ? 'service' : ''}
//                                                             value={payment.bankName}
//                                                             onChange={(e) => handleFieldChange(e, index, 'bankName', payment.paymentMode, balAdvAmt)}
//                                                             className={validationErrors[index]?.bankName ? 'is-invalid' : ''}
//                                                         />
//                                                         {/* {validationErrors[index]?.bankName && <div className="invalid-feedback">{validationErrors[index].bankName}</div>} */}
//                                                     </FormGroup>
//                                                 )}
//                                             </td>

//                                             <td>
//                                                 <FormGroup>
//                                                     <Input
//                                                         type="text"
//                                                         readOnly={operation === 'E'}
//                                                         id={operation === 'E' ? 'service' : ''}
//                                                         value={payment.amount}
//                                                         onChange={(e) => handleFieldChange(e, index, 'amount', payment.paymentMode, balAdvAmt)}
//                                                         className={validationErrors[index]?.amount ? 'is-invalid' : ''}
//                                                     />
//                                                     {/* {validationErrors[index]?.amount && <div className="invalid-feedback">{validationErrors[index].amount}</div>} */}
//                                                 </FormGroup>
//                                             </td>
//                                             {operation !== 'E' && (
//                                                 <td>
//                                                     {index === finTransDtl.length - 1 ? (
//                                                         <FontAwesomeIcon
//                                                             icon={faPlusCircle}
//                                                             onClick={handleAddRow}
//                                                             style={{ color: 'skyblue', cursor: 'pointer', fontSize: '24px', marginRight: '5px' }}
//                                                         />
//                                                     ) : (
//                                                         null
//                                                     )}
//                                                     <FontAwesomeIcon
//                                                         icon={faTimesCircle}
//                                                         onClick={() => handleRemoveRow(index)}
//                                                         style={{ color: 'red', cursor: 'pointer', fontSize: '24px' }}
//                                                     />
//                                                 </td>
//                                             )}
//                                         </tr>
//                                     ))}
//                                 </tbody>
//                             </Table>
//                         </div>




//                         {fintransInv.length > 0 ? (
//                             <div className="table-responsive mt-3">
//                                 <h5 style={{ marginLeft: '10px' }}>Pending Invoices :</h5>
//                                 <Table className="table table-bordered table-hover text-center" style={{ border: '2px solid black' }}>
//                                     <thead className="thead-dark bg-dark">
//                                         <tr className="text-center" style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '18px' }}>
//                                             {!operation && (
//                                                 <th style={{ backgroundColor: '#BADDDA', height: '60px' }} scope="col">
//                                                     <Input
//                                                         className="form-check-input radios"
//                                                         type="checkbox"
//                                                         style={{ width: '1.2vw', height: '1.2vw' }}
//                                                         checked={selectAll}
//                                                         onChange={() => handleSelectAllChange()}
//                                                     /></th>
//                                             )}
//                                             <th style={{ background: '#BADDDA' }}>Party Name</th>
//                                             <th style={{ background: '#BADDDA' }}>Invoice Number</th>
//                                             <th style={{ background: '#BADDDA' }}>Invoice Date</th>
//                                             <th style={{ background: '#BADDDA' }}>Invoice Amount</th>
//                                             <th style={{ background: '#BADDDA' }}>Balance Amount</th>


//                                             <th style={{ background: '#BADDDA' }}>Tds Percentage</th>
//                                             <th style={{ background: '#BADDDA' }}>Tds Amount</th>
//                                             <th style={{ background: '#BADDDA' }}>Amount To Be Cleared</th>
//                                         </tr>

//                                     </thead>
//                                     <tbody>
//                                         {fintransInv.map((invoice, index) =>
//                                             <tr className="text-center dynamic-row-width">
//                                                 {!operation && (
//                                                     <td>
//                                                         <Input
//                                                             className="form-check-input radios"
//                                                             type="checkbox"
//                                                             style={{ width: '1.2vw', height: '1.2vw' }}
//                                                             name="taxApplicable"
//                                                             checked={selectedItems.some((item) => item.invoiceNo === invoice.invoiceNo)}
//                                                             onChange={() => handleRowCheckboxChange(invoice)}
//                                                         />
//                                                     </td>
//                                                 )}
//                                                 <td>{invoice.partyName}</td>
//                                                 <td>{invoice.invoiceNo}</td>
//                                                 <td>{formatDateTime(invoice.invoiceDate)}</td>
//                                                 <td>{invoice.invoiceAmt}</td>
//                                                 <td>{invoice.balanceAmt}</td>
//                                                 <td className="text-center">
//                                                     {/* <Input
//                                                         type="text"
//                                                         name="tdsPercentage"
//                                                         className="form-control inputField text-center"
//                                                         placeholder="Tds Percentage"
//                                                         // readOnly={operation === 'E'}
//                                                         // id={operation === 'E' ? 'service' : ''}
//                                                         readOnly={operation}
//                                                         id={'service'}
//                                                         tabIndex={-1}
//                                                         value={invoice.tdsPercentage}
//                                                         // onChange={(e) => handleInputChange(invoice.invoiceNo, 'tdsPercentage', e.target.value)}
//                                                     /> */}
//                                                     {invoice.tdsPercentage}
//                                                 </td>
//                                                 <td className="text-center">
//                                                     {invoice.tdsAmt}
//                                                     {/* <Input
//                                                         type="text"
//                                                         name="tdsAmt"
//                                                         className="form-control inputField text-center"
//                                                         placeholder="Tds Amount"
//                                                         // readOnly={operation === 'E'}
//                                                         // id={operation === 'E' ? 'service' : ''}
//                                                         readOnly={operation}
//                                                         id={'service'}
//                                                         tabIndex={-1}
//                                                         value={invoice.tdsAmt}
//                                                         // onChange={(e) => handleInputChange(invoice.invoiceNo, 'tdsAmt', e.target.value)}
//                                                     /> */}
//                                                 </td>

//                                                 <td className="text-center">
//                                                     <Input
//                                                         type="text"
//                                                         name="receiptAmt"
//                                                         className="form-control inputField text-center"
//                                                         placeholder="Amount Paid"
//                                                         readOnly={operation === 'E'}
//                                                         id={operation === 'E' ? 'service' : ''}
//                                                         value={invoice.receiptAmt}
//                                                         onChange={(e) => handleInputChange(invoice.invoiceNo, 'receiptAmt', e.target.value)}
//                                                     />
//                                                 </td>

//                                             </tr>
//                                         )}
//                                     </tbody>
//                                 </Table>

//                             </div>
//                         ) : null}
//                     </CardBody>
//                 </Card>

//             </div>
//         </>
//     );



// }

// export default AddReceipts;






import React, { useState, useEffect, useContext, useLayoutEffect } from "react";
import AuthContext from "../Components/AuthProvider";
import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select, { components } from 'react-select';
import Rate_Chart_Service from "../services/Rate_Chart_Service";
import { Card, CardBody, Container, Row, Col, Form, FormGroup, label, Input, Table } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faClose, faPlaneCircleCheck, faPlaneUp, faPlus, faPlusCircle, faReceipt, faRefresh, faSearch, faTimesCircle, faTruckArrowRight, faTruckFast, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';
import InviceService from "../services/InviceService";


function AddReceipts() {


    const styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)', // Adjust the opacity and color as needed
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999, // Ensure the overlay is above other elements
        },
    };
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const {
        userId,
        username,
        branchId,
        companyid,
        role,
        login,
        logout,
    } = useContext(AuthContext);

    const { isAuthenticated } = useContext(AuthContext);
    const location = useLocation();
    const [errors, setErrors] = useState({});


    const transId = location.state?.transId;
    const partyId = location.state?.partyId;
    const operationScreen = location.state?.operation;
    const searchCriteria = location.state?.searchCriteria;
    const currentPage = location.state?.currentPage;
    const [balAdvAmt, setBalAdvAmt] = useState(0);
    const [selectedPartyPayment, setSelectedPartyPayment] = useState(null);
    const [billingPartyId, setBillingPartyId] = useState(null);
    const [advanceAmount, setAdvanceAmount] = useState(0);


    const [operation, setOperation] = useState(operationScreen);


    const Handleback = () => {
        navigate(`/parent/Receipts`, { state: { searchCriteria: searchCriteria, currentPage: currentPage } })
    };

    useEffect(() => {
        const fetchData = async () => {
            await findPartiesAll();
            if (transId && partyId) {
                await getReceiptById(companyid, branchId, transId, partyId);
            }
        };
        fetchData();
    }, [0]);


    const [partiesAll, setPartiesAll] = useState([]);
    const [partiesAllSearchData, setPartiesAllSearchData] = useState([]);

    const findPartiesAll = async (value) => {
        try {
            const partyResponse = await Rate_Chart_Service.getAllPartiesReceipt(companyid, branchId, value);
            const results = partyResponse.data;

            if (results && results.length > 0) {
                // Set only the first 200 results if data is available
                setPartiesAll(results);
                setPartiesAllSearchData(results.slice(0, 200));
            } else {
                // Handle the case where no data is returned
                setPartiesAll([]);
                setPartiesAllSearchData([]);
                toast.warn('No parties found!', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 900,
                });
            }
        } catch (error) {
            console.error("Error fetching parties:", error);
            toast.error('failed to fetch parties!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 900,
            });
        }
    };

    // console.log('partiesAll : \n', partiesAll);

    const findPartiesAllSearch = (inputValue) => {
        if (!inputValue) {
            if (partiesAll && partiesAll.length > 0) {
                setPartiesAllSearchData(partiesAll.slice(0, 200));
            } else {
                setPartiesAllSearchData([]);
            }
            return;
        }

        const lowerCasedInput = inputValue.toLowerCase();
        const filteredParties = partiesAll.filter(party =>
            party.value.toLowerCase().includes(lowerCasedInput) ||
            party.label.toLowerCase().includes(lowerCasedInput) ||
            party.partyCode.toLowerCase().includes(lowerCasedInput)
        );
        setPartiesAllSearchData(filteredParties);
    };



    const CustomOptionParty = (props) => {
        return (
            <components.Option {...props}>
                <span style={{ fontWeight: 'bold' }}>{props.data.partyCode}</span>
                {" - " + props.data.label}
            </components.Option>
        );
    };


    const [toBeCheckFinTransInv, setToBeCheckFinTransInv] = useState([]);
    const [toBeCheckFinTranTrans, setToBeCheckFinTrans] = useState([]);


    const getReceiptById = async (companyid, branchId, transId, partyId) => {
        setLoading(true);
        try {
            console.log("Calling");
            const response = await InviceService.getReceiptById(companyid, branchId, transId, partyId);
            const { finTrans: updatedFinTrans, finTransDtl: updatedFinTransDtl, finTransInv: updatedFinTransInv } = response.data;

            console.log('updatedFinTransInv : \n', updatedFinTransInv, ' \n finTransInv  All : ', response.data);
            setToBeCheckFinTrans(updatedFinTrans);
            setToBeCheckFinTransInv(updatedFinTransInv);
            setFinTrans(updatedFinTrans);
            setFinTransDtl(updatedFinTransDtl);
            setFintransInv(updatedFinTransInv);
            setSelectedItems(updatedFinTransInv);
            getTransByPartyId(partyId);
        }
        catch (error) {

            console.error("Error occurred while saving payment:", error);
            toast.error('Oops something went wrong!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 900,
            });
        }
        finally {
            setLoading(false);
        }
    };






    const handlePartyPayment = async (selectedOption) => {
        setErrors(prevErrors => ({
            ...prevErrors,
            selectedPartyPayment: selectedOption ? '' : 'Please Select Party'
        }));

        setSelectedPartyPayment(selectedOption ? selectedOption : null);
        setSelectedItems([]);
        setFintransInv([]);
        setFinTrans(initialFintrans);
        setFinTransDtl(initialFintransDtl);

        // Update the `partyId` and `partyName` fields
        setFinTrans(prevFinTrans => ({
            ...prevFinTrans,
            partyId: selectedOption ? selectedOption.value : '',
            partyName: selectedOption ? selectedOption.label : ''
        }));

        // Call the function to get invoice data
        if (selectedOption) {
            await getInvoiceDataByPartyId(selectedOption.value);
        }

        // Call `handlePartyPaymentNew` to handle the billing related updates
        await handlePartyPaymentNew(selectedOption);
    };

    const handlePartyPaymentNew = async (selectedOption) => {
        setErrors(prevErrors => ({
            ...prevErrors,
            billingPartyId: selectedOption ? '' : 'Please Select Party'
        }));

        setBillingPartyId(selectedOption);
        // console.log('selectedOption \n', selectedOption);

        // Update the `billingPartyId` and `billingPartyName` fields
        setFinTrans(prevFinTrans => ({
            ...prevFinTrans,
            billingPartyId: selectedOption ? selectedOption.value : '',
            billingPartyName: selectedOption ? selectedOption.label : ''
        }));

        // Call the function to get transaction data by party ID
        if (selectedOption) {
            await getTransByPartyId(selectedOption.value);
        } else {
            setBalAdvAmt(0);
        }
    };







    const getTransByPartyId = async (partyId) => {
        const response = await InviceService.getPartyAdvAndClearedAmount(companyid, branchId, partyId);
        setBalAdvAmt(response.data);
        // console.log('setBalAdvAmt(response.data); \n', response.data, ' \n companyid, branchId, partyId', companyid, branchId, partyId);
    };


    const initialFintrans = {
        srno: 1,
        branchId: branchId,
        companyId: companyid,
        docType: 'REC',
        transId: '',
        transDate: new Date(),
        partyId: '',
        partyName: '',
        billingPartyId: '',
        billingPartyName: '',
        currency: 'INR',
        totalAMt: 0,
        receiptAmt: 0,
        narration: '',
        clearedAmt: 0,
        advFlag: 'N',
        balAdvAmt: 0,
        advAmt: 0,
        tdsPercentage: 0,
        tdsAmt: 0,
        expensiveAmt: 0,
        tdsStatus: '',
        status: 'N',
        createdBy: userId,
        createdDate: new Date(),
        editedBy: userId,
        editedDate: new Date(),
        approvedBy: userId,
        approvedDate: new Date()
    };


    const initialFintransDtl = [{
        srno: 1,
        companyId: companyid,
        branchId: branchId,
        transId: '',
        transDate: new Date(),
        partyId: selectedPartyPayment ? selectedPartyPayment.value : '',
        partyName: selectedPartyPayment ? selectedPartyPayment.label : '',
        billingPartyId: billingPartyId ? billingPartyId.value : '',
        billingPartyName: billingPartyId ? billingPartyId.name : '',
        paymentMode: 'CHEQUE',
        paymentDate: null,
        paymentNo: '',
        bankName: '',
        amount: 0,
        status: 'A',
        createdBy: userId,
        createdDate: new Date(),
        editedBy: userId,
        editedDate: new Date(),
        approvedBy: userId,
        approvedDate: new Date()
    }];



    const [finTrans, setFinTrans] = useState(initialFintrans);
    const [finTransDtl, setFinTransDtl] = useState(initialFintransDtl);
    const [fintransInv, setFintransInv] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [totalReceiptAmount, setTotalReceiptAmount] = useState(0);
    const [totalTdsAmount, setTotalTdsAmount] = useState(0);



    useEffect(() => {
        if (partiesAll.length > 0 && transId && partyId) {
            const foundType = partiesAll.find(type => type.value === finTrans.partyId);
            setSelectedPartyPayment(foundType ? foundType : null);
        }
    }, [partiesAll, finTrans.partyId]);

    useEffect(() => {
        if (partiesAll.length > 0 && (transId && partyId)) {
            const foundType = partiesAll.find(type => type.value === finTrans.billingPartyId);
            setBillingPartyId(foundType ? foundType : null);
        }
    }, [partiesAll, finTrans.billingPartyId]);







    // Function to calculate total amount entered in finTransDtl
    const calculateTotalPaidAmount = () => {
        let total = 0;
        finTransDtl.forEach(payment => {
            const amount = parseFloat(payment.amount) || 0;
            total += amount;
        });
        setTotalReceiptAmount(parseFloat(total.toFixed(2)));
        // console.log('total', total, ' ');
    };

    // Function to calculate totals
    const calculateTotals = () => {
        let tdsTotal = 0;
        let receiptTotal = 0;

        selectedItems.forEach(item => {
            const tdsAmt = parseFloat(item.tdsAmt) || 0;
            const receiptAmt = parseFloat(item.receiptAmt) || 0;
            tdsTotal += tdsAmt;
            receiptTotal += receiptAmt;
        });

        // console.log('Calling in ',receiptTotal);


        setTotalTdsAmount(parseFloat(tdsTotal.toFixed(2)));
        // Update finTrance with calculated totals
        setFinTrans(prevState => ({
            ...prevState,
            // tdsAmt: parseFloat(tdsTotal.toFixed(2)),
            receiptAmt: parseFloat(receiptTotal.toFixed(2)),
            totalAMt: parseFloat(tdsTotal.toFixed(2)) + parseFloat(receiptTotal.toFixed(2))
        }));
    };


    // Call calculateTotalAmount whenever finTransDtl changes
    useEffect(() => {
        calculateTotalPaidAmount();
    }, [finTransDtl, finTrans.advAmt]);
    useEffect(() => {
        calculateTotals();
    }, [selectedItems]);


    const convertToFibtransInv = (invoices) => {
        return invoices.map((invoice) => ({
            srno: null, // or generate a unique srno if needed
            companyId: companyid,
            branchId: branchId,
            invoiceNo: invoice.invoiceNO,
            invoiceDate: invoice.invoiceDate,
            partyId: invoice.partyId,
            partyName: invoice.partyName,
            billingPartyId: '',
            billingPartyName: '',
            currency: 'INR',
            invoiceAmt: invoice.totalInvoiceAmount,
            tdsStatus: '',
            receiptAmt: invoice.balanceAmount,
            balanceAmt: invoice.balanceAmount,
            tdsPercentage: 0,
            tdsAmt: 0,
            status: '',
            createdBy: '',
            createdDate: null,
            editedBy: '',
            editedDate: null,
            approvedBy: '',
            approvedDate: null
        }));
    };


    console.log('Tds Total : ', totalTdsAmount);




    const formatDateTime = (value) => {
        if (!value) {
            return "";
        }
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };




    //   Get Invoice By Party
    const getInvoiceDataByPartyId = async (partyId) => {
        setLoading(true);
        try {
            setTotalTdsAmount(0);
            await InviceService.getPendingInvoicesPartyId(companyid, branchId, partyId).then((res) => {
                const fibtransInvList = convertToFibtransInv(res.data);
                setFintransInv(fibtransInvList);
            });
        }
        catch {
            console.log("Error");
        } finally {
            setLoading(false);
        }
    };



    // Function to handle individual checkbox change
    const handleRowCheckboxChange = (invoice) => {
        const isChecked = selectedItems.some((item) => item.invoiceNo === invoice.invoiceNo);

        if (isChecked) {
            const updatedSelectedItems = selectedItems.filter((item) => item.invoiceNo !== invoice.invoiceNo);
            setSelectedItems(updatedSelectedItems);
        } else {
            setSelectedItems([...selectedItems, invoice]);
        }
    };

    // Use useEffect to manage selectAll state
    useEffect(() => {
        setSelectAll(selectedItems.length === fintransInv.length);
    }, [selectedItems, fintransInv]);

    // Function to handle select all checkbox change
    const handleSelectAllChange = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            setSelectedItems(fintransInv);
        }
        setSelectAll(!selectAll);
    };


    // console.log("finTrans: \n", finTrans);



    // const handleInputChange = (invoiceNo, field, value) => {
    //     const updatedFintransInv = fintransInv.map((invoice) => {
    //         if (invoice.invoiceNo === invoiceNo) {
    //             let updatedValue = value;
    //             const currentTdsAmt = parseFloat(invoice.tdsAmt || 0);
    //             const currentReceiptAmt = parseFloat(invoice.receiptAmt || 0);
    //             const currentBalanceAmt = parseFloat(invoice.balanceAmt);
    //             const tdsPercentage = parseFloat(invoice.tdsPercentage || 0);

    //             // Handle tdsAmt based on tdsPercentage and balanceAmt
    //             if (field === 'tdsAmt' || field === 'receiptAmt') {
    //                 if (field === 'tdsAmt' && (!tdsPercentage || tdsPercentage === 0)) {
    //                     // Reset tdsAmt if tdsPercentage is not entered or is '0'
    //                     updatedValue = '';
    //                 } else {
    //                     // Ensure tdsAmt + receiptAmt does not exceed balanceAmt
    //                     let newTdsAmt = field === 'tdsAmt' ? parseFloat(updatedValue) : currentTdsAmt;
    //                     let newReceiptAmt = field === 'receiptAmt' ? parseFloat(updatedValue) : currentReceiptAmt;

    //                     if (newTdsAmt + newReceiptAmt > currentBalanceAmt) {
    //                         // Adjust the value to not exceed balanceAmt
    //                         updatedValue = field === 'tdsAmt'
    //                             ? (currentBalanceAmt - currentReceiptAmt).toString()
    //                             : (currentBalanceAmt - currentTdsAmt).toString();
    //                     }
    //                 }
    //             }

    //             // Clear tdsAmt when tdsPercentage is set to '0'
    //             if (field === 'tdsPercentage' && (!updatedValue || updatedValue === '0')) {
    //                 updatedValue = parseFloat(updatedValue || 0);
    //                 return { ...invoice, [field]: updatedValue, tdsAmt: 0 };
    //             }

    //             // Convert specific fields to numbers if necessary
    //             if (field === 'tdsPercentage' || field === 'tdsAmt' || field === 'receiptAmt') {
    //                 updatedValue = parseFloat(updatedValue || 0);
    //             }

    //             return { ...invoice, [field]: updatedValue };
    //         }
    //         return invoice;
    //     });

    //     setFintransInv(updatedFintransInv);

    //     // Update selectedItems if necessary
    //     const updatedSelectedItems = selectedItems.map((item) => {
    //         if (item.invoiceNo === invoiceNo) {
    //             let updatedValue = value;
    //             const currentTdsAmt = parseFloat(item.tdsAmt || 0);
    //             const currentReceiptAmt = parseFloat(item.receiptAmt || 0);
    //             const currentBalanceAmt = parseFloat(item.balanceAmt);
    //             const tdsPercentage = parseFloat(item.tdsPercentage || 0);

    //             // Handle tdsAmt based on tdsPercentage and balanceAmt in selectedItems
    //             if (field === 'tdsAmt' || field === 'receiptAmt') {
    //                 if (field === 'tdsAmt' && (!tdsPercentage || tdsPercentage === 0)) {
    //                     updatedValue = '';
    //                 } else {
    //                     let newTdsAmt = field === 'tdsAmt' ? parseFloat(updatedValue) : currentTdsAmt;
    //                     let newReceiptAmt = field === 'receiptAmt' ? parseFloat(updatedValue) : currentReceiptAmt;

    //                     if (newTdsAmt + newReceiptAmt > currentBalanceAmt) {
    //                         updatedValue = field === 'tdsAmt'
    //                             ? (currentBalanceAmt - currentReceiptAmt).toString()
    //                             : (currentBalanceAmt - currentTdsAmt).toString();
    //                     }
    //                 }
    //             }

    //             // Clear tdsAmt when tdsPercentage is set to '0'
    //             if (field === 'tdsPercentage' && (!updatedValue || updatedValue === '0')) {
    //                 updatedValue = parseFloat(updatedValue || 0);
    //                 return { ...item, [field]: updatedValue, tdsAmt: 0 };
    //             }

    //             // Convert specific fields to numbers if necessary
    //             if (field === 'tdsPercentage' || field === 'tdsAmt' || field === 'receiptAmt') {
    //                 updatedValue = parseFloat(updatedValue || 0);
    //             }

    //             return { ...item, [field]: updatedValue };
    //         }
    //         return item;
    //     });

    //     setSelectedItems(updatedSelectedItems);
    // };




    // const handleInputChange = (invoiceNo, field, value) => {
    //     const updatedFintransInv = fintransInv.map((invoice) => {
    //         if (invoice.invoiceNo === invoiceNo) {
    //             const currentTdsAmt = parseFloat(invoice.tdsAmt || 0);
    //             const currentReceiptAmt = parseFloat(invoice.receiptAmt || 0);
    //             const currentBalanceAmt = parseFloat(invoice.balanceAmt);
    //             let updatedValue = parseFloat(value || 0);

    //             if (field === 'tdsPercentage') {
    //                 // Calculate tdsAmt based on tdsPercentage and current receiptAmt
    //                 const newTdsAmt = (currentReceiptAmt * updatedValue) / 100;
    //                 // Calculate new receiptAmt after applying tdsAmt
    //                 const newReceiptAmt = currentReceiptAmt;

    //                 // Check if the new values are within the allowed range
    //                 if (newReceiptAmt - newTdsAmt > currentBalanceAmt) {
    //                     // If the constraint is violated, do not update the values
    //                     return invoice;
    //                 }

    //                 return { 
    //                     ...invoice, 
    //                     [field]: updatedValue, 
    //                     tdsAmt: newTdsAmt 
    //                 };
    //             }

    //             if (field === 'tdsAmt' || field === 'receiptAmt') {
    //                 let newTdsAmt = field === 'tdsAmt' ? updatedValue : currentTdsAmt;
    //                 let newReceiptAmt = field === 'receiptAmt' ? updatedValue : currentReceiptAmt;

    //                 // Check if the constraint `receiptAmt - tdsAmt <= balanceAmt` is maintained
    //                 if (newReceiptAmt - newTdsAmt > currentBalanceAmt) {
    //                     // If the constraint is violated, do not update the values
    //                     return invoice;
    //                 }

    //                 // Clear tdsAmt when tdsPercentage is set to '0'
    //                 if (field === 'tdsPercentage' && (!updatedValue || updatedValue === '0')) {
    //                     updatedValue = 0;
    //                     return { 
    //                         ...invoice, 
    //                         [field]: updatedValue, 
    //                         tdsAmt: 0 
    //                     };
    //                 }

    //                 // Convert specific fields to numbers if necessary
    //                 if (field === 'tdsPercentage' || field === 'tdsAmt' || field === 'receiptAmt') {
    //                     updatedValue = parseFloat(updatedValue || 0);
    //                 }

    //                 return { ...invoice, [field]: updatedValue };
    //             }

    //             return invoice;
    //         }
    //         return invoice;
    //     });

    //     setFintransInv(updatedFintransInv);

    //     // Update selectedItems if necessary
    //     const updatedSelectedItems = selectedItems.map((item) => {
    //         if (item.invoiceNo === invoiceNo) {
    //             const currentTdsAmt = parseFloat(item.tdsAmt || 0);
    //             const currentReceiptAmt = parseFloat(item.receiptAmt || 0);
    //             const currentBalanceAmt = parseFloat(item.balanceAmt);
    //             let updatedValue = parseFloat(value || 0);

    //             if (field === 'tdsPercentage') {
    //                 // Calculate tdsAmt based on tdsPercentage and current receiptAmt
    //                 const newTdsAmt = (currentReceiptAmt * updatedValue) / 100;
    //                 // Calculate new receiptAmt after applying tdsAmt
    //                 const newReceiptAmt = currentReceiptAmt;

    //                 // Check if the new values are within the allowed range
    //                 if (newReceiptAmt - newTdsAmt > currentBalanceAmt) {
    //                     // If the constraint is violated, do not update the values
    //                     return item;
    //                 }

    //                 return { 
    //                     ...item, 
    //                     [field]: updatedValue, 
    //                     tdsAmt: newTdsAmt 
    //                 };
    //             }

    //             if (field === 'tdsAmt' || field === 'receiptAmt') {
    //                 let newTdsAmt = field === 'tdsAmt' ? updatedValue : currentTdsAmt;
    //                 let newReceiptAmt = field === 'receiptAmt' ? updatedValue : currentReceiptAmt;

    //                 // Check if the constraint `receiptAmt - tdsAmt <= balanceAmt` is maintained
    //                 if (newReceiptAmt - newTdsAmt > currentBalanceAmt) {
    //                     // If the constraint is violated, do not update the values
    //                     return item;
    //                 }

    //                 // Clear tdsAmt when tdsPercentage is set to '0'
    //                 if (field === 'tdsPercentage' && (!updatedValue || updatedValue === '0')) {
    //                     updatedValue = 0;
    //                     return { 
    //                         ...item, 
    //                         [field]: updatedValue, 
    //                         tdsAmt: 0 
    //                     };
    //                 }

    //                 // Convert specific fields to numbers if necessary
    //                 if (field === 'tdsPercentage' || field === 'tdsAmt' || field === 'receiptAmt') {
    //                     updatedValue = parseFloat(updatedValue || 0);
    //                 }

    //                 return { ...item, [field]: updatedValue };
    //             }

    //             return item;
    //         }
    //         return item;
    //     });

    //     setSelectedItems(updatedSelectedItems);
    // };

    // console.log(' operation : ', operation);

    const handleInputChange = (invoiceNo, field, value) => {
        if (operation === 'U') {
            return handleInputChangeForModify(invoiceNo, field, value);
        }
        // Helper function to filter and format the value
        const formatValue = (val) => {
            // Remove any invalid characters except for digits and the decimal point
            let filteredValue = val.replace(/[^0-9.]/g, '');
            const parts = filteredValue.split('.');
            // If there's more than one decimal point, join the first part with the first two digits after the decimal
            if (parts.length > 2) {
                filteredValue = parts[0] + '.' + parts.slice(1).join('').substring(0, 2);
            } else {
                // If there's exactly one decimal point, limit to two digits
                if (parts.length === 2) {
                    filteredValue = parts[0] + '.' + parts[1].substring(0, 2);
                }
            }

            // Return '0' if the value is empty
            return filteredValue === '' ? '0' : filteredValue;
        };

        // Validate and format the input value
        const formattedValue = formatValue(value);


        const updatedFintransInv = fintransInv.map((invoice) => {
            if (invoice.invoiceNo === invoiceNo) {
                const currentTdsAmt = parseFloat(invoice.tdsAmt) || 0;
                const currentReceiptAmt = parseFloat(invoice.receiptAmt) || 0;
                const currentBalanceAmt = parseFloat(invoice.balanceAmt) || 0;

                let newTdsAmt = currentTdsAmt;
                let newReceiptAmt = currentReceiptAmt;

                // if (field === 'tdsPercentage') {
                //     const tdsPercentage = parseFloat(formattedValue);
                //     if (tdsPercentage < 0 || tdsPercentage > 100) {
                //         return invoice; // Constraint violated
                //     }
                //     // Calculate tdsAmt based on tdsPercentage and current receiptAmt
                //     newTdsAmt = parseFloat((currentBalanceAmt * parseFloat(formattedValue) / 100).toFixed(2));
                //     // Ensure receiptAmt - tdsAmt <= balanceAmt and receiptAmt - tdsAmt is not negative
                //     newReceiptAmt = (currentBalanceAmt - parseFloat(newTdsAmt).toFixed(2));

                //     newReceiptAmt = parseFloat(newReceiptAmt).toFixed(2);

                //     console.log('tdsPercentage : ', tdsPercentage);

                //     if ((parseFloat(newReceiptAmt) + parseFloat(newTdsAmt)).toFixed(2) < 0 || (parseFloat(newReceiptAmt) + parseFloat(newTdsAmt)).toFixed(2) > currentBalanceAmt) {

                //         console.log('Here !  : ', (parseFloat(newReceiptAmt) + parseFloat(newTdsAmt)), " 2: ", (parseFloat(newReceiptAmt) + parseFloat(newTdsAmt)), 'currentBalanceAmt ', currentBalanceAmt);
                //         return invoice; // Constraint violated
                //     }
                // }

                if (field === 'tdsAmt' || field === 'receiptAmt') {
                    newTdsAmt = field === 'tdsAmt' ? formattedValue : currentTdsAmt;
                    newReceiptAmt = field === 'receiptAmt' ? formattedValue : currentReceiptAmt;

                    // Ensure receiptAmt - tdsAmt <= balanceAmt and receiptAmt - tdsAmt is not negative
                    if ((parseFloat(newReceiptAmt) + parseFloat(newTdsAmt)).toFixed(2) < 0 || (parseFloat(newReceiptAmt) + parseFloat(newTdsAmt)).toFixed(2) > currentBalanceAmt) {
                        return invoice; // Constraint violated
                    }
                }

                return {
                    ...invoice,
                    // [field]: formattedValue,
                    // tdsAmt: newTdsAmt,
                    receiptAmt: newReceiptAmt
                };
            }
            return invoice;
        });

        setFintransInv(updatedFintransInv);

        // Update selectedItems if necessary
        const updatedSelectedItems = selectedItems.map((item) => {
            if (item.invoiceNo === invoiceNo) {
                const currentTdsAmt = parseFloat(item.tdsAmt) || 0;
                const currentReceiptAmt = parseFloat(item.receiptAmt) || 0;
                const currentBalanceAmt = parseFloat(item.balanceAmt) || 0;

                let newTdsAmt = currentTdsAmt;
                let newReceiptAmt = currentReceiptAmt;

                // if (field === 'tdsPercentage') {
                //     // Calculate tdsAmt based on tdsPercentage and current receiptAmt
                //     newTdsAmt = parseFloat((currentBalanceAmt * parseFloat(formattedValue) / 100).toFixed(2));
                //     // Ensure receiptAmt - tdsAmt <= balanceAmt and receiptAmt - tdsAmt is not negative
                //     newReceiptAmt = (currentBalanceAmt - parseFloat(newTdsAmt).toFixed(2));

                //     newReceiptAmt = parseFloat(newReceiptAmt).toFixed(2);


                //     if ((parseFloat(newReceiptAmt) + parseFloat(newTdsAmt)).toFixed(2) < 0 || (parseFloat(newReceiptAmt) + parseFloat(newTdsAmt)).toFixed(2) > currentBalanceAmt) {

                //         console.log('Here !  : ', (parseFloat(newReceiptAmt) + parseFloat(newTdsAmt)), " 2: ", (parseFloat(newReceiptAmt) + parseFloat(newTdsAmt)), 'currentBalanceAmt ', currentBalanceAmt);

                //         return item; // Constraint violated
                //     }
                // }

                if (field === 'tdsAmt' || field === 'receiptAmt') {
                    newTdsAmt = field === 'tdsAmt' ? formattedValue : currentTdsAmt;
                    newReceiptAmt = field === 'receiptAmt' ? formattedValue : currentReceiptAmt;
                    // Ensure receiptAmt - tdsAmt <= balanceAmt and receiptAmt - tdsAmt is not negative
                    if ((parseFloat(newReceiptAmt) + parseFloat(newTdsAmt)).toFixed(2) < 0 || (parseFloat(newReceiptAmt) + parseFloat(newTdsAmt)).toFixed(2) > currentBalanceAmt) {
                        return item; // Constraint violated
                    }
                }

                return {
                    ...item,
                    // [field]: formattedValue,
                    // tdsAmt: newTdsAmt,
                    receiptAmt: newReceiptAmt
                };
            }
            return item;
        });

        setSelectedItems(updatedSelectedItems);
    };



    const handleInputChangeForModify = (invoiceNo, field, value) => {
        // Helper function to filter and format the value
        const formatValue = (val) => {
            let filteredValue = val.replace(/[^0-9.]/g, '');
            const parts = filteredValue.split('.');
            if (parts.length > 2) {
                filteredValue = parts[0] + '.' + parts.slice(1).join('').substring(0, 2);
            } else {
                if (parts.length === 2) {
                    filteredValue = parts[0] + '.' + parts[1].substring(0, 2);
                }
            }
            return filteredValue === '' ? '0' : filteredValue;
        };

        // Validate and format the input value
        const formattedValue = formatValue(value);

        // Find the previous state for comparison
        const previousEntry = toBeCheckFinTransInv.find(entry => entry.invoiceNo === invoiceNo);
        if (!previousEntry) {
            // If no previous entry is found, just return or handle it accordingly
            return;
        }
        const previousTdsAmt = parseFloat(previousEntry.tdsAmt) || 0;
        const previousReceiptAmt = parseFloat(previousEntry.receiptAmt) || 0;

        // Updated state after input change
        const updatedFintransInv = fintransInv.map((invoice) => {
            if (invoice.invoiceNo === invoiceNo) {
                const currentTdsAmt = parseFloat(invoice.tdsAmt) || 0;
                const currentReceiptAmt = parseFloat(invoice.receiptAmt) || 0;

                let newTdsAmt = currentTdsAmt;
                let newReceiptAmt = currentReceiptAmt;

                if (field === 'tdsPercentage') {
                    const tdsPercentage = parseFloat(formattedValue);
                    if (tdsPercentage < 0 || tdsPercentage > 100) {
                        return invoice; // Constraint violated
                    }
                    newTdsAmt = parseFloat((previousReceiptAmt * tdsPercentage / 100).toFixed(2));
                    newReceiptAmt = (previousReceiptAmt - newTdsAmt).toFixed(2);
                }

                if (field === 'tdsAmt' || field === 'receiptAmt') {
                    newTdsAmt = field === 'tdsAmt' ? formattedValue : currentTdsAmt;
                    newReceiptAmt = field === 'receiptAmt' ? formattedValue : currentReceiptAmt;
                }

                // Check if updated values are within constraints compared to previous saved entry
                if ((parseFloat(newReceiptAmt) + parseFloat(newTdsAmt)).toFixed(2) < 0 || (parseFloat(newReceiptAmt) + parseFloat(newTdsAmt)).toFixed(2) > (previousReceiptAmt + previousTdsAmt)) {
                    return invoice; // Constraint violated
                }

                return {
                    ...invoice,
                    [field]: formattedValue,
                    tdsAmt: newTdsAmt,
                    receiptAmt: newReceiptAmt
                };
            }
            return invoice;
        });

        setFintransInv(updatedFintransInv);

        // Update selectedItems similarly
        const updatedSelectedItems = selectedItems.map((item) => {
            if (item.invoiceNo === invoiceNo) {
                const currentTdsAmt = parseFloat(item.tdsAmt) || 0;
                const currentReceiptAmt = parseFloat(item.receiptAmt) || 0;

                let newTdsAmt = currentTdsAmt;
                let newReceiptAmt = currentReceiptAmt;

                if (field === 'tdsPercentage') {
                    newTdsAmt = parseFloat((currentReceiptAmt * parseFloat(formattedValue) / 100).toFixed(2));
                    newReceiptAmt = (currentReceiptAmt - newTdsAmt).toFixed(2);
                }
                if (field === 'tdsAmt' || field === 'receiptAmt') {
                    newTdsAmt = field === 'tdsAmt' ? formattedValue : currentTdsAmt;
                    newReceiptAmt = field === 'receiptAmt' ? formattedValue : currentReceiptAmt;
                }
                // Check if updated values are within constraints compared to previous saved entry
                if ((parseFloat(newReceiptAmt) + parseFloat(newTdsAmt)).toFixed(2) < 0 || (parseFloat(newReceiptAmt) + parseFloat(newTdsAmt)).toFixed(2) > (previousReceiptAmt + previousTdsAmt)) {
                    return item; // Constraint violated
                }
                return {
                    ...item,
                    [field]: formattedValue,
                    tdsAmt: newTdsAmt,
                    receiptAmt: newReceiptAmt
                };
            }
            return item;
        });
        setSelectedItems(updatedSelectedItems);
    };













    const handlePaymentModeChange = (e, index) => {
        const { value } = e.target;
        setFinTransDtl(prevState => {
            const updatedTransDtl = [...prevState];
            updatedTransDtl[index] = {
                ...updatedTransDtl[index],
                paymentMode: value,
                paymentDate: null,
                paymentNo: '',
                bankName: '',
                amount: 0
            };
            return updatedTransDtl;
        });
    };



    const handleAddRow = () => {
        const newIndex = finTransDtl.length + 1;
        setFinTransDtl([
            ...finTransDtl,
            {
                srno: newIndex,
                companyId: companyid,
                branchId: branchId,
                transId: '',
                transDate: new Date(),
                partyId: selectedPartyPayment ? selectedPartyPayment.value : '',
                partyName: selectedPartyPayment ? selectedPartyPayment.label : '',
                billingPartyId: billingPartyId ? billingPartyId.value : '',
                billingPartyName: billingPartyId ? billingPartyId.label : '',
                paymentMode: 'CHEQUE',
                paymentDate: null,
                paymentNo: '',
                bankName: '',
                amount: 0,
                status: '',
                createdBy: userId,
                createdDate: new Date(),
                editedBy: userId,
                editedDate: new Date(),
                approvedBy: userId,
                approvedDate: new Date()
            }
        ]);
    };


    const handleRemoveRow = (index) => {
        if (finTransDtl.length > 1) {
            setFinTransDtl(finTransDtl.filter((_, i) => i !== index));
        }
    };




    const handleFieldChange = (e, index, fieldName, paymentMode, balAdvAmt) => {
        let { value } = e.target;

        // Validate and format based on fieldName
        if (fieldName === 'paymentNo') {
            // Limit to max length of 30 characters
            value = value.slice(0, 30);
        } else if (fieldName === 'amount') {
            // Ensure numeric format with up to 10 digits and up to 2 decimal places
            // Helper function to filter and format the value
            const formatValue = (val) => {
                // Remove any invalid characters except for digits and the decimal point
                let filteredValue = val.replace(/[^0-9.]/g, '');
                const parts = filteredValue.split('.');
                // If there's more than one decimal point, join the first part with the first two digits after the decimal
                if (parts.length > 2) {
                    filteredValue = parts[0] + '.' + parts.slice(1).join('').substring(0, 2);
                } else {
                    // If there's exactly one decimal point, limit to two digits
                    if (parts.length === 2) {
                        filteredValue = parts[0].substring(0, 10) + '.' + parts[1].substring(0, 2);
                    }
                }

                // Return '0' if the value is empty
                return filteredValue === '' ? '0' : filteredValue;
            };

            // Validate and format the input value
            value = formatValue(value);

            // Convert to a number for comparison
            const numericValue = parseFloat(value);

            // Check if payment mode is ADVANCE and amount exceeds balAdvAmt
            if (paymentMode === 'ADVANCE' && numericValue > balAdvAmt) {
                return; // Exit the function without updating the state
            }
        } else if (fieldName === 'bankName') {
            // Limit to max length of 30 characters
            value = value.slice(0, 30);
        }

        setFinTransDtl(prevState => {
            const updatedTransDtl = [...prevState];
            updatedTransDtl[index] = {
                ...updatedTransDtl[index],
                [fieldName]: fieldName === 'amount' ? value : value,
                paymentDate: fieldName === 'paymentDate' ? value : updatedTransDtl[index].paymentDate
            };
            return updatedTransDtl;
        });

        // Clear the validation error for the field
        setValidationErrors(prevErrors => {
            const updatedErrors = [...prevErrors];
            if (updatedErrors[index]) {
                delete updatedErrors[index][fieldName];
            }
            return updatedErrors;
        });


    };



    const handlePaymentDateChange = (date, index) => {
        setFinTransDtl(prevState => {
            const updatedTransDtl = [...prevState];
            updatedTransDtl[index] = {
                ...updatedTransDtl[index],
                paymentDate: date
            };
            return updatedTransDtl;
        });

        // Clear the validation error for paymentDate
        setValidationErrors(prevErrors => {
            const updatedErrors = [...prevErrors];
            if (updatedErrors[index]) {
                delete updatedErrors[index].paymentDate;
            }
            return updatedErrors;
        });
    };

    const [validationErrors, setValidationErrors] = useState([]);

    const validateFinTransDtl = (finTransDtl) => {
        let errors = [];

        finTransDtl.forEach((detail, index) => {
            const { paymentMode, amount, paymentDate, paymentNo, bankName } = detail;
            let error = {};

            switch (paymentMode) {
                case 'ADVANCE':
                    if (!amount || amount <= 0) {
                        error.amount = 'Amount is required for ADVANCE payment mode.';
                    }
                    break;

                case 'CHEQUE':
                    if (!paymentDate) error.paymentDate = 'Payment Date is required.';
                    if (!paymentNo) error.paymentNo = 'Payment No is required.';
                    if (!bankName) error.bankName = 'Bank Name is required.';
                    if (!amount || amount <= 0) error.amount = 'Amount is required.';
                    break;

                case 'CASH':
                    if (!amount || amount <= 0) {
                        error.amount = 'Amount is required for CASH payment mode.';
                    }
                    break;

                case 'UPI':
                    if (!paymentDate) error.paymentDate = 'Payment Date is required.';
                    if (!paymentNo) error.paymentNo = 'Payment No is required.';
                    if (!amount || amount <= 0) error.amount = 'Amount is required.';
                    break;

                case 'NEFT':
                    if (!paymentDate) error.paymentDate = 'Payment Date is required.';
                    if (!paymentNo) error.paymentNo = 'Payment No is required.';
                    if (!amount || amount <= 0) error.amount = 'Amount is required.';
                    break;

                default:
                    toast.error('Unknown payment mode.', {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 1500,
                        style: { width: '35vw' }
                    });
                    return false;
            }

            errors.push(error);
        });

        setValidationErrors(errors);

        // Check if there are any errors
        return errors.every(error => Object.keys(error).length === 0);
    };








    // Save Receipt
    const savePayment = async (selectedParty, billingParty) => {

        if (!Array.isArray(selectedItems) || selectedItems.length === 0) {
            toast.warning('Please select invoice!', {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 700,
            });
            return;
          }

        // value={finTrans.receiptAmt - parseFloat(totalReceiptAmount - finTrans.advAmt + parseFloat(finTrans.expensiveAmt)+ parseFloat(finTrans.tdsAmt))}



        // value={((finTrans.receiptAmt + finTrans.tdsAmt) - 
        //     (totalReceiptAmount + finTrans.tdsAmt - finTrans.advAmt + 
        //     )).toFixed(2)}

        const calculatedAmount = parseFloat(finTrans.receiptAmt) + parseFloat(finTrans.tdsAmt);


        // (
        //    ( (parseFloat(totalReceiptAmount) || 0) - ((parseFloat(finTrans.advAmt) || 0) +
        //     (parseFloat(finTrans.expensiveAmt) || 0)
        //     ) + (parseFloat(finTrans.tdsAmt) || 0))
        // ).toFixed(2);


        const formattedAmount = (
            parseFloat(totalReceiptAmount) + 
            parseFloat(finTrans.tdsAmt) - 
            parseFloat(finTrans.advAmt) + 
            (parseFloat(finTrans.expensiveAmt) || 0)
          ).toFixed(2);  // Ensure two decimal places
          
          // If you need it as a number, you can convert it back:
          const receiptAndTdsAmount = parseFloat(formattedAmount);


        // const receiptAndTdsAmount = (parseFloat(totalReceiptAmount) + parseFloat(finTrans.tdsAmt) - parseFloat(finTrans.advAmt) + (parseFloat(finTrans.expensiveAmt) || 0)).toFixed;
console.log('totalReceiptAmount',totalReceiptAmount, 'finTrans.advAmt ', finTrans.advAmt, ' finTrans.tdsAmt ',finTrans.tdsAmt, ' finTrans.expensiveAmt ',finTrans.expensiveAmt);



        // (
        //     ((parseFloat(finTrans.receiptAmt) || 0) - ((parseFloat(finTrans.advAmt) || 0) +
        //     (parseFloat(finTrans.expensiveAmt) || 0)
        //     ) (parseFloat(finTrans.tdsAmt) || 0))
        // ).toFixed(2);
        console.log('calculatedAmount ', calculatedAmount, 'receiptAndTdsAmount ', receiptAndTdsAmount);

        console.log('finTrans.tdsAmt ', parseFloat(finTrans.tdsAmt), 'totalTdsAmount ', totalTdsAmount);

        // if ((parseFloat(finTrans.tdsAmt) !== totalTdsAmount)) {
        //     toast.error('Header Tds Amount and detail tds Amount not matching !', {
        //         position: toast.POSITION.TOP_CENTER,
        //         autoClose: 1500,
        //         style: { width: '32vw' }
        //     });
        //     return;
        // }


        // const calculatedAmount = ((totalReceiptAmount - finTrans.advAmt + finTrans.expensiveAmt) + finTrans.tdsAmt).toFixed(2);
        // const receiptAndTdsAmount = (finTrans.receiptAmt + finTrans.tdsAmt - ).toFixed(2);

        if ((finTrans.receiptAmt === 0 && totalReceiptAmount === 0) || (calculatedAmount !== receiptAndTdsAmount)) {
            toast.error('Please check selected invoice amount, receipt amount and advance amount!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1500,
                style: { width: '39vw' }
            });
            return;
        }

        // console.log("parseFloat(calculatedAmount):", parseFloat(calculatedAmount), typeof parseFloat(calculatedAmount));
        // console.log("finTrans.totalAMt:", parseFloat(finTrans.totalAMt), typeof parseFloat(finTrans.totalAMt));
        if (operation === 'U') {
            if (finTrans.totalAMt !== parseFloat(calculatedAmount)) {
                toast.error(`Please check totalReceiptAmount + Tds Amount!`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 1500,
                    style: { width: '35vw' }
                });
                return;
            }
        }


        if (operation === 'U') {
            console.log('parseFloat(finTrans.totalAMt) ', parseFloat(finTrans.totalAMt), ' parseFloat(toBeCheckFinTranTrans.totalAMt) ', parseFloat(toBeCheckFinTranTrans.totalAMt));

            if (parseFloat(finTrans.totalAMt) !== parseFloat(toBeCheckFinTranTrans.totalAMt)) {
                toast.error('Previous Total Amount & Updated Total Amount not matching...', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 1500,
                    style: { width: '35vw' }
                });
                return;
            }
        }





        if (!validateFinTransDtl(finTransDtl)) {
            return; // If validation fails, stop the function
        }


        setLoading(true);
        try {
            const response = await InviceService.savePayment(companyid, branchId, selectedParty.value, selectedParty.label, billingParty.value, billingParty.label, userId, finTrans, finTransDtl, selectedItems);

            console.log(response.data);

            const { finTrans: updatedFinTrans, finTransDtl: updatedFinTransDtl, finTransInv: updatedFinTransInv } = response.data;
            setFinTrans(updatedFinTrans);
            setFinTransDtl(updatedFinTransDtl);
            setFintransInv(updatedFinTransInv);

            setToBeCheckFinTrans(updatedFinTrans);
            setToBeCheckFinTransInv(updatedFinTransInv);
            setSelectedItems(updatedFinTransInv);

            getTransByPartyId(billingParty.value);
            setOperation('U');
        }
        catch (error) {

            console.error("Error occurred while saving payment:", error);
            toast.error('Oops something went wrong!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 900,
            });
        }
        finally {
            setLoading(false);
        }
    };

    // DOWNLOAD RECEIPT
    const downLoadReceipt = async (partyId, receiptId, type) => {
        setLoading(true);
        try {
            const response = await InviceService.downloadReceipt(companyid, branchId, partyId, receiptId);
            if (response.status === 200) {
                const pdfData = response.data;
                const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
                const blobUrl = URL.createObjectURL(pdfBlob);

                if (type === "PDF") {
                    // Create an anchor element for downloading
                    const downloadLink = document.createElement('a');
                    downloadLink.href = blobUrl;
                    downloadLink.download = 'Receipt.pdf'; // Set the filename for the downloaded PDF
                    downloadLink.style.display = 'none';
                    document.body.appendChild(downloadLink);
                    // Trigger the download
                    downloadLink.click();
                    // Clean up
                    document.body.removeChild(downloadLink);
                    window.URL.revokeObjectURL(blobUrl);
                }
                if (type === 'PRINT') {
                    window.open(blobUrl, '_blank');
                }
                toast.success("Downloading Pdf!", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 800,
                });
            } else {
                console.error("Error downloading PDF:", response.statusText);
            }
        } catch (error) {
            console.error("Error downloading PDF:", error);
        } finally {
            setLoading(false);
        }
    };


    const clearReceipt = () => {
        setFinTrans(initialFintrans);
        setFinTransDtl(initialFintransDtl);
        setFintransInv([]);
        setSelectedItems([]);
        setSelectAll(false);
        setSelectedPartyPayment(null);
        setBillingPartyId(null);
        setValidationErrors([]);
        setOperation(null);
        setToBeCheckFinTrans([]);
        setToBeCheckFinTransInv([]);
    };



    const handleTdsAmountChange = async (e) => {
        if (selectedItems && selectedItems.length === 0) {
            toast.error('Please select invoices!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 900,
            });
            return;
        }

        const value = e.target.value;
        const regex = /^\d*(\.\d{0,2})?$/;

        console.log('value : ', value);


        if (regex.test(value) || value === '') {

            // Update finTrans state if necessary
            setFinTrans(prevState => ({
                ...prevState,
                tdsAmt: value === '' ? 0 : value
            }));

        }
    };

    // // Function to update finTransInv based on selectedItems
    // const updateFinTransInv = () => {
    //     const updatedFinTransInv = fintransInv.map(inv => {
    //         const relatedItem = selectedItems.find(item => item.invoiceNo === inv.invoiceNo);
    //         return {
    //             ...inv,
    //             receiptAmt:  parseFloat(relatedItem ? relatedItem.balanceAmt.toFixed(2) : 0) - parseFloat(relatedItem ? relatedItem.tdsAmt.toFixed(2) : 0),
    //             tdsAmt: relatedItem ? relatedItem.tdsAmt : 0,
    //             tdsPercentage: relatedItem ? relatedItem.tdsPercentage : 0
    //         };
    //     });

    //     setFintransInv(updatedFinTransInv);
    // };

    // Function to update finTransInv based on selectedItems
    const updateFinTransInv = (selectedItems) => {
        const updatedFinTransInv = fintransInv.map(inv => {
            const relatedItem = selectedItems.find(item => item.invoiceNo === inv.invoiceNo);
            return {
                ...inv,
                tdsAmt: relatedItem ? relatedItem.tdsAmt : 0,
                tdsPercentage: relatedItem ? relatedItem.tdsPercentage : 0,
                // If you need to update receiptAmt, include logic here
                receiptAmt: relatedItem ? (parseFloat(relatedItem.balanceAmt) - parseFloat(relatedItem.tdsAmt)).toFixed(2) : inv.receiptAmt
            };
        });
        setFintransInv(updatedFinTransInv);
    };

    // Function to calculate TDS amounts
    const calculateTdsAmounts = (tdsValue, selectedItems) => {
        if (selectedItems.length > 0) {
            const totalSelectedItems = selectedItems.length;
            let equalTdsAmount = tdsValue / totalSelectedItems;
            let carryOver = 0;
            const updatedItems = [];

            for (const item of selectedItems) {
                let newTotal = equalTdsAmount + carryOver;

                // Cap the total to the balance amount
                if (newTotal > item.balanceAmt) {
                    carryOver = newTotal - item.balanceAmt;
                    newTotal = item.balanceAmt;
                } else {
                    carryOver = 0;
                }

                updatedItems.push({
                    ...item,
                    tdsAmt: parseFloat(newTotal).toFixed(2),
                    tdsPercentage: parseFloat(((newTotal / parseFloat(item.balanceAmt)) * 100).toFixed(2)),
                    receiptAmt: (parseFloat(item.balanceAmt) - parseFloat(newTotal)).toFixed(2)
                });
            }
            setSelectedItems(updatedItems);
            return updatedItems; // Indicate that calculation was performed
            // }
        }
        return false; // No calculations performed
    };

    // Function to handle TDS updates
    const handleTdsUpdate = async (value, selectedItems) => {

        const tdsValue = parseFloat(value) || 0;
        if (!operation) {
            const returnValue = await calculateTdsAmounts(tdsValue, selectedItems);
            if (Array.isArray(returnValue) && returnValue.length > 0) {
                updateFinTransInv(returnValue);
            }
        }
        if (operation === 'U') {
            const returnValue = await calculateTdsAmountsUpdate(tdsValue, selectedItems);
            if (Array.isArray(returnValue) && returnValue.length > 0) {
                updateFinTransInvUpdate(returnValue);
            }
        }
    };


    const updateFinTransInvUpdate = (selectedItems) => {
        const updatedFinTransInv = fintransInv.map(inv => {
            const relatedItem = selectedItems.find(item => item.invoiceNo === inv.invoiceNo);
            return {
                ...inv,
                tdsAmt: relatedItem ? relatedItem.tdsAmt : 0,
                tdsPercentage: relatedItem ? relatedItem.tdsPercentage : 0,
                // If you need to update receiptAmt, include logic here
                receiptAmt: relatedItem ? parseFloat(relatedItem.receiptAmt).toFixed(2) : inv.receiptAmt
            };
        });
        setFintransInv(updatedFinTransInv);
    };

    // Function to calculate TDS amounts
    const calculateTdsAmountsUpdate = (tdsValue, selectedItems) => {
        if (selectedItems.length > 0) {
            const totalSelectedItems = selectedItems.length;
            let equalTdsAmount = tdsValue / totalSelectedItems;
            let carryOver = 0;
            const updatedItems = [];

            for (const item of selectedItems) {
                let newTotal = equalTdsAmount + carryOver;

                const previousEntry = toBeCheckFinTransInv.find(entry => entry.invoiceNo === item.invoiceNo);
                if (!previousEntry) {
                    return;
                }
                const previousTdsAmt = parseFloat(previousEntry.tdsAmt) || 0;
                const previousReceiptAmt = parseFloat(previousEntry.receiptAmt) || 0;
                let balanceAmt = previousTdsAmt + previousReceiptAmt;

                // Cap the total to the balance amount
                if (newTotal > balanceAmt) {
                    carryOver = newTotal - balanceAmt;
                    newTotal = balanceAmt;
                } else {
                    carryOver = 0;
                }

                updatedItems.push({
                    ...item,
                    tdsAmt: parseFloat(newTotal).toFixed(2),
                    tdsPercentage: parseFloat(((newTotal / parseFloat(balanceAmt)) * 100).toFixed(2)),
                    receiptAmt: (parseFloat(balanceAmt) - parseFloat(newTotal)).toFixed(2)
                });
            }
            setSelectedItems(updatedItems);
            return updatedItems; // Indicate that calculation was performed
            // }
        }
        return false; // No calculations performed
    };





    useEffect(() => {
        if (!operation) {
            handleTdsUpdate(finTrans.tdsAmt, selectedItems)
        }
    }, [selectedItems.length, operation]);

    // Call handleTdsUpdate whenever necessary
    // For example, in an event handler or after a specific user action


    // console.log('selectedItems : ', selectedItems);
    // console.log('finTrans : ', finTrans, ' \n finTransInv : ', fintransInv, ' \n selectedItems ', selectedItems);


    // console.log(((finTrans.receiptAmt + finTrans.tdsAmt) -
    //     (totalReceiptAmount + finTrans.tdsAmt - finTrans.advAmt +
    //         (parseFloat(finTrans.expensiveAmt) || 0))).toFixed(2));

    // console.log('finTrans.receiptAmt ', finTrans.receiptAmt, ' finTrans.tdsAmt ', finTrans.tdsAmt, ' totalReceiptAmount ', totalReceiptAmount,
    //     ' finTrans.tdsAmt - finTrans.advAmt ', finTrans.tdsAmt - finTrans.advAmt, ' (parseFloat(finTrans.expensiveAmt) || 0) ', (parseFloat(finTrans.expensiveAmt) || 0));

    return (
        <>
            {loading && (
                <div style={styles.overlay}>
                    <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
                </div>
            )}


            <div className="Container" >
                <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '2%', paddingRight: '-10px' }} > <FontAwesomeIcon
                    icon={faReceipt}
                    style={{
                        marginRight: '5px',
                        color: 'black',
                    }}
                />Add Receipt</h5>
                <Card>
                    <CardBody>
                        <Row style={{ marginBottom: '0', paddingBottom: '0' }}>
                            <Col md={3}>
                                <FormGroup>
                                    <label className="forlabel" for="branchId">Receipt Id</label>
                                    <Input
                                        type="text"
                                        name="approvedBy"
                                        id="service"
                                        readOnly
                                        value={finTrans.transId}
                                        className="small-input"
                                        tabIndex="-1"
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <label className="forlabel" for="branchId">Receipt Date</label>
                                    <div>
                                        <DatePicker
                                            selected={finTrans.transDate}
                                            wrapperClassName="custom-react-datepicker-wrapper"
                                            value={finTrans.transDate}
                                            dateFormat="dd/MM/yyyy"
                                            className="form-control small-input"
                                            disabled
                                            id='service'
                                            tabIndex="-1"
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <label className="forlabel" for="branchId">Receipt Amount</label>
                                    <Input
                                        type="number"
                                        name="receiptAmt"
                                        value={totalReceiptAmount}
                                        readOnly
                                        tabIndex="-1"
                                        id='service'
                                        className="small-input"
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <label className="forlabel" for="branchId">Selected Invoice Amount</label>
                                    <Input
                                        type="number"
                                        name="tdsAmt"
                                        value={finTrans.receiptAmt}
                                        readOnly
                                        tabIndex="-1"
                                        id='service'
                                        className="small-input"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: '0', paddingBottom: '0' }}>
                            <Col md={3}>
                                <FormGroup>
                                    <label className="forlabel" for="branchId">Select Invoice Party</label>
                                    <Select
                                        options={partiesAllSearchData}
                                        value={selectedPartyPayment}
                                        onInputChange={(inputValue, { action }) => {
                                            if (action === 'input-change') {
                                                findPartiesAllSearch(inputValue);
                                            }
                                        }}
                                        onChange={handlePartyPayment}
                                        className={`${errors.selectedPartyPayment ? 'error-border' : ''}`}
                                        isClearable
                                        isDisabled={operation}
                                        id={operation ? 'service' : ''}
                                        components={{ Option: CustomOptionParty }}
                                        filterOption={() => true}
                                        // styles={{
                                        //     control: (provided, state) => ({
                                        //         ...provided,
                                        //         border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                                        //         boxShadow: 'none',
                                        //         '&:hover': {
                                        //             border: '1px solid #ccc'
                                        //         }
                                        //     }),
                                        //     indicatorSeparator: () => ({
                                        //         display: 'none'
                                        //     }),
                                        //     dropdownIndicator: () => ({
                                        //         display: 'none'
                                        //     })
                                        // }}
                                        styles={{
                                            control: (provided, state) => ({
                                              ...provided,
                                              height: 35, // Set height
                                              minHeight: 35, // Set minimum height
                                              border: state.isFocused ? "1px solid #ccc" : "1px solid #ccc",
                                              boxShadow: "none",
                                              display: 'flex',
                                              alignItems: 'center', // Align items vertically
                                              padding: 0, // Remove padding to control height
                                              "&:hover": {
                                                border: "1px solid #ccc",
                                              },
                                              borderRadius: '6px', // Add border radius
                                              "&:hover": {
                                                border: "1px solid #ccc",
                                              },
                                            }),
                                            valueContainer: (provided) => ({
                                              ...provided,
                                              height: '100%', // Full height of the control
                                              display: 'flex',
                                              alignItems: 'center', // Align items vertically
                                              padding: '0 0.75rem', // Match padding
                                            }),
                                            singleValue: (provided) => ({
                                              ...provided,
                                              lineHeight: '32px', // Center text vertically
                                            }),
                                            indicatorSeparator: () => ({
                                              display: "none",
                                            }),
                                            dropdownIndicator: () => ({
                                              display: "none",
                                            }),
                                            placeholder: (provided) => ({
                                              ...provided,
                                              lineHeight: '32px', // Center placeholder text vertically
                                              color: "gray",
                                            }),
                                            clearIndicator: (provided) => ({
                                              ...provided,
                                              padding: 2, // Remove padding
                                              display: 'flex',
                                              alignItems: 'center', // Align clear indicator vertically
                                            }),
                                          }}
                                    />
                                </FormGroup>
                            </Col>


                            <Col md={3}>
                                <FormGroup>
                                    <label className="forlabel" for="branchId">Select Payment Party</label>
                                    <Select
                                        options={partiesAllSearchData}
                                        value={billingPartyId}
                                        onChange={handlePartyPaymentNew}
                                        onInputChange={(inputValue, { action }) => {
                                            if (action === 'input-change') {
                                                findPartiesAllSearch(inputValue);
                                            }
                                        }}
                                        className={`${errors.billingPartyId ? 'error-border' : ''}`}
                                        isClearable
                                        components={{ Option: CustomOptionParty }}
                                        isDisabled={operation}
                                        id={operation ? 'service' : ''}
                                        filterOption={() => true}
                                        // styles={{
                                        //     control: (provided, state) => ({
                                        //         ...provided,
                                        //         border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                                        //         boxShadow: 'none',
                                        //         '&:hover': {
                                        //             border: '1px solid #ccc'
                                        //         }
                                        //     }),
                                        //     indicatorSeparator: () => ({
                                        //         display: 'none'
                                        //     }),
                                        //     dropdownIndicator: () => ({
                                        //         display: 'none'
                                        //     })
                                        // }}

                                        styles={{
                                            control: (provided, state) => ({
                                              ...provided,
                                              height: 35, // Set height
                                              minHeight: 35, // Set minimum height
                                              border: state.isFocused ? "1px solid #ccc" : "1px solid #ccc",
                                              boxShadow: "none",
                                              display: 'flex',
                                              alignItems: 'center', // Align items vertically
                                              padding: 0, // Remove padding to control height
                                              "&:hover": {
                                                border: "1px solid #ccc",
                                              },
                                              borderRadius: '6px', // Add border radius
                                              "&:hover": {
                                                border: "1px solid #ccc",
                                              },
                                            }),
                                            valueContainer: (provided) => ({
                                              ...provided,
                                              height: '100%', // Full height of the control
                                              display: 'flex',
                                              alignItems: 'center', // Align items vertically
                                              padding: '0 0.75rem', // Match padding
                                            }),
                                            singleValue: (provided) => ({
                                              ...provided,
                                              lineHeight: '32px', // Center text vertically
                                            }),
                                            indicatorSeparator: () => ({
                                              display: "none",
                                            }),
                                            dropdownIndicator: () => ({
                                              display: "none",
                                            }),
                                            placeholder: (provided) => ({
                                              ...provided,
                                              lineHeight: '32px', // Center placeholder text vertically
                                              color: "gray",
                                            }),
                                            clearIndicator: (provided) => ({
                                              ...provided,
                                              padding: 2, // Remove padding
                                              display: 'flex',
                                              alignItems: 'center', // Align clear indicator vertically
                                            }),
                                          }}
                                    />
                                </FormGroup>
                            </Col>



                            <Col md={3}>

                                <FormGroup>
                                    <label className="forlabel" for="branchId">Tds Amount</label>
                                    <Input
                                        type="text"
                                        name="approvedBy"
                                        value={finTrans.tdsAmt}
                                        readOnly={operation === 'E'}
                                        id={operation === 'E' ? 'service' : ''}
                                        onChange={(e) => handleTdsAmountChange(e)}
                                        onBlur={(e) => handleTdsUpdate(e.target.value, selectedItems)}
                                        className="small-input"
                                    />
                                </FormGroup>


                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <label className="forlabel" for="branchId">Balance advance amount</label>
                                    <Input
                                        type="text"
                                        name="balAdvAmt"
                                        value={balAdvAmt}
                                        onChange={(e) => setBalAdvAmt(e.target.value)}
                                        maxLength={8}
                                        tabIndex="-1"
                                        readOnly
                                        id='service'
                                        className="small-input"
                                    />
                                </FormGroup>
                            </Col>

                        </Row>


                        {/* Add Paymenr By Payment Type  */}


                        <Row style={{ marginBottom: '0', paddingBottom: '0' }}>

                            <Col md={3}>
                                <FormGroup>
                                    <label className="forlabel" for="branchId">Currency</label>
                                    <Input
                                        type="text"
                                        name="INR"
                                        placeholder='INR'
                                        value={finTrans.currency}
                                        readOnly
                                        tabIndex="-1"
                                        id='service'
                                        className="small-input"
                                    />
                                </FormGroup>
                            </Col>

                            <Col md={3}>
                                <FormGroup>
                                    <label className="forlabel" for="branchId">Remarks</label>
                                    <textarea
                                        name="approvedBy"
                                        rows="2"
                                        value={finTrans.narration}
                                        className="inputField form-control small-input"
                                        maxLength={250}
                                        onChange={(e) => setFinTrans(prevState => ({ ...prevState, narration: e.target.value }))}
                                        readOnly={operation === 'E'}
                                        id={operation === 'E' ? 'service' : ''}
                                    ></textarea>

                                </FormGroup>
                            </Col>

                            <Col md={2}>
                                <FormGroup>
                                    <label className="forlabel" for="branchId">Difference Amount</label>
                                    <Input
                                        type="text"
                                        name="INR"
                                        placeholder='INR'
                                        // value={((parseFloat(totalReceiptAmount) || 0) + (parseFloat(finTrans.tdsAmt) || 0) - (parseFloat(finTrans.advAmt) || 0)) - ((parseFloat(finTrans.receiptAmt) || 0) + (parseFloat(finTrans.tdsAmt) || 0) - (parseFloat(finTrans.advAmt) || 0))}
                                        // value={(finTrans.receiptAmt + finTrans.tdsAmt) - (totalReceiptAmount + finTrans.tdsAmt - finTrans.advAmt + (parseFloat(finTrans.expensiveAmt) || 0))}
                                        value={((parseFloat(finTrans.receiptAmt) + parseFloat(finTrans.tdsAmt)) -
                                            (totalReceiptAmount + parseFloat(finTrans.tdsAmt) - parseFloat(finTrans.advAmt) +
                                                (parseFloat(finTrans.expensiveAmt) || 0))).toFixed(2)}

                                        readOnly
                                        tabIndex="-1"
                                        id='service'
                                        className="small-input"
                                    />
                                </FormGroup>
                            </Col>


                            <Col md={2}>
                                <FormGroup>
                                    <label className="forlabel" for="branchId">Advance Amount</label>
                                    <Input
                                        type="text"
                                        name="advanceAmount"
                                        placeholder='Enter Advance Amount'
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const regex = /^\d*(\.\d{0,2})?$/;

                                            if (regex.test(value) || value === '') {
                                                setFinTrans(prevState => ({
                                                    ...prevState,
                                                    advAmt: value === '' ? '0' : value
                                                }));
                                            }
                                        }}
                                        readOnly={operation}
                                        id={operation ? 'service' : ''}
                                        // tabIndex="-1"
                                        value={finTrans.advAmt}
                                        maxLength={10}
                                        className="small-input"
                                    />


                                </FormGroup>
                            </Col>

                            <Col md={2}>
                                <FormGroup>
                                    <label className="forlabel" for="branchId">Expensive Amount</label>
                                    <Input
                                        type="text"
                                        name="expensiveAmt"
                                        placeholder='Enter Expensive Amount'
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const regex = /^\d*(\.\d{0,2})?$/;

                                            if (regex.test(value) || value === '') {
                                                setFinTrans(prevState => ({
                                                    ...prevState,
                                                    expensiveAmt: value === '' ? 0 : value
                                                }));
                                            }
                                        }}
                                        readOnly={operation}
                                        id={operation ? 'service' : ''}
                                        className="small-input"
                                        value={finTrans.expensiveAmt}
                                        maxLength={5}
                                    />
                                </FormGroup>
                            </Col>


                        </Row>


                        <div className="text-center">
                            <Button
                                variant="outline-success"
                                style={{ marginTop: '0.1vw' }}
                                disabled={
                                    (
                                        !selectedPartyPayment ||
                                        !billingPartyId
                                    ) ||
                                    (operation === 'E')
                                } onClick={() => savePayment(selectedPartyPayment, billingPartyId)}
                            >
                                <FontAwesomeIcon icon={faSave} style={{ marginRight: '5px' }} />
                                SAVE
                            </Button>

                            <Button
                                variant="outline-primary"
                                style={{ marginTop: '0.1vw', marginLeft: '10px' }}
                                disabled={!selectedPartyPayment || !finTrans.transId}
                                onClick={() => downLoadReceipt(finTrans.partyId, finTrans.transId, 'PRINT')}
                            >
                                <FontAwesomeIcon icon={faSave} style={{ marginRight: '5px' }} />
                                Download
                            </Button>



                            {operation !== 'E' && (
                                <Button
                                    variant="outline-success"
                                    style={{ marginTop: '0.1vw', marginLeft: '10px' }}
                                    onClick={clearReceipt}
                                >
                                    <FontAwesomeIcon icon={faSave} style={{ marginRight: '5px' }} />
                                    Clear
                                </Button>
                            )}

                            <Button
                                variant="outline-danger"
                                style={{ marginTop: '0.1vw', marginLeft: '1.5vw' }}
                                onClick={Handleback}
                            >
                                <FontAwesomeIcon icon={faBackward} style={{ marginRight: '5px' }} />
                                BACK
                            </Button>

                        </div>


                        <div className="table-responsive mt-1">
                            <h6 style={{ marginLeft: '10px' }}><strong> Payment :</strong></h6>

                            <Table className="table table-bordered" style={{ border: '2px solid black' }}>
                                <thead>
                                    <tr>
                                        <th className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font', height: '40px' }}>Select Payment Mode</th>
                                        <th className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Transaction No / Cheque No</th>
                                        <th className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Transaction Date / Cheque Date</th>
                                        <th className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Bank Name</th>
                                        <th className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Amount</th>
                                        {operation !== 'E' && (
                                            <th className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Actions</th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {finTransDtl.map((payment, index) => (
                                        <tr key={index} className="text-center" style={{ marginBottom: 0,height: '20px' }}>
                                            <td style={{ marginBottom: 0 }}>
                                                <FormGroup>
                                                    <Input
                                                        type="select"
                                                        value={payment.paymentMode}
                                                        disabled={operation === 'E' || payment.transId}
                                                        id={operation === 'E' || payment.transId ? 'service' : ''}
                                                        onChange={(e) => handlePaymentModeChange(e, index)}
                                                    >
                                                        <option value="CHEQUE">Cheque</option>
                                                        <option value="NEFT">NEFT</option>
                                                        <option value="UPI">UPI</option>
                                                        <option value="CASH">Cash</option>
                                                        <option value="ADVANCE">Advance</option>
                                                    </Input>
                                                </FormGroup>
                                            </td>

                                            <td style={{ marginBottom: 0 }}>
                                                {(payment.paymentMode !== 'ADVANCE' && payment.paymentMode !== 'CASH') && (
                                                    <FormGroup>
                                                        <Input
                                                            type="text"
                                                            readOnly={operation === 'E'}
                                                            id={operation === 'E' ? 'service' : ''}
                                                            value={payment.paymentNo}
                                                            onChange={(e) => handleFieldChange(e, index, 'paymentNo', payment.paymentMode, balAdvAmt)}
                                                            className={ `small-input ${validationErrors[index]?.paymentNo ? 'is-invalid' : ''}`}
                                                        />
                                                        {/* {validationErrors[index]?.paymentNo && <div className="invalid-feedback">{validationErrors[index].paymentNo}</div>} */}
                                                    </FormGroup>
                                                )}
                                            </td>

                                            <td style={{ marginBottom: 0 }}>
                                                {(payment.paymentMode !== 'ADVANCE' && payment.paymentMode !== 'CASH') && (
                                                    <FormGroup>
                                                        <DatePicker
                                                            selected={payment.paymentDate}
                                                            onChange={(date) => handlePaymentDateChange(date, index)}
                                                            dateFormat="dd/MM/yyyy"
                                                            className={`small-input form-control ${validationErrors[index]?.paymentDate ? 'is-invalid' : ''}`}
                                                            customInput={<Input style={{ width: '100%' }} />}
                                                            disabled={operation === 'E'}
                                                            id={operation === 'E' ? 'service' : ''}
                                                        />
                                                        {/* {validationErrors[index]?.paymentDate && <div className="invalid-feedback">{validationErrors[index].paymentDate}</div>} */}
                                                    </FormGroup>
                                                )}
                                            </td>
                                            <td style={{ marginBottom: 0 }}>
                                                {payment.paymentMode === 'CHEQUE' && (
                                                    <FormGroup>
                                                        <Input
                                                            type="text"
                                                            readOnly={operation === 'E'}
                                                            id={operation === 'E' ? 'service' : ''}
                                                            value={payment.bankName}
                                                            onChange={(e) => handleFieldChange(e, index, 'bankName', payment.paymentMode, balAdvAmt)}
                                                            className={`small-input ${validationErrors[index]?.bankName ? 'is-invalid' : ''}`}
                                                        />
                                                        {/* {validationErrors[index]?.bankName && <div className="invalid-feedback">{validationErrors[index].bankName}</div>} */}
                                                    </FormGroup>
                                                )}
                                            </td>

                                            <td style={{ marginBottom: 0 }}>
                                                <FormGroup>
                                                    <Input
                                                        type="text"
                                                        readOnly={operation === 'E'}
                                                        id={operation === 'E' ? 'service' : ''}
                                                        value={payment.amount}
                                                        onChange={(e) => handleFieldChange(e, index, 'amount', payment.paymentMode, balAdvAmt)}
                                                        className={`small-input ${validationErrors[index]?.amount ? 'is-invalid' : ''}`}
                                                    />
                                                    {/* {validationErrors[index]?.amount && <div className="invalid-feedback">{validationErrors[index].amount}</div>} */}
                                                </FormGroup>
                                            </td>
                                            {operation !== 'E' && (
                                                <td style={{ marginBottom: 0 }}>
                                                    {index === finTransDtl.length - 1 ? (
                                                        <FontAwesomeIcon
                                                            icon={faPlusCircle}
                                                            onClick={handleAddRow}
                                                            style={{ color: 'skyblue', cursor: 'pointer', fontSize: '24px', marginRight: '5px' }}
                                                        />
                                                    ) : (
                                                        null
                                                    )}
                                                    <FontAwesomeIcon
                                                        icon={faTimesCircle}
                                                        onClick={() => handleRemoveRow(index)}
                                                        style={{ color: 'red', cursor: 'pointer', fontSize: '24px' }}
                                                    />
                                                </td>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>




                        {/* {fintransInv.length > 0 ? (
                            <div className="table-responsive mt-3">
                                <h5 style={{ marginLeft: '10px' }}>Pending Invoices :</h5>
                                <Table className="table table-bordered table-hover text-center" style={{ border: '2px solid black' }}>
                                    <thead className="thead-dark bg-dark">
                                        <tr className="text-center" style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '18px' }}>
                                            {!operation && (
                                                <th style={{ backgroundColor: '#BADDDA', height: '60px' }} scope="col">
                                                    <Input
                                                        className="form-check-input radios"
                                                        type="checkbox"
                                                        style={{ width: '1.2vw', height: '1.2vw' }}
                                                        checked={selectAll}
                                                        onChange={() => handleSelectAllChange()}
                                                    /></th>
                                            )}
                                            <th style={{ background: '#BADDDA' }}>Party Name</th>
                                            <th style={{ background: '#BADDDA' }}>Invoice Number</th>
                                            <th style={{ background: '#BADDDA' }}>Invoice Date</th>
                                            <th style={{ background: '#BADDDA' }}>Invoice Amount</th>
                                            <th style={{ background: '#BADDDA' }}>Balance Amount</th>


                                            <th style={{ background: '#BADDDA' }}>Tds Percentage</th>
                                            <th style={{ background: '#BADDDA' }}>Tds Amount</th>
                                            <th style={{ background: '#BADDDA' }}>Amount To Be Cleared</th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        {fintransInv.map((invoice, index) =>
                                            <tr className="text-center dynamic-row-width">
                                                {!operation && (
                                                    <td>
                                                        <Input
                                                            className="form-check-input radios"
                                                            type="checkbox"
                                                            style={{ width: '1.2vw', height: '1.2vw' }}
                                                            name="taxApplicable"
                                                            checked={selectedItems.some((item) => item.invoiceNo === invoice.invoiceNo)}
                                                            onChange={() => handleRowCheckboxChange(invoice)}
                                                        />
                                                    </td>
                                                )}
                                                <td>{invoice.partyName}</td>
                                                <td>{invoice.invoiceNo}</td>
                                                <td>{formatDateTime(invoice.invoiceDate)}</td>
                                                <td>{invoice.invoiceAmt}</td>
                                                <td>{invoice.balanceAmt}</td>
                                                <td className="text-center">
                                                    
                                                    {invoice.tdsPercentage}
                                                </td>
                                                <td className="text-center">
                                                    {invoice.tdsAmt}
                                                   
                                                </td>

                                                <td className="text-center">
                                                    <Input
                                                        type="text"
                                                        name="receiptAmt"
                                                        className="form-control inputField text-center"
                                                        placeholder="Amount Paid"
                                                        readOnly={operation === 'E'}
                                                        id={operation === 'E' ? 'service' : ''}
                                                        value={invoice.receiptAmt}
                                                        onChange={(e) => handleInputChange(invoice.invoiceNo, 'receiptAmt', e.target.value)}
                                                    />
                                                </td>

                                            </tr>
                                        )}
                                    </tbody>
                                </Table>

                            </div>
                        ) : null} */}



                        {fintransInv.length > 0 ? (
                            <div className="table-responsive mt-1" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                <h6 style={{ marginLeft: '10px' }}><strong>Pending Invoices :</strong></h6>
                                <Table className="table table-bordered table-hover text-center" style={{ border: '2px solid black', minWidth: '100%' }}>
                                    <thead className="thead-dark bg-dark">
                                        <tr className="text-center" style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '18px' }}>
                                            {!operation && (
                                                <th style={{ backgroundColor: '#BADDDA', height: '50px' }} scope="col">
                                                    <Input
                                                        className="form-check-input radios"
                                                        type="checkbox"
                                                        style={{ width: '1.2vw', height: '1.2vw' }}
                                                        checked={selectAll}
                                                        onChange={() => handleSelectAllChange()}
                                                    />
                                                </th>
                                            )}
                                            <th style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Party Name</th>
                                            <th style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Invoice Number</th>
                                            <th style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Invoice Date</th>
                                            <th style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Invoice Amount</th>
                                            <th style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Balance Amount</th>
                                            <th style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Tds Percentage</th>
                                            <th style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Tds Amount</th>
                                            <th style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Amount To Be Cleared</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fintransInv.map((invoice, index) => (
                                            <tr className="text-center dynamic-row-width" key={index}>
                                                {!operation && (
                                                    <td>
                                                        <Input
                                                            className="form-check-input radios"
                                                            type="checkbox"
                                                            style={{ width: '1.2vw', height: '1.2vw' }}
                                                            name="taxApplicable"
                                                            checked={selectedItems.some((item) => item.invoiceNo === invoice.invoiceNo)}
                                                            onChange={() => handleRowCheckboxChange(invoice)}
                                                        />
                                                    </td>
                                                )}
                                                <td>{invoice.partyName}</td>
                                                <td>{invoice.invoiceNo}</td>
                                                <td>{formatDateTime(invoice.invoiceDate)}</td>
                                                <td>{invoice.invoiceAmt}</td>
                                                <td>{invoice.balanceAmt}</td>
                                                <td className="text-center">{invoice.tdsPercentage}</td>
                                                <td className="text-center">{invoice.tdsAmt}</td>
                                                <td className="text-center">
                                                    <Input
                                                        type="text"
                                                        name="receiptAmt"
                                                        className="form-control inputField text-center small-input"
                                                        placeholder="Amount Paid"
                                                        readOnly={operation === 'E'}
                                                        id={operation === 'E' ? 'service' : ''}
                                                        value={invoice.receiptAmt}
                                                        onChange={(e) => handleInputChange(invoice.invoiceNo, 'receiptAmt', e.target.value)}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        ) : null}





                    </CardBody>
                </Card>

            </div>
        </>
    );



}

export default AddReceipts;