import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../Components/AuthProvider";
import { Pagination } from "react-bootstrap";

import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Button,
    Input,
    Table
} from "reactstrap";
import { toast } from "react-toastify";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAtom, faDownLong, faDownload, faEye, faPlus, faPrint, faReceipt, faSearch, faTrashAlt, faTruck, faTruckArrowRight, faTruckFast, faTruckFront } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import ReactLoading from 'react-loading';
import InviceService from "../services/InviceService";
import Swal from "sweetalert2";
import moment from 'moment';
import DatePicker from "react-datepicker";
import Rate_Chart_Service from "../services/Rate_Chart_Service";


export default function Receipts(props) {
    const {
        userId,
        branchId,
        companyid,
        role,
        companyname,
        branchname,
        login,
        logout,
    } = useContext(AuthContext);

    const reactPageName = "Airline";
    const navigate = useNavigate();
    const { isAuthenticated } = useContext(AuthContext);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate(
                "/login?message=You need to be authenticated to access this page."
            );
        }
    }, [isAuthenticated, navigate]);





    const [parties, setParties] = useState([]);
    const [selectedParty, setSelectedParty] = useState(null);

    const [searchCriteria, setSearchCriteria] = useState([]);
    const [loading, setLoading] = useState(false);
    const styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)', // Adjust the opacity and color as needed
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999, // Ensure the overlay is above other elements
        },
    };

    const location = useLocation();
    const updatedSerchcriteria = location.state?.searchCriteria;
    const updatedCurrentPage = location.state?.currentPage


    const initialSearchCriteria = {
        companyId: companyid,
        branchId: branchId,
        startDate: moment(new Date()).format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
        partyId: ''
    };


    useEffect(() => {
        const fetchData = async () => {
            const criteriaToSet = updatedSerchcriteria || initialSearchCriteria;
            const updatedPage = updatedCurrentPage || currentPage;
            setCurrentPage(updatedPage);
            setSearchCriteria(criteriaToSet);
            handleSearch(criteriaToSet);
        };
        fetchData();
    }, []);

  





    const findParties = async () => {
        const partyResponse = await Rate_Chart_Service.getAllActiveParties(companyid, branchId);
        const partyOptions = partyResponse.data.map(party => ({
            value: party[0],
            label: party[1]
        }));
        setParties(partyOptions);
    };

    useEffect(() => {
        findParties();
    }, []);




    const handleAdd = () => {
        navigate(`/parent/Add-Receipts`, { state: { searchCriteria: searchCriteria, currentPage: currentPage } });
    };


    const [filteredData, setFilteredData] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    // Function to handle page change
    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };
    const displayPages = () => {
        const centerPageCount = 5;
        const middlePage = Math.floor(centerPageCount / 2);
        let startPage = currentPage - middlePage;
        let endPage = currentPage + middlePage;

        if (startPage < 1) {
            startPage = 1;
            endPage = Math.min(totalPages, centerPageCount);
        }

        if (endPage > totalPages) {
            endPage = totalPages;
            startPage = Math.max(1, totalPages - centerPageCount + 1);
        }

        return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    };



    const handleSearch = async (searchCriteria) => {
        setLoading(true);
        try {
            const response = await InviceService.searchReceipts({ params: searchCriteria });
            setFilteredData(response.data);
        }
        catch {
            toast.error('Oops something went wrong!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 700,
            });
        }
        finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        const foundType = parties.find(type => type.value === searchCriteria.partyId);
        setSelectedParty(foundType ? foundType : null);
    }, [parties, selectedParty, searchCriteria.partyId]);



    const handleReset = () => {
        setSearchCriteria(initialSearchCriteria);
        setSelectedParty(null);
        handleSearch(initialSearchCriteria);
    };

    const handleViewClick = (transId, partyId, type) => {
        navigate(`/parent/Add-Receipts`, { state: { transId: transId, operation: type, partyId: partyId, searchCriteria: searchCriteria, currentPage: currentPage } });
    };


    const formatDateTimeMonth = (value) => {
        if (!value) {
            return ""; // Return an empty string if value is empty or undefined
        }

        const date = new Date(value);
        const day = date.getDate().toString().padStart(2, '0'); // Get the day of the month and pad with zero if needed
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get the month (0-based index, so add 1) and pad with zero if needed
        const year = date.getFullYear(); // Get the year

        // Construct the final formatted date string
        const formattedDate = `${day}/${month}/${year}`;

        return formattedDate;
    };

    const handlePartyChange = async (selectedOption) => {
        setSelectedParty(selectedOption);
        setSearchCriteria({ ...searchCriteria, partyId: selectedOption ? selectedOption.value : '' });
    };

// DOWNLOAD RECEIPT 

const downLoadReceipt = async (partyId, receiptId, type) => {
    setLoading(true);
    try {
      const response = await InviceService.downloadReceipt(companyid, branchId, partyId, receiptId);
      if (response.status === 200) {
        const pdfData = response.data;
        const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(pdfBlob);

        if (type === "PDF") {
          // Create an anchor element for downloading
          const downloadLink = document.createElement('a');
          downloadLink.href = blobUrl;
          downloadLink.download = 'Receipt.pdf'; // Set the filename for the downloaded PDF
          downloadLink.style.display = 'none';
          document.body.appendChild(downloadLink);
          // Trigger the download
          downloadLink.click();
          // Clean up
          document.body.removeChild(downloadLink);
          window.URL.revokeObjectURL(blobUrl);
        }
        if (type === 'PRINT') {
          window.open(blobUrl, '_blank');
        }
        toast.success("Downloading Pdf!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 800,
        });
      } else {
        console.error("Error downloading PDF:", response.statusText);
      }
    } catch (error) {
      console.error("Error downloading PDF:", error);
    } finally {
      setLoading(false);
    }
  };
    return (
        <>
            {loading && (
                <div style={styles.overlay}>
                    <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
                </div>
            )}



            <div className="Container">
                {/* <Container > */}
                <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '2%', paddingRight: '-20px' }}>
                    <FontAwesomeIcon
                        icon={faReceipt}
                        style={{
                            marginRight: '8px',
                            color: 'black',
                        }}
                    />Receipt</h5>
                <Card style={{
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '40vw',
                    overflowX: "hidden",
                }} >
                    <CardBody style={{ paddingBottom: '-5px' }} >
                        <div className="text-end">
                            <button
                                className="btn btn-outline-success text-end"
                                onClick={handleAdd}
                                style={{ marginRight: 5, fontWeight: 'bold' }}
                            >
                                  <FontAwesomeIcon icon={faPlus} />
                                  <FontAwesomeIcon icon={faReceipt} style={{ marginRight: '5px' }}  />
                                Add Receipt
                            </button>
                        </div>
                        <hr/>


                        <Row>
                            <Col md={2}>
                                <FormGroup>
                                    <Label className="forlabel" for="branchId">Date From</Label>
                                    <div> {/* Wrap in an input group */}

                                        <DatePicker
                                            selected={searchCriteria.startDate ? new Date(searchCriteria.startDate) : null}
                                            wrapperClassName="custom-react-datepicker-wrapper"
                                            onChange={(date) => {
                                                if (date) {
                                                    // Set the time to 12:00 AM in UTC time
                                                    date.setHours(12, 0, 0, 0);
                                                    // Convert the selected date and time to ISO format
                                                    const formattedDate = date.toISOString();
                                                    setSearchCriteria({ ...searchCriteria, startDate: formattedDate });
                                                } else {
                                                    setSearchCriteria({ ...searchCriteria, startDate: null });
                                                }
                                            }}
                                            dateFormat="dd/MM/yyyy" // Specify the combined format
                                            className="form-control border-right-0 inputField"
                                            customInput={<input style={{ width: '100%' }} />}

                                        />
                                    </div>
                                </FormGroup>
                            </Col>


                            <Col md={2}>
                                <FormGroup>
                                    <Label className="forlabel" for="branchId">Date To</Label>
                                    <div> {/* Wrap in an input group */}
                                        <DatePicker
                                            selected={searchCriteria.endDate ? new Date(searchCriteria.endDate) : null} // Use endDate from searchCriteria if it's defined
                                            wrapperClassName="custom-react-datepicker-wrapper"
                                            onChange={(date) => {
                                                if (date) {
                                                    // Set the time to 12:00 PM (noon)
                                                    date.setHours(12, 0, 0, 0);
                                                    const formattedDate = date.toISOString(); // Convert to ISO format
                                                    setSearchCriteria({ ...searchCriteria, endDate: formattedDate });
                                                } else {
                                                    setSearchCriteria({ ...searchCriteria, endDate: null });
                                                }
                                            }}
                                            dateFormat="dd/MM/yyyy"
                                            className="form-control border-right-0 inputField"
                                            customInput={<input style={{ width: '100%' }} />}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col md={5}>
                                <FormGroup>
                                    <Label className="forlabel" for="branchId">Select Importer</Label>
                                    <Select
                                        options={parties}
                                        placeholder="Select a party"
                                        isClearable
                                        value={selectedParty}
                                        onChange={handlePartyChange}
                                        styles={{
                                            control: (provided, state) => ({
                                                ...provided,
                                                border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                                                boxShadow: 'none',
                                                '&:hover': {
                                                    border: '1px solid #ccc'
                                                }
                                            }),
                                            indicatorSeparator: () => ({
                                                display: 'none'
                                            }),
                                            dropdownIndicator: () => ({
                                                display: 'none'
                                            }),
                                            placeholder: (provided) => ({
                                                ...provided,
                                                display: 'flex',
                                                color: 'gray',
                                            }),
                                        }}
                                    />
                                </FormGroup>
                            </Col>

                            <Col md={3}>
                                <div className="text-center" style={{ marginTop: '29px' }}>

                                    <Button
                                        color="primary"
                                        outline
                                        onClick={() => handleSearch(searchCriteria)}
                                        className="mr-2"
                                        style={{ marginRight: '25px', fontWeight: 'bold' }}
                                    >
                                        <FontAwesomeIcon icon={faSearch} style={{ marginRight: '5px' }} />
                                        SEARCH
                                    </Button>
                                    <Button
                                        color="danger"
                                        outline
                                        onClick={handleReset}
                                        className="mr-2"
                                        style={{ marginRight: '5px', fontWeight: 'bold' }}
                                    >
                                        <FontAwesomeIcon icon={faSyncAlt} style={{ marginRight: '5px' }} />
                                        RESET
                                    </Button>
                                </div>

                            </Col>


                        </Row>



                        <hr />


                        {filteredData && filteredData.length > 0 && (
                            <div className="table-responsive mt-3">
                                <Table className="table table-bordered table-hover" style={{ border: '2px solid black' }}>
                                    <thead className="thead-dark bg-dark"  >
                                        <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px' }}>
                                            <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font', height: '60px' }}>Sr No</th>
                                            <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Receipt Id</th>
                                            <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Date</th>
                                            <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Party</th>
                                            <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Receipt Amount</th>
                                            <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Tds Amount</th>
                                            <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Total Amount</th>
                                            <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentItems.map((item, index) => (
                                            <tr key={item.id} className='text-center'>
                                                <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{((currentPage - 1) * itemsPerPage) + index + 1}</td>
                                                <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.transId}</td>
                                                <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{formatDateTimeMonth(item.transDate)}</td>
                                                <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.partyName}</td>
                                                <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.receiptAmt}</td>
                                                <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.tdsAmt}</td>
                                                <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.totalAMt}</td>
                                                <td className="text-center">
                                                    <Button
                                                        color="primary"
                                                        type="button"
                                                        className="btn btn-primary dropdown-toggle"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                    ><FontAwesomeIcon icon={faAtom} style={{ marginRight: '5px' }} />
                                                    </Button>
                                                    <ul className="dropdown-menu">
                                                        <li>
                                                            <button
                                                                className="dropdown-item"
                                                                onClick={() => handleViewClick(item.transId, item.partyId,'E')}
                                                            ><FontAwesomeIcon icon={faEye} style={{ marginRight: '5px' }} />
                                                                View Details
                                                            </button>
                                                        </li>

                                                        <li>
                                                            <button
                                                                className="dropdown-item"
                                                                onClick={() => handleViewClick(item.transId, item.partyId,'U')}
                                                            ><FontAwesomeIcon icon={faEdit} style={{ marginRight: '5px' }} />
                                                                Modify Details
                                                            </button>
                                                        </li>

                                                        <li>
                                                            <button
                                                                className="dropdown-item"
                                                                onClick={() => downLoadReceipt(item.partyId,item.transId, "PRINT")}
                                                            ><FontAwesomeIcon icon={faPrint} style={{ marginRight: '5px' }} />
                                                               Print Receipt
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button
                                                                className="dropdown-item"
                                                                onClick={() => downLoadReceipt(item.partyId, item.transId,"PDF")}
                                                            ><FontAwesomeIcon icon={faDownload} style={{ marginRight: '5px' }} />
                                                               Download Receipt
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>



                                <Pagination style={{ display: 'flex', justifyContent: 'center', color: 'gray' }}>
                                    <Pagination.First onClick={() => handlePageChange(1)} />
                                    <Pagination.Prev
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        disabled={currentPage === 1}
                                    />
                                    <Pagination.Ellipsis />

                                    {displayPages().map((pageNumber) => (
                                        <Pagination.Item
                                            key={pageNumber}
                                            active={pageNumber === currentPage}
                                            onClick={() => handlePageChange(pageNumber)}
                                        >
                                            {pageNumber}
                                        </Pagination.Item>
                                    ))}

                                    <Pagination.Ellipsis />
                                    <Pagination.Next
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        disabled={currentPage === totalPages}
                                    />
                                    <Pagination.Last onClick={() => handlePageChange(totalPages)} />
                                </Pagination>
                            </div>
                        )}
                    </CardBody>
                </Card>
            </div>
        </>
    );
}