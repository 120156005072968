
import AuthContext from "../Components/AuthProvider";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useContext, useRef } from "react";
import "../Components/Style.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import ipaddress from "../Components/IpAddress";
import Card from "react-bootstrap/Card";
import { CardBody, Input } from "reactstrap";
import DGDCimage from "../Images/DGDC.png";
import { saveAs } from "file-saver";
import axios from "axios";
import dgdcImage from "../Images/report.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import the styles
import { faArrowsToEye, faBorderAll, faEye, faFileArrowUp, faRefresh, faSearch } from "@fortawesome/free-solid-svg-icons";
import {
    faCheck,
    faSave,
    faTimes,
    faSyncAlt,
    faFileExcel,
    faFilePdf,
    faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { Table } from "react-bootstrap";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import { Line, pdf, PDFDownloadLink } from "@react-pdf/renderer";
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
    Image,
} from "@react-pdf/renderer";
import { BlobProvider } from "@react-pdf/renderer";
import { data } from "jquery";
const styles = StyleSheet.create({
    page: {
        paddingTop: 18,
        paddingBottom: 54,
        paddingHorizontal: 30,
    },
    header: {
        marginBottom: 5,
    },
    heading: {
        fontSize: 9,
        marginBottom: 0,
        fontWeight: "bold",
        alignItems: "center",
    },
    table: {
        width: "100%",
        borderCollapse: "collapse",
        marginBottom: 4,
    },
    centeredText: {
        fontSize: 13,
        textAlign: "center", // Center text horizontally
    },
    leftColumn: {
        width: "100%",
        paddingTop: 9,
    },
    headingwithbox: {
        fontSize: 9,
        marginBottom: 0,
        fontWeight: "bold",
        alignItems: "center",

        // Add padding for space between text and border
    },
    headerText: {
        fontSize: 9,
        fontWeight: "bold",
    },
    viewheadingwithbox: {
        border: "1px solid black",
        padding: 5,
    },
    paragraph: {
        fontSize: 12,
        marginBottom: 5,
    },
    headingwithborder: {
        fontSize: 9,
        marginBottom: 0,
        fontWeight: "bold",
        alignItems: "center",
        borderBottom: "1px solid black",
        // Add padding for space between text and border
    },
    image: {
        width: 306,
        height: 100,
        marginLeft: 117,
        justifyContent: "center",
    },
    dateSize: {
        fontSize: 8,
    },
    normaltext: {
        fontSize: 9,
        marginTop: 4,
        fontWeight: "bold",
    },
    line: {
        width: "100%", // Adjust the width of the line
        marginTop: 10, // Adjust the space above the line
        marginBottom: 10, // Adjust the space below the line
        borderTop: "1pt solid black", // Style the line
    },
    rightText: {
        fontSize: 9,
        textAlign: "left", // Center text horizontally
        paddingTop: 5,
    },
    tableRow: {
        flexDirection: "row",
        borderBottomWidth: 0.4,
        borderBottomColor: "black",
        fontSize: 9,
        borderRightWidth: 0.4,
        borderLeftWidth: 0.4,
        borderLeftColor: "black",
        borderRightColor: "black",
        flexWrap: "wrap",
    },
    tableCell: {
        border: "0.4px solid black",
        padding: 1,
        fontSize: 7,
        flexWrap: "wrap",
        width: 73,
        textAlign: 'center'
    },
    tableCellHeader: {
        fontWeight: "bold",
        flexWrap: "wrap",
        width: 73,
        textAlign: 'center',
        border: "0.4px solid black",
    },
});

export default function CTMReport() {
    const navigate = useNavigate();
    const {
        jwtToken,
        userId,
        username,
        branchId,
        companyid,
        role,
        companyname,
        branchname,
        isAuthenticated,
        login,
        logout,
    } = useContext(AuthContext);


    useEffect(() => {
        if (!isAuthenticated) {
            navigate(
                "/login?message=You need to be authenticated to access this page."
            );
        }
    }, [isAuthenticated, navigate]);
    const [consoleData, setConsoleData] = useState([]);
    const [consoleId, setConsoleId] = useState('');
    const getConsoleData = () => {
        const type = "Console";
        axios.get(`https://${ipaddress}externalparty/getSpecificData/${companyid}/${branchId}/${type}`)
            .then((response) => {
                setConsoleData(response.data);
                const data = response.data;
                const namesMap = {};
                data.forEach(party => {
                    namesMap[party[0]] = party[1];
                });
                setConsoleId(namesMap);
            })
            .catch((error) => {
                if (error) {

                }
            })
    }

    useEffect(() => {
        getConsoleData();
    }, [])

    const [selectedConsole, setSelectedConsole] = useState('');
    const [mawbData, setMawbData] = useState([]);
    const [selectedMawb, setSelectedMawb] = useState('');

    const handleMAWBData = (e) => {
        setMawbData([]);
        setSelectedMawb('');
        setSelectedConsole('');
        const val = e.target.value;
        setSelectedConsole(val);
        getMAWBData(val);
    }

    const getMAWBData = (id) => {
        axios.get(`https://${ipaddress}import/getMAWBData/${companyid}/${branchId}/${id}`)
            .then((response) => {
                setMawbData(response.data);
            })
            .catch((error) => {

            })
    }


    const [ctmData, setCtmData] = useState([]);
    const getCTMData = (console, mawb) => {
        if (!console) {
            toast.error("Please select the console", {
                autoClose: 800
            })
            return;
        }


        if (!mawb) {
            toast.error("Please select the MAWB", {
                autoClose: 800
            })
            return;
        }
        axios.get(`https://${ipaddress}import/getCTMData/${companyid}/${branchId}/${console}/${mawb}`)
            .then((response) => {
                const data = response.data;
                if (data.length === 0) {
                    toast.error("Data not found", {
                        autoClose: 800
                    })
                    return;
                }
                else {
                    setCtmData(data);
                    toast.uccess("Data found Successfully", {
                        autoClose: 800
                    })
                }
            })
            .catch((error) => {

            })
    }

    const handleClear = () => {
        setCtmData([]);
        setSelectedMawb('');
        setMawbData([]);
        setSelectedConsole('');
        toast.error("Data cleared successfully", {
            autoClose: 800
        })
    }

    const ctmPrint = async (console,mawb) => {
  
        await axios.post(`https://${ipaddress}import/ctmReport?companyId=${companyid}&branchId=${branchId}&console=${console}&mawb=${mawb}`)
          .then((response) => {
            const base64PDF = response.data;
    
            // Create a new window for displaying the PDF
            const newWindow = window.open("", "_blank");
    
            // Write the HTML content to the new window
            newWindow.document.write(`
            <!DOCTYPE html>
            <html>
            <head>
              <title>CTM Report</title>
              <style>
                body {
                  margin: 0;
                  padding: 0;
                  overflow: hidden;
                }
                embed {
                  width: 100vw;
                  height: 100vh;
                }
              </style>
            </head>
            <body>
              <embed src="data:application/pdf;base64,${base64PDF}" type="application/pdf" width="100%" height="100%">
            </body>
            </html>
          `);
          })
          .catch((error) => {
            if (error) {
              toast.error("Something went wrong", {
                autoClose: 1000
              })
            }
          })
      }

      const ctmPdf = async (console,mawb) => {
   
        await axios.post(`https://${ipaddress}import/ctmReport?companyId=${companyid}&branchId=${branchId}&console=${console}&mawb=${mawb}`)
          .then((response) => {
            const pdfBase64 = response.data; // Assuming response.data contains the Base64-encoded PDF
            // Create a Blob from the Base64 data
            const pdfBlob = new Blob([Uint8Array.from(atob(pdfBase64), c => c.charCodeAt(0))], { type: 'application/pdf' });
            // Create a URL for the Blob
            const blobUrl = URL.createObjectURL(pdfBlob);
            // Create an anchor element for downloading
            const downloadLink = document.createElement('a');
            downloadLink.href = blobUrl;
            downloadLink.download = 'CTMReport.pdf'; // Set the filename for the downloaded PDF
            downloadLink.style.display = 'none';
            document.body.appendChild(downloadLink);
            // Trigger the download
            downloadLink.click();
            // Clean up
            document.body.removeChild(downloadLink);
            window.URL.revokeObjectURL(blobUrl);
            toast.success("Downloading Pdf!", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 800,
            });
          })
          .catch((error) => {
            if (error) {
              toast.error("Something went wrong", {
                autoClose: 1000
              })
            }
          })
      }


    return (
        <div className="Container" >
            <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '2%', paddingRight: '-20px' }} > <FontAwesomeIcon
                icon={faFileArrowUp}
                style={{
                    marginRight: '8px',
                    color: 'black', // Set the color to golden
                }}
            />Custom CTM Report</h5>
            <Card >
                <CardBody>
                    <Row>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label className="forlabel">Select Console</Form.Label>
                                <select
                                    name="company"
                                    className="form-select"
                                    value={selectedConsole}
                                    onChange={handleMAWBData}
                                >
                                    <option value="">Select Console</option>
                                    {consoleData.map((item, index) => (
                                        <option key={index} value={item[0]}>{item[1]}</option>
                                    ))
                                    }
                                </select>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label className="forlabel">Select MAWB</Form.Label>
                                <select
                                    name="company"
                                    className="form-select"
                                    value={selectedMawb}
                                    onChange={(e) => setSelectedMawb(e.target.value)}
                                >

                                    <option value="">Select MAWB</option>
                                    {mawbData.map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))
                                    }
                                </select>
                            </Form.Group>
                        </Col>
                        <Col md={4} style={{ marginTop: 32 }}>
                            <Button
                                variant="outline-primary"
                                style={{ marginRight: 20 }}
                                onClick={() => getCTMData(selectedConsole, selectedMawb)}
                            >
                                <FontAwesomeIcon
                                    icon={faSearch}
                                    style={{ marginRight: "5px" }}
                                />
                                Search
                            </Button>
                            <Button
                                variant="outline-danger"
                                style={{ marginRight: 20 }}
                                onClick={handleClear}
                            >
                                <FontAwesomeIcon
                                    icon={faRefresh}
                                    style={{ marginRight: "5px" }}
                                />
                                Reset
                            </Button>
                        </Col>
                    </Row>
                    <hr />
                    {ctmData.length > 0 && (
                        <>
                            <Row>
                                <Col>
                                    <Button
                                        variant="outline-primary"
                                        style={{ marginRight: 20 }}
                                        onClick={()=>ctmPrint(selectedConsole,selectedMawb)}
                                    >
                                        <FontAwesomeIcon
                                            icon={faPrint}
                                            style={{ marginRight: "5px" }}
                                        />
                                        PRINT
                                    </Button>
                                    <Button
                                        variant="outline-danger"
                                        style={{ marginRight: 20 }}
                                        onClick={()=>ctmPdf(selectedConsole,selectedMawb)}
                                    >
                                        <FontAwesomeIcon
                                            icon={faFilePdf}
                                            style={{ marginRight: "5px" }}
                                        />
                                        PDF
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <div className="table-responsive" >
                                    <Table className="table table-bordered text-center custom-table mt-3">
                                        <thead>
                                            <tr>
                                                <th style={{ backgroundColor: "#BADDDA" }}>SR No</th>
                                                <th style={{ backgroundColor: "#BADDDA" }}>MAWB No.</th>
                                                <th style={{ backgroundColor: "#BADDDA" }}>HAWB No.</th>

                                                <th style={{ backgroundColor: "#BADDDA" }}>IGM No.</th>
                                                <th style={{ backgroundColor: "#BADDDA" }}>IGM Date</th>
                                                <th style={{ backgroundColor: "#BADDDA" }}>Importing Airlines</th>
                                            </tr>
                                        </thead>
                                        <thead>
                                            <tr>
                                                <th style={{ backgroundColor: "#BADDDA" }}>Total</th>
                                                <th style={{ backgroundColor: "#BADDDA" }}>{ctmData.length} </th>
                                                <th style={{ backgroundColor: "#BADDDA" }}> </th>

                                                <th style={{ backgroundColor: "#BADDDA" }}> </th>
                                                <th style={{ backgroundColor: "#BADDDA" }}></th>
                                                <th style={{ backgroundColor: "#BADDDA" }}></th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ctmData.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item[0]}</td>
                                                    <td>{item[1]}</td>
                                                    <td>{item[2]}</td>
                                                    <td>{item[3]}</td>
                                                    <td>{item[4]}</td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>

                            </Row >
                        </>
                    )}

                </CardBody >
            </Card >


        </div >
    )
}
