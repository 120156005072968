// import AuthContext from "../Components/AuthProvider";
// import { useNavigate } from "react-router-dom";
// import React, { useEffect, useState, useContext, useCallback, useRef } from "react";
// import Tab from 'react-bootstrap/Tab';
// import Tabs from 'react-bootstrap/Tabs';
// import Button from 'react-bootstrap/Button';
// import ipaddress from "../Components/IpAddress";
// import * as XLSX from "xlsx";
// import ReactLoading from 'react-loading';
// import {
//     Card,
//     CardBody,
//     Container,
//     Row,
//     Col,
//     Form,
//     FormGroup,
//     Label,
//     Input,
//     Table,
// } from "reactstrap";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowDown, faArrowUp, faBarcode, faFileExcel, faSearch } from '@fortawesome/free-solid-svg-icons';
// import { faEdit } from '@fortawesome/free-solid-svg-icons';
// import { faTrash } from '@fortawesome/free-solid-svg-icons';
// import { faCheck, faSave, faTimes, faSyncAlt, faCancel, faCog, faPrint, faXmark, faFileLines, faChessKing } from '@fortawesome/free-solid-svg-icons';
// import axios from "axios";
// import { toast } from "react-toastify";
// import "../Components/Style.css";
// import InviceService from "../services/InviceService";

// export default function Scan_Parcels() {
//     const styles = {
//         overlay: {
//             position: 'fixed',
//             top: 0,
//             left: 0,
//             width: '100%',
//             height: '100%',
//             backgroundColor: 'rgba(255, 255, 255, 0.8)', // Adjust the opacity and color as needed
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             zIndex: 9999, // Ensure the overlay is above other elements
//         },
//     };
//     const navigate = useNavigate();
//     const [getlink, setGetLink] = useState("");
//     const [getalldata, setAlldata] = useState([]);
//     const {
//         jwtToken,
//         userId,
//         username,
//         branchId,
//         companyid,
//         role,
//         companyname,
//         branchname,
//         login,
//         logout,
//     } = useContext(AuthContext);
//     const { isAuthenticated } = useContext(AuthContext);
//     useEffect(() => {
//         if (!isAuthenticated) {
//             navigate(
//                 "/login?message=You need to be authenticated to access this page."
//             );
//         }
//     }, [isAuthenticated, navigate]);


//     const getScan = {
//         companyId: companyid,
//         branchId: branchId,
//         createdBy: userId,
//         editedBy: userId,
//         approvedBy: userId,
//         link: getlink,
//         status: "A",


//     }

//     const handleSubmit = () => {
//         getScan.companyId = companyid;
//         getScan.branchId = branchId;
//         getScan.createdBy = userId;
//         getScan.editedBy = userId;
//         getScan.approvedBy = userId;


//         axios.post(`https://${ipaddress}export/readgateinurl`, getScan)
//             .then(response => {
//                 toast.success("Data scanned successfully", {
//                     autoClose: 700
//                 })

//                 console.log('Post request successful:', response.data);
//                 setGetLink('');

//                 getalldataa();
//             })
//             .catch(error => {
//                 // Handle error
//                 console.error('Error sending post request:', error);

//                 // Check if the error status code is 401
//                 if (error.response && error.response.status === 401) {
//                     // Show an error message for unauthorized access
//                     toast.error("Data already exists.", {
//                         autoClose: 700
//                     });
//                 }
//                 setGetLink('');
//             });
//     };
//     const [len, setLen] = useState(0); // Initialize len with 0
//     //     if(handleSubmit){
//     //         setTimeout(() => {

//     //     const linkLength = getlink.length;
//     //     setLen(linkLength);
//     // }, 10);

//     // }

//     // useEffect(() => {
//     //     // Check if the length of getlink matches len
//     //     if (getlink.length >= 50) {
//     //         // Delay the execution of handleSubmit by 10 milliseconds
//     //         const timer = setTimeout(() => {
//     //             handleSubmit();
//     //         }, 60);

//     //         // Cleanup the timer to avoid multiple calls
//     //         return () => clearTimeout(timer);
//     //     }
//     // }, [getlink, len]);



//     const [exportincount, setExportinCount] = useState(0);
//     const [importincount, setImportincount] = useState(0);

//     const [exportoutcount, setExportoutCount] = useState(0);
//     const [importoutcount, setImportoutcount] = useState(0);
//     const [subexportoutcount, setSubexportoutCount] = useState(0);
//     const [subimportoutcount, setSubimportoutcount] = useState(0);


//     const getalldataa = () => {
//         axios
//             .get(`https://${ipaddress}scan/seepzdata/${companyid}/${branchId}`)
//             .then((response) => {
//                 const exportInCount = response.data.filter(item => item.typeOfTransaction === 'Export-in').length;
//                 setExportinCount(exportInCount);
//                 const importInCount = response.data.filter(item => item.typeOfTransaction === 'Import-in').length;
//                 setImportincount(importInCount);
//                 const exportOutCount = response.data.filter(item => item.typeOfTransaction === 'Export-out').length;
//                 setExportoutCount(exportOutCount);
//                 const importOutCount = response.data.filter(item => item.typeOfTransaction === 'Import-out').length;
//                 setImportoutcount(importOutCount);
//                 const subexportOutCount = response.data.filter(item => item.typeOfTransaction === 'DTA-Export-Out').length;
//                 setSubexportoutCount(subexportOutCount);
//                 const subimportOutCount = response.data.filter(item => item.typeOfTransaction === 'DTA-Import-out').length;
//                 setSubimportoutcount(subimportOutCount);
//                 setAlldata(response.data);
//                 // Store the list in the state
//             })
//             .catch((error) => {
//                 console.error("GET list error:", error);
//             });
//     };



//     const [SHBInData, setSHBInData] = useState([]);
//     const [SHBOutData, setSHBOutData] = useState([]);
//     const [SHBInSummary, setSHBInSummary] = useState([]);
//     const [SHBOutSummary, setSHBOutSummary] = useState([]);
//     const [SHBExportOutSummary, setSHBExportOutSummary] = useState([]);
//     const [TotalImportIn, setTotalImportIn] = useState(0);

//     const [TotalImportParcelIn, setTotalImportParcelIn] = useState(0);


//     const [totalImportParcelsOut, setTotalImportParcelsOut] = useState(0);
//     const [totalExportParcelsOut, setTotalExportParcelsOut] = useState(0);

//     const [TotalExportOut, setTotalExportOut] = useState(0);
//     const [TotalImportOut, setTotalImportOut] = useState(0);

//     const getSHBInData = async () => {
//         const { List, summary } = await getData('seepz', 'Import-in', '');
//         setSHBInData(List);
//         let importTotal = 0;
//         let importParcelTotal = 0;
//         summary.forEach(item => {
//             importTotal += item[1];
//             importParcelTotal += item[3];
//         });
//         setTotalImportIn(importTotal);
//         setTotalImportParcelIn(importParcelTotal);
//         setSHBInSummary(summary);

//     };

//     const getSHBOutData = async () => {
//         const { List, importSummary, exportSummary } = await getScanningDataSHBOut('seepz', 'Import-out', 'Export-out');
//         setSHBOutData(List);
//         let importTotal = 0;
//         let exportTotal = 0;
//         let importParcelTotal = 0;
//         let exportParcelTotal = 0;

//         importSummary.forEach(item => {
//             importTotal += item[1];
//             importParcelTotal += item[2];
//         });
//         exportSummary.forEach(item => {
//             exportTotal += item[1];
//             exportParcelTotal += item[2];
//         });
//         setTotalImportOut(importTotal);
//         setTotalExportOut(exportTotal);
//         setTotalImportParcelsOut(importParcelTotal);
//         setTotalExportParcelsOut(exportParcelTotal);
//         setSHBExportOutSummary(exportSummary);
//         setSHBOutSummary(importSummary);

//         console.log("out importSummary");
//         console.log(importSummary);

//         console.log("out exportSummary");
//         console.log(exportSummary);
//     };


//     const getData = async (type, trans1, trans2) => {
//         const response = await InviceService.getScanningData(companyid, branchId, type, trans1, trans2);
//         return response.data;
//     };

//     const getScanningDataSHBOut = async (type, trans1, trans2) => {
//         const response = await InviceService.getScanningDataSHBOut(companyid, branchId, type, trans1, trans2);
//         return response.data;
//     };

//     useEffect(() => {
//         getSHBInData();
//         getSHBOutData();
//     }, [companyid, branchId])


//     const convertTimestampToDateTime = (timestamp) => {
//         const date = new Date(timestamp);

//         // Get the individual components (day, month, year, hours, minutes, seconds)
//         const day = date.getDate();
//         const month = date.getMonth() + 1; // Note: Months are zero-based, so we add 1
//         const year = date.getFullYear();
//         const hours = date.getHours();
//         const minutes = date.getMinutes();
//         const seconds = date.getSeconds();

//         // Create a formatted date and time string
//         const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

//         return formattedDateTime;
//     }

//     const [partys, setPartys] = useState([]);
//     const [getpartyId, setGetpartyId] = useState({});

//     const [loading, setLoading] = useState(false);
//     const [scanalldata, setScalalldata] = useState('');
//     const index1 = scanalldata.length - 4;
//     const sir = scanalldata.substring(0, index1).toUpperCase();
//     const packnum = scanalldata.substring(index1);
//     const [exportsubdata, setExportsubdata] = useState([]);



//     const handleSubmit1 = () => {
//         setLoading(true);
//         axios.post(`https://${ipaddress}scan/alldataforseepzgateout/${companyid}/${branchId}/${userId}/${sir}/${packnum}`)
//             .then(response => {
//                 if (response.data === "wrong barcode") {
//                     toast.error("Invalid qr or barcode format", {
//                         autoClose: 1000,
//                         style: { width: `25vw` },
//                     });
//                     setScalalldata('');
//                     getSHBOutData();
//                     setLoading(false);
//                     return;
//                 }

//                 if (response.data === "not found") {
//                     toast.error("Data not found", {
//                         autoClose: 700
//                     });
//                     setScalalldata('');
//                     getSHBOutData();
//                     setLoading(false);
//                     return;
//                 }
//                 if (response.data === 'NOP is not matched') {
//                     toast.success("Data scanned successfully", {
//                         autoClose: 1000
//                     })
//                     setScalalldata('');
//                     getSHBOutData();
//                     setLoading(false);
//                     return;
//                 }
//                 if (response.data === "wrong status") {
//                     toast.error("Data hasn't been handed over to CHA or Carting agent", {
//                         autoClose: 1000,
//                         style: { width: `32vw` },
//                     });
//                     setScalalldata('');
//                     getSHBOutData();
//                     setLoading(false);
//                     return;
//                 }
//                 if (response.data === "Dgdc_seepz_out_scan is already 'Y'") {
//                     toast.error("Data already scanned", {
//                         autoClose: 700
//                     });
//                     setScalalldata('');
//                     getSHBOutData();
//                     setLoading(false);
//                     return;
//                 }

//                 if (response.data === "success") {
//                     toast.success("Data scanned successfully", {
//                         autoClose: 700
//                     });

//                     setScalalldata('');
//                     getSHBOutData();
//                     setLoading(false);
//                 }
//                 setLoading(false);
               
//             })
//             .catch(error => {
//                 if (error) {
//                     toast.error("Data not found", {
//                         autoClose: 700
//                     });
//                 }
//                 setScalalldata('');
//                 getSHBOutData();
//                 setLoading(false);
//             })

//     };




//     const [scanalldata1, setScalalldata1] = useState('');




//     const [sir1, setSir1] = useState('');
//     const [pack1, setPack1] = useState('');
//     const [exportsubdata1, setExportsubdata1] = useState([]);

//     const handleSuccess = (message) => {
//         toast.success(message, {
//             autoClose: 1000,
//             style: { width: `30vw` },
//         });
//         resetInputs();
//     };

//     const handleError = (message) => {
//         toast.error(message, {
//             autoClose: 1000,
//             style: { width: `30vw` },
//         });
//         resetInputs();
//     };

//     const handleError1 = (message) => {
//         toast.error(message, {
//             autoClose: 1000,
//             style: { width: `30vw` },
//         });
//         resetInputs();
//     };

//     const resetInputs = () => {
//         setScalalldata1('');
//         // setSir1('');
//         // setPack1('');
//         getalldataa();
//     };

//     function formatDate1(date) {
//         const day = date.getDate().toString().padStart(2, "0");
//         const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
//         const year = date.getFullYear().toString();

//         return `${year}-${month}-${day} 00:00:00`;
//     }
//     const [loading1, setLoading1] = useState(false);
//     const checkexportsubout1 = () => {
//         setLoading1(true);
//         const date = formatDate1(new Date());
//         const index2 = scanalldata1.length - 4;
//         const sir1 = scanalldata1.substring(0, index2).toUpperCase();
//         const pack1 = scanalldata1.substring(index2);
//         if (scanalldata1.startsWith("https")) {
//             const getScan = {
//                 companyId: companyid,
//                 branchId: branchId,
//                 createdBy: userId,
//                 editedBy: userId,
//                 approvedBy: userId,
//                 link: scanalldata1,
//             };
//             axios.post(`https://${ipaddress}export/readgateinurl/${date}`, getScan)
//                 .then((response) => {
//                     if (!response.data) {
//                         handleError("Data not found");
//                         getalldataa();
//                         setLoading1(false);
//                     } else if (response.data === "found") {
//                         handleError("Data already scan");
//                         getalldataa();
//                         setLoading1(false);
//                     }
//                     if (response.data === 'NOP is not matched') {
//                         handleSuccess("Data scanned successfully");
//                         getalldataa();
//                         setLoading1(false);
//                     }

//                     else if (response.data.startsWith("The LOA for the")) {
//                         handleError1(response.data);
//                         getalldataa();
//                         setLoading1(false);
//                     }
//                     else if (response.data === "success") {
//                         handleSuccess("Data scanned successfully");
//                         getalldataa();
//                         setLoading1(false);
//                     } else {
//                         console.log('Post request successful:', response.data);
//                         handleSuccess("Data scanned successfully");
//                         getalldataa();
//                         setLoading1(false);
//                     }
//                     setLoading1(false);
//                 })
//                 .catch((error) => {
//                     console.error('Error sending post request:', error);
//                     if (error.response && error.response.status === 401) {
//                         handleError("Data not found.");
//                         getalldataa();
//                         setLoading1(false);
//                     }
//                     setLoading1(false);
//                     getalldataa();
//                 });
//         } else {

//             axios.post(`https://${ipaddress}scan/alldataforseepzgatein/${companyid}/${branchId}/${userId}/${sir1}/${pack1}`)
//                 .then((response) => {
//                     if (response.data === "wrong barcode") {
//                         handleError("Invalid qr or barcode format");
//                         getSHBInData();
//                         setLoading1(false);
//                     }
//                     if (response.data === "not found") {
//                         handleError("Data not found");
//                         getSHBInData();
//                         setLoading1(false);
//                     }



//                     if (response.data === "wrong status") {

//                         handleError("Data hasn't been exit from cargo gate");
//                         getSHBInData();
//                         setLoading1(false);
//                     }
//                     if (response.data === "Dgdc_seepz_out_scan is already 'Y'") {
//                         handleError("Data already scanned");
//                         getSHBInData();
//                         setLoading1(false);
//                     }
//                     if (response.data === "success") {

//                         handleSuccess("Data scanned successfully");
//                         getSHBInData();
//                         setLoading1(false);
//                     }


//                     setLoading1(false);
//                 })
//                 .catch((error) => {
//                     if (error) {
//                         handleError("Data not found");
//                         getSHBInData();
//                         setLoading1(false);
//                     }
//                     setLoading1(false);
//                 });

//         }

//     };




//     const inputRef = useRef();

//     const handleKeyPress = (event) => {
//         if (event.key === 'Enter') {
//             event.preventDefault();
//             document.getElementById("submitButton").click();
//         }
//     };


//     const inputRef1 = useRef();

//     const handleKeyPress1 = (event) => {
//         if (event.key === 'Enter') {
//             event.preventDefault();
//             document.getElementById("submitButton10").click();
//         }
//     };









//     // const handleExcelDownload = () => {
//     //     getimportdata();

//     //     // Create a subset of tpdata containing only the fields you want to export
//     //     const excelData = importdata.map((item, index) => ({
//     //         Sr_No: index + 1,
//     //         SIR_No: item.sirNo,
//     //         MAWB: item.mawb,
//     //         HAWB: item.hawb,
//     //         Party_Name: getpartyId[item.importerId],
//     //         Parcel_Qty: item.nop,
//     //     }));

//     //     const excelData1 = exportdata.map((item, index) => ({
//     //         Sr_No: index + 1,
//     //         SER_No: item.serNo,
//     //         Request_Id: item.sbRequestId,
//     //         SB_No: item.sbNo,
//     //         Party_Name: getpartyId[item.nameOfExporter],
//     //         Parcel_Qty: item.noOfPackages,
//     //     }));


//     //     const excelData2 = importsubdata.map((item, index) => ({
//     //         Sr_No: index + 1,
//     //         SER_No: item.sirNo,
//     //         Request_Id: item.requestId,
//     //         SB_No: '',
//     //         Party_Name: getpartyId[item.exporter],
//     //         Parcel_Qty: item.nop,
//     //     }));


//     //     const excelData3 = exportSubdata.map((item, index) => ({
//     //         Sr_No: index + 1,
//     //         SER_No: item.serNo,
//     //         Request_Id: item.requestId,
//     //         SB_No: '',
//     //         Party_Name: getpartyId[item.exporter],
//     //         Parcel_Qty: item.nop,
//     //     }));

//     //     // Define headers for both sets of data
//     //     const headers = [
//     //         "Sr No",
//     //         "SIR No/SER No",
//     //         "MAWB/Request Id",
//     //         "HAWB/SB No",
//     //         "Party Name",
//     //         "Parcel Qty",
//     //     ];

//     //     // Concatenate data from excelData and excelData1 with headers and empty rows
//     //     const combinedData = [
//     //         [`Delivery Import Register`],
//     //         headers,
//     //         ...excelData.map((data) => Object.values(data)),
//     //         [`Total: ${importdata.length}`],
//     //         [], // Empty row
//     //         [], // Empty row
//     //         ['Delivery Export Register'],
//     //         headers,
//     //         ...excelData1.map((data) => Object.values(data)),
//     //         [`Total: ${exportdata.length}`],
//     //         [], // Empty row
//     //         [], // Empty row
//     //         ['Delivery Subcontract Import Register'],
//     //         headers,
//     //         ...excelData2.map((data) => Object.values(data)),
//     //         [`Total: ${importsubdata.length}`],
//     //         [], // Empty row
//     //         [], // Empty row
//     //         ['Delivery Subcontract Export Register'],
//     //         headers,
//     //         ...excelData3.map((data) => Object.values(data)),
//     //         [`Total: ${exportSubdata.length}`],
//     //     ];

//     //     const ws = XLSX.utils.aoa_to_sheet(combinedData);

//     //     const wb = XLSX.utils.book_new();

//     //     // Set custom column widths (change the numbers to adjust the widths)
//     //     ws["!cols"] = [
//     //         { wch: 10 }, // Column A width
//     //         { wch: 15 }, // Column B width
//     //         { wch: 25 }, // Column C width
//     //         { wch: 20 }, // Column D width
//     //         { wch: 40 }, // Column E width
//     //         { wch: 12 }, // Column F width
//     //     ];
//     //     // Make the headers bold
//     //     ws["!rows"] = [{ hpt: 16, hpx: 16 }];
//     //     XLSX.utils.book_append_sheet(wb, ws, "TranshipmentData");

//     //     const excelFileName = "Delivery_Register.xlsx";

//     //     // Use the writeFile function to create and download the Excel file
//     //     XLSX.writeFile(wb, excelFileName);
//     // };





//     // Sort  Table 
//     const [sortBy, setSortBy] = useState(null);
//     const [sortOrder, setSortOrder] = useState('asc'); // Initial sort order
//     const handleSort = (columnName) => {
//         if (sortBy === columnName) {
//             // If already sorting by this column, reverse the order
//             setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
//         } else {
//             // If sorting by a different column, set it as the new sort column
//             setSortBy(columnName);
//             setSortOrder('asc'); // Default to ascending order
//         }
//     };
//     const sortedInData = [...SHBInData].sort((a, b) => {
//         if (sortBy) {
//             const valueA = a[sortBy];
//             const valueB = b[sortBy];
//             if (valueA < valueB) {
//                 return sortOrder === 'asc' ? -1 : 1;
//             }
//             if (valueA > valueB) {
//                 return sortOrder === 'asc' ? 1 : -1;
//             }
//             return 0;
//         }
//         return 0;
//     });


//     const [sortOutBy, setSortOutBy] = useState(null);
//     const [sortOrderOut, setSortOrderOut] = useState('asc'); // Initial sort order
//     const sortedOutData = [...SHBOutData].sort((a, b) => {
//         if (sortOutBy) {
//             const valueA = a[sortOutBy];
//             const valueB = b[sortOutBy];
//             if (valueA < valueB) {
//                 return sortOrderOut === 'asc' ? -1 : 1;
//             }
//             if (valueA > valueB) {
//                 return sortOrderOut === 'asc' ? 1 : -1;
//             }
//             return 0;
//         }
//         return 0;
//     });

//     const handleSortOut = (columnName) => {
//         if (sortOutBy === columnName) {
//             // If already sorting by this column, reverse the order
//             setSortOrderOut(sortOrderOut === 'asc' ? 'desc' : 'asc');
//         } else {
//             // If sorting by a different column, set it as the new sort column
//             setSortOutBy(columnName);
//             setSortOrderOut('asc'); // Default to ascending order
//         }
//     };










//     return (
//         <>
//             <Container>
//                 <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '4%', paddingRight: '-50px' }} > <FontAwesomeIcon
//                     icon={faBarcode}
//                     style={{
//                         marginRight: '8px',
//                         color: 'black', // Set the color to golden
//                     }}
//                 />Scan Parcel</h5>

//                 <Card  >
//                     <CardBody>


//                         <Tabs
//                             defaultActiveKey="home"
//                             transition={false}
//                             id="noanim-tab-example"
//                             className="mb-3"
//                         >

//                             <Tab eventKey="home" title="Incoming">
//                                 {loading1 && (
//                                     <div style={styles.overlay}>
//                                         <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
//                                     </div>
//                                 )}
//                                 <Row>
//                                     <Col md={4}>
//                                         <FormGroup>
//                                             <Label className="forlabel" for="branchId">Scan URL</Label>
//                                             <Input
//                                                 type="text"
//                                                 name="remarks"
//                                                 id="branchname"
//                                                 placeholder='Scan QR/SIR'
//                                                 value={scanalldata1}
//                                                 ref={inputRef1}
//                                                 onKeyDown={handleKeyPress1}
//                                                 autoFocus
//                                                 onChange={(e) => setScalalldata1(e.target.value)}
//                                                 className="inputField"

//                                             />
//                                         </FormGroup>
//                                     </Col>
//                                     <Col md={2}>
//                                         <Button id="submitButton10" variant="outline-primary" onClick={checkexportsubout1} style={{ marginTop: 32 }}>
//                                             <FontAwesomeIcon icon={faSearch} style={{ marginRight: '5px' }} />
//                                             Scan
//                                         </Button>
//                                     </Col>
//                                     <Col md={6}>
//                                         {SHBInSummary && SHBInSummary.length > 0 && (
//                                             <Table className="table table-bordered table-hover">
//                                                 <thead className="thead-dark bg-dark">
//                                                     <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px' }}>
//                                                         <th rowSpan='2' className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Console</th>
//                                                         <th colSpan='2' className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Import</th>
//                                                     </tr>
//                                                     <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '18px' }}>
//                                                         <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Parcel</th>
//                                                         <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Packets</th>
//                                                     </tr>
//                                                 </thead>
//                                                 <tbody>
//                                                     {SHBInSummary.map((item, index) => (

//                                                         <tr key={index} className='text-center' style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '15px' }}>
//                                                             <td>{item[0]}</td>
//                                                             <td>{item[3]}</td>
//                                                             <td>{item[1]}</td>
//                                                         </tr>
//                                                     ))}
//                                                     <tr className="text-center" style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '19px', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
//                                                         <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>Total</td>
//                                                         <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{TotalImportParcelIn}</td>
//                                                         <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{TotalImportIn}</td>
//                                                     </tr>
//                                                 </tbody>

//                                             </Table>
//                                         )}
//                                     </Col>
//                                 </Row>

//                                 {sortedInData && sortedInData.length > 0 && (
//                                     <Row style={{ marginTop: 30 }}>
//                                         <div className="table-responsive">
//                                             <Table className="table table-bordered table-hover" style={{ border: '2px solid black' }}>
//                                                 <thead className="thead-dark bg-dark">
//                                                     <tr className='text-center' style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', fontSize: '17px', color: 'black', fontFamily: 'Your-Heading-Font', height: '60px' }}>
//                                                         <th scope="col" style={{ backgroundColor: '#BADDDA' }}>Sr No.</th>
//                                                         <th scope="col" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Type of Transaction</th>

//                                                         <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSort('srNo')} className="sortable-header">

//                                                             SIR Number
//                                                             <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                                 {sortOrder === 'asc' ? (
//                                                                     <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                                 ) : (
//                                                                     <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                                 )}
//                                                             </span>
//                                                         </th>

//                                                         <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSort('cartingAgent')} className="sortable-header">

//                                                             Console
//                                                             <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                                 {sortOrder === 'asc' ? (
//                                                                     <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                                 ) : (
//                                                                     <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                                 )}
//                                                             </span>
//                                                         </th>

//                                                         <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSort('hawb')} className="sortable-header">

//                                                             HAWB
//                                                             <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                                 {sortOrder === 'asc' ? (
//                                                                     <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                                 ) : (
//                                                                     <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                                 )}
//                                                             </span>
//                                                         </th>
//                                                         <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSort('party')} className="sortable-header">
//                                                             Importer Name

//                                                             <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                                 {sortOrder === 'asc' ? (
//                                                                     <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                                 ) : (
//                                                                     <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                                 )}
//                                                             </span>
//                                                         </th>
//                                                         <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSort('nop')} className="sortable-header">
//                                                             NOP
//                                                             <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                                 {sortOrder === 'asc' ? (
//                                                                     <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                                 ) : (
//                                                                     <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                                 )}
//                                                             </span>
//                                                         </th>
//                                                         <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSort('packnum')} className="sortable-header">
//                                                             PKG NO
//                                                             <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                                 {sortOrder === 'asc' ? (
//                                                                     <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                                 ) : (
//                                                                     <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                                 )}
//                                                             </span>
//                                                         </th>
//                                                         <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSort('gateiout')} className="sortable-header">
//                                                             Date & Time

//                                                             <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                                 {sortOrder === 'asc' ? (
//                                                                     <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                                 ) : (
//                                                                     <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                                 )}
//                                                             </span>
//                                                         </th>


//                                                     </tr>
//                                                 </thead>
//                                                 <tbody>
//                                                     {sortedInData.map((item, index) => (

//                                                         <tr key={index} className={item.status === 'Y' ? 'status-Y' : 'status-N'} style={item.status === 'Y' ? { backgroundColor: 'red' } : {}}>

//                                                             <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }} >{index + 1}</td>
//                                                             <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.typeOfTransaction}</td>
//                                                             <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.srNo}</td>
//                                                             <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.cartingAgent}</td>
//                                                             <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.hawb}</td>
//                                                             <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.party}</td>
//                                                             <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.nop}</td>
//                                                             <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.packnum}</td>
//                                                             <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{convertTimestampToDateTime(item.gateiout)}</td>

//                                                         </tr>
//                                                     ))}
//                                                 </tbody>
//                                             </Table>
//                                         </div>

//                                     </Row>

//                                 )}
//                             </Tab>

//                             <Tab eventKey="profile" title="Outgoing">
//                                 {loading && (
//                                     <div style={styles.overlay}>
//                                         <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
//                                     </div>
//                                 )}
//                                 <Row>
//                                     <Col md={4}>

//                                         <Row>
//                                             <Col md={8}>
//                                                 <FormGroup>
//                                                     <Label className="forlabel" for="branchId">Scan URL</Label>
//                                                     <Input
//                                                         type="text"
//                                                         name="remarks"
//                                                         id="branchname"
//                                                         placeholder='Scan SIR/SER'
//                                                         value={scanalldata}
//                                                         onChange={(e) => setScalalldata(e.target.value)}
//                                                         ref={inputRef}
//                                                         onKeyDown={handleKeyPress}
//                                                         className="inputField"
//                                                         autoFocus
//                                                     />
//                                                 </FormGroup>


//                                             </Col>
//                                             <Col md={4}>
//                                                 <Button id="submitButton" variant="outline-primary" onClick={handleSubmit1} style={{ marginTop: 32 }}>
//                                                     <FontAwesomeIcon icon={faSearch} style={{ marginRight: '5px' }} />
//                                                     Scan
//                                                 </Button>
//                                             </Col>

//                                         </Row>

//                                     </Col>

//                                     <Col md={8}>


//                                         <Row>

//                                             <Col md={6}>


//                                                 {SHBOutSummary && SHBOutSummary.length > 0 && (
//                                                     <Table className="table table-bordered table-hover">
//                                                         <thead className="thead-dark bg-dark">
//                                                             <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px' }}>
//                                                                 <th rowSpan='2' className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>CHA</th>
//                                                                 <th colSpan='2' className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Import</th>
//                                                             </tr>
//                                                             <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '18px' }}>
//                                                                 <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Parcel</th>
//                                                                 <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Packets</th>

//                                                             </tr>
//                                                         </thead>
//                                                         <tbody>
//                                                             {SHBOutSummary.map((item, index) => (

//                                                                 <tr key={index} className='text-center' style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '15px' }}>
//                                                                     <td>{item[0]}</td>
//                                                                     <td>{item[2]}</td>
//                                                                     <td>{item[1]}</td>

//                                                                 </tr>
//                                                             ))}
//                                                             <tr className="text-center" style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '19px', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
//                                                                 <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>Total</td>
//                                                                 <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{totalImportParcelsOut}</td>
//                                                                 <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{TotalImportOut}</td>
//                                                             </tr>
//                                                         </tbody>
//                                                     </Table>
//                                                 )}



//                                             </Col>

//                                             <Col md={6}>
//                                                 {SHBExportOutSummary && SHBExportOutSummary.length > 0 && (
//                                                     <Table className="table table-bordered table-hover">
//                                                         <thead className="thead-dark bg-dark">
//                                                             <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px' }}>
//                                                                 <th rowSpan='2' className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Console</th>
//                                                                 <th colSpan='2' className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Export</th>
//                                                             </tr>
//                                                             <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '18px' }}>
//                                                                 <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Parcel</th>
//                                                                 <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Packets</th>

//                                                             </tr>
//                                                         </thead>
//                                                         <tbody>
//                                                             {SHBExportOutSummary.map((item, index) => (

//                                                                 <tr key={index} className='text-center' style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '15px' }}>
//                                                                     <td>{item[0]}</td>
//                                                                     <td>{item[2]}</td>
//                                                                     <td>{item[1]}</td>
//                                                                 </tr>
//                                                             ))}
//                                                             <tr className="text-center" style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '19px', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
//                                                                 <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>Total</td>
//                                                                 <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{totalExportParcelsOut}</td>
//                                                                 <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{TotalExportOut}</td>
//                                                             </tr>
//                                                         </tbody>
//                                                     </Table>
//                                                 )}

//                                             </Col>
//                                         </Row>




//                                     </Col>
//                                 </Row>
//                                 <Row>


//                                 </Row>

//                                 {sortedOutData && sortedOutData.length > 0 && (
//                                     <Row style={{ marginTop: 30 }}>
//                                         <div className="table-responsive">
//                                             <Table className="table table-bordered table-hover" style={{ border: '2px solid black' }}>
//                                                 <thead className="thead-dark bg-dark">
//                                                     <tr className='text-center' style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', fontSize: '17px', color: 'black', fontFamily: 'Your-Heading-Font', height: '60px' }}>
//                                                         <th scope="col" style={{ backgroundColor: '#BADDDA' }}>Sr No.</th>
//                                                         <th scope="col" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Type of Transaction</th>

//                                                         <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('srNo')} className="sortable-header">

//                                                             SER / SIR NO
//                                                             <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                                 {sortOrderOut === 'asc' ? (
//                                                                     <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                                 ) : (
//                                                                     <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                                 )}
//                                                             </span>
//                                                         </th>
//                                                         <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('cartingAgent')} className="sortable-header">

//                                                             CNSL / CHA
//                                                             <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                                 {sortOrder === 'asc' ? (
//                                                                     <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                                 ) : (
//                                                                     <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                                 )}
//                                                             </span>
//                                                         </th>

//                                                         <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('hawb')} className="sortable-header">
//                                                             HAWB
//                                                             <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                                 {sortOrderOut === 'asc' ? (
//                                                                     <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                                 ) : (
//                                                                     <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                                 )}
//                                                             </span>

//                                                         </th>

//                                                         <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('doc_Ref_No')} className="sortable-header">
//                                                             SB / BE No.

//                                                             <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                                 {sortOrderOut === 'asc' ? (
//                                                                     <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                                 ) : (
//                                                                     <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                                 )}
//                                                             </span>

//                                                         </th>
//                                                         <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('party')} className="sortable-header">
//                                                             Exporter/Importer Name

//                                                             <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                                 {sortOrderOut === 'asc' ? (
//                                                                     <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                                 ) : (
//                                                                     <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                                 )}
//                                                             </span>
//                                                         </th>
//                                                         <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('nop')} className="sortable-header">
//                                                             NOP

//                                                             <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                                 {sortOrderOut === 'asc' ? (
//                                                                     <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                                 ) : (
//                                                                     <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                                 )}
//                                                             </span>
//                                                         </th>
//                                                         <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('packnum')} className="sortable-header">
//                                                             PKG NO


//                                                             <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                                 {sortOrderOut === 'asc' ? (
//                                                                     <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                                 ) : (
//                                                                     <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                                 )}
//                                                             </span>
//                                                         </th>
//                                                         <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('gateiout')} className="sortable-header">
//                                                             Date & Time

//                                                             <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                                 {sortOrderOut === 'asc' ? (
//                                                                     <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                                 ) : (
//                                                                     <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                                 )}
//                                                             </span>
//                                                         </th>


//                                                     </tr>
//                                                 </thead>
//                                                 <tbody>
//                                                     {sortedOutData.map((item, index) => (

//                                                         <tr key={index} className={item.status === 'Y' ? 'status-Y' : 'status-N'} style={item.status === 'Y' ? { backgroundColor: 'red' } : {}}>

//                                                             <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }} >{index + 1}</td>
//                                                             <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.typeOfTransaction}</td>
//                                                             <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.srNo}</td>
//                                                             <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.cartingAgent}</td>
//                                                             <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.hawb}</td>
//                                                             <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.doc_Ref_No}</td>
//                                                             <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.party}</td>
//                                                             <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.nop}</td>
//                                                             <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.packnum}</td>
//                                                             <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{convertTimestampToDateTime(item.gateiout)}</td>

//                                                         </tr>
//                                                     ))}
//                                                 </tbody>
//                                             </Table>
//                                         </div>

//                                     </Row>
//                                 )}
//                             </Tab>


//                         </Tabs>

//                     </CardBody>
//                 </Card  >
//             </Container>




//         </>

//     )
// }



import AuthContext from "../Components/AuthProvider";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useContext, useCallback, useRef } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import ipaddress from "../Components/IpAddress";
import * as XLSX from "xlsx";
import ReactLoading from 'react-loading';
import {
    Card,
    CardBody,
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Table,
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faBarcode, faFileExcel, faSearch } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faCheck, faSave, faTimes, faSyncAlt, faCancel, faCog, faPrint, faXmark, faFileLines, faChessKing } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import { toast } from "react-toastify";
import "../Components/Style.css";
import InviceService from "../services/InviceService";

export default function Scan_Parcels() {
    const styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)', // Adjust the opacity and color as needed
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999, // Ensure the overlay is above other elements
        },
    };
    const navigate = useNavigate();
    const [getlink, setGetLink] = useState("");
    const [getalldata, setAlldata] = useState([]);
    const {
        jwtToken,
        userId,
        username,
        branchId,
        companyid,
        role,
        companyname,
        branchname,
        login,
        logout,
    } = useContext(AuthContext);
    const { isAuthenticated } = useContext(AuthContext);
    useEffect(() => {
        if (!isAuthenticated) {
            navigate(
                "/login?message=You need to be authenticated to access this page."
            );
        }
    }, [isAuthenticated, navigate]);


    const getScan = {
        companyId: companyid,
        branchId: branchId,
        createdBy: userId,
        editedBy: userId,
        approvedBy: userId,
        link: getlink,
        status: "A",


    }

    const handleSubmit = () => {
        getScan.companyId = companyid;
        getScan.branchId = branchId;
        getScan.createdBy = userId;
        getScan.editedBy = userId;
        getScan.approvedBy = userId;


        axios.post(`https://${ipaddress}export/readgateinurl`, getScan)
            .then(response => {
                toast.success("Data scanned successfully", {
                    autoClose: 700
                })

                console.log('Post request successful:', response.data);
                setGetLink('');

                getalldataa();
            })
            .catch(error => {
                // Handle error
                console.error('Error sending post request:', error);

                // Check if the error status code is 401
                if (error.response && error.response.status === 401) {
                    // Show an error message for unauthorized access
                    toast.error("Data already exists.", {
                        autoClose: 700
                    });
                }
                setGetLink('');
            });
    };
    const [len, setLen] = useState(0);


    const [exportincount, setExportinCount] = useState(0);
    const [importincount, setImportincount] = useState(0);

    const [exportoutcount, setExportoutCount] = useState(0);
    const [importoutcount, setImportoutcount] = useState(0);
    const [subexportoutcount, setSubexportoutCount] = useState(0);
    const [subimportoutcount, setSubimportoutcount] = useState(0);


    const getalldataa = () => {
        axios
            .get(`https://${ipaddress}scan/seepzdata/${companyid}/${branchId}`)
            .then((response) => {
                const exportInCount = response.data.filter(item => item.typeOfTransaction === 'Export-in').length;
                setExportinCount(exportInCount);
                const importInCount = response.data.filter(item => item.typeOfTransaction === 'Import-in').length;
                setImportincount(importInCount);
                const exportOutCount = response.data.filter(item => item.typeOfTransaction === 'Export-out').length;
                setExportoutCount(exportOutCount);
                const importOutCount = response.data.filter(item => item.typeOfTransaction === 'Import-out').length;
                setImportoutcount(importOutCount);
                const subexportOutCount = response.data.filter(item => item.typeOfTransaction === 'DTA-Export-Out').length;
                setSubexportoutCount(subexportOutCount);
                const subimportOutCount = response.data.filter(item => item.typeOfTransaction === 'DTA-Import-out').length;
                setSubimportoutcount(subimportOutCount);
                setAlldata(response.data);
                // Store the list in the state
            })
            .catch((error) => {
                console.error("GET list error:", error);
            });
    };



    const [SHBInData, setSHBInData] = useState([]);
    const [SHBOutData, setSHBOutData] = useState([]);
    const [SHBInSummary, setSHBInSummary] = useState([]);
    const [SHBOutSummary, setSHBOutSummary] = useState([]);
    const [SHBExportOutSummary, setSHBExportOutSummary] = useState([]);

    const [TotalImportIn, setTotalImportIn] = useState(0);
    const [TotalImportParcelIn, setTotalImportParcelIn] = useState(0);


    const [totalImportParcelsOut, setTotalImportParcelsOut] = useState(0);
    const [totalExportParcelsOut, setTotalExportParcelsOut] = useState(0);

    const [TotalExportOut, setTotalExportOut] = useState(0);
    const [TotalImportOut, setTotalImportOut] = useState(0);



    const [SHBPendingData, setSHBPendingData] = useState([]);

    const [SHBPendingSummary, setSHBPendingSummary] = useState([]);
    const [SHBExportPendingSummary, setSHBExportPendingSummary] = useState([]);
    const [totalImportParcelsPending, setTotalImportParcelsPending] = useState(0);
    const [totalExportParcelsPending, setTotalExportParcelsPending] = useState(0);

    const [TotalExportPending, setTotalExportPending] = useState(0);
    const [TotalImportPending, setTotalImportPending] = useState(0);



    const getSHBInData = async () => {
        const { List, summary } = await getData('seepz', 'Import-in', '');
        setSHBInData(List);
        let importTotal = 0;
        let importParcelTotal = 0;
        summary.forEach(item => {
            importTotal += item[1];
            importParcelTotal += item[3];
        });
        setTotalImportIn(importTotal);
        setTotalImportParcelIn(importParcelTotal);
        setSHBInSummary(summary);

    };

    const getSHBOutData = async () => {
        const { List, importSummary, exportSummary } = await getScanningDataSHBOut('seepz', 'Import-out', 'Export-out');
        setSHBOutData(List);
        let importTotal = 0;
        let exportTotal = 0;
        let importParcelTotal = 0;
        let exportParcelTotal = 0;

        importSummary.forEach(item => {
            importTotal += item[1];
            importParcelTotal += item[2];
        });
        exportSummary.forEach(item => {
            exportTotal += item[1];
            exportParcelTotal += item[2];
        });
        setTotalImportOut(importTotal);
        setTotalExportOut(exportTotal);
        setTotalImportParcelsOut(importParcelTotal);
        setTotalExportParcelsOut(exportParcelTotal);
        setSHBExportOutSummary(exportSummary);
        setSHBOutSummary(importSummary);

        await getSHBPendingData();
    };


    const getSHBPendingData = async () => {
        const { data, importSummary, exportSummary } = await getScanningDataSHBPending();

        // console.log(data, ' \n', importSummary, ' \n', exportSummary);


        setSHBPendingData(data);
        let importTotal = 0;
        let exportTotal = 0;
        let importParcelTotal = 0;
        let exportParcelTotal = 0;

        importSummary.forEach(item => {
            importTotal += item[1];
            importParcelTotal += item[2];
        });
        exportSummary.forEach(item => {
            exportTotal += item[1];
            exportParcelTotal += item[2];
        });
        setTotalImportPending(importTotal);
        setTotalExportPending(exportTotal);
        setTotalImportParcelsPending(importParcelTotal);
        setTotalExportParcelsPending(exportParcelTotal);
        setSHBExportPendingSummary(exportSummary);
        setSHBPendingSummary(importSummary);
    };






    const getData = async (type, trans1, trans2) => {
        const response = await InviceService.getScanningData(companyid, branchId, type, trans1, trans2);
        return response.data;
    };

    const getScanningDataSHBOut = async (type, trans1, trans2) => {
        const response = await InviceService.getScanningDataSHBOut(companyid, branchId, type, trans1, trans2);
        return response.data;
    };

    const getScanningDataSHBPending = async () => {
        const response = await InviceService.getPendingScanData(companyid, branchId);
        return response.data;
    };

    useEffect(() => {
        getSHBInData();
        getSHBOutData();
        // getSHBPendingData();
    }, [companyid, branchId])


    const convertTimestampToDateTime = (timestamp) => {
        const date = new Date(timestamp);

        // Get the individual components (day, month, year, hours, minutes, seconds)
        const day = date.getDate();
        const month = date.getMonth() + 1; // Note: Months are zero-based, so we add 1
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        // Create a formatted date and time string
        const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

        return formattedDateTime;
    }

    const [partys, setPartys] = useState([]);
    const [getpartyId, setGetpartyId] = useState({});

    const [loading, setLoading] = useState(false);
    const [scanalldata, setScalalldata] = useState('');
    const index1 = scanalldata.length - 4;
    const sir = scanalldata.substring(0, index1).toUpperCase();
    const packnum = scanalldata.substring(index1);
    const [exportsubdata, setExportsubdata] = useState([]);



    const handleSubmit1 = () => {
        setLoading(true);
        axios.post(`https://${ipaddress}scan/alldataforseepzgateout/${companyid}/${branchId}/${userId}/${sir}/${packnum}`)
            .then(response => {
                if (response.data === "wrong barcode") {
                    toast.error("Invalid qr or barcode format", {
                        autoClose: 1000,
                        style: { width: `25vw` },
                    });
                    setScalalldata('');
                    getSHBOutData();
                    setLoading(false);
                    return;
                }

                if (response.data === "not found") {
                    toast.error("Data not found", {
                        autoClose: 700
                    });
                    setScalalldata('');
                    getSHBOutData();
                    setLoading(false);
                    return;
                }
                if (response.data === 'NOP is not matched') {
                    toast.success("Data scanned successfully", {
                        autoClose: 1000
                    })
                    setScalalldata('');
                    getSHBOutData();
                    setLoading(false);
                    return;
                }
                if (response.data === "wrong status") {
                    toast.error("Data hasn't been handed over to CHA or Carting agent", {
                        autoClose: 1000,
                        style: { width: `32vw` },
                    });
                    setScalalldata('');
                    getSHBOutData();
                    setLoading(false);
                    return;
                }
                if (response.data === "Dgdc_seepz_out_scan is already 'Y'") {
                    toast.error("Data already scanned", {
                        autoClose: 700
                    });
                    setScalalldata('');
                    getSHBOutData();
                    setLoading(false);
                    return;
                }

                if (response.data === "success") {
                    toast.success("Data scanned successfully", {
                        autoClose: 700
                    });

                    setScalalldata('');
                    getSHBOutData();
                    setLoading(false);
                }
                setLoading(false);

            })
            .catch(error => {
                if (error) {
                    toast.error("Data not found", {
                        autoClose: 700
                    });
                }
                setScalalldata('');
                getSHBOutData();
                setLoading(false);
            })

    };




    const [scanalldata1, setScalalldata1] = useState('');




    const [sir1, setSir1] = useState('');
    const [pack1, setPack1] = useState('');
    const [exportsubdata1, setExportsubdata1] = useState([]);

    const handleSuccess = (message) => {
        toast.success(message, {
            autoClose: 1000,
            style: { width: `30vw` },
        });
        resetInputs();
    };

    const handleError = (message) => {
        toast.error(message, {
            autoClose: 1000,
            style: { width: `30vw` },
        });
        resetInputs();
    };

    const handleError1 = (message) => {
        toast.error(message, {
            autoClose: 1000,
            style: { width: `30vw` },
        });
        resetInputs();
    };

    const resetInputs = () => {
        setScalalldata1('');
        // setSir1('');
        // setPack1('');
        getalldataa();
    };

    function formatDate1(date) {
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
        const year = date.getFullYear().toString();

        return `${year}-${month}-${day} 00:00:00`;
    }
    const [loading1, setLoading1] = useState(false);
    const checkexportsubout1 = () => {
        setLoading1(true);
        const date = formatDate1(new Date());
        const index2 = scanalldata1.length - 4;
        const sir1 = scanalldata1.substring(0, index2).toUpperCase();
        const pack1 = scanalldata1.substring(index2);
        if (scanalldata1.startsWith("https")) {
            const getScan = {
                companyId: companyid,
                branchId: branchId,
                createdBy: userId,
                editedBy: userId,
                approvedBy: userId,
                link: scanalldata1,
            };
            axios.post(`https://${ipaddress}export/readgateinurl/${date}`, getScan)
                .then((response) => {
                    if (!response.data) {
                        handleError("Data not found");
                        getalldataa();
                        setLoading1(false);
                    } else if (response.data === "found") {
                        handleError("Data already scan");
                        getalldataa();
                        setLoading1(false);
                    }
                    if (response.data === 'NOP is not matched') {
                        handleSuccess("Data scanned successfully");
                        getalldataa();
                        setLoading1(false);
                    }

                    else if (response.data.startsWith("The LOA for the")) {
                        handleError1(response.data);
                        getalldataa();
                        setLoading1(false);
                    }
                    else if (response.data === "success") {
                        handleSuccess("Data scanned successfully");
                        getalldataa();
                        setLoading1(false);
                    } else {
                        console.log('Post request successful:', response.data);
                        handleSuccess("Data scanned successfully");
                        getalldataa();
                        setLoading1(false);
                    }
                    setLoading1(false);
                })
                .catch((error) => {
                    console.error('Error sending post request:', error);
                    if (error.response && error.response.status === 401) {
                        handleError("Data not found.");
                        getalldataa();
                        setLoading1(false);
                    }
                    setLoading1(false);
                    getalldataa();
                });
        } else {

            axios.post(`https://${ipaddress}scan/alldataforseepzgatein/${companyid}/${branchId}/${userId}/${sir1}/${pack1}`)
                .then((response) => {
                    if (response.data === "wrong barcode") {
                        handleError("Invalid qr or barcode format");
                        getSHBInData();
                        setLoading1(false);
                    }
                    if (response.data === "not found") {
                        handleError("Data not found");
                        getSHBInData();
                        setLoading1(false);
                    }



                    if (response.data === "wrong status") {

                        handleError("Data hasn't been exit from cargo gate");
                        getSHBInData();
                        setLoading1(false);
                    }
                    if (response.data === "Dgdc_seepz_out_scan is already 'Y'") {
                        handleError("Data already scanned");
                        getSHBInData();
                        setLoading1(false);
                    }
                    if (response.data === "success") {

                        handleSuccess("Data scanned successfully");
                        getSHBInData();
                        setLoading1(false);
                    }


                    setLoading1(false);
                })
                .catch((error) => {
                    if (error) {
                        handleError("Data not found");
                        getSHBInData();
                        setLoading1(false);
                    }
                    setLoading1(false);
                });

        }

    };




    const inputRef = useRef();

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            document.getElementById("submitButton").click();
        }
    };


    const inputRef1 = useRef();

    const handleKeyPress1 = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            document.getElementById("submitButton10").click();
        }
    };




    // Sort  Table 
    const [sortBy, setSortBy] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc'); // Initial sort order
    const handleSort = (columnName) => {
        if (sortBy === columnName) {
            // If already sorting by this column, reverse the order
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            // If sorting by a different column, set it as the new sort column
            setSortBy(columnName);
            setSortOrder('asc'); // Default to ascending order
        }
    };
    const sortedInData = [...SHBInData].sort((a, b) => {
        if (sortBy) {
            const valueA = a[sortBy];
            const valueB = b[sortBy];
            if (valueA < valueB) {
                return sortOrder === 'asc' ? -1 : 1;
            }
            if (valueA > valueB) {
                return sortOrder === 'asc' ? 1 : -1;
            }
            return 0;
        }
        return 0;
    });


    const [sortOutBy, setSortOutBy] = useState(null);
    const [sortOrderOut, setSortOrderOut] = useState('asc'); // Initial sort order
    const sortedOutData = [...SHBOutData].sort((a, b) => {
        if (sortOutBy) {
            const valueA = a[sortOutBy];
            const valueB = b[sortOutBy];
            if (valueA < valueB) {
                return sortOrderOut === 'asc' ? -1 : 1;
            }
            if (valueA > valueB) {
                return sortOrderOut === 'asc' ? 1 : -1;
            }
            return 0;
        }
        return 0;
    });

    const handleSortOut = (columnName) => {
        if (sortOutBy === columnName) {
            // If already sorting by this column, reverse the order
            setSortOrderOut(sortOrderOut === 'asc' ? 'desc' : 'asc');
        } else {
            // If sorting by a different column, set it as the new sort column
            setSortOutBy(columnName);
            setSortOrderOut('asc'); // Default to ascending order
        }
    };





    // Sort  Table 
    const [sortPendingBy, setSortPendingBy] = useState(null);
    const [sortPendingOrder, setSortPendingOrder] = useState('asc'); // Initial sort order
    const handlePendingSort = (index) => {
        if (sortPendingBy === index) {
            // If already sorting by this column, reverse the order
            setSortPendingOrder(sortPendingOrder === 'asc' ? 'desc' : 'asc');
        } else {
            // If sorting by a different column, set it as the new sort column
            setSortPendingBy(index);
            setSortPendingOrder('asc'); // Default to ascending order
        }
    };


    const sortedPendingData = [...SHBPendingData].sort((a, b) => {
        if (sortPendingBy !== null) {
            const valueA = a[sortPendingBy];
            const valueB = b[sortPendingBy];

            if (valueA < valueB) {
                return sortPendingOrder === 'asc' ? -1 : 1;
            }
            if (valueA > valueB) {
                return sortPendingOrder === 'asc' ? 1 : -1;
            }
            return 0;
        }
        return 0;
    });











    return (
        <>
            <Container>
                <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '4%', paddingRight: '-50px' }} > <FontAwesomeIcon
                    icon={faBarcode}
                    style={{
                        marginRight: '8px',
                        color: 'black', // Set the color to golden
                    }}
                />Scan Parcel</h5>

                <Card  >
                    <CardBody>


                        <Tabs
                            defaultActiveKey="home"
                            transition={false}
                            id="noanim-tab-example"
                            className="mb-3"
                        >

                            <Tab eventKey="home" title="Incoming">
                                {loading1 && (
                                    <div style={styles.overlay}>
                                        <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
                                    </div>
                                )}
                                <Row>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label className="forlabel" for="branchId">Scan URL</Label>
                                            <Input
                                                type="text"
                                                name="remarks"
                                                id="branchname"
                                                placeholder='Scan QR/SIR'
                                                value={scanalldata1}
                                                ref={inputRef1}
                                                onKeyDown={handleKeyPress1}
                                                autoFocus
                                                onChange={(e) => setScalalldata1(e.target.value)}
                                                className="inputField"

                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <Button id="submitButton10" variant="outline-primary" onClick={checkexportsubout1} style={{ marginTop: 32 }}>
                                            <FontAwesomeIcon icon={faSearch} style={{ marginRight: '5px' }} />
                                            Scan
                                        </Button>
                                    </Col>
                                    <Col md={6}>
                                        {SHBInSummary && SHBInSummary.length > 0 && (
                                            <Table className="table table-bordered table-hover">
                                                <thead className="thead-dark bg-dark">
                                                    <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px' }}>
                                                        <th rowSpan='2' className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Console</th>
                                                        <th colSpan='2' className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Import</th>
                                                    </tr>
                                                    <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '18px' }}>
                                                        <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Parcel</th>
                                                        <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Packets</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {SHBInSummary.map((item, index) => (

                                                        <tr key={index} className='text-center' style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '15px' }}>
                                                            <td>{item[0]}</td>
                                                            <td>{item[3]}</td>
                                                            <td>{item[1]}</td>
                                                        </tr>
                                                    ))}
                                                    <tr className="text-center" style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '19px', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
                                                        <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>Total</td>
                                                        <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{TotalImportParcelIn}</td>
                                                        <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{TotalImportIn}</td>
                                                    </tr>
                                                </tbody>

                                            </Table>
                                        )}
                                    </Col>
                                </Row>

                                {sortedInData && sortedInData.length > 0 && (
                                    <Row style={{ marginTop: 30 }}>
                                        <div className="table-responsive">
                                            <Table className="table table-bordered table-hover" style={{ border: '2px solid black' }}>
                                                <thead className="thead-dark bg-dark">
                                                    <tr className='text-center' style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', fontSize: '17px', color: 'black', fontFamily: 'Your-Heading-Font', height: '60px' }}>
                                                        <th scope="col" style={{ backgroundColor: '#BADDDA' }}>Sr No.</th>
                                                        <th scope="col" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Type of Transaction</th>

                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSort('srNo')} className="sortable-header">

                                                            SIR Number
                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortOrder === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>
                                                        </th>

                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSort('cartingAgent')} className="sortable-header">

                                                            Console
                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortOrder === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>
                                                        </th>

                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSort('hawb')} className="sortable-header">

                                                            HAWB
                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortOrder === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>
                                                        </th>
                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSort('party')} className="sortable-header">
                                                            Importer Name

                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortOrder === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>
                                                        </th>
                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSort('nop')} className="sortable-header">
                                                            NOP
                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortOrder === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>
                                                        </th>
                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSort('packnum')} className="sortable-header">
                                                            PKG NO
                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortOrder === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>
                                                        </th>
                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSort('gateiout')} className="sortable-header">
                                                            Date & Time

                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortOrder === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>
                                                        </th>


                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {sortedInData.map((item, index) => (

                                                        <tr key={index} className={item.status === 'Y' ? 'status-Y' : 'status-N'} style={item.status === 'Y' ? { backgroundColor: 'red' } : {}}>

                                                            <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }} >{index + 1}</td>
                                                            <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.typeOfTransaction}</td>
                                                            <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.srNo}</td>
                                                            <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.cartingAgent}</td>
                                                            <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.hawb}</td>
                                                            <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.party}</td>
                                                            <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.nop}</td>
                                                            <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.packnum}</td>
                                                            <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{convertTimestampToDateTime(item.gateiout)}</td>

                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>

                                    </Row>

                                )}
                            </Tab>

                            <Tab eventKey="profile" title="Outgoing">
                                {loading && (
                                    <div style={styles.overlay}>
                                        <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
                                    </div>
                                )}
                                <Row>
                                    <Col md={4}>

                                        <Row>
                                            <Col md={8}>
                                                <FormGroup>
                                                    <Label className="forlabel" for="branchId">Scan URL</Label>
                                                    <Input
                                                        type="text"
                                                        name="remarks"
                                                        id="branchname"
                                                        placeholder='Scan SIR/SER'
                                                        value={scanalldata}
                                                        onChange={(e) => setScalalldata(e.target.value)}
                                                        ref={inputRef}
                                                        onKeyDown={handleKeyPress}
                                                        className="inputField"
                                                        autoFocus
                                                    />
                                                </FormGroup>


                                            </Col>
                                            <Col md={4}>
                                                <Button id="submitButton" variant="outline-primary" onClick={handleSubmit1} style={{ marginTop: 32 }}>
                                                    <FontAwesomeIcon icon={faSearch} style={{ marginRight: '5px' }} />
                                                    Scan
                                                </Button>
                                            </Col>

                                        </Row>

                                    </Col>

                                    <Col md={8}>


                                        <Row>

                                            <Col md={6}>


                                                {SHBOutSummary && SHBOutSummary.length > 0 && (
                                                    <Table className="table table-bordered table-hover">
                                                        <thead className="thead-dark bg-dark">
                                                            <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px' }}>
                                                                <th rowSpan='2' className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>CHA</th>
                                                                <th colSpan='2' className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Import</th>
                                                            </tr>
                                                            <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '18px' }}>
                                                                <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Parcel</th>
                                                                <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Packets</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {SHBOutSummary.map((item, index) => (

                                                                <tr key={index} className='text-center' style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '15px' }}>
                                                                    <td>{item[0]}</td>
                                                                    <td>{item[2]}</td>
                                                                    <td>{item[1]}</td>

                                                                </tr>
                                                            ))}
                                                            <tr className="text-center" style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '19px', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
                                                                <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>Total</td>
                                                                <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{totalImportParcelsOut}</td>
                                                                <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{TotalImportOut}</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                )}



                                            </Col>

                                            <Col md={6}>
                                                {SHBExportOutSummary && SHBExportOutSummary.length > 0 && (
                                                    <Table className="table table-bordered table-hover">
                                                        <thead className="thead-dark bg-dark">
                                                            <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px' }}>
                                                                <th rowSpan='2' className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Console</th>
                                                                <th colSpan='2' className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Export</th>
                                                            </tr>
                                                            <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '18px' }}>
                                                                <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Parcel</th>
                                                                <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Packets</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {SHBExportOutSummary.map((item, index) => (

                                                                <tr key={index} className='text-center' style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '15px' }}>
                                                                    <td>{item[0]}</td>
                                                                    <td>{item[2]}</td>
                                                                    <td>{item[1]}</td>
                                                                </tr>
                                                            ))}
                                                            <tr className="text-center" style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '19px', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
                                                                <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>Total</td>
                                                                <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{totalExportParcelsOut}</td>
                                                                <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{TotalExportOut}</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                )}

                                            </Col>
                                        </Row>




                                    </Col>
                                </Row>
                                <Row>


                                </Row>

                                {sortedOutData && sortedOutData.length > 0 && (
                                    <Row style={{ marginTop: 30 }}>
                                        <div className="table-responsive">
                                            <Table className="table table-bordered table-hover" style={{ border: '2px solid black' }}>
                                                <thead className="thead-dark bg-dark">
                                                    <tr className='text-center' style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', fontSize: '17px', color: 'black', fontFamily: 'Your-Heading-Font', height: '60px' }}>
                                                        <th scope="col" style={{ backgroundColor: '#BADDDA' }}>Sr No.</th>
                                                        <th scope="col" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Type of Transaction</th>

                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('srNo')} className="sortable-header">

                                                            SER / SIR NO
                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortOrderOut === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>
                                                        </th>
                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('cartingAgent')} className="sortable-header">

                                                            CNSL / CHA
                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortOrder === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>
                                                        </th>

                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('hawb')} className="sortable-header">
                                                            HAWB
                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortOrderOut === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>

                                                        </th>

                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('doc_Ref_No')} className="sortable-header">
                                                            SB / BE No.

                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortOrderOut === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>

                                                        </th>
                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('party')} className="sortable-header">
                                                            Exporter/Importer Name

                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortOrderOut === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>
                                                        </th>
                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('nop')} className="sortable-header">
                                                            NOP

                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortOrderOut === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>
                                                        </th>
                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('packnum')} className="sortable-header">
                                                            PKG NO


                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortOrderOut === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>
                                                        </th>
                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('gateiout')} className="sortable-header">
                                                            Date & Time

                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortOrderOut === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>
                                                        </th>


                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {sortedOutData.map((item, index) => (

                                                        <tr key={index} className={item.status === 'Y' ? 'status-Y' : 'status-N'} style={item.status === 'Y' ? { backgroundColor: 'red' } : {}}>

                                                            <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }} >{index + 1}</td>
                                                            <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.typeOfTransaction}</td>
                                                            <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.srNo}</td>
                                                            <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.cartingAgent}</td>
                                                            <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.hawb}</td>
                                                            <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.doc_Ref_No}</td>
                                                            <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.party}</td>
                                                            <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.nop}</td>
                                                            <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.packnum}</td>
                                                            <td style={item.status === 'Y' ? { backgroundColor: '#b0e8dc', textAlign: 'center', fontFamily: 'Your-Data-Font' } : { textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{convertTimestampToDateTime(item.gateiout)}</td>

                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>

                                    </Row>
                                )}
                            </Tab>


                            <Tab eventKey="pending" title="Out Pending">
                                {loading && (
                                    <div style={styles.overlay}>
                                        <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
                                    </div>
                                )}
                                <Row>



                                    <Col md={6}>


                                        {SHBPendingSummary && SHBPendingSummary.length > 0 && (
                                            <Table className="table table-bordered table-hover">
                                                <thead className="thead-dark bg-dark">
                                                    <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px' }}>
                                                        <th rowSpan='2' className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>CHA</th>
                                                        <th colSpan='2' className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Import</th>
                                                    </tr>
                                                    <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '18px' }}>
                                                        <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Parcel</th>
                                                        <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Packets</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {SHBPendingSummary.map((item, index) => (

                                                        <tr key={index} className='text-center' style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '15px' }}>
                                                            <td>{item[0]}</td>
                                                            <td>{item[2]}</td>
                                                            <td>{item[1]}</td>

                                                        </tr>
                                                    ))}
                                                    <tr className="text-center" style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '19px', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
                                                        <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>Total</td>
                                                        <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{totalImportParcelsPending}</td>
                                                        <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{TotalImportPending}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        )}



                                    </Col>

                                    <Col md={6}>
                                        {SHBExportPendingSummary && SHBExportPendingSummary.length > 0 && (
                                            <Table className="table table-bordered table-hover">
                                                <thead className="thead-dark bg-dark">
                                                    <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px' }}>
                                                        <th rowSpan='2' className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Console</th>
                                                        <th colSpan='2' className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Export</th>
                                                    </tr>
                                                    <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '18px' }}>
                                                        <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Parcel</th>
                                                        <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Packets</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {SHBExportPendingSummary.map((item, index) => (

                                                        <tr key={index} className='text-center' style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '15px' }}>
                                                            <td>{item[0]}</td>
                                                            <td>{item[2]}</td>
                                                            <td>{item[1]}</td>
                                                        </tr>
                                                    ))}
                                                    <tr className="text-center" style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '19px', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
                                                        <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>Total</td>
                                                        <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{totalExportParcelsPending}</td>
                                                        <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{TotalExportPending}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        )}

                                    </Col>

                                </Row>
                                <Row>


                                </Row>

                                {sortedPendingData && sortedPendingData.length > 0 && (
                                    <Row style={{ marginTop: 30 }}>
                                        <div className="table-responsive">
                                            <Table className="table table-bordered table-hover" style={{ border: '2px solid black' }}>
                                                <thead className="thead-dark bg-dark">
                                                    <tr className='text-center' style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', fontSize: '17px', color: 'black', fontFamily: 'Your-Heading-Font', height: '60px' }}>
                                                        <th scope="col" style={{ backgroundColor: '#BADDDA' }}>Sr No.</th>
                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handlePendingSort(0)} className="sortable-header">

                                                            Type
                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortPendingOrder === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>
                                                        </th>
                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handlePendingSort(1)} className="sortable-header">

                                                            SER / SIR NO
                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortPendingOrder === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>
                                                        </th>
                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handlePendingSort(2)} className="sortable-header">

                                                            CNSL / CHA
                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortPendingOrder === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>
                                                        </th>

                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handlePendingSort(3)} className="sortable-header">
                                                            HAWB
                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortPendingOrder === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>

                                                        </th>

                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handlePendingSort(4)} className="sortable-header">
                                                            SB / BE No.

                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortPendingOrder === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>

                                                        </th>
                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handlePendingSort(5)} className="sortable-header">
                                                            Exporter/Importer Name

                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortPendingOrder === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>
                                                        </th>
                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handlePendingSort(6)} className="sortable-header">
                                                            NOP

                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortPendingOrder === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>
                                                        </th>

                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handlePendingSort(7)} className="sortable-header">
                                                            Date & Time

                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortPendingOrder === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>
                                                        </th>
                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handlePendingSort(8)} className="sortable-header">
                                                            Status


                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortPendingOrder === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>
                                                        </th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {sortedPendingData.map((item, index) => {
                                                        // Determine row and cell styles
                                                        const isHandedOver = item[8] === 'Handed over to Console' || item[8] === 'Handed Over to CHA';
                                                        const rowStyle = isHandedOver ? { backgroundColor: 'red' } : {};
                                                        const cellStyle = isHandedOver
                                                            ? { backgroundColor: 'rgb(246, 198, 198)', textAlign: 'center', fontFamily: 'Your-Data-Font' }
                                                            : { textAlign: 'center', fontFamily: 'Your-Data-Font' };

                                                        return (
                                                            <tr key={index}
                                                                className={isHandedOver ? 'status-Y' : 'status-N'}
                                                                style={rowStyle}
                                                            >
                                                                <td style={cellStyle}>{index + 1}</td>
                                                                <td style={cellStyle}>{item[0]}</td>
                                                                <td style={cellStyle}>{item[1]}</td>
                                                                <td style={cellStyle}>{item[2]}</td>
                                                                <td style={cellStyle}>{item[3]}</td>
                                                                <td style={cellStyle}>{item[4]}</td>
                                                                <td style={cellStyle}>{item[5]}</td>
                                                                <td style={cellStyle}>{item[6]}</td>
                                                                <td style={cellStyle}>{convertTimestampToDateTime(item[7])}</td>
                                                                <td style={cellStyle}>{item[8]}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>

                                            </Table>
                                        </div>

                                    </Row>
                                )}
                            </Tab>
                        </Tabs>
                    </CardBody>
                </Card  >
            </Container>
        </>
    )
}






