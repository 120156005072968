import React, { useState, useEffect, useContext, useLayoutEffect } from "react";
import AuthContext from "../Components/AuthProvider";
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2';
import Select from 'react-select';
import Rate_Chart_Service from "../services/Rate_Chart_Service";
import { Card, CardBody, Container, Row, Col, Form, FormGroup, Label, Input, Table, Button } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faCancel, faClose, faMoneyBillAlt, faPlaneCircleCheck, faPlaneUp, faPlus, faPlusCircle, faReceipt, faRefresh, faSearch, faSyncAlt, faTimesCircle, faTruckArrowRight, faTruckFast, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';
import InviceService from "../services/InviceService";
import moment from 'moment';

function AddCancelInvoice() {

    const styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)', // Adjust the opacity and color as needed
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999, // Ensure the overlay is above other elements
        },
    };
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const {
        userId,
        username,
        branchId,
        companyid,
        role,
        login,
        logout,
    } = useContext(AuthContext);

    const { isAuthenticated } = useContext(AuthContext);
    const location = useLocation();
    const [errors, setErrors] = useState({});

    const partyId = location.state?.partyId;
    const operationScreen = location.state?.operation;
    const documentNo = location.state?.documentNo;
    const activeTab = location.state?.activeTab;


    const searchCriteria = location.state?.searchCriteriaIC;
    const currentPage = location.state?.currentPageIC;
    const [selectedPartyPayment, setSelectedPartyPayment] = useState(null);
    const [operation, setOperation] = useState(operationScreen);


    const initialSearchCriteria = {
        companyId: companyid,
        branchId: branchId,
        startDate: moment(new Date()).format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
        partyId: '',
    };

    const [searchCriteriaNew, setSearchCriteriaNew] = useState(initialSearchCriteria);


    const handlePartyPayment = async (selectedOption) => {
        setErrors(prevErrors => ({
            ...prevErrors,
            partyId: ''
        }));
        setSelectedPartyPayment(selectedOption ? selectedOption : null);
        setSearchCriteriaNew({ ...searchCriteriaNew, partyId: selectedOption ? selectedOption.value : '' });
        setInvoiceCancelDtl([]);
        setSelectedItems([]);
        setSelectAll(false);

    };









    const Handleback = () => {
        navigate(`/parent/creditNote`, { state: { searchCriteriaIC: searchCriteria, currentPageIC: currentPage,activeTab:activeTab } })
    };

    useEffect(() => {
        const fetchData = async () => {
            await findPartiesAll();
            // if (documentNo && partyId) {
            //     await getCreditNoteById(companyid, branchId, documentNo, partyId);
            // }
        };
        fetchData();
    }, [0]);


    const [partiesAll, setPartiesAll] = useState([]);
    const findPartiesAll = async () => {
        const partyResponse = await Rate_Chart_Service.getAllActiveParties(companyid, branchId);
        const partyOptions = partyResponse.data.map(party => ({
            value: party[0],
            label: party[1]
        }));
        setPartiesAll(partyOptions);
    };




    // const getCreditNoteById = async (companyid, branchId, documentNo, partyId) => {
    //     setLoading(true);
    //     try {
    //         console.log("Calling");
    //         const response = await InviceService.getCreditNoteById(companyid, branchId, documentNo, partyId);
    //         const { dtlList, hdr } = response.data;

    //        setInvoiceCancelDtl(dtlList);
    //     }
    //     catch (error) {
    //         console.error("Error occurred while saving payment:", error);
    //         toast.error('Oops something went wrong!', {
    //             position: toast.POSITION.TOP_CENTER,
    //             autoClose: 900,
    //         });
    //     }
    //     finally {
    //         setLoading(false);
    //     }
    // };



    const initialInvoiceCancelDtl = {
        srNo: 0,
        branchId: "",
        companyId: "",
        documentNo: "",             // Placeholder for varchar(30)
        partyId: "",                // Placeholder for varchar(10)
        invoiceNo: "",              // Placeholder for varchar(20)
        documentDate: new Date(),   // Placeholder for Date
        partyName: "",              // Placeholder for varchar(250)
        invoiceDate: new Date(),    // Placeholder for Date
        igstAmount: 0.0,            // Placeholder for BigDecimal
        sgstAmount: 0.0,
        cgstAmount: 0.0,
        billAmount: 0.0,
        totalInvoiceAmount: 0.0,
        status: "",                 // Placeholder for varchar(1)
        createdBy: "",              // Placeholder for varchar(30)
        createdDate: new Date(),     // Placeholder for Date
        editedBy: "",               // Placeholder for varchar(30)
        editedDate: new Date(),      // Placeholder for Date
        approvedBy: "",             // Placeholder for varchar(30)
        approvedDate: new Date()      // Placeholder for Date
    };

    const [InvoiceCancelDtl, setInvoiceCancelDtl] = useState([]);

    const [selectedItems, setSelectedItems] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const handleReset = async () => {
        setSearchCriteriaNew(initialSearchCriteria);
        setInvoiceCancelDtl([]);
        setSelectedPartyPayment(null);
        setSelectedItems([]);
        setErrors([]);
    };

    const handleSearch = async (searchCriteria) => {
        const newErrors = {};
        // Check for required fields
        if (!searchCriteria.startDate) {
            newErrors.startDate = 'Start date is required.';
        }
        if (!searchCriteria.endDate) {
            newErrors.endDate = 'End date is required.';
        }
        if (!searchCriteria.partyId) {
            newErrors.partyId = 'Party ID is required.';
        }

        setErrors(newErrors);
        // If there are errors, return early
        if (Object.keys(newErrors).length > 0) {
            console.error(newErrors); // You can handle the errors as needed (e.g., display them to the user)
            return;
        }
        setInvoiceCancelDtl([]);
        setSelectAll(false);
        setSelectedItems([]);

        setLoading(true);
        try {
            const response = await InviceService.searchInvoiceDetails({ params: searchCriteria });
            const InvoiceCancelDtlList = convertToInvoiceCancelDtl(response.data);
            console.log('response.data : ', response.data);

            setInvoiceCancelDtl(InvoiceCancelDtlList);
        }
        catch {
            toast.error('Oops something went wrong!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 700,
            });
        }
        finally {
            setLoading(false);
        }
    };

    const convertToInvoiceCancelDtl = (invoices) => {
        return invoices.map((invoice) => ({
            srNo: 0,
            companyId: companyid,
            branchId: branchId,
            documentNo: invoice.invoiceNO,
            partyId: invoice.partyId,
            partyName: invoice.partyName,
            invoiceNo: invoice.invoiceNO,
            documentDate: invoice.invoiceDate,
            invoiceDate: invoice.invoiceDate,
            billAmount: invoice.billAmount,
            igstAmount: invoice.igstAmount,
            cgstAmount: invoice.cgstAmount,
            sgstAmount: invoice.sgstAmount,
            totalInvoiceAmount: invoice.totalInvoiceAmount,
            status: "",
            createdBy: "",
            createdDate: new Date(),
            editedBy: "",
            editedDate: new Date(),
            approvedBy: "",
            approvedDate: new Date()
        }));
    };




    // Function to handle individual checkbox change
    const handleRowCheckboxChange = (invoice) => {
        const isChecked = selectedItems.some((item) => item.invoiceNo === invoice.invoiceNo);
        if (isChecked) {
            const updatedSelectedItems = selectedItems.filter((item) => item.invoiceNo !== invoice.invoiceNo);
            setSelectedItems(updatedSelectedItems);
        } else {
            setSelectedItems([...selectedItems, invoice]);
        }
    };

    // Use useEffect to manage selectAll state
    useEffect(() => {
        setSelectAll(selectedItems.length === InvoiceCancelDtl.length);
    }, [selectedItems, InvoiceCancelDtl]);

    // Function to handle select all checkbox change
    const handleSelectAllChange = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            setSelectedItems(InvoiceCancelDtl);
        }
        setSelectAll(!selectAll);
    };


    const formatDateTime = (value) => {
        if (!value) {
            return "";
        }
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };




    const clearCreditNote = () => {
        setSelectedItems([]);
    }

    const saveCancleInvoice = async () => {
        const newErrors = {};

        // Check for required fields
        if (selectedItems && !selectedItems.length > 0) {
            toast.error('Please select at least one invoice', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1000,
            });
            return;
        }
        Swal.fire({
            title: 'Are you sure?',
            html: `Do you want to cancel the selected invoices?`, // Corrected text
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue Anyway',
            cancelButtonText: 'Cancel',
            showCancelButton: true, // Enable the cancel button
        }).then(async (result) => {
            if (result.isConfirmed) {
                await proceedToSave();
            }
        });

    };

    const proceedToSave = async () => {
        setLoading(true);
        try {
            const response = await InviceService.saveCancleInvoice(companyid, branchId, selectedItems, userId);
            setInvoiceCancelDtl(response.data);
            setOperation('E');
        } catch (error) {
            const errorMessage = error.response ? error.response.data : "An error occurred during saving data.";
            const contentWidth = errorMessage.length * 12;
            toast.error(errorMessage, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                style: { width: `${contentWidth}px` },
            });
        } finally {
            setLoading(false);
        }
    };


    const handleInputChange = (invoiceNo, field, value) => {
        const updatedFintransInv = InvoiceCancelDtl.map((invoice) => {
            if (invoice.invoiceNo === invoiceNo) {
                return {
                    ...invoice,
                    [field]: value,
                };
            }
            return invoice;
        });
        setInvoiceCancelDtl(updatedFintransInv);
        const updatedSelectedItems = selectedItems.map((item) => {
            if (item.invoiceNo === invoiceNo) {
                return {
                    ...item,
                    [field]: value,
                };
            }
            return item;
        });
        setSelectedItems(updatedSelectedItems);
    };







    return (
        <>
            {loading && (
                <div style={styles.overlay}>
                    <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
                </div>
            )}


            <div className="Container" >
                <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '2%', paddingRight: '-10px' }} > <FontAwesomeIcon
                    icon={faCancel}
                    style={{
                        marginRight: '5px',
                        color: 'black',
                    }}
                />Cancel Invoice</h5>
                <Card>
                    <CardBody>
                        <Row>

                            {!operation ? (<>
                                <Col md={2}>
                                    <FormGroup>
                                        <Label className="forlabel" for="branchId">Date From</Label>
                                        <div> {/* Wrap in an input group */}

                                            <DatePicker
                                                selected={searchCriteriaNew.startDate ? new Date(searchCriteriaNew.startDate) : null}
                                                wrapperClassName="custom-react-datepicker-wrapper"
                                                onChange={(date) => {
                                                    if (date) {
                                                        setErrors(prevErrors => ({
                                                            ...prevErrors,
                                                            startDate: ''
                                                        }));
                                                        // Set the time to 12:00 AM in UTC time
                                                        date.setHours(12, 0, 0, 0);
                                                        // Convert the selected date and time to ISO format
                                                        const formattedDate = date.toISOString();
                                                        setSearchCriteriaNew({ ...searchCriteriaNew, startDate: formattedDate });
                                                    } else {
                                                        setSearchCriteriaNew({ ...searchCriteriaNew, startDate: null });
                                                    }
                                                }}
                                                dateFormat="dd/MM/yyyy" // Specify the combined format
                                                className={`form-control border-right-0 inputField ${errors.startDate ? 'error-border' : ''}`}
                                                customInput={<input style={{ width: '100%' }} />}
                                                id={operation ? 'service' : ''}
                                                disabled={operation}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>


                                <Col md={2}>
                                    <FormGroup>
                                        <Label className="forlabel" for="branchId">Date To</Label>
                                        <div> {/* Wrap in an input group */}
                                            <DatePicker
                                                selected={searchCriteriaNew.endDate ? new Date(searchCriteriaNew.endDate) : null} // Use endDate from searchCriteria if it's defined
                                                wrapperClassName="custom-react-datepicker-wrapper"
                                                onChange={(date) => {
                                                    if (date) {
                                                        setErrors(prevErrors => ({
                                                            ...prevErrors,
                                                            endDate: ''
                                                        }));
                                                        // Set the time to 12:00 PM (noon)
                                                        date.setHours(12, 0, 0, 0);
                                                        const formattedDate = date.toISOString(); // Convert to ISO format
                                                        setSearchCriteriaNew({ ...searchCriteriaNew, endDate: formattedDate });
                                                    } else {
                                                        setSearchCriteriaNew({ ...searchCriteriaNew, endDate: null });
                                                    }
                                                }}
                                                dateFormat="dd/MM/yyyy"
                                                className={`form-control border-right-0 inputField ${errors.endDate ? 'error-border' : ''}`}
                                                customInput={<input style={{ width: '100%' }} />}
                                                id={operation ? 'service' : ''}
                                                disabled={operation}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                            </>

                            ) : null}
                            <Col md={5}>
                                <FormGroup>
                                    <Label className="forlabel" for="branchId">Select Party</Label>
                                    <Select
                                        options={partiesAll}
                                        value={selectedPartyPayment}
                                        onChange={handlePartyPayment}
                                        className={errors.partyId ? 'error-border' : ''}
                                        isClearable
                                        isDisabled={operation}
                                        id={operation ? 'service' : ''}
                                        styles={{
                                            control: (provided, state) => ({
                                                ...provided,
                                                border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                                                boxShadow: 'none',
                                                '&:hover': {
                                                    border: '1px solid #ccc'
                                                }
                                            }),
                                            indicatorSeparator: () => ({
                                                display: 'none'
                                            }),
                                            dropdownIndicator: () => ({
                                                display: 'none'
                                            })
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            {!operation ? (

                                <Col md={3}>
                                    <div className="text-center" style={{ marginTop: '29px' }}>

                                        <Button
                                            color="primary"
                                            outline
                                            onClick={() => handleSearch(searchCriteriaNew)}
                                            className="mr-2"
                                            style={{ marginRight: '25px', fontWeight: 'bold' }}
                                        >
                                            <FontAwesomeIcon icon={faSearch} style={{ marginRight: '5px' }} />
                                            SEARCH
                                        </Button>
                                        <Button
                                            color="danger"
                                            outline
                                            onClick={handleReset}
                                            className="mr-2"
                                            style={{ marginRight: '5px', fontWeight: 'bold' }}
                                        >
                                            <FontAwesomeIcon icon={faSyncAlt} style={{ marginRight: '5px' }} />
                                            RESET
                                        </Button>
                                    </div>
                                </Col>

                            ) : null}
                        </Row>

                        <hr />

                        <div className="text-center">
                            {!operation && (
                                <Button
                                    color="success"
                                    outline
                                    disabled={!selectedPartyPayment}
                                    onClick={saveCancleInvoice} className="mr-2"
                                    style={{ marginTop: '15px', fontWeight: 'bold' }}
                                >
                                    <FontAwesomeIcon icon={faSave} style={{ marginRight: '5px' }} />
                                    SAVE
                                </Button>
                            )}

                            {!operation && (
                                <Button
                                    color="danger"
                                    outline
                                    style={{ marginTop: '15px', marginLeft: '10px', fontWeight: 'bold' }}
                                    onClick={clearCreditNote}
                                >
                                    <FontAwesomeIcon icon={faSyncAlt} style={{ marginRight: '5px' }} />
                                    Reset
                                </Button>
                            )}
                            <Button
                                color="primary"
                                outline
                                style={{ marginTop: '15px', marginLeft: '10px' }}
                                onClick={Handleback}
                            >
                                <FontAwesomeIcon icon={faBackward} style={{ marginRight: '5px' }} />
                                BACK
                            </Button>
                        </div>



                        {InvoiceCancelDtl.length > 0 ? (
                            <div className="table-responsive mt-3">
                                <Table className="table table-bordered table-hover text-center" style={{ border: '2px solid black' }}>
                                    <thead className="thead-dark bg-dark">
                                        <tr className="text-center" style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '18px' }}>
                                            {!operation && (
                                                <th style={{ backgroundColor: '#BADDDA', height: '60px' }} scope="col">
                                                    <Input
                                                        className="form-check-input radios"
                                                        type="checkbox"
                                                        style={{ width: '1.2vw', height: '1.2vw' }}
                                                        checked={selectAll}
                                                        onChange={() => handleSelectAllChange()}
                                                    /></th>
                                            )}
                                            <th style={{ background: '#BADDDA' }}>Invoice Number</th>
                                            <th style={{ background: '#BADDDA' }}>Invoice Date</th>
                                            <th style={{ background: '#BADDDA' }}>Party Name</th>
                                            <th style={{ background: '#BADDDA' }}>Bill Amount</th>
                                            <th style={{ background: '#BADDDA' }}>Cgst Amount</th>
                                            <th style={{ background: '#BADDDA' }}>Sgst Amount</th>
                                            <th style={{ background: '#BADDDA' }}>Igst Amount</th>
                                            <th style={{ background: '#BADDDA' }}>Total Invoice Amount</th>
                                            <th style={{ background: '#BADDDA' }}>Remarks</th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        {InvoiceCancelDtl.map((invoice, index) =>
                                            <tr className="text-center dynamic-row-width">
                                                {!operation && (
                                                    <td>
                                                        <Input
                                                            className="form-check-input radios"
                                                            type="checkbox"
                                                            style={{ width: '1.2vw', height: '1.2vw' }}
                                                            name="taxApplicable"
                                                            checked={selectedItems.some((item) => item.invoiceNo === invoice.invoiceNo)}
                                                            onChange={() => handleRowCheckboxChange(invoice)}
                                                        />
                                                    </td>
                                                )}
                                                <td>{invoice.invoiceNo}</td>
                                                <td>{formatDateTime(invoice.invoiceDate)}</td>
                                                <td>{invoice.partyName}</td>
                                                <td>{invoice.billAmount}</td>
                                                <td>{invoice.cgstAmount}</td>
                                                <td>{invoice.sgstAmount}</td>
                                                <td>{invoice.igstAmount}</td>
                                                <td>{invoice.totalInvoiceAmount}</td>
                                                <td className="text-center">

                                                    <textarea
                                                        name="approvedBy"
                                                        rows="2"
                                                        style={{width:'20vw'}}
                                                        className="inputField form-control"
                                                        value={invoice.remarks}
                                                        maxLength={200}
                                                        onChange={(e) => handleInputChange(invoice.invoiceNo, 'remarks', e.target.value)} readOnly={operation === 'E'}
                                                        id={operation === 'E' ? 'service' : ''}
                                                    ></textarea>

                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>

                            </div>
                        ) : null}

                    </CardBody>
                </Card>

            </div>
        </>
    );
}

export default AddCancelInvoice;