// import { redirect } from 'react-router-dom';
// import AuthContext from '../Components/AuthProvider';
// import { useNavigate } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import React, { useEffect, useState, useContext, useRef } from 'react';
// import '../Components/Style.css';
// import axios from 'axios';
// import { toast } from "react-toastify";
// import Dashboard from '../Components/Dashboard';
// import ipaddress from '../Components/IpAddress';
// import { Button } from 'react-bootstrap';
// import { faArrowDown, faArrowUp, faBarcode, faForward, faSearch, faUserCircle } from '@fortawesome/free-solid-svg-icons';
// import Tab from 'react-bootstrap/Tab';
// import Tabs from 'react-bootstrap/Tabs';
// import {
//     Card,
//     CardBody,
//     Container,
//     Row,
//     Col,
//     Form,
//     FormGroup,
//     Label,


//     Input,
//     Table,
// } from "reactstrap";
// import ReactLoading from 'react-loading';


// export default function Forwardparcel() {

//     const styles = {
//         overlay: {
//             position: 'fixed',
//             top: 0,
//             left: 0,
//             width: '100%',
//             height: '100%',
//             backgroundColor: 'rgba(255, 255, 255, 0.8)', // Adjust the opacity and color as needed
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             zIndex: 9999, // Ensure the overlay is above other elements
//         },
//     };

//     const [loading, setLoading] = useState(false);

//     const navigate = useNavigate();
//     const {
//         jwtToken,
//         userId,
//         username,
//         branchId,
//         companyid,
//         role,
//         companyname,
//         branchname,
//         login,
//         logout,
//     } = useContext(AuthContext);
//     const { isAuthenticated } = useContext(AuthContext);
//     const [getSir, setGetSir] = useState('');



//     const [getSir1, setGetSir1] = useState('');

//     // If the user is not authenticated, redirect to the login page
//     useEffect(() => {
//         if (!isAuthenticated) {

//             navigate('/login?message=You need to be authenticated to access this page.');
//         }
//     }, [isAuthenticated, navigate]);
//     const [importlist, setImportlist] = useState([]);
//     const [importsublist, setImportsublist] = useState([]);


//     const [exportincount, setExportinCount] = useState(0);
//     const [importincount, setImportincount] = useState(0);

//     const [exportoutcount, setExportoutCount] = useState(0);
//     const [importoutcount, setImportoutcount] = useState(0);





//     const getImportlist = (sir, packnum) => {
//         if (!(sir.startsWith("IM") || sir.startsWith("D-IM") || sir.startsWith("D-EX") || sir.startsWith("EX"))) {
//             toast.error("Invalid Barcode Format", {
//                 autoClose: 700
//             })
//             setGetSir('');
//             return;
//         }
//         if (sir.startsWith("IM")) {
//             axios
//                 .get(`https://${ipaddress}import/single/${companyid}/${branchId}/${sir}`)
//                 .then((response) => {
//                     // console.log("GET list response:", response.data);
//                     setImportlist(response.data); // Store the list in the state
//                     if (!(response.data.dgdcStatus === 'Handed over to DGDC SHB')) {
//                         toast.error("Parcel should be enter in DGDC SHB", {
//                             autoClose: 900,
//                             style: { width: `27vw` },
//                         })
//                         setGetSir('');
//                         return;
//                     }
//                     if (!response.data.chaName) {
//                         toast.error(`CHA not selected for IR No ` + sir, {
//                             autoClose: 900,
//                             style: { width: `25vw` },
//                         })
//                         setGetSir('');
//                         return;
//                     }
//                     getForwardOutdata1(sir, packnum);

//                 })
//                 .catch((error) => {

//                     toast.error("Data not found", {
//                         autoClose: 900
//                     })
//                     setGetSir('');
//                     return;

//                 });
//         }
//         if (sir.startsWith("D-IM")) {
//             axios
//                 .get(`https://${ipaddress}importsub/single/${companyid}/${branchId}/${sir}`)
//                 .then((response) => {
//                     // console.log("GET list response:", response.data);
//                     setImportlist(response.data); // Store the list in the state
//                     if (!(response.data.dgdcStatus === 'Handed over to DGDC SHB')) {
//                         toast.error("Parcel should be enter in DGDC SHB", {
//                             autoClose: 700
//                         })
//                         setGetSir('');
//                         return;
//                     }
//                     getForwardOutdata1(sir, packnum);

//                 })
//                 .catch((error) => {
//                     console.error("GET list error:", error);
//                     toast.error("Data not found", {
//                         autoClose: 700
//                     })
//                     setGetSir('');
//                     return;
//                     setGetSir('');
//                     return;
//                 });
//         }
//         if (sir.startsWith("D-EX")) {
//             axios
//                 .get(`https://${ipaddress}exportsub/byser/${companyid}/${branchId}/${sir}`)
//                 .then((response) => {
//                     // console.log("GET list response:", response.data);
//                     setImportlist(response.data); // Store the list in the state
//                     if (!(response.data.dgdcStatus === 'Handed over to DGDC SHB')) {
//                         toast.error("Parcel should be enter in DGDC SHB", {
//                             autoClose: 700
//                         })
//                         setGetSir('');
//                         return;
//                     }
//                     getForwardOutdata1(sir, packnum);

//                 })
//                 .catch((error) => {
//                     console.error("GET list error:", error);
//                     toast.error("Data not found", {
//                         autoClose: 700
//                     })
//                     setGetSir('');
//                     return;
//                     setGetSir('');
//                     return;
//                 });
//         }

//         if (sir.startsWith("EX")) {
//             axios
//                 .get(`https://${ipaddress}exportshb/byer/${companyid}/${branchId}/${sir}`)
//                 .then((response) => {
//                     // console.log("GET list response:", response.data);
//                     setImportlist(response.data); // Store the list in the state
//                     if (!(response.data.dgdcStatus === 'Handed over to DGDC SHB')) {
//                         toast.error("Parcel should be enter in DGDC SHB", {
//                             autoClose: 700
//                         })
//                         setGetSir('');
//                         return;
//                     }
//                     getForwardOutdata1(sir, packnum);

//                 })
//                 .catch((error) => {
//                     console.error("GET list error:", error);
//                     toast.error("Data not found", {
//                         autoClose: 700
//                     })
//                     setGetSir('');
//                     return;
//                     setGetSir('');
//                     return;
//                 });
//         }

//     };


//     const [forwardoutDate1, setForwardOutData1] = useState([]);
//     const getForwardOutdata1 = (sir, packnum) => {
//         setLoading(true);
//         try {
//             axios
//                 .get(`https://${ipaddress}forwardout/getsingledata/${companyid}/${branchId}/${sir}/${packnum}`)
//                 .then((response) => {
//                     // console.log("GET list response:", response.data);
//                     setForwardOutData1(response.data); // Store the list in the state
//                     console.log('forwardoutDate ', forwardoutDate);
//                     if (response.data.length === 0) {
//                         handleSubmit(sir, packnum);
//                         return;
//                     }
//                     toast.error("Data already scan", {
//                         autoClose: 700
//                     })
//                     setGetSir('');
//                     return;
//                 })
//         }
//         catch {
//             console.error("GET list error:");
//             handleSubmit(sir, packnum);
//         }
//         finally {
//             setLoading(false);
//         }

//     };

//     const handlesub = () => {
//         const index1 = getSir.length - 4;
//         const sir = getSir.substring(0, index1).toUpperCase();
//         const packnum = getSir.substring(index1);
//         getImportlist(sir, packnum);
//     }


//     const handleSubmit = (sir, packnum) => {

//         axios.post(`https://${ipaddress}forwardout/save/${companyid}/${branchId}/${sir}/${packnum}`)
//             .then(response => {
//                 toast.success("Data scanned successfully", {
//                     autoClose: 700
//                 })
//                 setGetSir('');
//                 getForwardlist();
//                 getImportlist();

//             })
//             .catch(error => {
//                 console.error('Error sending post request:', error);
//                 setGetSir('');
//                 if (error.response && error.response.status === 401) {
//                     toast.error("Data already exists.", {
//                         autoClose: 700
//                     });
//                 }

//             });
//     };



//     const [inSummary, setInSummary] = useState([]);
//     const [outSummary, setOutSummary] = useState([]);
//     const [forwardlist, setForwardlist] = useState([]);

//     const [totalImportIn, setTotalImportIn] = useState(0);
//     const [totalExportIn, setTotalExportIn] = useState(0);
//     const [totalImportParcelsIn, setTotalImportParcelsIn] = useState(0);
//     const [totalExportParcelsIn, setTotalExportParcelsIn] = useState(0);


//     const [totalImportOut, setTotalImportOut] = useState(0);
//     const [totalExportOut, setTotalExportOut] = useState(0);
//     const [totalImportParcelsOut, setTotalImportParcelsOut] = useState(0);
//     const [totalExportParcelsOut, setTotalExportParcelsOut] = useState(0);

//     const getForwardlist = () => {
//         axios
//             .get(`https://${ipaddress}forwardout/getdata/${companyid}/${branchId}`)
//             .then((response) => {
//                 console.log("forwardout");
//                 console.log(response.data);
//                 const { List, summary } = response.data;

//                 let importTotal = 0;
//                 let exportTotal = 0;
//                 let importParcelsTotal = 0;
//                 let exportParcelsTotal = 0;

//                 summary.forEach(item => {
//                     importTotal += item[1];
//                     exportTotal += item[2];
//                     importParcelsTotal += item[3];
//                     exportParcelsTotal += item[4];
//                 });
//                 setTotalImportParcelsOut(importParcelsTotal);
//                 setTotalExportParcelsOut(exportParcelsTotal);
//                 setTotalImportOut(importTotal);
//                 setTotalExportOut(exportTotal);
//                 setOutSummary(summary);
//                 setForwardlist(List);

//             })
//             .catch((error) => {
//                 console.error("GET list error:", error);
//             });
//     };


//     useEffect(() => {
//         getForwardlist();

//     }, [companyid, branchId])

//     const convertTimestampToDateTime = (timestamp) => {
//         const date = new Date(timestamp);

//         // Get the individual components (day, month, year, hours, minutes, seconds)
//         const day = date.getDate();
//         const month = date.getMonth() + 1; // Note: Months are zero-based, so we add 1
//         const year = date.getFullYear();
//         const hours = date.getHours();
//         const minutes = date.getMinutes();
//         const seconds = date.getSeconds();

//         // Create a formatted date and time string
//         const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

//         return formattedDateTime;
//     }

//     const formatDateTime = (value) => {
//         if (!value) {
//             return ""; // Return an empty string if value is empty or undefined
//         }

//         const date = new Date(value);
//         const day = String(date.getDate()).padStart(2, "0");
//         const month = String(date.getMonth() + 1).padStart(2, "0");
//         const year = date.getFullYear();
//         const hours = String(date.getHours()).padStart(2, "0");
//         const minutes = String(date.getMinutes()).padStart(2, "0");
//         const seconds = String(date.getSeconds()).padStart(2, "0");

//         return `${day}/${month}/${year} `;
//     };
//     const [partys, setPartys] = useState([]);
//     const [getpartyId, setGetpartyId] = useState({});



//     const [forwardoutDate, setForwardOutData] = useState([]);
//     const handlesub1 = () => {
//         const index2 = getSir1.length - 4;
//         const sir1 = getSir1.substring(0, index2).toUpperCase();
//         const packnum1 = getSir1.substring(index2);

//         if (!(sir1.startsWith("IM") || sir1.startsWith("D-IM") || sir1.startsWith("D-EX") || sir1.startsWith("EX"))) {
//             toast.error("Invalid Barcode Format", {
//                 autoClose: 700
//             })
//             setGetSir1('');
//             return;
//         }
//         getForwardOutdata(sir1, packnum1);
//     }
//     const getForwardOutdata = (sir1, packnum1) => {
//         setLoading(true);
//         try {
//             axios
//                 .get(`https://${ipaddress}forwardout/getsingledata/${companyid}/${branchId}/${sir1}/${packnum1}`)
//                 .then((response) => {
//                     // console.log("GET list response:", response.data);
//                     setForwardOutData(response.data); // Store the list in the state
//                     if (response.data) {

//                         setGetSir1('');
//                         getForwardIndata(sir1, packnum1);
//                         return;
//                     }
//                     if (response.data.length === 0) {

//                         toast.error("Data not found in forward out", {
//                             autoClose: 700
//                         })
//                         setGetSir1('');

//                         return;
//                     }
//                     setGetSir1('');
//                     console.log('forwardoutDate ', forwardoutDate);
//                     return;

//                 })
//         } catch {
//             console.error("GET list error:");
//         }
//         finally {
//             setLoading(false);
//         }
//     };

//     const getForwardIndata = (sir1, packnum1) => {
//         axios
//             .get(`https://${ipaddress}forwardin/getsingledata/${companyid}/${branchId}/${sir1}/${packnum1}`)
//             .then((response) => {

//                 if (response.data.length === 0) {


//                     handleSubmit1(sir1, packnum1);
//                     return;
//                 }
//                 toast.error("Data already scan", {
//                     autoClose: 700
//                 })
//                 setGetSir1('');
//                 return;
//                 console.log('forwardoutDate ', forwardoutDate);
//             })
//             .catch((error) => {
//                 console.error("GET list error:", error);
//             });
//     };



//     const handleSubmit1 = (sir1, packnum1) => {

//         axios.post(`https://${ipaddress}forwardin/save/${companyid}/${branchId}/${sir1}/${packnum1}`)
//             .then(response => {
//                 toast.success("Data scanned successfully", {
//                     autoClose: 700
//                 })
//                 getForwardlist1();
//                 setGetSir1('');
//                 getForwardlist();
//                 getImportlist();


//                 getForwardOutdata();
//             })
//             .catch(error => {
//                 console.error('Error sending post request:', error);
//                 setGetSir1('');
//                 if (error.response && error.response.status === 401) {
//                     toast.error("Data already exists.", {
//                         autoClose: 700
//                     });
//                 }

//             });
//     };


//     const [forwardlist1, setForwardlist1] = useState([]);

//     const getForwardlist1 = () => {
//         axios
//             .get(`https://${ipaddress}forwardin/getdata/${companyid}/${branchId}`)
//             .then((response) => {

//                 const { List, summary } = response.data;

//                 setForwardlist1(List);
//                 setInSummary(summary);
//                 let importTotal = 0;
//                 let exportTotal = 0;
//                 let importParcelsTotal = 0;
//                 let exportParcelsTotal = 0;

//                 summary.forEach(item => {
//                     importTotal += item[1];
//                     exportTotal += item[2];
//                     importParcelsTotal += item[3];
//                     exportParcelsTotal += item[4];
//                 });

//                 // Update state with totals
//                 setTotalImportIn(importTotal);
//                 setTotalExportIn(exportTotal);
//                 setTotalExportParcelsIn(exportParcelsTotal);
//                 setTotalImportParcelsIn(importParcelsTotal);
//             })
//             .catch((error) => {
//                 console.error("GET list error:", error);
//             });
//     };


//     useEffect(() => {
//         getForwardlist1();

//     }, [companyid, branchId])


//     const inputRef = useRef();

//     const handleKeyPress = (event) => {
//         if (event.key === 'Enter') {
//             event.preventDefault();
//             document.getElementById("submitButton").click();
//         }
//     };


//     const inputRef1 = useRef();

//     const handleKeyPress1 = (event) => {
//         if (event.key === 'Enter') {
//             event.preventDefault();
//             document.getElementById("submitButton1").click();
//         }
//     };

//     // Sort  Table 
//     const [sortBy, setSortBy] = useState(null);
//     const [sortOrder, setSortOrder] = useState('asc'); // Initial sort order
//     const handleSort = (columnName) => {
//         if (sortBy === columnName) {
//             // If already sorting by this column, reverse the order
//             setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
//         } else {
//             // If sorting by a different column, set it as the new sort column
//             setSortBy(columnName);
//             setSortOrder('asc'); // Default to ascending order
//         }
//     };
//     const sortedInData = [...forwardlist].sort((a, b) => {
//         if (sortBy) {
//             const valueA = a[sortBy];
//             const valueB = b[sortBy];
//             if (valueA < valueB) {
//                 return sortOrder === 'asc' ? -1 : 1;
//             }
//             if (valueA > valueB) {
//                 return sortOrder === 'asc' ? 1 : -1;
//             }
//             return 0;
//         }
//         return 0;
//     });


//     const [sortOutBy, setSortOutBy] = useState(null);
//     const [sortOrderOut, setSortOrderOut] = useState('asc'); // Initial sort order
//     const sortedOutData = [...forwardlist1].sort((a, b) => {
//         if (sortOutBy) {
//             const valueA = a[sortOutBy];
//             const valueB = b[sortOutBy];
//             if (valueA < valueB) {
//                 return sortOrderOut === 'asc' ? -1 : 1;
//             }
//             if (valueA > valueB) {
//                 return sortOrderOut === 'asc' ? 1 : -1;
//             }
//             return 0;
//         }
//         return 0;
//     });

//     const handleSortOut = (columnName) => {
//         if (sortOutBy === columnName) {
//             // If already sorting by this column, reverse the order
//             setSortOrderOut(sortOrderOut === 'asc' ? 'desc' : 'asc');
//         } else {
//             // If sorting by a different column, set it as the new sort column
//             setSortOutBy(columnName);
//             setSortOrderOut('asc'); // Default to ascending order
//         }
//     };

//     return (

//         <>
//             {loading && (
//                 <div style={styles.overlay}>
//                     <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
//                 </div>
//             )}

//             <Container>

//                 <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '4%', paddingRight: '-50px' }} > <FontAwesomeIcon
//                     icon={faForward}
//                     style={{
//                         marginRight: '8px',
//                         color: 'black',
//                     }}
//                 />Forward Parcel</h5>

//                 <Card  >
//                     <CardBody>
//                         <Tabs
//                             defaultActiveKey="home"
//                             transition={false}
//                             id="noanim-tab-example"
//                             className="mb-3"
//                         >
//                             <Tab eventKey="home" title="Forward Out">
//                                 <Row>
//                                     <Col md={4}>
//                                         <FormGroup>
//                                             <Label className="forlabel" for="branchId">Scan URL</Label>
//                                             <Input
//                                                 type="text"
//                                                 name="remarks"
//                                                 id="branchname"
//                                                 autoFocus
//                                                 ref={inputRef}
//                                                 onKeyDown={handleKeyPress}
//                                                 placeholder='Scan SIR'
//                                                 onChange={(e) => setGetSir(e.target.value)}
//                                                 value={getSir}
//                                                 className="inputField"

//                                             />
//                                         </FormGroup>
//                                     </Col>
//                                     <Col md={2}>
//                                         <Button id="submitButton" variant="outline-primary" onClick={handlesub} style={{ marginTop: 32 }}>
//                                             <FontAwesomeIcon icon={faSearch} style={{ marginRight: '5px' }} />
//                                             Scan
//                                         </Button>
//                                     </Col>
//                                     <Col md={6}>

//                                         {outSummary && outSummary.length > 0 && (
//                                             <Table className="table table-bordered table-hover">
//                                                 <thead className="thead-dark bg-dark">
//                                                     <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px' }}>
//                                                         <th rowSpan="2" className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>CHA</th>
//                                                         <th colSpan="2" className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Import</th>
//                                                         <th colSpan="2" className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Export</th>
//                                                         <th colSpan="2" className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Total</th>
//                                                     </tr>
//                                                     <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '18px' }}>
//                                                         <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Parcel</th>
//                                                         <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Packets</th>
//                                                         <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Parcel</th>
//                                                         <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Packets</th>
//                                                         <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Parcel</th>
//                                                         <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Packets</th>

//                                                     </tr>
//                                                 </thead>
//                                                 <tbody>
//                                                     {outSummary.map((item, index) => (

//                                                         <tr key={index} className='text-center' style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '15px' }}>
//                                                             <td>{item[0]}</td>
//                                                             <td>{item[3]}</td>
//                                                             <td>{item[1]}</td>
//                                                             <td>{item[4]}</td>
//                                                             <td>{item[2]}</td>
//                                                             <td>{item[3] + item[4]}</td>
//                                                             <td>{item[1] + item[2]}</td>
//                                                         </tr>
//                                                     ))}
//                                                     <tr className="text-center" style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '19px', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
//                                                         <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>Total</td>
//                                                         <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{totalImportParcelsOut}</td>
//                                                         <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{totalImportOut}</td>
//                                                         <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{totalExportParcelsOut}</td>
//                                                         <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{totalExportOut}</td>
//                                                         <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{totalImportParcelsOut + totalExportParcelsOut}</td>
//                                                         <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{totalImportOut + totalExportOut}</td>
//                                                     </tr>
//                                                 </tbody>

//                                             </Table>
//                                         )}
//                                     </Col>
//                                 </Row>

//                                 {sortedInData && sortedInData.length > 0 && (
//                                     <Row style={{ marginTop: 30 }}>

//                                         <div className="table-responsive">
//                                             <Table className="table table-bordered table-hover" style={{ border: '2px solid black' }}>
//                                                 <thead>
//                                                     <tr className='text-center' style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', fontSize: '17px' }}>
//                                                         <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black' }}>Sr No.</th>

//                                                         <th style={{ backgroundColor: '#BADDDA', height: '60px', fontWeight: 'bold', border: '2px solid black' }}>Type of Transaction</th>
//                                                         <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSort('sirNo')} className="sortable-header">SIR Number
//                                                             <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                                 {sortOrder === 'asc' ? (
//                                                                     <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                                 ) : (
//                                                                     <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                                 )}
//                                                             </span>
//                                                         </th>
//                                                         <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSort('sirDate')} className="sortable-header">SIR Date
//                                                             <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                                 {sortOrder === 'asc' ? (
//                                                                     <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                                 ) : (
//                                                                     <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                                 )}
//                                                             </span>
//                                                         </th>
//                                                         <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSort('beNumber')} className="sortable-header">BE Number
//                                                             <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                                 {sortOrder === 'asc' ? (
//                                                                     <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                                 ) : (
//                                                                     <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                                 )}
//                                                             </span>
//                                                         </th>
//                                                         <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSort('party')} className="sortable-header">Importer Name
//                                                             <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                                 {sortOrder === 'asc' ? (
//                                                                     <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                                 ) : (
//                                                                     <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                                 )}
//                                                             </span>
//                                                         </th>
//                                                         <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSort('nop')} className="sortable-header">NOP
//                                                             <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                                 {sortOrder === 'asc' ? (
//                                                                     <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                                 ) : (
//                                                                     <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                                 )}
//                                                             </span>
//                                                         </th>
//                                                         <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSort('packageNo')} className="sortable-header">Package Number
//                                                             <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                                 {sortOrder === 'asc' ? (
//                                                                     <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                                 ) : (
//                                                                     <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                                 )}
//                                                             </span>
//                                                         </th>
//                                                         <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSort('forwardoutDate')} className="sortable-header">Date & Time
//                                                             <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                                 {sortOrder === 'asc' ? (
//                                                                     <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                                 ) : (
//                                                                     <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                                 )}
//                                                             </span>
//                                                         </th>


//                                                     </tr>
//                                                 </thead>
//                                                 <tbody>
//                                                     {sortedInData.map((item, index) => {
//                                                         // Check if sirNo and packageNo are not present in sortedOutData
//                                                         const comboNotPresent = !sortedOutData.some(data => data.sirNo === item.sirNo && data.packageNo === item.packageNo);
//                                                         console.log('comboNotPresent ' + item.sirNo);
//                                                         console.log(comboNotPresent);
//                                                         // 
//                                                         return (
//                                                             <tr key={index} className='text-center'>
//                                                                 <td className={`text-center ${comboNotPresent ? 'highlight-red' : ''}`}>{index + 1}</td>
//                                                                 <td className={`text-center ${comboNotPresent ? 'highlight-red' : ''}`}>{item.typeOfTransaction}</td>
//                                                                 <td className={`text-center ${comboNotPresent ? 'highlight-red' : ''}`}>{item.sirNo}</td>
//                                                                 <td className={`text-center ${comboNotPresent ? 'highlight-red' : ''}`}>{formatDateTime(item.sirDate)}</td>
//                                                                 <td className={`text-center ${comboNotPresent ? 'highlight-red' : ''}`}>{item.beNumber}</td>
//                                                                 <td className={`text-center ${comboNotPresent ? 'highlight-red' : ''}`}>{item.party}</td>
//                                                                 <td className={`text-center ${comboNotPresent ? 'highlight-red' : ''}`}>{item.nop}</td>
//                                                                 <td className={`text-center ${comboNotPresent ? 'highlight-red' : ''}`}>{item.packageNo}</td>
//                                                                 <td className={`text-center ${comboNotPresent ? 'highlight-red' : ''}`}>{convertTimestampToDateTime(item.forwardoutDate)}</td>
//                                                             </tr>
//                                                         );
//                                                     })}
//                                                 </tbody>
//                                             </Table>
//                                         </div>

//                                     </Row>
//                                 )}
//                             </Tab>
//                             <Tab eventKey="profile" title="Forward In">
//                                 <Row>
//                                     <Col md={4}>
//                                         <FormGroup>
//                                             <Label className="forlabel" for="branchId">Scan URL</Label>
//                                             <Input
//                                                 type="text"
//                                                 name="remarks"
//                                                 id="branchname"
//                                                 ref={inputRef1}
//                                                 onKeyDown={handleKeyPress1}
//                                                 placeholder='Scan SIR'
//                                                 onChange={(e) => setGetSir1(e.target.value)}
//                                                 value={getSir1}
//                                                 className="inputField"

//                                             />
//                                         </FormGroup>
//                                     </Col>
//                                     <Col md={2}>
//                                         <Button id="submitButton1" onClick={handlesub1} variant="outline-primary" style={{ marginTop: 32 }}>
//                                             <FontAwesomeIcon icon={faSearch} style={{ marginRight: '5px' }} />
//                                             Scan
//                                         </Button>
//                                     </Col>
//                                     <Col md={6}>

//                                         {inSummary && inSummary.length > 0 && (
//                                             <Table className="table table-bordered table-hover">
//                                                 <thead className="thead-dark bg-dark">
//                                                     <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px' }}>
//                                                         <th rowSpan="2" className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>CHA</th>
//                                                         <th colSpan="2" className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Import</th>
//                                                         <th colSpan="2" className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Export</th>
//                                                         <th colSpan="2" className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Total</th>
//                                                     </tr>
//                                                     <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '18px' }}>
//                                                         <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Parcel</th>
//                                                         <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Packets</th>
//                                                         <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Parcel</th>
//                                                         <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Packets</th>
//                                                         <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Parcel</th>
//                                                         <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Packets</th>

//                                                     </tr>
//                                                 </thead>
//                                                 <tbody>
//                                                     {inSummary.map((item, index) => (

//                                                         <tr key={index} className='text-center' style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '15px' }}>
//                                                             <td>{item[0]}</td>
//                                                             <td>{item[3]}</td>
//                                                             <td>{item[1]}</td>
//                                                             <td>{item[4]}</td>
//                                                             <td>{item[2]}</td>
//                                                             <td>{item[3] + item[4]}</td>
//                                                             <td>{item[1] + item[2]}</td>

//                                                         </tr>
//                                                     ))}
//                                                     <tr className="text-center" style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '19px', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>

//                                                         <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>Total</td>
//                                                         <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{totalImportParcelsIn}</td>
//                                                         <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{totalImportIn}</td>
//                                                         <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{totalExportParcelsIn}</td>
//                                                         <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{totalExportIn}</td>
//                                                         <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{totalImportParcelsIn + totalExportParcelsIn}</td>
//                                                         <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{totalImportIn + totalExportIn}</td>

//                                                     </tr>
//                                                 </tbody>
//                                             </Table>
//                                         )}

//                                     </Col>
//                                 </Row>
//                                 {sortedOutData && sortedOutData.length > 0 && (
//                                     <Row style={{ marginTop: 30 }}>

//                                         <div className="table-responsive">
//                                             <Table className="table table-bordered table-hover" style={{ border: '2px solid black' }}>
//                                                 <thead>
//                                                     <tr className='text-center' style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', fontSize: '17px' }}>
//                                                         <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black' }}>Sr No.</th>

//                                                         <th style={{ backgroundColor: '#BADDDA', height: '60px', fontWeight: 'bold', border: '2px solid black' }}>Type of Transaction</th>
//                                                         <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('sirNo')} className="sortable-header">SIR Number
//                                                             <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                                 {sortOrderOut === 'asc' ? (
//                                                                     <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                                 ) : (
//                                                                     <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                                 )}
//                                                             </span>
//                                                         </th>
//                                                         <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('sirDate')} className="sortable-header">SIR Date
//                                                             <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                                 {sortOrderOut === 'asc' ? (
//                                                                     <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                                 ) : (
//                                                                     <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                                 )}
//                                                             </span>
//                                                         </th>
//                                                         <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('beNumber')} className="sortable-header">BE Number
//                                                             <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                                 {sortOrderOut === 'asc' ? (
//                                                                     <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                                 ) : (
//                                                                     <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                                 )}
//                                                             </span>
//                                                         </th>
//                                                         <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('party')} className="sortable-header">Importer Name
//                                                             <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                                 {sortOrderOut === 'asc' ? (
//                                                                     <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                                 ) : (
//                                                                     <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                                 )}
//                                                             </span>
//                                                         </th>
//                                                         <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('nop')} className="sortable-header">NOP
//                                                             <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                                 {sortOrderOut === 'asc' ? (
//                                                                     <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                                 ) : (
//                                                                     <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                                 )}
//                                                             </span>
//                                                         </th>
//                                                         <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('packageNo')} className="sortable-header">Package Number
//                                                             <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                                 {sortOrderOut === 'asc' ? (
//                                                                     <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                                 ) : (
//                                                                     <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                                 )}
//                                                             </span>
//                                                         </th>
//                                                         <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('forwardinDate')} className="sortable-header">Date & Time
//                                                             <span className="sort-arrow" style={{ marginLeft: '12px' }}>
//                                                                 {sortOrderOut === 'asc' ? (
//                                                                     <FontAwesomeIcon icon={faArrowUp} size="lg" />
//                                                                 ) : (
//                                                                     <FontAwesomeIcon icon={faArrowDown} size="lg" />
//                                                                 )}
//                                                             </span>
//                                                         </th>


//                                                     </tr>
//                                                 </thead>
//                                                 <tbody>
//                                                     {sortedOutData.map((item, index) => (
//                                                         <tr key={index} className='text-center'>
//                                                             <td>{index + 1}</td>
//                                                             <td>{item.typeOfTransaction}</td>
//                                                             <td>{item.sirNo}</td>
//                                                             <td>{formatDateTime(item.sirDate)}</td>
//                                                             <td>{item.beNumber}</td>
//                                                             <td>{item.party}</td>
//                                                             <td>{item.nop}</td>
//                                                             <td>{item.packageNo}</td>
//                                                             <td>{convertTimestampToDateTime(item.forwardinDate)}</td>

//                                                         </tr>
//                                                     ))}
//                                                 </tbody>
//                                             </Table>
//                                         </div>

//                                     </Row>
//                                 )}
//                             </Tab>

//                         </Tabs>
//                     </CardBody>
//                 </Card  >
//             </Container>
//         </>
//     )
// }



import { redirect } from 'react-router-dom';
import AuthContext from '../Components/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState, useContext, useRef } from 'react';
import '../Components/Style.css';
import axios from 'axios';
import { toast } from "react-toastify";
import Dashboard from '../Components/Dashboard';
import ipaddress from '../Components/IpAddress';
import { Button } from 'react-bootstrap';
import { faArrowDown, faArrowUp, faBarcode, faForward, faSearch, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {
    Card,
    CardBody,
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Label,


    Input,
    Table,
} from "reactstrap";
import ReactLoading from 'react-loading';


export default function Forwardparcel() {

    const styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)', // Adjust the opacity and color as needed
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999, // Ensure the overlay is above other elements
        },
    };

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const {
        jwtToken,
        userId,
        username,
        branchId,
        companyid,
        role,
        companyname,
        branchname,
        login,
        logout,
    } = useContext(AuthContext);
    const { isAuthenticated } = useContext(AuthContext);
    const [getSir, setGetSir] = useState('');



    const [getSir1, setGetSir1] = useState('');

    // If the user is not authenticated, redirect to the login page
    useEffect(() => {
        if (!isAuthenticated) {

            navigate('/login?message=You need to be authenticated to access this page.');
        }
    }, [isAuthenticated, navigate]);
    const [importlist, setImportlist] = useState([]);
    const [importsublist, setImportsublist] = useState([]);


    const [exportincount, setExportinCount] = useState(0);
    const [importincount, setImportincount] = useState(0);

    const [exportoutcount, setExportoutCount] = useState(0);
    const [importoutcount, setImportoutcount] = useState(0);





    // const getImportlist = (sir, packnum) => {
    //     if (!(sir.startsWith("IM") || sir.startsWith("D-IM") || sir.startsWith("D-EX") || sir.startsWith("EX"))) {
    //         toast.error("Invalid Barcode Format", {
    //             autoClose: 700
    //         })
    //         setGetSir('');
    //         return;
    //     }
    //     if (sir.startsWith("IM")) {
    //         axios
    //             .get(`https://${ipaddress}import/single/${companyid}/${branchId}/${sir}`)
    //             .then((response) => {
    //                 // console.log("GET list response:", response.data);
    //                 setImportlist(response.data); // Store the list in the state
    //                 if (!(response.data.dgdcStatus === 'Handed over to DGDC SHB')) {
    //                     toast.error("Parcel should be enter in DGDC SHB", {
    //                         autoClose: 900,
    //                         style: { width: `27vw` },
    //                     })
    //                     setGetSir('');
    //                     return;
    //                 }
    //                 if (!response.data.chaName) {
    //                     toast.error(`CHA not selected for IR No ` + sir, {
    //                         autoClose: 900,
    //                         style: { width: `25vw` },
    //                     })
    //                     setGetSir('');
    //                     return;
    //                 }
    //                 getForwardOutdata1(sir, packnum);

    //             })
    //             .catch((error) => {

    //                 toast.error("Data not found", {
    //                     autoClose: 900
    //                 })
    //                 setGetSir('');
    //                 return;

    //             });
    //     }
    //     if (sir.startsWith("D-IM")) {
    //         axios
    //             .get(`https://${ipaddress}importsub/single/${companyid}/${branchId}/${sir}`)
    //             .then((response) => {
    //                 // console.log("GET list response:", response.data);
    //                 setImportlist(response.data); // Store the list in the state
    //                 if (!(response.data.dgdcStatus === 'Handed over to DGDC SHB')) {
    //                     toast.error("Parcel should be enter in DGDC SHB", {
    //                         autoClose: 700
    //                     })
    //                     setGetSir('');
    //                     return;
    //                 }
    //                 getForwardOutdata1(sir, packnum);

    //             })
    //             .catch((error) => {
    //                 console.error("GET list error:", error);
    //                 toast.error("Data not found", {
    //                     autoClose: 700
    //                 })
    //                 setGetSir('');
    //                 return;
    //                 setGetSir('');
    //                 return;
    //             });
    //     }
    //     if (sir.startsWith("D-EX")) {
    //         axios
    //             .get(`https://${ipaddress}exportsub/byser/${companyid}/${branchId}/${sir}`)
    //             .then((response) => {
    //                 // console.log("GET list response:", response.data);
    //                 setImportlist(response.data); // Store the list in the state
    //                 if (!(response.data.dgdcStatus === 'Handed over to DGDC SHB')) {
    //                     toast.error("Parcel should be enter in DGDC SHB", {
    //                         autoClose: 700
    //                     })
    //                     setGetSir('');
    //                     return;
    //                 }
    //                 getForwardOutdata1(sir, packnum);

    //             })
    //             .catch((error) => {
    //                 console.error("GET list error:", error);
    //                 toast.error("Data not found", {
    //                     autoClose: 700
    //                 })
    //                 setGetSir('');
    //                 return;
    //                 setGetSir('');
    //                 return;
    //             });
    //     }

    //     if (sir.startsWith("EX")) {
    //         axios
    //             .get(`https://${ipaddress}exportshb/byer/${companyid}/${branchId}/${sir}`)
    //             .then((response) => {
    //                 // console.log("GET list response:", response.data);
    //                 setImportlist(response.data); // Store the list in the state
    //                 if (!(response.data.dgdcStatus === 'Handed over to DGDC SHB')) {
    //                     toast.error("Parcel should be enter in DGDC SHB", {
    //                         autoClose: 700
    //                     })
    //                     setGetSir('');
    //                     return;
    //                 }
    //                 getForwardOutdata1(sir, packnum);

    //             })
    //             .catch((error) => {
    //                 console.error("GET list error:", error);
    //                 toast.error("Data not found", {
    //                     autoClose: 700
    //                 })
    //                 setGetSir('');
    //                 return;
    //                 setGetSir('');
    //                 return;
    //             });
    //     }

    // };


    const [forwardoutDate1, setForwardOutData1] = useState([]);
    // const getForwardOutdata1 = (sir, packnum) => {
    //     setLoading(true);
    //     try {
    //         axios
    //             .get(`https://${ipaddress}forwardout/getsingledata/${companyid}/${branchId}/${sir}/${packnum}`)
    //             .then((response) => {
    //                 // console.log("GET list response:", response.data);
    //                 setForwardOutData1(response.data); // Store the list in the state
    //                 console.log('forwardoutDate ', forwardoutDate);
    //                 if (response.data.length === 0) {
    //                     handleSubmit(sir, packnum);
    //                     return;
    //                 }
    //                 toast.error("Data already scan", {
    //                     autoClose: 700
    //                 })
    //                 setGetSir('');
    //                 return;
    //             })
    //     }
    //     catch {
    //         console.error("GET list error:");
    //         handleSubmit(sir, packnum);
    //     }
    //     finally {
    //         setLoading(false);
    //     }

    // };




    const handleSubmitOut = async() => {
        if (!getSir) {
            toast.error("Enter Ir / Er No", {
                autoClose: 700,
            });
            return;
        }

        setLoading(true);
        const index1 = getSir.length - 4;
        const sir = getSir.substring(0, index1).toUpperCase();
        const packnum = getSir.substring(index1);
        try {

            if (!(sir.startsWith("IM") || sir.startsWith("EX"))) {
                toast.error("Invalid Barcode Format", {
                    autoClose: 700
                });
                setGetSir('');
                return;
            }

           await axios.post(`https://${ipaddress}forwardout/save/${companyid}/${branchId}/${sir}/${packnum}`)
                .then(response => {
                    const { success, message } = response.data;
                    console.log(response.data);

                    if (success) {
                        toast.success(message, {
                            autoClose: 700,
                            style: { width: '30vw' }
                        });
                        setGetSir('');
                        getForwardlist();
                    } else {
                        toast.error(message, {
                            autoClose: 700,
                            style: { width: '30vw' }
                        });
                        setGetSir('');
                        getForwardlist();
                    }
                })
                .catch(error => {
                    console.error('Error sending post request:', error);
                    setGetSir('');
                    toast.error(error, {
                        autoClose: 700,
                        style: { width: '30vw' }
                    });
                    setGetSir('');
                    getForwardlist();
                });
        } catch (error) {
            console.error('Unexpected error:', error);
            setGetSir('');
            toast.error("Unexpected error occurred.", {
                autoClose: 700,
            });
        }
        finally {
            setLoading(false);
        }
    };



    const handleSubmitIn = async (getSir1) => {

        setLoading(true);
        const index1 = getSir1.length - 4;
        const sir = getSir1.substring(0, index1).toUpperCase();
        const packnum = getSir1.substring(index1);
        try {
            if (!(sir.startsWith("IM") || sir.startsWith("EX"))) {
                toast.error("Invalid Barcode Format", {
                    autoClose: 700
                });
                setGetSir1('');
                return;
            }
            await axios.post(`https://${ipaddress}forwardin/save/${companyid}/${branchId}/${sir}/${packnum}`).then
                (response => {
                    const { success, message } = response.data;
                    console.log(response.data);

                    if (success) {
                        toast.success(message, {
                            autoClose: 700,
                            style: { width: '30vw' }
                        });
                        getForwardlist1();
                        setGetSir1('');
                        getForwardlist();
                    } else {
                        toast.error(message, {
                            autoClose: 700,
                            style: { width: '30vw' }
                        });
                        getForwardlist1();
                        setGetSir1('');
                        getForwardlist();
                    }
                }).catch(error => {
                    console.error('Error sending post request:', error);
                    toast.error(error, {
                        autoClose: 700,
                        style: { width: '30vw' }
                    });
                    getForwardlist1();
                    setGetSir1('');
                    getForwardlist();
                });
        } catch (error) {
            console.error('Unexpected error:', error);
            setGetSir('');
            toast.error("Unexpected error occurred.", {
                autoClose: 700,
            });
        }
        finally {
            setLoading(false);
        }
    };




    const [inSummary, setInSummary] = useState([]);
    const [outSummary, setOutSummary] = useState([]);
    const [forwardlist, setForwardlist] = useState([]);

    const [totalImportIn, setTotalImportIn] = useState(0);
    const [totalExportIn, setTotalExportIn] = useState(0);
    const [totalImportParcelsIn, setTotalImportParcelsIn] = useState(0);
    const [totalExportParcelsIn, setTotalExportParcelsIn] = useState(0);


    const [totalImportOut, setTotalImportOut] = useState(0);
    const [totalExportOut, setTotalExportOut] = useState(0);
    const [totalImportParcelsOut, setTotalImportParcelsOut] = useState(0);
    const [totalExportParcelsOut, setTotalExportParcelsOut] = useState(0);

    const getForwardlist = () => {
        axios
            .get(`https://${ipaddress}forwardout/getdata/${companyid}/${branchId}`)
            .then((response) => {
                console.log("forwardout");
                console.log(response.data);
                const { List, summary } = response.data;

                let importTotal = 0;
                let exportTotal = 0;
                let importParcelsTotal = 0;
                let exportParcelsTotal = 0;

                summary.forEach(item => {
                    importTotal += item[1];
                    exportTotal += item[2];
                    importParcelsTotal += item[3];
                    exportParcelsTotal += item[4];
                });
                setTotalImportParcelsOut(importParcelsTotal);
                setTotalExportParcelsOut(exportParcelsTotal);
                setTotalImportOut(importTotal);
                setTotalExportOut(exportTotal);
                setOutSummary(summary);
                setForwardlist(List);

            })
            .catch((error) => {
                console.error("GET list error:", error);
            });
    };


    useEffect(() => {
        getForwardlist();
    }, [companyid, branchId])

    const convertTimestampToDateTime = (timestamp) => {
        const date = new Date(timestamp);

        // Get the individual components (day, month, year, hours, minutes, seconds)
        const day = date.getDate();
        const month = date.getMonth() + 1; // Note: Months are zero-based, so we add 1
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        // Create a formatted date and time string
        const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

        return formattedDateTime;
    }

    const formatDateTime = (value) => {
        if (!value) {
            return ""; // Return an empty string if value is empty or undefined
        }

        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}/${month}/${year} `;
    };


    const [forwardoutDate, setForwardOutData] = useState([]);




    // const getForwardOutdata = (sir1, packnum1) => {
    //     setLoading(true);
    //     try {
    //         axios
    //             .get(`https://${ipaddress}forwardout/getsingledata/${companyid}/${branchId}/${sir1}/${packnum1}`)
    //             .then((response) => {
    //                 // console.log("GET list response:", response.data);
    //                 setForwardOutData(response.data); // Store the list in the state
    //                 if (response.data) {

    //                     setGetSir1('');
    //                     getForwardIndata(sir1, packnum1);
    //                     return;
    //                 }
    //                 if (response.data.length === 0) {

    //                     toast.error("Data not found in forward out", {
    //                         autoClose: 700
    //                     })
    //                     setGetSir1('');

    //                     return;
    //                 }
    //                 setGetSir1('');
    //                 console.log('forwardoutDate ', forwardoutDate);
    //                 return;

    //             })
    //     } catch {
    //         console.error("GET list error:");
    //     }
    //     finally {
    //         setLoading(false);
    //     }
    // };

    // const getForwardIndata = (sir1, packnum1) => {
    //     axios
    //         .get(`https://${ipaddress}forwardin/getsingledata/${companyid}/${branchId}/${sir1}/${packnum1}`)
    //         .then((response) => {

    //             if (response.data.length === 0) {
    //                 handleSubmit1(sir1, packnum1);
    //                 return;
    //             }
    //             toast.error("Data already scan", {
    //                 autoClose: 700
    //             })
    //             setGetSir1('');
    //             return;
    //             console.log('forwardoutDate ', forwardoutDate);
    //         })
    //         .catch((error) => {
    //             console.error("GET list error:", error);
    //         });
    // };






    const [forwardlist1, setForwardlist1] = useState([]);

    const getForwardlist1 = () => {
        axios
            .get(`https://${ipaddress}forwardin/getdata/${companyid}/${branchId}`)
            .then((response) => {

                const { List, summary } = response.data;

                setForwardlist1(List);
                setInSummary(summary);
                let importTotal = 0;
                let exportTotal = 0;
                let importParcelsTotal = 0;
                let exportParcelsTotal = 0;

                summary.forEach(item => {
                    importTotal += item[1];
                    exportTotal += item[2];
                    importParcelsTotal += item[3];
                    exportParcelsTotal += item[4];
                });

                // Update state with totals
                setTotalImportIn(importTotal);
                setTotalExportIn(exportTotal);
                setTotalExportParcelsIn(exportParcelsTotal);
                setTotalImportParcelsIn(importParcelsTotal);
            })
            .catch((error) => {
                console.error("GET list error:", error);
            });
    };


    useEffect(() => {
        getForwardlist1();

    }, [companyid, branchId])


    const inputRef = useRef();

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            document.getElementById("submitButton").click();
        }
    };


    const inputRef1 = useRef();

    const handleKeyPress1 = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            document.getElementById("submitButton1").click();
        }
    };

    // Sort  Table 
    const [sortBy, setSortBy] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc'); // Initial sort order
    const handleSort = (columnName) => {
        if (sortBy === columnName) {
            // If already sorting by this column, reverse the order
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            // If sorting by a different column, set it as the new sort column
            setSortBy(columnName);
            setSortOrder('asc'); // Default to ascending order
        }
    };
    const sortedInData = [...forwardlist].sort((a, b) => {
        if (sortBy) {
            const valueA = a[sortBy];
            const valueB = b[sortBy];
            if (valueA < valueB) {
                return sortOrder === 'asc' ? -1 : 1;
            }
            if (valueA > valueB) {
                return sortOrder === 'asc' ? 1 : -1;
            }
            return 0;
        }
        return 0;
    });


    const [sortOutBy, setSortOutBy] = useState(null);
    const [sortOrderOut, setSortOrderOut] = useState('asc'); // Initial sort order
    const sortedOutData = [...forwardlist1].sort((a, b) => {
        if (sortOutBy) {
            const valueA = a[sortOutBy];
            const valueB = b[sortOutBy];
            if (valueA < valueB) {
                return sortOrderOut === 'asc' ? -1 : 1;
            }
            if (valueA > valueB) {
                return sortOrderOut === 'asc' ? 1 : -1;
            }
            return 0;
        }
        return 0;
    });

    const handleSortOut = (columnName) => {
        if (sortOutBy === columnName) {
            // If already sorting by this column, reverse the order
            setSortOrderOut(sortOrderOut === 'asc' ? 'desc' : 'asc');
        } else {
            // If sorting by a different column, set it as the new sort column
            setSortOutBy(columnName);
            setSortOrderOut('asc'); // Default to ascending order
        }
    };

    return (

        <>
            {loading && (
                <div style={styles.overlay}>
                    <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
                </div>
            )}

            <Container>

                <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '4%', paddingRight: '-50px' }} > <FontAwesomeIcon
                    icon={faForward}
                    style={{
                        marginRight: '8px',
                        color: 'black',
                    }}
                />Forward Parcel</h5>

                <Card  >
                    <CardBody>
                        <Tabs
                            defaultActiveKey="home"
                            transition={false}
                            id="noanim-tab-example"
                            className="mb-3"
                        >
                            <Tab eventKey="home" title="Forward Out">
                                <Row>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label className="forlabel" for="branchId">Scan URL Out</Label>
                                            <Input
                                                type="text"
                                                name="remarks"
                                                id="branchname"
                                                autoFocus
                                                ref={inputRef}
                                                onKeyDown={handleKeyPress}
                                                placeholder='Scan IR / ER'
                                                onChange={(e) => setGetSir(e.target.value)}
                                                value={getSir}
                                                className="inputField"

                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <Button id="submitButton" variant="outline-primary" onClick={handleSubmitOut} style={{ marginTop: 32 }}>
                                            <FontAwesomeIcon icon={faSearch} style={{ marginRight: '5px' }} />
                                            Scan
                                        </Button>
                                    </Col>
                                    <Col md={6}>

                                        {outSummary && outSummary.length > 0 && (
                                            <Table className="table table-bordered table-hover">
                                                <thead className="thead-dark bg-dark">
                                                    <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px' }}>
                                                        <th rowSpan="2" className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>CHA</th>
                                                        <th colSpan="2" className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Import</th>
                                                        <th colSpan="2" className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Export</th>
                                                        <th colSpan="2" className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Total</th>
                                                    </tr>
                                                    <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '18px' }}>
                                                        <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Parcel</th>
                                                        <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Packets</th>
                                                        <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Parcel</th>
                                                        <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Packets</th>
                                                        <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Parcel</th>
                                                        <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Packets</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {outSummary.map((item, index) => (

                                                        <tr key={index} className='text-center' style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '15px' }}>
                                                            <td>{item[0]}</td>
                                                            <td>{item[3]}</td>
                                                            <td>{item[1]}</td>
                                                            <td>{item[4]}</td>
                                                            <td>{item[2]}</td>
                                                            <td>{item[3] + item[4]}</td>
                                                            <td>{item[1] + item[2]}</td>
                                                        </tr>
                                                    ))}
                                                    <tr className="text-center" style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '19px', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
                                                        <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>Total</td>
                                                        <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{totalImportParcelsOut}</td>
                                                        <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{totalImportOut}</td>
                                                        <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{totalExportParcelsOut}</td>
                                                        <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{totalExportOut}</td>
                                                        <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{totalImportParcelsOut + totalExportParcelsOut}</td>
                                                        <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{totalImportOut + totalExportOut}</td>
                                                    </tr>
                                                </tbody>

                                            </Table>
                                        )}
                                    </Col>
                                </Row>

                                {sortedInData && sortedInData.length > 0 && (
                                    <Row style={{ marginTop: 30 }}>

                                        <div className="table-responsive">
                                            <Table className="table table-bordered table-hover" style={{ border: '2px solid black' }}>
                                                <thead>
                                                    <tr className='text-center' style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', fontSize: '17px' }}>
                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black' }}>Sr No.</th>

                                                        <th style={{ backgroundColor: '#BADDDA', height: '60px', fontWeight: 'bold', border: '2px solid black' }}>Type of Transaction</th>
                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSort('sirNo')} className="sortable-header">SIR Number
                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortOrder === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>
                                                        </th>
                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSort('cartingAgent')} className="sortable-header">CHA
                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortOrder === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>
                                                        </th>
                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSort('doc_ref_no')} className="sortable-header">HAWB
                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortOrder === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>
                                                        </th>
                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSort('party')} className="sortable-header">Importer Name
                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortOrder === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>
                                                        </th>
                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSort('nop')} className="sortable-header">NOP
                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortOrder === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>
                                                        </th>
                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSort('packageNo')} className="sortable-header">Package Number
                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortOrder === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>
                                                        </th>
                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSort('forwardoutDate')} className="sortable-header">Date & Time
                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortOrder === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>
                                                        </th>


                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {sortedInData.map((item, index) => {
                                                        // Check if sirNo and packageNo are not present in sortedOutData
                                                        const comboNotPresent = item.forwardedInStatus === 'N';
                                                        console.log('comboNotPresent ' + item.forwardedInStatus);
                                                        console.log(comboNotPresent);
                                                        // 
                                                        return (
                                                            <tr key={index} className='text-center'>
                                                                <td className={`text-center ${comboNotPresent ? 'highlight-red' : ''}`}>{index + 1}</td>
                                                                <td className={`text-center ${comboNotPresent ? 'highlight-red' : ''}`}>{item.typeOfTransaction}</td>
                                                                <td className={`text-center ${comboNotPresent ? 'highlight-red' : ''}`}>{item.sirNo}</td>
                                                                <td className={`text-center ${comboNotPresent ? 'highlight-red' : ''}`}>{item.cartingAgent}</td>
                                                                <td className={`text-center ${comboNotPresent ? 'highlight-red' : ''}`}>{item.doc_ref_no}</td>
                                                                <td className={`text-center ${comboNotPresent ? 'highlight-red' : ''}`}>{item.party}</td>
                                                                <td className={`text-center ${comboNotPresent ? 'highlight-red' : ''}`}>{item.nop}</td>
                                                                <td className={`text-center ${comboNotPresent ? 'highlight-red' : ''}`}>{item.packageNo}</td>
                                                                <td className={`text-center ${comboNotPresent ? 'highlight-red' : ''}`}>{convertTimestampToDateTime(item.forwardoutDate)}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </Table>
                                        </div>

                                    </Row>
                                )}
                            </Tab>
                            <Tab eventKey="profile" title="Forward In">
                                <Row>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label className="forlabel" for="branchId">Scan URL</Label>
                                            <Input
                                                type="text"
                                                name="remarks"
                                                id="branchname"
                                                ref={inputRef1}
                                                onKeyDown={handleKeyPress1}
                                                placeholder='Scan SIR'
                                                onChange={(e) => setGetSir1(e.target.value)}
                                                value={getSir1}
                                                className="inputField"

                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <Button id="submitButton1" onClick={(e) => handleSubmitIn(getSir1)} variant="outline-primary" style={{ marginTop: 32 }}>
                                            <FontAwesomeIcon icon={faSearch} style={{ marginRight: '5px' }} />
                                            Scan
                                        </Button>
                                    </Col>
                                    <Col md={6}>

                                        {inSummary && inSummary.length > 0 && (
                                            <Table className="table table-bordered table-hover">
                                                <thead className="thead-dark bg-dark">
                                                    <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px' }}>
                                                        <th rowSpan="2" className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>CHA</th>
                                                        <th colSpan="2" className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Import</th>
                                                        <th colSpan="2" className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Export</th>
                                                        <th colSpan="2" className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Total</th>
                                                    </tr>
                                                    <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '18px' }}>
                                                        <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Parcel</th>
                                                        <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Packets</th>
                                                        <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Parcel</th>
                                                        <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Packets</th>
                                                        <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Parcel</th>
                                                        <th className="text-center" style={{ backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font' }}>Packets</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {inSummary.map((item, index) => (

                                                        <tr key={index} className='text-center' style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '15px' }}>
                                                            <td>{item[0]}</td>
                                                            <td>{item[3]}</td>
                                                            <td>{item[1]}</td>
                                                            <td>{item[4]}</td>
                                                            <td>{item[2]}</td>
                                                            <td>{item[3] + item[4]}</td>
                                                            <td>{item[1] + item[2]}</td>

                                                        </tr>
                                                    ))}
                                                    <tr className="text-center" style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '19px', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>

                                                        <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>Total</td>
                                                        <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{totalImportParcelsIn}</td>
                                                        <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{totalImportIn}</td>
                                                        <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{totalExportParcelsIn}</td>
                                                        <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{totalExportIn}</td>
                                                        <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{totalImportParcelsIn + totalExportParcelsIn}</td>
                                                        <td style={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>{totalImportIn + totalExportIn}</td>

                                                    </tr>
                                                </tbody>
                                            </Table>
                                        )}

                                    </Col>
                                </Row>
                                {sortedOutData && sortedOutData.length > 0 && (
                                    <Row style={{ marginTop: 30 }}>

                                        <div className="table-responsive">
                                            <Table className="table table-bordered table-hover" style={{ border: '2px solid black' }}>
                                                <thead>
                                                    <tr className='text-center' style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', fontSize: '17px' }}>
                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black' }}>Sr No.</th>

                                                        <th style={{ backgroundColor: '#BADDDA', height: '60px', fontWeight: 'bold', border: '2px solid black' }}>Type of Transaction</th>
                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('sirNo')} className="sortable-header">SIR Number
                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortOrderOut === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>
                                                        </th>
                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('cartingAgent')} className="sortable-header">CHA
                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortOrderOut === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>
                                                        </th>
                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('doc_ref_no')} className="sortable-header">HAWB
                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortOrderOut === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>
                                                        </th>
                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('party')} className="sortable-header">Importer Name
                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortOrderOut === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>
                                                        </th>
                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('nop')} className="sortable-header">NOP
                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortOrderOut === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>
                                                        </th>
                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('packageNo')} className="sortable-header">Package Number
                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortOrderOut === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>
                                                        </th>
                                                        <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', cursor: 'pointer' }} onClick={() => handleSortOut('forwardinDate')} className="sortable-header">Date & Time
                                                            <span className="sort-arrow" style={{ marginLeft: '12px' }}>
                                                                {sortOrderOut === 'asc' ? (
                                                                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faArrowDown} size="lg" />
                                                                )}
                                                            </span>
                                                        </th>


                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {sortedOutData.map((item, index) => (
                                                        <tr key={index} className='text-center'>
                                                            <td>{index + 1}</td>
                                                            <td>{item.typeOfTransaction}</td>
                                                            <td>{item.sirNo}</td>
                                                            <td>{item.cartingAgent}</td>
                                                            <td>{item.doc_ref_no}</td>
                                                            <td>{item.party}</td>
                                                            <td>{item.nop}</td>
                                                            <td>{item.packageNo}</td>
                                                            <td>{convertTimestampToDateTime(item.forwardinDate)}</td>

                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>

                                    </Row>
                                )}
                            </Tab>

                        </Tabs>
                    </CardBody>
                </Card  >
            </Container>
        </>
    )
}

