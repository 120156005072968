import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../Components/AuthProvider";
import { Pagination } from "react-bootstrap";
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    ModalHeader,
    ModalBody,
    FormGroup,
    Modal,
    Row,
    Label,
    Button,
    Input,
    Table
} from "reactstrap";
import { toast } from "react-toastify";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAtom, faDownLong, faDownload, faEye, faFileExcel, faFileInvoice, faMoneyBill, faMoneyBillAlt, faPlus, faPrint, faReceipt, faSave, faSearch, faTimes, faTrashAlt, faTruck, faTruckArrowRight, faTruckFast, faTruckFront, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import ReactLoading from 'react-loading';
import InviceService from "../services/InviceService";
import moment from 'moment';
import DatePicker from "react-datepicker";
import Rate_Chart_Service from "../services/Rate_Chart_Service";

export default function CreditNote() {
    const {
        userId,
        branchId,
        companyid,
        role,
        companyname,
        branchname,
        login,
        logout,
    } = useContext(AuthContext);

    const navigate = useNavigate();
    const { isAuthenticated } = useContext(AuthContext);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate(
                "/login?message=You need to be authenticated to access this page."
            );
        }
    }, [isAuthenticated, navigate]);

    const [activeTab, setActiveTab] = useState();

    const handleSelect = (k) => {
        setActiveTab(k);
        setSearchCriteria({ ...searchCriteria, docType: k ? k : '' });
        setSearchCriteriaIC({ ...searchCriteriaIC, docType: k ? k : '' });
    };


    const initialSearchCriteria = {
        companyId: companyid,
        branchId: branchId,
        startDate: moment(new Date()).format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
        partyId: '',
        docType: 'c'
    };




    const [parties, setParties] = useState([]);
    const [selectedParty, setSelectedParty] = useState(null);
    const [selectedPartyIC, setSelectedPartyIC] = useState(null);
    const [selectedPartyDB, setSelectedPartyDB] = useState(null);

    const [searchCriteria, setSearchCriteria] = useState([]);
    const [searchCriteriaIC, setSearchCriteriaIC] = useState([]);
    const [searchCriteriaDB, setSearchCriteriaDB] = useState(initialSearchCriteria);



    console.log(' searchCriteria \n', searchCriteria);
    console.log(' searchCriteriaIC \n', searchCriteriaIC);



    const [loading, setLoading] = useState(false);
    const styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)', // Adjust the opacity and color as needed
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999, // Ensure the overlay is above other elements
        },
    };

    const location = useLocation();
    const updatedSerchcriteria = location.state?.searchCriteria;
    const updatedCurrentPage = location.state?.currentPage

    const updatedSerchcriteriaIC = location.state?.searchCriteriaIC;
    const updatedCurrentPageIC = location.state?.currentPageIC

    const updatedActiveTab = location.state?.activeTab




    useEffect(() => {
        const fetchData = async () => {
            const criteriaToSet = updatedSerchcriteria || initialSearchCriteria;
            const criteriaToSetICNew = updatedSerchcriteriaIC || initialSearchCriteria;
            const updatedPage = updatedCurrentPage || currentPage;
            const updatesActiveTabNew = updatedActiveTab || 'C';
            const updatedCurrentPageICNew = updatedCurrentPageIC || currentPageIC;

            setActiveTab(updatesActiveTabNew);
            setCurrentPage(updatedPage);
            setCurrentPageIC(updatedCurrentPageICNew);
            setSearchCriteria(criteriaToSet);
            setSearchCriteriaIC(criteriaToSetICNew);
            await handleSearch(criteriaToSet);
            await handleSearchIC(criteriaToSetICNew);
        };
        fetchData();
    }, []);







    const findParties = async () => {
        const partyResponse = await Rate_Chart_Service.getAllActiveParties(companyid, branchId);
        const partyOptions = partyResponse.data.map(party => ({
            value: party[0],
            label: party[1]
        }));
        setParties(partyOptions);
    };

    useEffect(() => {
        findParties();
    }, []);




    const handleAdd = () => {
        navigate(`/parent/Add-CreditNote`, { state: { searchCriteria: searchCriteria, currentPage: currentPage, activeTab: activeTab } });
    };

    const handleAddCancel = () => {
        navigate(`/parent/Add-CancelInvoice`, { state: { searchCriteriaIC: searchCriteriaIC, currentPage: currentPageIC, activeTab: activeTab } });
    };


    const [filteredData, setFilteredData] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    // Function to handle page change
    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };
    const displayPages = () => {
        const centerPageCount = 5;
        const middlePage = Math.floor(centerPageCount / 2);
        let startPage = currentPage - middlePage;
        let endPage = currentPage + middlePage;

        if (startPage < 1) {
            startPage = 1;
            endPage = Math.min(totalPages, centerPageCount);
        }

        if (endPage > totalPages) {
            endPage = totalPages;
            startPage = Math.max(1, totalPages - centerPageCount + 1);
        }
        return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    };



    const handleSearch = async (searchCriteria) => {

        setLoading(true);
        try {
            const response = await InviceService.searchInvoiceCancels({ params: searchCriteria });
            setFilteredData(response.data);
        }
        catch {
            toast.error('Oops something went wrong!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 700,
            });
        }
        finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        const foundType = parties.find(type => type.value === searchCriteria.partyId);
        setSelectedParty(foundType ? foundType : null);
    }, [parties, selectedParty, searchCriteria.partyId]);



    const handleReset = (tab) => {
        if (tab === 'CN') {
            setSearchCriteria(initialSearchCriteria);
            setSelectedParty(null);
            handleSearch(initialSearchCriteria);
            setFilteredData([]);
        }
        if (tab === 'IC') {
            setSearchCriteriaIC(initialSearchCriteria);
            setSelectedPartyIC(null);
            handleSearchIC(initialSearchCriteria);
            setFilteredDataIC([]);
        }
    };

    const handleViewClick = (documentNo, partyId, type) => {
        navigate(`/parent/Add-CreditNote`, { state: { documentNo: documentNo, operation: type, partyId: partyId, searchCriteria: searchCriteria, currentPage: currentPage, activeTab: activeTab } });
    };


    const formatDateTimeMonth = (value) => {
        if (!value) {
            return ""; // Return an empty string if value is empty or undefined
        }

        const date = new Date(value);
        const day = date.getDate().toString().padStart(2, '0'); // Get the day of the month and pad with zero if needed
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get the month (0-based index, so add 1) and pad with zero if needed
        const year = date.getFullYear(); // Get the year

        // Construct the final formatted date string
        const formattedDate = `${day}/${month}/${year}`;

        return formattedDate;
    };

    const handlePartyChange = async (selectedOption) => {
        setSelectedParty(selectedOption);
        setSearchCriteria({ ...searchCriteria, partyId: selectedOption ? selectedOption.value : '' });
    };

    // DOWNLOAD RECEIPT 

    const downLoadReceipt = async (partyId, receiptId, type) => {
        setLoading(true);
        try {
            const response = await InviceService.downloadReceipt(companyid, branchId, partyId, receiptId);
            if (response.status === 200) {
                const pdfData = response.data;
                const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
                const blobUrl = URL.createObjectURL(pdfBlob);

                if (type === "PDF") {
                    // Create an anchor element for downloading
                    const downloadLink = document.createElement('a');
                    downloadLink.href = blobUrl;
                    downloadLink.download = 'Receipt.pdf'; // Set the filename for the downloaded PDF
                    downloadLink.style.display = 'none';
                    document.body.appendChild(downloadLink);
                    // Trigger the download
                    downloadLink.click();
                    // Clean up
                    document.body.removeChild(downloadLink);
                    window.URL.revokeObjectURL(blobUrl);
                }
                if (type === 'PRINT') {
                    window.open(blobUrl, '_blank');
                }
                toast.success("Downloading Pdf!", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 800,
                });
            } else {
                console.error("Error downloading PDF:", response.statusText);
            }
        } catch (error) {
            console.error("Error downloading PDF:", error);
        } finally {
            setLoading(false);
        }
    };



    // In
    const [filteredDataIC, setFilteredDataIC] = useState([]);

    const [currentPageIC, setCurrentPageIC] = useState(1);
    const [itemsPerPageIC] = useState(10);

    const indexOfLastItemIC = currentPageIC * itemsPerPageIC;
    const indexOfFirstItemIC = indexOfLastItemIC - itemsPerPageIC;
    const currentItemsIC = filteredDataIC.slice(indexOfFirstItemIC, indexOfLastItemIC);
    const totalPagesIC = Math.ceil(filteredDataIC.length / itemsPerPageIC);



    const handlePartyChangeIC = async (selectedOption) => {
        setSelectedPartyIC(selectedOption);
        setSearchCriteriaIC({ ...searchCriteriaIC, partyId: selectedOption ? selectedOption.value : '' });
    };

    // Function to handle page change
    const handlePageChangeIC = (page) => {
        if (page >= 1 && page <= totalPagesIC) {
            setCurrentPageIC(page);
        }
    };

    const displayPagesIC = () => {
        const centerPageCount = 5;
        const middlePage = Math.floor(centerPageCount / 2);
        let startPage = currentPageIC - middlePage;
        let endPage = currentPageIC + middlePage;

        if (startPage < 1) {
            startPage = 1;
            endPage = Math.min(totalPagesIC, centerPageCount);
        }

        if (endPage > totalPagesIC) {
            endPage = totalPagesIC;
            startPage = Math.max(1, totalPagesIC - centerPageCount + 1);
        }
        return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    };



    const handleSearchIC = async (searchCriteria) => {
        setLoading(true);
        try {
            const response = await InviceService.searchInvoiceCancelsNew({ params: searchCriteria });
            setFilteredDataIC(response.data);
            console.log('response.data : \n', response.data);
        }
        catch {
            toast.error('Oops something went wrong!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 700,
            });
        }
        finally {
            setLoading(false);
        }
    };


    const handleXLSdownLoad = async (fileName, searchCriteriaDaily) => {
        setLoading(true);
        try {


            let response;
            if (fileName === 'credit') {
                response = await InviceService.downLoadInvoiceCancelXls(searchCriteriaDaily);
            }
            if (fileName === 'cancel') {
                response = await InviceService.downLoadInvoiceCancelXlsNew(searchCriteriaDaily);
            }
            if (fileName === 'debit') {
                response = await InviceService.downLoadDebitXls(searchCriteriaDaily);
            }
            if (response.status === 200) {
                const blob = new Blob([response.data], { type: response.headers['content-type'] });

                // Create a temporary URL for the blob
                const url = window.URL.createObjectURL(blob);

                // Create a link element to trigger the download
                const a = document.createElement("a");
                a.href = url;


                let file = 'Report.xlsx';

                if (fileName === 'credit') {


                    file = 'CreditNote_Report.xlsx';

                } if (fileName === 'cancel') {

                    file = 'InvoiceCancel_Report.xlsx';
                }
                if (fileName === 'debit') {

                    file = 'DebitNote_Report.xlsx';
                }
                a.download = file;

                document.body.appendChild(a);
                a.click();
                // Clean up
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }
        }
        catch {
            toast.error("error downLoading file!", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 800,
            });
        }
        finally {
            setLoading(false);
        }
    };









    const initialInvoiceCancelHdr = {
        branchId: branchId,
        companyId: companyid,
        documentNo: '',
        partyId: '',
        partyName: '',
        documentDate: new Date(),
        documentType: 'D',
        documentAmt: 0.0,
        remarks: '',
        status: '',
        createdBy: '',
        createdDate: new Date(),
        editedBy: '',
        editedDate: new Date(),
        approvedBy: '',
        approvedDate: new Date(),
    };


    const [InvoiceCancelHdr, setInvoiceCancelHdr] = useState(initialInvoiceCancelHdr);

    const [errors, setErrors] = useState([]);
    const [filteredDataDB, setFilteredDataDB] = useState([]);
    const [selectedPartyDBIn, setSelectedPartyDBIn] = useState(null);

    const [currentPageDB, setCurrentPageDB] = useState(1);
    const [itemsPerPageDB] = useState(10);

    const indexOfLastItemDB = currentPageDB * itemsPerPageDB;
    const indexOfFirstItemDB = indexOfLastItemDB - itemsPerPageDB;
    const currentItemsDB = filteredDataDB.slice(indexOfFirstItemDB, indexOfLastItemDB);
    const totalPagesDB = Math.ceil(filteredDataDB.length / itemsPerPageDB);



    const handlePartyChangeDB = async (selectedOption) => {
        setSelectedPartyDB(selectedOption);
        setSearchCriteriaDB({ ...searchCriteriaDB, partyId: selectedOption ? selectedOption.value : '' });
    };



    const handlePartyChangeDBIn = async (selectedOption) => {
        setSelectedPartyDBIn(selectedOption);
        setInvoiceCancelHdr({ ...InvoiceCancelHdr, partyId: selectedOption ? selectedOption.value : '', partyName: selectedOption ? selectedOption.label : '' });
    };

    // Function to handle page change
    const handlePageChangeDB = (page) => {
        if (page >= 1 && page <= totalPagesDB) {
            setCurrentPageDB(page);
        }
    };

    const displayPagesDB = () => {
        const centerPageCount = 5;
        const middlePage = Math.floor(centerPageCount / 2);
        let startPage = currentPageDB - middlePage;
        let endPage = currentPageDB + middlePage;

        if (startPage < 1) {
            startPage = 1;
            endPage = Math.min(totalPagesDB, centerPageCount);
        }

        if (endPage > totalPagesDB) {
            endPage = totalPagesDB;
            startPage = Math.max(1, totalPagesDB - centerPageCount + 1);
        }
        return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    };



    const handleSearchDB = async (searchCriteria) => {
        setLoading(true);
        try {
            const response = await InviceService.searchDebitNote({ params: searchCriteria });
            setFilteredDataDB(response.data);
            console.log('response.data DB: \n', response.data);
        }
        catch {
            toast.error('Oops something went wrong!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 700,
            });
        }
        finally {
            setLoading(false);
        }
    };



    const handleSaveDebit = async () => {

        const newErrors = {};

        // // Check for required fields
        if (!InvoiceCancelHdr.documentNo) {
            newErrors.documentNo = 'CreditNoteNo is required.';
        }
        if (!InvoiceCancelHdr.documentDate) {
            newErrors.documentDate = 'CreditNote Date is required.';
        }
        if (!InvoiceCancelHdr.documentAmt || parseFloat(InvoiceCancelHdr.documentAmt) <= 0) {
            newErrors.documentAmt = 'Credit Note Amount is required';
        }
        if (!InvoiceCancelHdr.partyId) {
            newErrors.partyId = 'party is required';
        }

        setErrors(newErrors);
        // If there are errors, return early
        if (Object.keys(newErrors).length > 0) {
            console.error(newErrors);

            toast.error('Oops Something went wrong!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1000,
            });
            return;
        }

        setLoading(true);
        try {
            const response = await InviceService.saveDebitNote(companyid, branchId, InvoiceCancelHdr, userId);
            setInvoiceCancelHdr(response.data);
            setModalOp('U');
            await handleSearchDB(searchCriteriaDB);
            // Handle successful response
            console.log('Debit Note saved successfully \n', response.data);
        } catch (error) {
            // Handle the error response, e.g., show an error toast
            const errorMessage = error.response ? error.response.data : "An error occurred during saving data.";
            const contentWidth = errorMessage.length * 12;
            toast.error(errorMessage, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                style: { width: `${contentWidth}px` },
            });
        } finally {
            setLoading(false);
        }
    }

    const [isOpenDbModel, setIsOpenDbModel] = useState(false);
    const [modalOp, setModalOp] = useState('');

    const handleAddDebitNote = (operation) => {
        setModalOp(operation)
        setIsOpenDbModel(true);
    };



    const closeDbModel = async () => {
        setIsOpenDbModel(false);
        setModalOp("");
        setInvoiceCancelHdr(initialInvoiceCancelHdr);
        setSelectedPartyDBIn(null);
    }



    const clearDebitNote = () => {
        setInvoiceCancelHdr(initialInvoiceCancelHdr);
        setSelectedPartyDBIn(null);
        setModalOp("");
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (errors[name]) {
            const { [name]: _, ...rest } = errors; // Remove the specific error
            setErrors(rest);
        }
        // Handle specific validation for documentAmt
        if (name === 'documentAmt') {
            // Regular expression to validate the number format (up to two decimal places)
            const regex = /^\d+(\.\d{0,2})?$/;

            // If the input value is valid, update the state
            if (value === '' || regex.test(value)) {
                setInvoiceCancelHdr((prev) => ({
                    ...prev,
                    [name]: value,
                }));
            }
        } else {
            // For all other fields, simply update the state
            setInvoiceCancelHdr((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };

    return (
        <>
            {loading && (
                <div style={styles.overlay}>
                    <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
                </div>
            )}


            <Container>
                <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '4%', paddingRight: '-50px' }} > <FontAwesomeIcon
                    icon={faMoneyBillAlt}
                    style={{
                        marginRight: '8px',
                        color: 'black', // Set the color to golden
                    }}
                />Credit Note & Invoice Cancel</h5>

                <Card>
                    <CardBody>
                        <Tabs
                            activeKey={activeTab}
                            onSelect={handleSelect}
                            transition={false}
                            id="noanim-tab-example"
                            className="mb-3"
                        >
                            <Tab eventKey="C" title="Credit Note">
                                <div>
                                    <CardBody style={{ paddingBottom: '-5px' }} >

                                        <div className="text-end" style={{ margin: 0, padding: 0 }}>
                                            <button
                                                className="btn btn-outline-success"
                                                onClick={handleAdd}
                                                style={{ marginRight: 5, fontWeight: 'bold' }}
                                            >
                                                <FontAwesomeIcon icon={faMoneyBill} style={{ marginRight: '5px' }} />
                                                Add CreditNote
                                            </button>
                                        </div>

                                        <hr />


                                        <Row>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label className="forlabel" for="branchId">Date From</Label>
                                                    <div> {/* Wrap in an input group */}

                                                        <DatePicker
                                                            selected={searchCriteria.startDate ? new Date(searchCriteria.startDate) : null}
                                                            wrapperClassName="custom-react-datepicker-wrapper"
                                                            onChange={(date) => {
                                                                if (date) {
                                                                    // Set the time to 12:00 AM in UTC time
                                                                    date.setHours(12, 0, 0, 0);
                                                                    // Convert the selected date and time to ISO format
                                                                    const formattedDate = date.toISOString();
                                                                    setSearchCriteria({ ...searchCriteria, startDate: formattedDate });
                                                                } else {
                                                                    setSearchCriteria({ ...searchCriteria, startDate: null });
                                                                }
                                                            }}
                                                            dateFormat="dd/MM/yyyy" // Specify the combined format
                                                            className="form-control border-right-0 inputField"
                                                            customInput={<input style={{ width: '100%' }} />}

                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>


                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label className="forlabel" for="branchId">Date To</Label>
                                                    <div> {/* Wrap in an input group */}
                                                        <DatePicker
                                                            selected={searchCriteria.endDate ? new Date(searchCriteria.endDate) : null} // Use endDate from searchCriteria if it's defined
                                                            wrapperClassName="custom-react-datepicker-wrapper"
                                                            onChange={(date) => {
                                                                if (date) {
                                                                    // Set the time to 12:00 PM (noon)
                                                                    date.setHours(12, 0, 0, 0);
                                                                    const formattedDate = date.toISOString(); // Convert to ISO format
                                                                    setSearchCriteria({ ...searchCriteria, endDate: formattedDate });
                                                                } else {
                                                                    setSearchCriteria({ ...searchCriteria, endDate: null });
                                                                }
                                                            }}
                                                            dateFormat="dd/MM/yyyy"
                                                            className="form-control border-right-0 inputField"
                                                            customInput={<input style={{ width: '100%' }} />}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col md={5}>
                                                <FormGroup>
                                                    <Label className="forlabel" for="branchId">Select Party</Label>
                                                    <Select
                                                        options={parties}
                                                        placeholder="Select a party"
                                                        isClearable
                                                        value={selectedParty}
                                                        onChange={handlePartyChange}
                                                        styles={{
                                                            control: (provided, state) => ({
                                                                ...provided,
                                                                border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                                                                boxShadow: 'none',
                                                                '&:hover': {
                                                                    border: '1px solid #ccc'
                                                                }
                                                            }),
                                                            indicatorSeparator: () => ({
                                                                display: 'none'
                                                            }),
                                                            dropdownIndicator: () => ({
                                                                display: 'none'
                                                            }),
                                                            placeholder: (provided) => ({
                                                                ...provided,
                                                                display: 'flex',
                                                                color: 'gray',
                                                            }),
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md={3}>
                                                <div className="text-center" style={{ marginTop: '29px' }}>

                                                    <Button
                                                        color="primary"
                                                        outline
                                                        onClick={() => handleSearch(searchCriteria)}
                                                        className="mr-2"
                                                        style={{ marginRight: '25px', fontWeight: 'bold' }}
                                                    >
                                                        <FontAwesomeIcon icon={faSearch} style={{ marginRight: '5px' }} />
                                                        SEARCH
                                                    </Button>
                                                    <Button
                                                        color="danger"
                                                        outline
                                                        onClick={(e) => handleReset('CN')}
                                                        className="mr-2"
                                                        style={{ marginRight: '5px', fontWeight: 'bold' }}
                                                    >
                                                        <FontAwesomeIcon icon={faSyncAlt} style={{ marginRight: '5px' }} />
                                                        RESET
                                                    </Button>
                                                </div>

                                            </Col>


                                        </Row>



                                        <hr />


                                        {filteredData && filteredData.length > 0 && (

                                            <>
                                                <Row>
                                                    <Col className="text-end mt-1">
                                                        <Button
                                                            color="success"
                                                            outline
                                                            className="mr-2"
                                                            style={{ fontWeight: 'bold' }}
                                                            onClick={() => handleXLSdownLoad('credit', searchCriteria)}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faFileExcel}
                                                                style={{ marginRight: "5px" }}
                                                            />
                                                            XLS
                                                        </Button>
                                                    </Col>
                                                </Row>

                                                <div className="table-responsive mt-3">
                                                    <Table className="table table-bordered table-hover" style={{ border: '2px solid black' }}>
                                                        <thead className="thead-dark bg-dark"  >
                                                            <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px' }}>
                                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font', height: '60px' }}>Sr No</th>
                                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>CreditNote No</th>
                                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Date</th>
                                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Party</th>
                                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>CreditNote Amount</th>
                                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Remarks</th>
                                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {currentItems.map((item, index) => (
                                                                <tr key={item.id} className='text-center'>
                                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{((currentPage - 1) * itemsPerPage) + index + 1}</td>
                                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.documentNo}</td>
                                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{formatDateTimeMonth(item.documentDate)}</td>
                                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.partyName}</td>
                                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.documentAmt}</td>
                                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.remarks}</td>
                                                                    <td className="text-center">
                                                                        <button
                                                                            className="dropdown-item"
                                                                            onClick={() => handleViewClick(item.documentNo, item.partyId, 'E')}
                                                                        ><FontAwesomeIcon on icon={faEye} style={{ marginRight: '5px' }} />

                                                                        </button>
                                                                    </td>

                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>



                                                    <Pagination style={{ display: 'flex', justifyContent: 'center', color: 'gray' }}>
                                                        <Pagination.First onClick={() => handlePageChange(1)} />
                                                        <Pagination.Prev
                                                            onClick={() => handlePageChange(currentPage - 1)}
                                                            disabled={currentPage === 1}
                                                        />
                                                        <Pagination.Ellipsis />

                                                        {displayPages().map((pageNumber) => (
                                                            <Pagination.Item
                                                                key={pageNumber}
                                                                active={pageNumber === currentPage}
                                                                onClick={() => handlePageChange(pageNumber)}
                                                            >
                                                                {pageNumber}
                                                            </Pagination.Item>
                                                        ))}

                                                        <Pagination.Ellipsis />
                                                        <Pagination.Next
                                                            onClick={() => handlePageChange(currentPage + 1)}
                                                            disabled={currentPage === totalPages}
                                                        />
                                                        <Pagination.Last onClick={() => handlePageChange(totalPages)} />
                                                    </Pagination>
                                                </div>
                                            </>
                                        )}
                                    </CardBody>
                                </div>
                            </Tab>


                            <Tab eventKey="D" title="Debit Note">
                                <div>
                                    <CardBody style={{ paddingBottom: '-5px' }} >

                                        <div className="text-end" style={{ margin: 0, padding: 0 }}>
                                            <button
                                                className="btn btn-outline-success"
                                                onClick={(e) => handleAddDebitNote("")}
                                                style={{ marginRight: 5, fontWeight: 'bold' }}
                                            >
                                                <FontAwesomeIcon icon={faMoneyBill} style={{ marginRight: '5px' }} />
                                                Add DebitNote
                                            </button>
                                        </div>
                                        <hr />
                                        <Row>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label className="forlabel" for="branchId">Date From</Label>
                                                    <div> {/* Wrap in an input group */}

                                                        <DatePicker
                                                            selected={searchCriteriaDB.startDate ? new Date(searchCriteriaDB.startDate) : null}
                                                            wrapperClassName="custom-react-datepicker-wrapper"
                                                            onChange={(date) => {
                                                                if (date) {
                                                                    // Set the time to 12:00 AM in UTC time
                                                                    date.setHours(12, 0, 0, 0);
                                                                    // Convert the selected date and time to ISO format
                                                                    const formattedDate = date.toISOString();
                                                                    setSearchCriteriaDB({ ...searchCriteriaDB, startDate: formattedDate });
                                                                } else {
                                                                    setSearchCriteriaDB({ ...searchCriteriaDB, startDate: null });
                                                                }
                                                            }}
                                                            dateFormat="dd/MM/yyyy" // Specify the combined format
                                                            className="form-control border-right-0 inputField"
                                                            customInput={<input style={{ width: '100%' }} />}

                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>


                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label className="forlabel" for="branchId">Date To</Label>
                                                    <div> {/* Wrap in an input group */}
                                                        <DatePicker
                                                            selected={searchCriteriaDB.endDate ? new Date(searchCriteriaDB.endDate) : null} // Use endDate from searchCriteria if it's defined
                                                            wrapperClassName="custom-react-datepicker-wrapper"
                                                            onChange={(date) => {
                                                                if (date) {
                                                                    // Set the time to 12:00 PM (noon)
                                                                    date.setHours(12, 0, 0, 0);
                                                                    const formattedDate = date.toISOString(); // Convert to ISO format
                                                                    setSearchCriteriaDB({ ...searchCriteriaDB, endDate: formattedDate });
                                                                } else {
                                                                    setSearchCriteriaDB({ ...searchCriteriaDB, endDate: null });
                                                                }
                                                            }}
                                                            dateFormat="dd/MM/yyyy"
                                                            className="form-control border-right-0 inputField"
                                                            customInput={<input style={{ width: '100%' }} />}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col md={5}>
                                                <FormGroup>
                                                    <Label className="forlabel" for="branchId">Select Party</Label>
                                                    <Select
                                                        options={parties}
                                                        placeholder="Select a party"
                                                        isClearable
                                                        value={selectedPartyDB}
                                                        onChange={handlePartyChangeDB}
                                                        styles={{
                                                            control: (provided, state) => ({
                                                                ...provided,
                                                                border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                                                                boxShadow: 'none',
                                                                '&:hover': {
                                                                    border: '1px solid #ccc'
                                                                }
                                                            }),
                                                            indicatorSeparator: () => ({
                                                                display: 'none'
                                                            }),
                                                            dropdownIndicator: () => ({
                                                                display: 'none'
                                                            }),
                                                            placeholder: (provided) => ({
                                                                ...provided,
                                                                display: 'flex',
                                                                color: 'gray',
                                                            }),
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md={3}>
                                                <div className="text-center" style={{ marginTop: '29px' }}>

                                                    <Button
                                                        color="primary"
                                                        outline
                                                        onClick={() => handleSearchDB(searchCriteriaDB)}
                                                        className="mr-2"
                                                        style={{ marginRight: '25px', fontWeight: 'bold' }}
                                                    >
                                                        <FontAwesomeIcon icon={faSearch} style={{ marginRight: '5px' }} />
                                                        SEARCH
                                                    </Button>
                                                    <Button
                                                        color="danger"
                                                        outline
                                                        onClick={(e) => handleReset('DB')}
                                                        className="mr-2"
                                                        style={{ marginRight: '5px', fontWeight: 'bold' }}
                                                    >
                                                        <FontAwesomeIcon icon={faSyncAlt} style={{ marginRight: '5px' }} />
                                                        RESET
                                                    </Button>
                                                </div>

                                            </Col>


                                        </Row>



                                        <hr />


                                        {filteredDataDB && filteredDataDB.length > 0 && (

                                            <>
                                                <Row>
                                                    <Col className="text-end mt-1">
                                                        <Button
                                                            color="success"
                                                            outline
                                                            className="mr-2"
                                                            style={{ fontWeight: 'bold' }}
                                                            onClick={() => handleXLSdownLoad('debit', searchCriteriaDB)}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faFileExcel}
                                                                style={{ marginRight: "5px" }}
                                                            />
                                                            XLS
                                                        </Button>
                                                    </Col>
                                                </Row>

                                                <div className="table-responsive mt-3">
                                                    <Table className="table table-bordered table-hover" style={{ border: '2px solid black' }}>
                                                        <thead className="thead-dark bg-dark"  >
                                                            <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px' }}>
                                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font', height: '60px' }}>Sr No</th>
                                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>DebitNote No</th>
                                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Date</th>
                                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Party</th>
                                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>DebitNote Amount</th>
                                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Remarks</th>
                                                                {/* <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Action</th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {currentItemsDB.map((item, index) => (
                                                                <tr key={item.id} className='text-center'>
                                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{((currentPage - 1) * itemsPerPage) + index + 1}</td>
                                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.documentNo}</td>
                                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{formatDateTimeMonth(item.documentDate)}</td>
                                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.partyName}</td>
                                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.documentAmt}</td>
                                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item.remarks}</td>
                                                                    {/* <td className="text-center">
                                                                        <button
                                                                            className="dropdown-item"
                                                                            onClick={() => handleViewClick(item.documentNo, item.partyId, 'E')}
                                                                        ><FontAwesomeIcon on icon={faEye} style={{ marginRight: '5px' }} />

                                                                        </button>
                                                                    </td> */}

                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>



                                                    <Pagination style={{ display: 'flex', justifyContent: 'center', color: 'gray' }}>
                                                        <Pagination.First onClick={() => handlePageChangeDB(1)} />
                                                        <Pagination.Prev
                                                            onClick={() => handlePageChangeDB(currentPageDB - 1)}
                                                            disabled={currentPageDB === 1}
                                                        />
                                                        <Pagination.Ellipsis />

                                                        {displayPagesDB().map((pageNumber) => (
                                                            <Pagination.Item
                                                                key={pageNumber}
                                                                active={pageNumber === currentPageDB}
                                                                onClick={() => handlePageChangeDB(pageNumber)}
                                                            >
                                                                {pageNumber}
                                                            </Pagination.Item>
                                                        ))}

                                                        <Pagination.Ellipsis />
                                                        <Pagination.Next
                                                            onClick={() => handlePageChangeDB(currentPageDB + 1)}
                                                            disabled={currentPageDB === totalPagesDB}
                                                        />
                                                        <Pagination.Last onClick={() => handlePageChangeDB(totalPagesDB)} />
                                                    </Pagination>
                                                </div>
                                            </>
                                        )}
                                    </CardBody>
                                </div>
                            </Tab>


                            <Tab eventKey="x" title="Invoice Cancel">
                                <div>
                                    <CardBody style={{ paddingBottom: '-5px' }} >

                                        <div className="text-end" style={{ margin: 0, padding: 0 }}>
                                            <button
                                                className="btn btn-outline-success"
                                                onClick={handleAddCancel}
                                                style={{ marginRight: 5, fontWeight: 'bold' }}
                                            >
                                                <FontAwesomeIcon icon={faFileInvoice} style={{ marginRight: '5px' }} />
                                                Add Invoice
                                            </button>
                                        </div>
                                        <hr />
                                        <Row>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label className="forlabel" for="branchId">Date From</Label>
                                                    <div> {/* Wrap in an input group */}

                                                        <DatePicker
                                                            selected={searchCriteriaIC.startDate ? new Date(searchCriteriaIC.startDate) : null}
                                                            wrapperClassName="custom-react-datepicker-wrapper"
                                                            onChange={(date) => {
                                                                if (date) {
                                                                    // Set the time to 12:00 AM in UTC time
                                                                    date.setHours(12, 0, 0, 0);
                                                                    // Convert the selected date and time to ISO format
                                                                    const formattedDate = date.toISOString();
                                                                    setSearchCriteriaIC({ ...searchCriteriaIC, startDate: formattedDate });
                                                                } else {
                                                                    setSearchCriteriaIC({ ...searchCriteriaIC, startDate: null });
                                                                }
                                                            }}
                                                            dateFormat="dd/MM/yyyy" // Specify the combined format
                                                            className="form-control border-right-0 inputField"
                                                            customInput={<input style={{ width: '100%' }} />}

                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>


                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label className="forlabel" for="branchId">Date To</Label>
                                                    <div> {/* Wrap in an input group */}
                                                        <DatePicker
                                                            selected={searchCriteriaIC.endDate ? new Date(searchCriteriaIC.endDate) : null} // Use endDate from searchCriteria if it's defined
                                                            wrapperClassName="custom-react-datepicker-wrapper"
                                                            onChange={(date) => {
                                                                if (date) {
                                                                    // Set the time to 12:00 PM (noon)
                                                                    date.setHours(12, 0, 0, 0);
                                                                    const formattedDate = date.toISOString(); // Convert to ISO format
                                                                    setSearchCriteriaIC({ ...searchCriteriaIC, endDate: formattedDate });
                                                                } else {
                                                                    setSearchCriteriaIC({ ...searchCriteriaIC, endDate: null });
                                                                }
                                                            }}
                                                            dateFormat="dd/MM/yyyy"
                                                            className="form-control border-right-0 inputField"
                                                            customInput={<input style={{ width: '100%' }} />}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col md={5}>
                                                <FormGroup>
                                                    <Label className="forlabel" for="branchId">Select Party</Label>
                                                    <Select
                                                        options={parties}
                                                        placeholder="Select a party"
                                                        isClearable
                                                        value={selectedPartyIC}
                                                        onChange={handlePartyChangeIC}
                                                        styles={{
                                                            control: (provided, state) => ({
                                                                ...provided,
                                                                border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                                                                boxShadow: 'none',
                                                                '&:hover': {
                                                                    border: '1px solid #ccc'
                                                                }
                                                            }),
                                                            indicatorSeparator: () => ({
                                                                display: 'none'
                                                            }),
                                                            dropdownIndicator: () => ({
                                                                display: 'none'
                                                            }),
                                                            placeholder: (provided) => ({
                                                                ...provided,
                                                                display: 'flex',
                                                                color: 'gray',
                                                            }),
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md={3}>
                                                <div className="text-center" style={{ marginTop: '29px' }}>

                                                    <Button
                                                        color="primary"
                                                        outline
                                                        onClick={() => handleSearchIC(searchCriteriaIC)}
                                                        className="mr-2"
                                                        style={{ marginRight: '25px', fontWeight: 'bold' }}
                                                    >
                                                        <FontAwesomeIcon icon={faSearch} style={{ marginRight: '5px' }} />
                                                        SEARCH
                                                    </Button>
                                                    <Button
                                                        color="danger"
                                                        outline
                                                        onClick={(e) => handleReset('IC')}
                                                        className="mr-2"
                                                        style={{ marginRight: '5px', fontWeight: 'bold' }}
                                                    >
                                                        <FontAwesomeIcon icon={faSyncAlt} style={{ marginRight: '5px' }} />
                                                        RESET
                                                    </Button>
                                                </div>

                                            </Col>


                                        </Row>



                                        <hr />


                                        {filteredDataIC && filteredDataIC.length > 0 && (
                                            <>
                                                <Row>
                                                    <Col className="text-end mt-1">
                                                        <Button
                                                            color="success"
                                                            outline
                                                            className="mr-2"
                                                            style={{ fontWeight: 'bold' }}
                                                            onClick={() => handleXLSdownLoad('cancel', searchCriteriaIC)}                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faFileExcel}
                                                                style={{ marginRight: "5px" }}
                                                            />
                                                            XLS
                                                        </Button>
                                                    </Col>
                                                </Row>



                                                <div className="table-responsive mt-3">

                                                    <Table className="table table-bordered table-hover" style={{ border: '2px solid black' }}>
                                                        <thead className="thead-dark bg-dark"  >
                                                            <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px' }}>
                                                                <th style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font', height: '60px' }}>Sr No</th>
                                                                <th style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Invoice Number</th>
                                                                <th style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Invoice Date</th>
                                                                <th style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Party Name</th>
                                                                <th style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Bill Amount</th>
                                                                <th style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Cgst Amount</th>
                                                                <th style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Sgst Amount</th>
                                                                <th style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Igst Amount</th>
                                                                <th style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Total Invoice Amount</th>
                                                                <th style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Remarks</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {currentItemsIC.map((invoice, index) => (
                                                                <tr key={invoice.id} className='text-center'>
                                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{((currentPageIC - 1) * itemsPerPageIC) + index + 1}</td>

                                                                    <td>{invoice.invoiceNo}</td>
                                                                    <td>{formatDateTimeMonth(invoice.invoiceDate)}</td>
                                                                    <td>{invoice.partyName}</td>
                                                                    <td>{invoice.billAmount}</td>
                                                                    <td>{invoice.cgstAmount}</td>
                                                                    <td>{invoice.sgstAmount}</td>
                                                                    <td>{invoice.igstAmount}</td>
                                                                    <td>{invoice.totalInvoiceAmount}</td>
                                                                    <td>{invoice.remarks}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>



                                                    <Pagination style={{ display: 'flex', justifyContent: 'center', color: 'gray' }}>
                                                        <Pagination.First onClick={() => handlePageChangeIC(1)} />
                                                        <Pagination.Prev
                                                            onClick={() => handlePageChangeIC(currentPageIC - 1)}
                                                            disabled={currentPageIC === 1}
                                                        />
                                                        <Pagination.Ellipsis />
                                                        {displayPagesIC().map((pageNumber) => (
                                                            <Pagination.Item
                                                                key={pageNumber}
                                                                active={pageNumber === currentPageIC}
                                                                onClick={() => handlePageChangeIC(pageNumber)}
                                                            >
                                                                {pageNumber}
                                                            </Pagination.Item>
                                                        ))}

                                                        <Pagination.Ellipsis />
                                                        <Pagination.Next
                                                            onClick={() => handlePageChangeIC(currentPageIC + 1)}
                                                            disabled={currentPageIC === totalPagesIC}
                                                        />
                                                        <Pagination.Last onClick={() => handlePageChangeIC(totalPagesIC)} />
                                                    </Pagination>
                                                </div>
                                            </>
                                        )}
                                    </CardBody>
                                </div>
                            </Tab>
                        </Tabs>











                        {/* Model for add debit note */}






                        {/* <Modal show={isOpenDbModel} onHide={closeDbModel} size="lg">

                            <Card style={{ backgroundImage: 'url(https://img.freepik.com/free-vector/gradient-wavy-background_23-2149123392.jpg?t=st=1694859409~exp=1694860009~hmac=b397945a9c2d45405ac64956165f76bd10a0eff99334c52cd4c88d4162aad58e)', backgroundSize: 'cover' }}>
                                <CardBody>
                                    <h5 className="pageHead text-center " style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '4%', paddingRight: '-50px' }} > <FontAwesomeIcon
                                        icon={faUserCircle}
                                        style={{
                                            marginRight: '8px',
                                            color: 'black', // Set the color to golden
                                        }}
                                    />Add Debit Note</h5>


                                    <button
                                        className="close-button"
                                        onClick={closeDbModel}
                                    >
                                        <FontAwesomeIcon
                                            icon={faTimes}
                                        />
                                    </button>
                                    <hr /> */}
                        <Modal Modal isOpen={isOpenDbModel} onClose={closeDbModel} toggle={closeDbModel} style={{ maxWidth: '1000px', fontSize: 14, wioverflow: '-moz-hidden-unscrollable' }}>


                            <ModalHeader toggle={closeDbModel} style={{
                                backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font', textAlign: 'center', background: '#26a69a',
                                boxShadow: '0px 5px 10px rgba(0, 77, 64, 0.3)',
                                border: '1px solid rgba(0, 0, 0, 0.3)',
                                borderRadius: '0',
                                backgroundImage: 'radial-gradient( circle farthest-corner at 48.4% 47.5%,  rgba(122,183,255,1) 0%, rgba(21,83,161,1) 90% )',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                            }} >

                                <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', color: 'white' }} > <FontAwesomeIcon
                                    icon={faUserCircle}
                                    style={{
                                        marginRight: '8px',
                                        color: 'white',
                                    }}
                                />Add Debit Note</h5>

                            </ModalHeader>
                            <ModalBody style={{ backgroundImage: 'url(https://img.freepik.com/free-vector/gradient-wavy-background_23-2149123392.jpg?t=st=1694859409~exp=1694860009~hmac=b397945a9c2d45405ac64956165f76bd10a0eff99334c52cd4c88d4162aad58e)', backgroundSize: 'cover' }} >

                                <Row>


                                    <Col md={4}>
                                        <FormGroup>
                                            <Label className="forlabel" for="branchId">Select Party</Label><span className="error-message">*</span>
                                            <Select
                                                options={parties}
                                                value={selectedPartyDBIn}
                                                onChange={handlePartyChangeDBIn}
                                                className={errors.partyId ? 'error-border' : ''}
                                                isClearable
                                                isDisabled={modalOp}
                                                id={modalOp ? 'service' : ''}
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                                                        boxShadow: 'none',
                                                        '&:hover': {
                                                            border: '1px solid #ccc'
                                                        }
                                                    }),
                                                    indicatorSeparator: () => ({
                                                        display: 'none'
                                                    }),
                                                    dropdownIndicator: () => ({
                                                        display: 'none'
                                                    })
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>


                                    <Col md={8}>


                                        <Row>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label className="forlabel" for="branchId">DebitNote No.</Label><span className="error-message">*</span>
                                                    <Input
                                                        type="documentNo"
                                                        name="documentNo"
                                                        id={modalOp ? 'service' : ''}
                                                        readOnly={modalOp}
                                                        value={InvoiceCancelHdr.documentNo}
                                                        onChange={handleChange}
                                                        maxLength={20}
                                                        className={`form-control ${errors.documentNo ? 'error-border' : ''}`}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label className="forlabel" for="branchId">DebitNote Date</Label><span className="error-message">*</span>
                                                    {/* <DatePicker
                                                        selected={InvoiceCancelHdr.documentDate}
                                                        wrapperClassName="custom-react-datepicker-wrapper"
                                                        value={InvoiceCancelHdr.documentDate}
                                                        onChange={(date) => {
                                                            if (date) {
                                                                setErrors(prevErrors => ({
                                                                    ...prevErrors,
                                                                    documentDate: ''
                                                                }));
                                                                // Set the time to 12:00 PM (noon)
                                                                date.setHours(12, 0, 0, 0);
                                                                const formattedDate = date.toISOString(); // Convert to ISO format
                                                                setInvoiceCancelHdr({ ...InvoiceCancelHdr, documentDate: formattedDate });
                                                            } else {
                                                                setInvoiceCancelHdr({ ...InvoiceCancelHdr, documentDate: null });
                                                            }
                                                        }}
                                                        dateFormat="dd/MM/yyyy"
                                                        className={`form-control ${errors.documentDate ? 'error-border' : ''}`}
                                                        id={modalOp ? 'service' : ''}
                                                        disabled={modalOp}
                                                    /> */}


                                                    <DatePicker
                                                        selected={InvoiceCancelHdr.documentDate ? new Date(InvoiceCancelHdr.documentDate) : null} // Convert to Date object
                                                        wrapperClassName="custom-react-datepicker-wrapper"
                                                        onChange={(date) => {
                                                            if (date) {
                                                                // Clear previous errors
                                                                setErrors(prevErrors => ({
                                                                    ...prevErrors,
                                                                    documentDate: ''
                                                                }));

                                                                // Create a new date instance and set hours to noon
                                                                const updatedDate = new Date(date);
                                                                updatedDate.setHours(12, 0, 0, 0); // Set to 12:00 PM

                                                                // Format the date to ISO string
                                                                const formattedDate = updatedDate.toISOString();
                                                                setInvoiceCancelHdr({ ...InvoiceCancelHdr, documentDate: formattedDate });
                                                            } else {
                                                                setInvoiceCancelHdr({ ...InvoiceCancelHdr, documentDate: null });
                                                            }
                                                        }}
                                                        dateFormat="dd/MM/yyyy"
                                                        className={`form-control ${errors.documentDate ? 'error-border' : ''}`}
                                                        id={modalOp ? 'service' : ''}
                                                        disabled={modalOp}
                                                    />



                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label className="forlabel" for="branchId">DebitNote Amount</Label><span className="error-message">*</span>
                                                    <Input
                                                        type="documentAmt"
                                                        name="documentAmt"
                                                        id={modalOp ? 'service' : ''}
                                                        readOnly={modalOp}
                                                        onChange={handleChange}
                                                        maxLength={10}
                                                        value={InvoiceCancelHdr.documentAmt}
                                                        className={`form-control ${errors.documentAmt ? 'error-border' : ''}`}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label className="forlabel" for="branchId">Remarks</Label>
                                            <textarea
                                                name="remarks"
                                                rows="2"
                                                value={InvoiceCancelHdr.remarks}
                                                className="inputField form-control"
                                                maxLength={250}
                                                onChange={handleChange}
                                                readOnly={modalOp}
                                                id={modalOp ? 'service' : ''}
                                            ></textarea>
                                        </FormGroup>
                                    </Col>
                                </Row>



                                <div className="text-center">
                                    {!modalOp && (
                                        <Button
                                            color="success"
                                            outline
                                            disabled={!selectedPartyDBIn}
                                            onClick={handleSaveDebit} className="mr-2"
                                            style={{ marginTop: '15px', fontWeight: 'bold' }}
                                        >
                                            <FontAwesomeIcon icon={faSave} style={{ marginRight: '5px' }} />
                                            SAVE
                                        </Button>
                                    )}


                                    <Button
                                        color="danger"
                                        outline
                                        style={{ marginTop: '15px', marginLeft: '10px', fontWeight: 'bold' }}
                                        onClick={clearDebitNote}
                                    >
                                        <FontAwesomeIcon icon={faSyncAlt} style={{ marginRight: '5px' }} />
                                        Reset
                                    </Button>

                                </div>
                                {/* </CardBody>
                        </Card> */}
                            </ModalBody>
                        </Modal >





                    </CardBody>
                </Card >
            </Container >
        </>

    )
}